import axios from "axios";
import {
  SET_MARKET_SPACE,
  GET_MARKET_SPACE,
  CLEAR_ERRORS
} from "./types";

export const fnGetLookupAllMarketSpace = (id) => dispatch => {
  dispatch(clearErrors());

  axios
    .get("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-lookup-admin-market-space-get-grid")
    .then(res => {
      const MarketSpaceListData = res.data.data;
      const MarketSpaceList = []
      for (let i = 0; i < MarketSpaceListData.length; i++) {
        MarketSpaceList.push(MarketSpaceListData[i])
      }
      dispatch(setGetAllMarketSpace(MarketSpaceList));
    })
    .catch(err => {
      console.log(err)
    }
    );
};

export const fnUpdateLookupAllMarketSpace = (userData) => dispatch => {
  dispatch(clearErrors());
  axios
    .post("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-lookup-admin-market-space-post-update", userData)
    .then(res => {
      console.log(res.data.message)
    })
    .catch(err => { console.log(err) });
};


export const fnInsertLookupAllMarketSpace = (userData) => dispatch => {
  dispatch(clearErrors());
  axios
    .post("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-lookup-admin-market-space-post-insert", userData)
    .then(res => {
      console.log(res.data.message)
    })
    .catch(err => { console.log(err) });
};
export const fnDeleteLookupAllMarketSpace = (userData) => dispatch => {
  dispatch(clearErrors());
  
  axios
    .delete("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-lookup-admin-market-space-del?admin_lookup_market_space_id="+ userData)
    .then(res => {
      console.log(res.data.message)
    })
    .catch(err => { console.log(err) });
};
//GetAllProfile
export const setGetAllMarketSpace = (MarketSpaceList) => {
  return {
    type: GET_MARKET_SPACE,
    payload: MarketSpaceList
  };
};

// clear errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS
  };
};

