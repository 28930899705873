import {
  SET_MARKET_SPACE,
  ROLES_LOADING,
  GET_MARKET_SPACE
} from "../actions/types";

const initialState = {
  role: {},
  adminLookupMarketSpace: null,
  loading: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ROLES_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_MARKET_SPACE:
      return {
        ...state,
        adminLookupMarketSpace: action.payload,
        loading: true
      };

    default:
      return state;
  }
}
