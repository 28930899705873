import React from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Grid, GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
import { orderBy } from '@progress/kendo-data-query';
import { MyCommandCell } from './actioneditbutton.js';
import { filterBy } from '@progress/kendo-data-query';
import { 
    fnGetLookupAllSchoolpay,
    fnInsertLookupAllSchoolpay,
    fnUpdateLookupAllSchoolpay,
    fnDeleteLookupAllSchoolpay
} from "../../actions/adminLookupSchoolpay";
import Spinner from "../spinner/loading";

class LookupEducationSchoolPay extends React.Component {
  editField = "inEdit";
  CommandCell;


  constructor(props) {
    super(props);

    this.state = {
      data: [],
      action: "",
      filter: undefined,
      skip: 0,
      take: 10,
      loading: true,
    };
    this.CommandCell = MyCommandCell({
      edit: this.enterEdit,
      remove: this.remove,

      add: this.add,
      discard: this.discard,

      update: this.update,
      cancel: this.cancel,

      editField: this.editField
    });

  }
  filterChange = (event) => {
    this.setState({
        data: filterBy(this.props.adminLookupSchoolPay.adminLookupSchoolPay, event.filter),
        filter: event.filter
    });
}

  enterEdit = (dataItem) => {
    this.state.action = "action"

    this.setState({
      data: this.state.data.map(item =>
        item._id === dataItem._id ?
          { ...item, inEdit: true } : item
      )
    });
  }

  remove = (dataItem) => {
    this.state.action = "action"
    const data = [...this.state.data];
    this.props.    fnDeleteLookupAllSchoolpay(dataItem["_id"])

    this.removeItem(data, dataItem);
   this.removeItem(this.props.adminLookupSchoolPay.adminLookupSchoolPay, dataItem);

    this.setState({ data });
  }

  add = (dataItem) => {
    this.state.action = "actionssss"
    dataItem.inEdit = undefined;

    const insertSchoolpayData = {
      school_name: dataItem["school_name"],
      STEM: dataItem["STEM"],
      early_career_median_pay: dataItem["early_career_median_pay"],
      degree_type: dataItem["degree_type"],
      mid_career_median_pay: dataItem["mid_career_median_pay"],
      state: dataItem["state"],
      relative_capital_score: dataItem["relative_capital_score"],
      source: dataItem["source"],

    }
    this.props.fnInsertLookupAllSchoolpay(insertSchoolpayData)
    this.props.fnGetLookupAllSchoolpay();
    dataItem._id = this.generateId(this.props.adminLookupSchoolPay.adminLookupSchoolPay);

    this.props.adminLookupSchoolPay.adminLookupSchoolPay.unshift(dataItem);
    this.setState({
      data: [...this.state.data]
    });
  }

  discard = (dataItem) => {
    this.state.action = "action"
    const data = [...this.state.data];
    this.removeItem(data, dataItem);
    this.setState({ data });
  }

  update = (dataItem) => {
    this.state.action = "action"
    const data = [...this.state.data];
    const updatedItem = { ...dataItem, inEdit: undefined };

    const updateSchoolpayData = {
      education_school_pay_id: dataItem["_id"],
      school_name: dataItem["school_name"],
      STEM: dataItem["STEM"],
      early_career_median_pay: dataItem["early_career_median_pay"],
      degree_type: dataItem["degree_type"],
      mid_career_median_pay: dataItem["mid_career_median_pay"],
      state: dataItem["state"],
      relative_capital_score: dataItem["relative_capital_score"],
      source: dataItem["source"]

    }

    this.props.fnUpdateLookupAllSchoolpay(updateSchoolpayData)
    this.updateItem(data, updatedItem);
    this.updateItem(this.props.adminLookupSchoolPay.adminLookupSchoolPay, updatedItem);
    this.setState({ data });
  }

  

  updateItem = (data, item) => {
    this.state.action = "action"
    let index = data.findIndex(p => p === item || (item._id && p._id === item._id));
    if (index >= 0) {
      data[index] = { ...item };
    }
  }
  cancel = (dataItem) => {
    this.state.action = "action"
    const originalItem = this.props.adminLookupSchoolPay.adminLookupSchoolPay.find(p => p._id === dataItem._id);
    const data = this.state.data.map(item => item._id === originalItem._id ? originalItem : item);

    this.setState({ data });
  }

  itemChange = (event) => {
    this.state.action = "action"
    const data = this.state.data.map(item =>
      item._id === event.dataItem._id ?
        { ...item, [event.field]: event.value } : item
    );
    this.setState({ data });
  }

  addNew = () => {
    const newDataItem = { inEdit: true, active: true };
    var daaa = [newDataItem, ...this.state.data]
    this.state.action = "action"
    this.setState({
      data: [newDataItem, ...this.state.data]
    });
  }

  cancelCurrentChanges = () => {
    this.state.action = "action"
    this.setState({ data: this.props.adminLookupSchoolPay.adminLookupSchoolPay });
  }


    pageChange = (event) => {
        this.setState({
          skip: event.page.skip,
          take: event.page.take
        });
      }

    componentDidMount() { 
    
        if (this.props.location.pathname === "/home/lookup-school") {
          this.props.fnGetLookupAllSchoolpay();
        }
        
      }

    render() 
    {
      if (this.state.action === "action") {
        this.state.data = this.state.data
      } else {  
        if (this.state.filter != undefined) {
          this.state.data = this.state.data
              } else { 
                if (this.props.adminLookupSchoolPay.adminLookupSchoolPay != null) {
                  const SchoolpayList = this.props.adminLookupSchoolPay.adminLookupSchoolPay
          
                  const SchoolpayData  = []
                  for (let i = 0; i <SchoolpayList.length; i++) {
                    if (SchoolpayList[i]['active'] === 1) {
                      SchoolpayList[i]['active'] = true
                    }
                    if (SchoolpayList[i]['active'] === 0) {
                      SchoolpayList[i]['active'] = false
                    }
                    SchoolpayData .push(SchoolpayList[i])
                  }
                  this.state.data = SchoolpayList
                  this.state.loading = false
                } else {
                  this.state.data = this.state.data
                }    
              }
            }
      const { data } = this.state;
      const hasEditedItem = data.some(p => p.inEdit);
      console.log("this.props")
      console.log(this.props)
      console.log("this.props")
  
            
        return (
          <div className="container-fluid role-main-div">
          {this.state.loading === true ? <Spinner /> : null}
          <Grid
            className="col-md-12 col-xs-12 col-lg-12 col-sm-12"
            // style={{ height: '420px' }}
            data={data}
            data={data.slice(this.state.skip, this.state.take + this.state.skip)}
            skip={this.state.skip}
            take={this.state.take}
            total={data.length}
            pageable={true}
            onPageChange={this.pageChange}
            onItemChange={this.itemChange}
            editField={this.editField}
            filterable={true}
            filter={this.state.filter}
            onFilterChange={this.filterChange}
          >
            <GridToolbar>
              <div className="row">
                <div className="col-md-6 col-xs-12 col-lg-6 col-sm-12" style={{ fontSize: "23px", color: "black", marginTop: "-10px" }}>             School</div>
                <div className="col-md-6 col-xs-12 col-lg-6 col-sm-12">
                  <button
                    title="Add new"
                    className="k-button k-danger"
                    onClick={this.addNew}
                    style={{ float: "right", border: "1px solid #3A4158" }}
                  >
                    <span className="k-icon k-i-plus"></span>
                  </button>
                  {hasEditedItem && (
                    <button
                      title="Cancel current changes"
                      className="k-button"
                      onClick={this.cancelCurrentChanges}
                      style={{ float: "right" }}
                    >
                      Cancel current changes
                          </button>
                  )}
                </div>
  
  
              </div>
              </GridToolbar>
  
          <Column width="250px" field="school_name"  title="SCHOOL-NAME"  />
          <Column  field="STEM"  filterable={false} title="STEM" />
          <Column field="degree_type" filterable={false} title="DEGREE-TYPE"   />
          <Column field="early_career_median_pay" filterable={false} title="EARLY CAREER MEDIAN PAY "  />
          <Column field="mid_career_median_pay"  filterable={false} title="MID CAREER MEDIAN PAY" />
          <Column field="state" title="STATE"  filterable={false}  />
          <Column field="relative_capital_score" title="RELATIVE CAPITAL SCORE" filterable={false}  />
          {/* <Column field="source" title="SOURCE"  filterable={false}  /> */}
          <Column title="ACTION" filterable={false} cell={this.CommandCell} />
        </Grid>
      </div>          
        );
    }
    generateId = data => data.reduce((acc, current) => Math.max(acc, current._id), 0) + 1;

    removeItem(data, item) {
      let index = data.findIndex(p => p === item || item._id && p._id === item._id);
      if (index >= 0) {
        data.splice(index, 1);
  
      }
    }
  
}




LookupEducationSchoolPay.propTypes = {
    auth: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };
    
  const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors,
    adminLookupSchoolPay: state.adminLookupSchoolPay
  });
  
  export default connect(mapStateToProps, 
    { fnGetLookupAllSchoolpay,
      fnInsertLookupAllSchoolpay,
      fnUpdateLookupAllSchoolpay,
      fnDeleteLookupAllSchoolpay
      }
  )(LookupEducationSchoolPay);
  