import React from 'react';
import { GridCell } from '@progress/kendo-react-grid';
import { Link } from "react-router-dom";
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import '@progress/kendo-ui';

export default function MyRoleButtons(editField) {
    let visible= false;
    let url = '';

    function deleteSubmit(id, visible_value){
        url = id;
        visible= visible_value;
    }


    return class extends GridCell {
        render() {
            const EDIT_TAG_URL = "/home/update-lookup-companyform?company_id=" + this.props.dataItem["_id"]
            const DELETE_URL = "/home/delete-lookup-companyform?id="+this.props.dataItem["_id"]

            return !this.props.dataItem[editField]
                ? (
                    <td>
                        <Link type="button"
                            to={EDIT_TAG_URL}
                            title="Edit"
                            className="k-button" >
                            <span className="k-icon k-i-edit .k-i-pencil"></span>
                        </Link>
                        <Link type="button" 
                                title="Delete" 
                                className="k-button" 
                                id={DELETE_URL} 
                                onClick={() => deleteSubmit(DELETE_URL, true)}>
                            <span className="k-icon k-i-delete .k-i-trash"></span>
                          </Link>
                          {visible && <Dialog title={"Please confirm"}>
                         <p style={{ margin: "25px", textAlign: "center" }}>
                             Are you sure you want to continue?
                         </p>
                       <DialogActionsBar>
                         <Link className="k-button" onClick={() => deleteSubmit('', false)}>No</Link>
                         <Link type="submit" className="k-button" to={url}>Yes</Link> 
                       </DialogActionsBar>
                     </Dialog>} 

                    </td>
                )
                : null;
        }
    }
};