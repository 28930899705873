import React from 'react'
import '../../../../css/dashboard.css';
import { connect } from "react-redux";
import 'hammerjs';
import { inputValueAction } from "../../../../actions/dashboard";
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout'
import { NumericTextBox } from '@progress/kendo-react-inputs';
import Progressbar from 'react-progressbar';
export class OfferRightSide extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      totalselected: 0,
      cashselected: 0,
      annualEquitySelected: 0
    }
  }

  totalSelect = (e) => {
    this.setState({ totalselected: e.selected });
  }
  cashSelect = (e) => {
    this.setState({ cashselected: e.selected });
  }
  annualEquitySelect = (e) => {
    this.setState({ annualEquitySelected: e.selected });
  }
  render() {

    if (this.props.candidateOffers.rightSideDashboardData !== null) {

      var offer_base_percentile_value = this.props.candidateOffers.rightSideDashboardData.offer_base_percentile / 100;
      var offer_bonus_percentile_value = this.props.candidateOffers.rightSideDashboardData.offer_bonus_percentile / 100;
      var offer_equity_percentile_value = this.props.candidateOffers.rightSideDashboardData.offer_equity_percentile / 100;
      var offer_base_value = this.props.candidateOffers.rightSideDashboardData.offer_base;
      var offer_bonus_value = this.props.candidateOffers.rightSideDashboardData.offer_bonus;
      var offer_equity_value = this.props.candidateOffers.rightSideDashboardData.offer_equity;
      var offer_cash_value = offer_base_value + offer_bonus_value;
      var offer_total_value = offer_cash_value + offer_equity_value;
      var Total_lower = offer_total_value - (offer_total_value / 2);
      var Total_upper = offer_total_value * 3;
      var Total_mean = (Total_upper + Total_lower) / 2;
      var cash_lower = offer_cash_value - (offer_cash_value / 2);
      var cash_upper = offer_cash_value * 3;
      var cash_mean = (cash_lower + cash_upper) / 2;
      var equity_lower = offer_equity_value - (offer_equity_value / 2);
      var equity_upper = offer_equity_value * 3;
      var equity_mean = (equity_lower + equity_upper) / 2;
    }
    if (offer_bonus_value === undefined) {
      offer_bonus_value = 0;
    }
    if (offer_base_value === undefined) {
      offer_base_value = 0;
    }
    if (offer_total_value === undefined) {
      offer_total_value = 0;
    }
    if (offer_cash_value === undefined) {
      offer_cash_value = 0;
    }
    if (offer_equity_value === undefined) {
      offer_equity_value = 0;
    }
    return (
      <div>
        <div className="offer-right-dashboard-tabstrip div-main-offer-dashboard">
          <TabStrip selected={this.state.totalselected} onSelect={this.totalSelect}>
            <TabStripTab title="SUMMARY">
              <div className="tabstrip-div-main-offer-dashboard">
                <div className="row tabstrip-div-row-offer-dashboard">
                  <div className="col-md-3 col-lg-3 col-xs-3 col-sm-3 tabstrip-div-row-column1-offer-dashboard">
                    Total
                    </div>
                  <div className="col-md-3 col-lg-3 col-xs-3 col-sm-3 tabstrip-div-row-column2-offer-dashboard">
                    <div className="tabstrip-div-row-column2-font-offer-dashboard">
                      0
                      </div>
                    Percentile
                    </div >
                  <div className="col-md-6 col-lg-6 col-xs-6 col-sm-6">
                    <div style={{ fontSize: "23px", textAlign: "right" }}>
                      ${new Intl.NumberFormat({ maximumSignificantDigits: 0 }).format(parseFloat(offer_total_value).toFixed(0) || 0)}

                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12 progress-linear-style">
                    <Progressbar className="progress-bar-color" completed={0} max={10000} style={{ marginBottom: "5px", backgroundColor: "rgb(197,202,233)", height: "5px" }} />
                    <div className="apollo-progress-markers" style={{ display: "flex", flexDirection: "row" }}>
                      <div className="left col-xs-4" style={{ width: "33.33333%" }}>$ {new Intl.NumberFormat({ maximumSignificantDigits: 0 }).format(Math.round(Total_lower) || 0)} </div>
                      <div className="center col-xs-4" style={{ width: "33.33333%" }}>$ {new Intl.NumberFormat({ maximumSignificantDigits: 0 }).format(Math.round(Total_mean) || 0)}</div>
                      <div className="right col-xs-4" style={{ width: "33.33333%" }}>$ {new Intl.NumberFormat({ maximumSignificantDigits: 0 }).format(Math.round(Total_upper || 0))}</div>
                    </div>
                  </div>
                </div>
              </div>
            </TabStripTab>
            <TabStripTab title="DETAILS">
              <div className="percentile-column percentile-center-donut">
                <div className="apollo-percentile-circle-block text-center" style={{ textAlign: "center", color: "white", fontSize: "18px", marginTop: "20px", marginBottom: "10px" }}>
                  Total
                    </div>
              </div>
              <div className="row text-center" style={{ display: "flex", flexDirection: "row", paddingLeft: "0px", width: "100%", marginLeft: "0px" }}>
                <div className="col-md-4 col-sm-4 col-xs-4" >
                  <NumericTextBox
                    name="compensationPercentBase"
                    className="offer-dashboard-input-box"
                    type="number"
                    format="p"
                    spinners={false}
                    onChange={this.percentChange}
                    min={0}
                    max={100}
                    disabled
                    value={offer_base_percentile_value}
                    placeholder="XX%"
                  />
                  <div
                    className="col-md-12 col-sm-4 col-xs-4"
                    onClick={() => this.toggleClass('base')}
                    title="Lock/Unlock"
                    style={{
                      color: "#9dacdd",
                      fontSize: "14px"
                    }}>
                    Base
                      </div>
                </div>
                <div className="col-md-4 col-sm-4 col-xs-4"  >
                  <NumericTextBox
                    name="compensationPercentBonuses"
                    className="offer-dashboard-input-box"
                    type="text"
                    format="p"
                    spinners={false}
                    onChange={this.percentChange}
                    disabled
                    value={offer_bonus_percentile_value}
                    placeholder="XX%"
                    style={{ color: "white", width: "100%", textAlign: "center", fontSize: "24px" }}
                  />
                  <div
                    className="col-md-12 col-sm-4 col-xs-4"
                    onClick={() => this.toggleClass('bonus')}
                    title="Lock/Unlock"
                    style={{
                      color: "#9dacdd",
                      fontSize: "14px"
                    }}>
                    Bonus
                      </div>
                </div>
                <div className="col-md-4 col-sm-4 col-xs-4" style={{ paddingLeft: "5px" }}>
                  <NumericTextBox
                    name="compensationPercentEquity"
                    className="offer-dashboard-input-box"
                    type="text"
                    format="p"
                    spinners={false}
                    onChange={this.percentChange}
                    value={offer_equity_percentile_value}
                    placeholder="XX%"
                    disabled
                    style={{ color: "white", width: "100%", textAlign: "center", fontSize: "24px" }}
                  />
                  <div
                    className="col-md-12 col-sm-4 col-xs-4"
                    onClick={() => this.toggleClass('equity')}
                    title="Lock/Unlock"
                    style={{
                      color: "#9dacdd",
                      fontSize: "14px"
                    }}>
                    Equity
                      </div>
                </div>
              </div>
              <div class="col-md-12 text-center"> <span class="median_label" style={{ border: "1px solid #9dacdd", fontSize: "13px", color: "#9dacdd", marginTop: "30px", display: "block" }}> Median Total Compensation: $0 </span> </div>
            </TabStripTab>
          </TabStrip >
        </div >
        <div className="offer-right-dashboard-tabstrip div-main-offer-dashboard">
          <TabStrip selected={this.state.cashselected} onSelect={this.cashSelect}>
            <TabStripTab title="SUMMARY">
              <div className="tabstrip-div-main-offer-dashboard">
                <div className="row tabstrip-div-row-offer-dashboard">
                  <div className="col-md-3 col-lg-3 col-xs-3 col-sm-3 tabstrip-div-row-column1-offer-dashboard">
                    Cash
                    </div>
                  <div className="col-md-3 col-lg-3 col-xs-3 col-sm-3 tabstrip-div-row-column2-offer-dashboard">
                    <div className="tabstrip-div-row-column2-font-offer-dashboard">
                      0</div>
                    Percentile
                          </div >
                  <div className="col-md-6 col-lg-6 col-xs-6 col-sm-6">
                    <div style={{ fontSize: "23px", textAlign: "right" }}>
                      ${new Intl.NumberFormat({ maximumSignificantDigits: 0 }).format(parseFloat(offer_cash_value).toFixed(0) || 0)}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 progress-linear-style">
                    <Progressbar className="progress-bar-color" completed={0} style={{ marginBottom: "5px", backgroundColor: "rgb(197,202,233)", height: "5px" }} />
                    <div className="apollo-progress-markers" style={{ display: "flex", flexDirection: "row" }}>
                      <div className="left col-xs-4" style={{ width: "33.33333%" }}>$ {new Intl.NumberFormat({ maximumSignificantDigits: 0 }).format(Math.round(cash_lower) || 0)} </div>
                      <div className="center col-xs-4" style={{ width: "33.33333%" }}>$ {new Intl.NumberFormat({ maximumSignificantDigits: 0 }).format(Math.round(cash_mean) || 0)}</div>
                      <div className="right col-xs-4" style={{ width: "33.33333%" }}>$ {new Intl.NumberFormat({ maximumSignificantDigits: 0 }).format(Math.round(cash_upper) || 0)}</div>
                    </div>
                  </div>
                </div>
              </div>
            </TabStripTab>
            <TabStripTab title="DETAILS">
              <div className="percentile-column percentile-center-donut">
                <div className="apollo-percentile-circle-block text-center" style={{ textAlign: "center", color: "white", fontSize: "18px", marginTop: "20px", marginBottom: "10px" }}>
                  Cash
                    </div>
              </div>
              <div className="row text-center" style={{ display: "flex", flexDirection: "row", paddingLeft: "0px" }}>
                <div className="col-md-6 col-sm-6 col-xs-6" >
                  <div
                    className="col-md-12 col-sm-4 col-xs-4"
                    title="Lock/Unlock"
                    style={{
                      color: "#9dacdd",
                      fontSize: "14px"
                    }}>
                    Base
                      </div>
                  <div style={{ color: "white", width: "100%", textAlign: "center", fontSize: "24px", paddingTop: "10px" }}>${new Intl.NumberFormat({ maximumSignificantDigits: 0 }).format(parseFloat(offer_base_value).toFixed(0))}</div>

                </div>
                <div className="col-md-6 col-sm-6 col-xs-6"  >
                  <div
                    className="col-md-12 col-sm-4 col-xs-4"
                    title="Lock/Unlock"
                    style={{
                      color: "#9dacdd",
                      fontSize: "14px"
                    }}>
                    Bonus
                      </div>

                  <div style={{ color: "white", width: "100%", textAlign: "center", fontSize: "24px", paddingTop: "10px" }}>${new Intl.NumberFormat({ maximumSignificantDigits: 0 }).format(parseFloat(offer_bonus_value).toFixed(0))}</div>

                </div>
              </div>
              <div class="col-md-12 text-center"> <span class="median_label" style={{ border: "1px solid #9dacdd", fontSize: "13px", color: "#9dacdd", marginTop: "30px", display: "block" }}> Median Total Compensation: $0 </span> </div>

            </TabStripTab>
          </TabStrip >
        </div >
        <div className="offer-right-dashboard-tabstrip div-main-offer-dashboard">
          <TabStrip selected={this.state.annualEquitySelected} onSelect={this.annualEquitySelect}>
            <TabStripTab title="SUMMARY">
              <div className="tabstrip-div-main-offer-dashboard">
                <div className="row tabstrip-div-row-offer-dashboard">
                  <div className="col-md-3 col-lg-3 col-xs-3 col-sm-3 tabstrip-div-row-column1-offer-dashboard">
                    Equity
                      </div>
                  <div className="col-md-3 col-lg-3 col-xs-3 col-sm-3 tabstrip-div-row-column2-offer-dashboard">
                    <div className="tabstrip-div-row-column2-font-offer-dashboard">
                      0
                    </div>
                    Percentile
                    </div>
                  <div className="col-md-6 col-lg-6 col-xs-6 col-sm-6" >
                    <div style={{ fontSize: "23px", textAlign: "right" }}>
                      ${new Intl.NumberFormat({ maximumSignificantDigits: 0 }).format(parseFloat(offer_equity_value).toFixed(0) || 0)}
                    </div>
                    <div style={{ fontSize: "12px", marginLeft: "10px", marginTop: "-12px", textAlign: "right" }}>Annual Equity</div>

                  </div>
                  <div className="col-sm-12 col-md-12 progress-linear-style">
                    <Progressbar className="progress-bar-color" completed={0} style={{ marginBottom: "5px", backgroundColor: "rgb(197,202,233)", height: "5px" }} />
                    <div className="apollo-progress-markers" style={{ display: "flex", flexDirection: "row" }}>
                      <div className="left col-xs-4" style={{ width: "33.33333%" }}>$ {new Intl.NumberFormat({ maximumSignificantDigits: 0 }).format(Math.round(equity_lower) || 0)} </div>
                      <div className="center col-xs-4" style={{ width: "33.33333%" }}>$ {new Intl.NumberFormat({ maximumSignificantDigits: 0 }).format(Math.round(equity_mean) || 0)}</div>
                      <div className="right col-xs-4" style={{ width: "33.33333%" }}>$ {new Intl.NumberFormat({ maximumSignificantDigits: 0 }).format(Math.round(equity_upper) || 0)}</div>
                    </div>
                  </div>
                </div>
              </div>
            </TabStripTab>
            <TabStripTab title="DETAILS">
              <div className="percentile-column percentile-center-donut">
                <div className="apollo-percentile-circle-block text-center" style={{ textAlign: "center", color: "white", fontSize: "18px", marginTop: "20px", marginBottom: "10px" }}>
                  Equity
                    </div>
              </div>
              <div className="row text-center" style={{ display: "flex", flexDirection: "row", paddingLeft: "0px" }}>
                <div className="col-md-6 col-sm-6 col-xs-6" >
                  <div
                    className="col-md-12 col-sm-4 col-xs-4"
                    onClick={() => this.toggleClass('base')}
                    title="Lock/Unlock"
                    style={{
                      paddingLeft: "0px",
                      color: "#9dacdd",
                      fontSize: "14px"
                    }}>
                    %
                      </div>
                  <NumericTextBox
                    name="compensationPercentBase"
                    className="offer-dashboard-input-box"
                    type="number"
                    format="p"
                    spinners={false}
                    onChange={this.percentChange}
                    min={0}
                    max={100}
                    disabled
                    value={offer_equity_percentile_value}
                    placeholder="XX%"
                  />

                </div>
                <div className="col-md-6 col-sm-6 col-xs-6"  >
                  <div
                    className="col-md-12 col-sm-4 col-xs-4"
                    onClick={() => this.toggleClass('bonus')}
                    title="Lock/Unlock"
                    style={{
                      color: "#9dacdd",
                      fontSize: "14px"
                    }}>
                    Value (est)
                      </div>
                  <NumericTextBox
                    name="compensationPercentBonuses"
                    className="offer-dashboard-rightside-input-box"
                    type="number"
                    format="c0"
                    spinners={false}
                    onChange={this.percentChange}
                    disabled
                    value={offer_equity_value}
                    placeholder="$X,XXX"
                    style={{ color: "white", width: "100%", textAlign: "center", fontSize: "24px" }}
                  />

                </div>
              </div>
              <div class="col-md-12 text-center"> <span class="median_label" style={{ border: "1px solid #9dacdd", fontSize: "13px", color: "#9dacdd", marginTop: "30px", display: "block" }}> Median Total Compensation: $0 </span> </div>

            </TabStripTab>
          </TabStrip >
        </div >
      </div >

    )
  }
}
const mapStateToProps = state =>
  ({
    estimateDashboard: state.estimateDashboard,
    candidateOffers: state.candidateOffers
  });
export default connect(mapStateToProps, { inputValueAction }
)(OfferRightSide);
