import {
  SET_TITLE_FUNCTIONS,
  ROLES_LOADING,
  GET_TITLE_FUNCTIONS
} from "../actions/types";

const initialState = {
  role: {},
  adminLookupTitleAndFunction: null,
  loading: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ROLES_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_TITLE_FUNCTIONS:
      return {
        ...state,
        adminLookupTitleAndFunction: action.payload,
        loading: true
      };

    default:
      return state;
  }
}
