import * as React from 'react';
import { Link } from "react-router-dom";
import { Input } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { AutoComplete } from '@progress/kendo-react-dropdowns';
export class LookupTitleform extends React.Component {
  constructor(props) {
    super(props);
   
    this.state = {
      success: false,
      
    };
  
  }

  render() {
   
    return (
      <div>
        <div className="container-fluid card-layout">
          <div className="row card-block">
            <div className="col-sm-12 col-md-12 col-xs-12 col-lg-12">
              <form className="k-form" onSubmit={this.RoleHrFormSubmit} >
                <fieldset style={{ marginBottom: "0px" }}>
                    <h1 className="h1s">Title lookup Details</h1>
                  <h3 className="h3s"></h3>
                  <div className="mb-3 row">
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                    <p style={{fontSize:"16px"}}> 
                <label
                    className="k-checkbox-label"
                    style={{ marginLeft:"5px"}}
                    htmlFor="auth-2fa">
                   
                </label>
                Active
                </p>
                </div>
              
                  </div>

                  <div className="mb-3 row">
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                        <Input
                      style={{ width: '100%' }}
                      onChange={this.onChange}
                      label="Company *"
                    /></div>

                  </div>
                
                  <div className="mb-3 row">
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        style={{ width: '100%' }}
                        onChange={this.onChange}
                        label="City "
                      />
                    </div>
                  </div>
                 
                  <div className="mb-3 row">
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        style={{ width: '100%' }}
                        onChange={this.onChange}
                        label="State "
                      />
                    </div>
                  </div>
               
                  <div className="mb-3 row">
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        label="Region "
                        data={this.state.companyLookupData}
                        onChange={this.companyChange}
                        value={this.state.company_name}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  
                </fieldset>
                <div className="row">
                  <div className="col-md-4"></div>
                  <div className="col-md-2 col-xs-6 col-sm-6 col-lg-2">
                    <Button className="mt-3 col-md-12 col-xs-12 col-sm-12 col-lg-12" type="submit" primary={true} >Submit</Button>
                  </div>
                  <div className="col-md-2 col-xs-6 col-sm-6 col-lg-2" style={{ border: "1px slid red" }}>
                    <Link type="button" to="/home/lookup-titlefunction"className="k-button col-md-12 col-xs-12 col-sm-12 col-lg-12" style={{ marginTop: "16px" }}>CANCEL</Link>
                  </div>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LookupTitleform;