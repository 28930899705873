import axios from "axios";
import { SET_TITLE_FUNCTIONS, 
         GET_TITLE_FUNCTIONS, 
         CLEAR_ERRORS 
       } from "./types";

export const fnGetLookupAllTitleAndFunctions = (id) => dispatch => {
  dispatch(clearErrors());

  axios
    .get("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-lookup-admin-title-function-get-grid")
    .then(res => {
      const TitleAndFunctionsData = res.data.data;
      const TitleAndFunctionsList = []
      for (let i = 0; i < TitleAndFunctionsData.length; i++) {
        TitleAndFunctionsList.push(TitleAndFunctionsData[i])
      }
      dispatch(setGetAllTitleAndFunctions(TitleAndFunctionsList));
    })
    .catch(err => {
      console.log(err)
    }
    );
};
//Insert
export const fnInsertLookupAllTitleandFunction = (userData) => dispatch => {
  dispatch(clearErrors());
  axios
    .post("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-lookup-admin-title-function-post-insert", userData)
    .then(res => {
      console.log(res.data.message)
    })
    .catch(err => { console.log(err) });
};
//update
export const fnUpdateLookupAllTitleAndFunction = (userData) => dispatch => {
  dispatch(clearErrors());
  axios
    .post("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-lookup-admin-title-function-post-update", userData)
    .then(res => {
      console.log(res.data.message)
    })
    .catch(err => { console.log(err) });
};

export const fnDeleteLookupAllTitleandFunction = (userData) => dispatch => {
  dispatch(clearErrors());
  
  axios
    .delete("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-lookup-admin-title-function-del?admin_lookp_title_function_id="+ userData)
    .then(res => {
      console.log(res.data.message)
    })
    .catch(err => { console.log(err) });
};




//GetAllProfile
export const setGetAllTitleAndFunctions = (TitleAndFunctions) => {
  return {
    type: GET_TITLE_FUNCTIONS,
    payload: TitleAndFunctions
  };
};

// clear errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS
  };
};
      
