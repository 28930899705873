import React from 'react';
import { connect } from "react-redux";
import { NumericTextBox } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { Link } from "react-router-dom";
import { getCompanyLocationRegionData } from "../dropdown/lookups";
import { DropDownList, AutoComplete } from '@progress/kendo-react-dropdowns';
import { Input } from '@progress/kendo-react-inputs';
import PropTypes from "prop-types";
import { fnGetNewHires, fnGetUpdateHires } from "../../actions/roleActions";
import "../../css/createRole.css"
import FormValidator from '../../validation/FormValidator';
import validationParams from '../../validation/validationParams.json'
import {
  getCompanyLookUpData,
  getTitleLookUpData,
  getFunctionLookUpData,
  getStageLookUpData,
  getgraphAPIListMarketSpaceLookUpData, getCompanyLocationRegionLookUpData,
  getFunctionBasedOnTitle
} from "../../actions/lookupAction"
import { fnEstimateDashboard, fnComparisonCompensationEstimateDashboard } from "../../actions/dashboard";
import intialParams from './roles.json';
import { titlesDataSort, functionalityDataSort, regionDataSort, marketSpaceDataSort, companyDataSort, stageDataSort, stateDataSort, cityDataSort } from "../dropdown/lookupSortingData";
import Spinner from "../spinner/loading";

class UpdateRole extends React.Component {
  constructor(props) {
    super(props);
    this.validator = new FormValidator(validationParams.createRoleValidation);
    intialParams.initalRolePrams.validation = this.validator.valid();
    this.state = intialParams.initalRolePrams;
    this.state.equity_rsu_years_to_vest = 4;
    this.state.equity_stock_years_to_vest = 4;
    this.state.equity_percent_years_to_vest = 4;
    this.state.equity_estimate_years_to_vest = 4;

    this.state.compensation_base_low = null;
    this.state.compensation_bonus_low = null;
    this.state.compensation_comission_low = null;
    this.state.compensation_equity_low = null;

    this.state.compensation_sign_on_low = null;
    this.state.compensation_total_low = null;
    this.state.compensation_base_high = null;
    this.state.compensation_bonus_high = null;

    this.state.compensation_comission_high = null;
    this.state.compensation_equity_high = null;
    this.state.compensation_sign_on_high = null;
    this.state.compensation_total_high = null;
    this.submitted = false;
    this.state.loading = true;
  }
  componentDidMount() {
    this.props.getCompanyLookUpData();
    this.props.getTitleLookUpData();
    this.props.getFunctionLookUpData();
    this.props.getStageLookUpData();
    this.props.getgraphAPIListMarketSpaceLookUpData();
    this.props.getCompanyLocationRegionLookUpData();
    fnGetNewHires(this.props.location.search.slice(4)).then(response => {
      const GetNewHire = response.data.response;
      GetNewHire.isLoading = false;
      this.state.loading = false
      this.setState(GetNewHire);
    }).catch(error => console.log(error));

    getCompanyLocationRegionData().then(response => {
      const LocationRegionData = response.data.data;
      const Locations = []
      const States = []
      const Regions = []
      const locationRegiongCity = []
      for (let i = 0; i < LocationRegionData.length; i++) {
        Locations.push(LocationRegionData[i]["_id"]["location_city"])
        States.push(LocationRegionData[i]["_id"]["location_state"])
        Regions.push(LocationRegionData[i]["_id"]["region"])
        locationRegiongCity.push(LocationRegionData[i]["_id"])
      }
      this.setState({
        Locations: Locations,
        States: States,
        Regions: Regions,
        locationRegiongCity: locationRegiongCity,
        loading: false
      });
    }
    ).catch(error => console.log(error));

  }

  onChange = e => {
    const value = e.target.value;
    this.setState({
      [e.target.name]: value,
      titleLookupData: titlesDataSort(value, this.props.lookups.titleLookUp.data),
      functionalityLookupData: functionalityDataSort(value, this.props.lookups.functionLookUp.data),
      companyLookupData: companyDataSort(value, this.props.lookups.companyLookUp.data),
      stageLookupData: stageDataSort(value, this.props.lookups.stageLookUp.data),
      stateLookupData: stateDataSort(value, this.props.lookups.companyStatesLookUp),
      marketSpaceLookupData: marketSpaceDataSort(value, this.props.lookups.marketSpaceLookUp.data),
      cityLookupData: cityDataSort(value, this.props.lookups.companyLocationLookUp),
      regionLookupData: regionDataSort(value, this.props.lookups.companyRegionLookUp)
    });

    if (e.target.name === 'role_title') {
      this.state.Title = e.target.value;

      if (e.target.name === 'role_title') {
        this.state.Title = e.target.value;

        getFunctionBasedOnTitle(this.state.Title).then(response => {
          let titleResultData = response.data.data
          for (let i = 0; i < titleResultData.length; i++) {
            if (titleResultData[i]["title"] === e.target.value) {
              this.setState({
                Function_name: titleResultData[i]["function"],
                Function: titleResultData[i]["function"],
                role_function: titleResultData[i]["function"]
              });
            }
          }
          let userMLData = {
            Title: this.state.Title,
            Function: this.state.Function_name,
            role_years: '',
            base_percentile: '0.75',
            MarketSpace: this.state.role_market_space,
            Company: this.state.role_company,
            Region: this.state.role_region,
            Stage: this.state.role_stage
          }
          this.props.fnComparisonCompensationEstimateDashboard(userMLData);
          this.state.compensation_base_low = null;
          this.state.compensation_base_high = null;
          this.state.compensation_comission_low = null;
          this.state.compensation_comission_high = null;
          this.state.compensation_sign_on_low = null;
          this.state.compensation_sign_on_high = null;
          this.state.compensation_bonus_percentage_low = null;
          this.state.compensation_bonus_percentage_high = null;

        });
        if (this.state.Title === "") {
          this.setState({
            Function: "",
            Function_name: "",
            role_function: ""
          });
        }

      }

    }
    else if (e.target.name === 'role_function') {
      this.state.Function_name = e.target.value;
    }

    if (e.target.name === 'role_title' || e.target.name === 'role_function') {
      let userMLData = {
        Title: this.state.Title ? this.state.Title : this.state.role_title,
        Function: this.state.Function_name ? this.state.Function_name : this.state.role_function,
        role_years: '',
        base_percentile: '0.75',
        MarketSpace: this.state.role_market_space,
        Company: this.state.role_company,
        Region: this.state.role_region,
        Stage: this.state.role_stage
      }
      this.props.fnComparisonCompensationEstimateDashboard(userMLData);

      this.state.compensation_base_low = null;
      this.state.compensation_base_high = null;
      this.state.compensation_comission_low = null;
      this.state.compensation_comission_high = null;
      this.state.compensation_sign_on_low = null;
      this.state.compensation_sign_on_high = null;
      this.state.compensation_bonus_percentage_low = null;
      this.state.compensation_bonus_percentage_high = null;
      this.state.compensation_total_low = null;
      this.state.compensation_total_high = null;
    }


    if (e.target.name === "role_city") {
      const locationregioncity = this.state.locationRegiongCity;
      for (let i = 0; i < locationregioncity.length; i++) {
        if (locationregioncity[i]["location_city"] === e.target.value) {

          this.setState({
            role_state: locationregioncity[i]["location_state"],
            role_region: locationregioncity[i]["region"]
          });
        }
      }
    }
    if (e.target.name === "equity_rsu_stock_price") {
      this.setState({
        equity_rsu_total_equity: value,
        equity_rsu_annual_equity: value / 2
      });
    }
    if (e.target.name === "equity_stock_current_value") {
      const equityTotalOptions = this.state.equity_stock_total_options
      const equityStockStrikeprice = this.state.equity_stock_strike_price
      const equityStockStockYearsToVest = this.state.equity_stock_years_to_vest

      this.setState({
        equity_stock_net_option_value: value - equityStockStrikeprice,
        equity_stock_annual_equity_value: (equityTotalOptions - this.state.equity_stock_net_option_value) / equityStockStockYearsToVest
      });
    }

    if (e.target.name === "compensation_bonus_percentage_low") {
      this.state.compensation_bonus_low =
        (e.target.value * this.state.compensation_base_low) / 100;
    }

    if (e.target.name === "compensation_bonus_percentage_high") {
      this.state.compensation_bonus_high =
        (e.target.value * this.state.compensation_base_high) / 100;
    }

    if (e.target.name === "compensation_bonus_low") {
      this.state.compensation_bonus_percentage_low =
        (e.target.value / this.state.compensation_base_low) * 100;
    }

    if (e.target.name === "compensation_bonus_high") {
      this.state.compensation_bonus_percentage_high =
        (e.target.value / this.state.compensation_base_high) * 100;
    }

  };

  valueChange = (e) => {
    let { value, name } = e.target;

    this.setState({
      [name]: value
    });

    const { estimateDashboard } = this.props.estimateDashboard;

    if (e.target.name === "compensation_bonus_low") {
      if (estimateDashboard.data) {
        estimateDashboard.data.compensation.bonuses = e.target.value;
        this.state.compensation_bonus_percentage_low = (e.target.value / estimateDashboard.data.compensation.base) * 100;
      } else {
        this.state.compensation_bonus_low = e.target.value;
        this.state.compensation_bonus_percentage_low = (e.target.value / this.state.compensation_base_low) * 100;
      }
    }

    if (e.target.name === "compensation_bonus_high") {
      if (estimateDashboard.data) {
        estimateDashboard.data.compensation.bonuses = e.target.value;
        this.state.compensation_bonus_percentage_high = (e.target.value / estimateDashboard.data.compensation.base) * 100;
      } else {
        this.state.compensation_bonus_high = e.target.value;
        this.state.compensation_bonus_percentage_high = (e.target.value / this.state.compensation_base_high) * 100;
      }
    }

    if (e.target.name === "compensation_bonus_percentage_low") {
      if (estimateDashboard.data) {
        estimateDashboard.data.compensation.bonuses = (e.target.value * estimateDashboard.data.compensation.base) / 100;
      } else {
        this.state.compensation_bonus_low = (e.target.value * this.state.compensation_base_low) / 100;
      }
    }

    if (e.target.name === "compensation_bonus_percentage_high") {
      if (estimateDashboard.data) {
        estimateDashboard.data.compensation.bonuses = (e.target.value * estimateDashboard.data.compensation.base) / 100;
      } else {
        this.state.compensation_bonus_high = (e.target.value * this.state.compensation_base_high) / 100;
      }
    }

  }


  benchmark = (event) => {
    const value = event.target.value;
    this.setState({
      [event.target.name]: value
    })
    if (event.target.value === "Custom percentile") {
      this.setState({
        custPercentile: true,
        companyRank: false
      });
    } else if (event.target.value === "Your Company Rank") {
      this.setState({
        custPercentile: false,
        companyRank: true
      });
    }
    else {
      this.setState({
        custPercentile: false,
        companyRank: false
      });
    }
  }

  equity = (event) => {
    const value = event.target.value;
    this.setState({
      [event.target.name]: value
    })
    if (event.target.value === "RSUs") {
      this.setState({
        hideRSUs: true,
        yourEquityEstimate: false,
        percentEquity: false,
        stockOptions: false
      });
    } else if (event.target.value === "Stock Options") {
      this.setState({
        stockOptions: true,
        hideRSUs: false,
        yourEquityEstimate: false,
        percentEquity: false
      });
    } else if (event.target.value === "Percent (%) equity") {
      this.setState({
        percentEquity: true,
        stockOptions: false,
        hideRSUs: false,
        yourEquityEstimate: false
      });
    } else if (event.target.value === "Your equity estimate") {
      this.setState({
        yourEquityEstimate: true,
        percentEquity: false,
        stockOptions: false,
        hideRSUs: false
      });
    }
    else {
      this.setState({
        yourEquityEstimate: false,
        percentEquity: false,
        stockOptions: false,
        hideRSUs: false
      });
    }
  }

  updateRoleSubmit = (event) => {
    event.preventDefault();

    const userData = {
      id: this.props.location.search.slice(4),
      user_id: this.props.auth.user._id,
      role_company: this.state.role_company,
      role_stage: this.state.role_stage,
      role_market_space: this.state.role_market_space,
      role_city: this.state.role_city,
      role_state: this.state.role_state,
      role_region: this.state.role_region,
      role_status: this.state.role_status,
      role_title: this.state.role_title,
      role_function: this.state.role_function,
      role_department: this.state.role_department,
      benchmark_type: this.state.benchmark_type,
      benchmark_company_rank: this.state.benchmark_company_rank,
      equity_type: this.state.equity_type,
      equity_stock_annual_equity_value: this.state.equity_stock_annual_equity_value,
      equity_rsu_total: this.state.equity_rsu_total,
      equity_rsu_total_equity: this.state.equity_rsu_total_equity,
      equity_rsu_years_to_vest: this.state.equity_rsu_years_to_vest,
      equity_rsu_annual_equity: this.state.equity_rsu_annual_equity,
      equity_rsu_stock_price: this.state.equity_rsu_stock_price,
      compensation_base_low: this.state.compensation_base_low,
      compensation_total_low: this.state.compensation_total_low,
      compensation_base_high: this.state.compensation_base_high,
      compensation_total_high: this.state.compensation_total_high,
      compensation_bonus_low: this.state.compensation_bonus_low,
      compensation_bonus_high: this.state.compensation_bonus_high,
      compensation_comission_low: this.state.compensation_comission_low,
      compensation_comission_high: this.state.compensation_comission_high,
      compensation_equity_low: this.state.compensation_equity_low,
      compensation_equity_high: this.state.compensation_equity_high,
      compensation_sign_on_low: this.state.compensation_sign_on_low,
      compensation_sign_on_high: this.state.compensation_sign_on_high,
      equity_type_rsu: this.state.equity_type_rsu,
      equity_type_stock_option: this.state.equity_type_stock_option,
      benchmark_custom_percentile: this.state.benchmark_custom_percentile,
      equity_stock_total_options: this.state.equity_stock_total_options,
      equity_stock_strike_price: this.state.equity_stock_strike_price,
      equity_stock_years_to_vest: this.state.equity_stock_years_to_vest,
      equity_stock_current_value: this.state.equity_stock_current_value,
      equity_stock_net_option_value: this.state.equity_stock_net_option_value,
      equity_stock_total_equity_value: this.state.equity_stock_total_equity_value,
      equity_stock_annual_equity_value: this.state.equity_stock_annual_equity_value,
      equity_percent_equity: this.state.equity_percent_equity,
      equity_percent_current_company_value: this.state.equity_percent_current_company_value,
      equity_percent_years_to_vest: this.state.equity_percent_years_to_vest,
      equity_percent_total_equity: this.state.equity_percent_total_equity,
      equity_percent_annual_equity: this.state.equity_percent_annual_equity,
      equity_estimate_total_equity_estimate: this.state.equity_estimate_total_equity_estimate,
      equity_estimate_years_to_vest: this.state.equity_estimate_years_to_vest,
      equity_estimate_annual_equity_estimate: this.state.equity_estimate_annual_equity_estimate,
      compensation_bonus_percentage_low: this.state.compensation_bonus_percentage_low,
      compensation_bonus_percentage_high: this.state.compensation_bonus_percentage_high
    };

    const validation = this.validator.validate(this.state);

    this.setState({ validation });
    this.submitted = true;

    if (this.state.compensation_base_high < this.state.compensation_base_low) {
      this.setState({
        compensation_base_error_message:
          "Base high mustbe greater than base low"
      });
    } else if (this.state.compensation_bonus_high < this.state.compensation_bonus_low) {
      this.setState({
        compensation_bonus_error_message:
          "Bonus high mustbe greater than bonus low"
      });
    } else if (this.state.compensation_comission_high < this.state.compensation_comission_low) {
      this.setState({
        compensation_comission_error_message:
          "Comission high mustbe greater than comission low"
      });
    } else if (this.state.compensation_equity_high < this.state.compensation_equity_low) {
      this.setState({
        compensation_equity_error_message:
          "Equity high mustbe greater than equity low"
      });
    } else if (this.state.compensation_sign_on_high < this.state.compensation_sign_on_low) {
      this.setState({
        compensation_sign_on_error_message:
          "Signon high mustbe greater than signon low"
      });
    } else if (this.state.compensation_total_high < this.state.compensation_total_low) {
      this.setState({
        compensation_total_error_message:
          "Total high mustbe greater than total low"
      });
    } else {
      if (validation.isValid) {
        this.props.fnGetUpdateHires(userData, this.props.history);
      }
    }
  }

  toggleChange = () => {
    this.setState({
      equity_type_stock_option: !this.state.equity_type_stock_option,
    });
  }
  toggleChange2 = () => {
    this.setState({
      equity_type_rsu: !this.state.equity_type_rsu
    });
  }
  render() {
    const { estimateDashboard } = this.props.estimateDashboard
    console.log("estimateDashboard", estimateDashboard)
    if (estimateDashboard.data) {

      this.state.compensation_base_low = estimateDashboard.data.compensation.base
      this.state.compensation_base_high = estimateDashboard.data.compensation.base
      this.state.compensation_bonus_low = estimateDashboard.data.compensation.bonuses
      this.state.compensation_bonus_high = estimateDashboard.data.compensation.bonuses
      this.state.compensation_equity_low = estimateDashboard.data.compensation.equity
      this.state.compensation_equity_high = estimateDashboard.data.compensation.equity
    }

    let compensationTotalHigh = '';
    let compensationTotalLow = '';
    if (this.state.role_stage === "PUBLIC" &&
      this.state.equity_type === "Stock Options") {
      if (this.state.equity_stock_annual_equity_value === undefined) {
        this.state.equity_stock_annual_equity_value = 0
        this.state.compensation_equity_low = this.state.equity_stock_annual_equity_value
        this.state.compensation_equity_high = this.state.equity_stock_annual_equity_value
      } else {
        this.state.equity_stock_annual_equity_value = (((this.state.equity_stock_current_value || 0) - (this.state.equity_stock_strike_price || 0)) * (this.state.equity_stock_total_options || 0)) / this.state.equity_stock_years_to_vest
        this.state.compensation_equity_low = this.state.equity_stock_annual_equity_value
        this.state.compensation_equity_high = this.state.equity_stock_annual_equity_value
      }
      compensationTotalHigh = this.state.compensation_base_high + this.state.compensation_bonus_high +
        this.state.compensation_comission_high + this.state.compensation_equity_high +
        (this.state.compensation_sign_on_high) / 4;

      compensationTotalLow = this.state.compensation_base_low + this.state.compensation_bonus_low +
        this.state.compensation_comission_low + this.state.compensation_equity_low +
        (this.state.compensation_sign_on_low) / 4;

    } else if (this.state.role_stage !== "PUBLIC" &&
      this.state.equity_type === "Stock Options") {
      if (this.state.equity_stock_annual_equity_value === undefined) {
        this.state.equity_stock_annual_equity_value = 0
      } else {
        this.state.equity_stock_annual_equity_value = (((this.state.equity_stock_current_value || 0) - (this.state.equity_stock_strike_price || 0)) * (this.state.equity_stock_total_options || 0)) / this.state.equity_stock_years_to_vest
      }
      compensationTotalHigh = this.state.compensation_base_high + this.state.compensation_bonus_high +
        this.state.compensation_comission_high +
        (this.state.compensation_sign_on_high) / 4 +
        this.state.equity_stock_annual_equity_value;

      compensationTotalLow = this.state.compensation_base_low + this.state.compensation_bonus_low +
        this.state.compensation_comission_low +
        (this.state.compensation_sign_on_low) / 4 +
        this.state.equity_stock_annual_equity_value

    } else if (this.state.equity_type === "RSUs") {
      if (this.state.equity_rsu_annual_equity === undefined) {
        this.state.equity_rsu_annual_equity = 0
      } else {
        this.state.equity_rsu_annual_equity = (
          (this.state.equity_rsu_total || 0) * (this.state.equity_rsu_stock_price || 0)) / (this.state.equity_rsu_years_to_vest)
      }
      console.log("compensationTotalHigh", this.state.compensation_base_high, this.state.compensation_bonus_high,
        this.state.compensation_comission_high,
        (this.state.compensation_sign_on_high) / 4,
        this.state.equity_rsu_annual_equity)
      compensationTotalHigh = this.state.compensation_base_high + this.state.compensation_bonus_high +
        this.state.compensation_comission_high +
        (this.state.compensation_sign_on_high) / 4 +
        this.state.equity_rsu_annual_equity

      compensationTotalLow = this.state.compensation_base_low + this.state.compensation_bonus_low +
        this.state.compensation_comission_low +
        (this.state.compensation_sign_on_low) / 4 +
        this.state.equity_rsu_annual_equity
    } else if (this.state.equity_type === "Percent (%) equity" ||
      this.state.equity_type === "Your equity estimate") {

      compensationTotalHigh = this.state.compensation_base_high + this.state.compensation_bonus_high +
        this.state.compensation_comission_high +
        (this.state.compensation_sign_on_high) / 4;

      compensationTotalLow = this.state.compensation_base_low + this.state.compensation_bonus_low +
        this.state.compensation_comission_low +
        (this.state.compensation_sign_on_low) / 4;
    } else if (this.state.equity_type.length === 0) {
      compensationTotalHigh = this.state.compensation_base_high + this.state.compensation_bonus_high +
        this.state.compensation_comission_high + this.state.compensation_equity_high +
        (this.state.compensation_sign_on_high) / 4;

      compensationTotalLow = this.state.compensation_base_low + this.state.compensation_bonus_low +
        this.state.compensation_comission_low + this.state.compensation_equity_low +
        (this.state.compensation_sign_on_low) / 4;
    }

    this.state.compensation_total_high = compensationTotalHigh;
    this.state.compensation_total_low = compensationTotalLow;



    const data = [
      'Open',
      'Close'
    ];
    const benchmark = [
      'Competition',
      '60th percentile',
      'Custom percentile',
      'Your Company Rank'
    ];
    const equity = [
      'NONE',
      'RSUs',
      'Stock Options',
      'Percent (%) equity',
      'Your equity estimate'
    ];
    let validation = this.submitted ?
      this.validator.validate(this.state) :
      this.state.validation


    if (this.state.equity_type === "RSUs") {
      this.state.hideRSUs = true;
      this.state.yourEquityEstimate = false;
      this.state.percentEquity = false;
      this.state.stockOptions = false;

    } else if (this.state.equity_type === "Stock Options") {
      this.state.stockOptions = true;
      this.state.hideRSUs = false;
      this.state.yourEquityEstimate = false;
      this.state.percentEquity = false;
    } else if (this.state.equity_type === "Percent (%) equity") {

      this.state.percentEquity = true;
      this.state.stockOptions = false;
      this.state.hideRSUs = false;
      this.state.yourEquityEstimate = false;
    } else if (this.state.equity_type === "Your equity estimate") {
      this.state.yourEquityEstimate = true;
      this.state.percentEquity = false;
      this.state.stockOptions = false;
      this.state.hideRSUs = false;
    }
    else {
      this.state.yourEquityEstimate = false;
      this.state.percentEquity = false;
      this.state.stockOptions = false;
      this.state.hideRSUs = false;
    }
    if (this.state.benchmark_type === "Custom percentile") {
      this.state.custPercentile = true;
      this.state.companyRank = false;
    } else if (this.state.benchmark_type === "Your Company Rank") {

      this.state.custPercentile = false;
      this.state.companyRank = true;
    }
    else {
      this.state.custPercentile = false;
      this.state.companyRank = false;
    }

    return (

      <div className="row example-wrapper role-create-main-div">
        {this.state.loading === true ? <Spinner /> : null}
        <div className="col-sm-12 col-md-12 col-xs-12 col-lg-12">
          <form className="k-form" onSubmit={this.updateRoleSubmit}>


            <div className="role-create-form-main-div">
              <h2 className="role-create-form-main-header">Update Role</h2>
              <div className="mb-3 row">
                <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                  <DropDownList
                    label="Status *"
                    name="role_status"
                    onChange={this.onChange}
                    value={this.state.role_status}
                    data={data}
                    defaultValue={data[0]}
                    style={{ width: '100%' }}
                  />
                  <div style={{
                    color: "red",
                    paddingLeft: "0px"
                  }}>
                    {validation.role_status.message}
                  </div>
                </div>
              </div>
              <div className="mb-3 row">
                <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                  <AutoComplete
                    name="role_title"
                    onChange={this.onChange}
                    data={this.state.titleLookupData}
                    value={this.state.role_title}
                    label="Level *"
                    style={{ width: '100%' }}
                  />
                  <div style={{
                    color: "red",
                    paddingLeft: "0px"
                  }}>
                    {validation.role_title.message}
                  </div>
                </div>
                <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                  <AutoComplete
                    name="role_function"
                    onChange={this.onChange}
                    data={this.state.functionalityLookupData}
                    value={this.state.role_function}
                    label="Function *"
                    style={{ width: '100%' }}
                  />
                  <div style={{
                    color: "red",
                    paddingLeft: "0px"
                  }}>
                    {validation.role_function.message}
                  </div>
                </div>
              </div>
              <div className="mb-3 row">
                <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                  <AutoComplete
                    name="role_company"
                    onChange={this.onChange}
                    data={this.state.companyLookupData}
                    value={this.state.role_company}
                    label="Company *"
                    style={{ width: '100%' }}
                    disabled
                  />
                  <div style={{
                    color: "red",
                    paddingLeft: "0px"
                  }}>
                    {validation.role_company.message}
                  </div>
                </div>
                <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                  <AutoComplete
                    name="role_stage"
                    onChange={this.onChange}
                    data={this.state.stageLookupData}
                    value={this.state.role_stage}
                    label="Stage *"
                    style={{ width: '100%' }}
                    disabled
                  />
                  <div style={{
                    color: "red",
                    paddingLeft: "0px"
                  }}>
                    {validation.role_stage.message}
                  </div>
                </div>
              </div>
              <div className="mb-3 row">
                <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                  <AutoComplete
                    name="role_market_space"
                    onChange={this.onChange}
                    data={this.state.marketSpaceLookupData}
                    value={this.state.role_market_space}
                    label="Market Space *"
                    style={{ width: '100%' }}
                  />
                  <div style={{
                    color: "red",
                    paddingLeft: "0px"
                  }}>
                    {validation.role_market_space.message}
                  </div>
                </div>
                <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                  <AutoComplete
                    name="role_city"
                    onChange={this.onChange}
                    data={this.state.cityLookupData}
                    value={this.state.role_city}
                    label="City *"
                    style={{ width: '100%' }}
                  />
                  <div style={{
                    color: "red",
                    paddingLeft: "0px"
                  }}>
                    {validation.role_city.message}
                  </div>
                </div>
              </div>
              <div className="mb-3 row">
                <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                  <AutoComplete
                    name="role_state"
                    onChange={this.onChange}
                    data={this.state.stateLookupData}
                    value={this.state.role_state}
                    label="State *"
                    style={{ width: '100%' }}
                    disabled
                  />
                  <div style={{
                    color: "red",
                    paddingLeft: "0px"
                  }}>
                    {validation.role_state.message}
                  </div>
                </div>
                <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                  <AutoComplete
                    name="role_region"
                    onChange={this.onChange}
                    data={this.state.regionLookupData}
                    value={this.state.role_region}
                    label="Region"
                    disabled
                    style={{ width: '100%' }}
                  />
                </div>
              </div>
              <div>
                <div>
                  <h2 style={{ color: "white" }}>  Benchmark</h2></div>
                <div style={{ paddingBottom: "0px", fontSize: "18px", fontWeight: "500" }}><h2 className="role-create-header2"> BENCHMARK </h2><p>(Pick a compensation benchmark to estimate what you’ll need to pay)</p>
                </div>
              </div>
              <div className="mb-3 row">
                <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                  <DropDownList
                    label="Pick Benchmark"
                    name="benchmark_type"
                    value={this.state.benchmark_type}
                    data={benchmark}
                    onChange={this.benchmark}
                    style={{ width: '100%' }}
                  />
                </div>
                <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5"></div>
              </div>
              {this.state.custPercentile ? <div className="mb-3 row">
                <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                  <Input
                    name="benchmark_custom_percentile"
                    style={{ width: "100%" }}
                    label="Custom Percentile"
                    minLength={2}
                    onChange={this.onChange}
                    value={this.state.benchmark_custom_percentile}
                  />
                </div>
                <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5"></div>
              </div> : false}
              {this.state.companyRank ? <div className="mb-3 row">
                <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                  <Input
                    name="benchmark_company_rank"
                    style={{ width: "100%" }}
                    label="Custom Percentile"
                    minLength={2}
                    onChange={this.onChange}
                    value={this.state.benchmark_company_rank}
                  />
                </div>
                <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5"></div>
              </div> : false}
              <div> <h2 className="role-create-header3">EQUITY </h2> </div>
              <div className="mb-3 row">
                <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                  <DropDownList
                    label="Equity"
                    name="equity_type"
                    data={equity}
                    onChange={this.equity}
                    value={this.state.equity_type}
                    style={{ width: '100%' }}
                  />
                  <div style={{
                    color: "red",
                    paddingLeft: "0px"
                  }}>
                    {validation.equity_type.message}
                  </div>
                </div>
                <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5"></div>
              </div>
              {this.state.hideRSUs ? <div>
                <div className="mb-3 row">
                  <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                  <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                    <NumericTextBox
                      name="equity_rsu_total"
                      onChange={this.onChange}
                      value={this.state.equity_rsu_total}
                      label="Target RSUs"
                      width="100%"
                      spinners={false}
                    />
                  </div>
                  <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                  <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                    <NumericTextBox
                      name="equity_rsu_years_to_vest"
                      onChange={this.onChange}
                      min={0}
                      max={5}
                      step={1}
                      spinners={true}
                      value={this.state.equity_rsu_years_to_vest}
                      label="Years to Vest"
                      width="100%"
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                  <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                    <NumericTextBox
                      name="equity_rsu_stock_price"
                      onChange={this.onChange}
                      value={this.state.equity_rsu_stock_price}
                      label="Stock price"
                      width="100%"
                      spinners={false}
                    />
                  </div>
                  <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                  <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                    <NumericTextBox
                      name="equity_rsu_total_equity"
                      onChange={this.onChange}
                      value={(this.state.equity_rsu_total) * (this.state.equity_rsu_stock_price) || 0}
                      required={true}
                      label="$ Total Equity"
                      width="100%"
                      disabled="disabled"
                      spinners={false}
                      format="n2"
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                  <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                    <NumericTextBox
                      name="equity_rsu_annual_equity"
                      onChange={this.onChange}
                      value={((this.state.equity_rsu_total) * (this.state.equity_rsu_stock_price)) / (this.state.equity_rsu_years_to_vest) || 0}
                      required={true}
                      label="$ Annual Equity"
                      width="100%"
                      disabled="disabled"
                      spinners={false}
                      format="n2"
                    />
                  </div>
                  <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                  <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5"></div>
                </div>
              </div> : false}
              {this.state.stockOptions ? <div>
                <div className="mb-3 row">
                  <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                  <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                    <NumericTextBox
                      name="equity_stock_total_options"
                      onChange={this.onChange}
                      value={this.state.equity_stock_total_options}
                      label="Target Options"
                      width="100%"
                      spinners={false}
                    />
                  </div>
                  <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                  <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                    <NumericTextBox
                      name="equity_stock_strike_price"
                      onChange={this.onChange}
                      spinners={false}
                      value={this.state.equity_stock_strike_price}
                      label="Strike price"
                      width="100%"
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                  <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                    <NumericTextBox
                      name="equity_stock_years_to_vest"
                      onChange={this.onChange}
                      min={0}
                      max={5}
                      step={1}
                      spinners={true}
                      value={this.state.equity_stock_years_to_vest}
                      label="Years to Vest"
                      width="100%"
                    />
                  </div>
                  <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                  <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                    <NumericTextBox
                      name="equity_stock_current_value"
                      onChange={this.onChange}
                      value={this.state.equity_stock_current_value}
                      label="Current Value"
                      spinners={false}
                      width="100%"
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                  <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                    <NumericTextBox
                      name="equity_stock_net_option_value"
                      onChange={this.onChange}
                      value={(this.state.equity_stock_current_value) - (this.state.equity_stock_strike_price) || 0}
                      spinners={false}
                      label="$ Net Option Value"
                      width="100%"
                      format="n2"
                      disabled="disabled"
                    />
                  </div>
                  <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                  <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                    <NumericTextBox
                      name="equity_stock_total_equity_value"
                      onChange={this.onChange}
                      value={((this.state.equity_stock_current_value) - (this.state.equity_stock_strike_price)) * (this.state.equity_stock_total_options) || ''}
                      spinners={false}
                      label="$ Total Equity Value"
                      width="100%"
                      format="n2"
                      disabled="disabled"
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                  <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                    <NumericTextBox
                      name="equity_stock_annual_equity_value"
                      onChange={this.onChange}
                      value={(((this.state.equity_stock_current_value) - (this.state.equity_stock_strike_price)) * (this.state.equity_stock_total_options)) / this.state.equity_stock_years_to_vest || 0}
                      spinners={false}
                      width="100%"
                      format="n2"
                      label="$ Annual Equity Value"
                      disabled="disabled"
                    />
                  </div>
                  <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                  <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                  </div>
                </div>
              </div> : false}
              {this.state.percentEquity ? <div>
                <div className="mb-3 row">
                  <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                  <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                    <NumericTextBox
                      name="equity_percent_equity"
                      onChange={this.onChange}
                      value={this.state.equity_percent_equity}
                      label="Equity %"
                      width='100%'
                      spinners={false}
                    />
                  </div>
                  <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                  <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                    <NumericTextBox
                      name="equity_percent_current_company_value"
                      onChange={this.onChange}
                      value={this.state.equity_percent_current_company_value}
                      label="Current Comapany Value"
                      width='100%'
                      spinners={false}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                  <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                    <NumericTextBox
                      name="equity_percent_years_to_vest"
                      onChange={this.onChange}
                      min={0}
                      max={5}
                      step={1}
                      spinners={true}
                      value={this.state.equity_percent_years_to_vest}
                      label="Years to Vest"
                      width='100%'
                    />
                  </div>
                  <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                  <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                    <NumericTextBox
                      name="equity_percent_total_equity"
                      onChange={this.onChange}
                      value={(this.state.equity_percent_equity) *
                        (this.state.equity_percent_current_company_value) || 0}
                      label="$ Total equity"
                      format="n2"
                      width='100%'
                      disabled="disabled"
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                  <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                    <NumericTextBox
                      name="equity_percent_annual_equity"
                      onChange={this.onChange}
                      value={((this.state.equity_percent_equity) *
                        (this.state.equity_percent_current_company_value)) /
                        (this.state.equity_percent_years_to_vest) || 0}
                      label="$ Annual Equity"
                      format="n2"
                      width='100%'
                      disabled="disabled"
                    />
                  </div>
                  <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                  <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5"></div>
                </div>
              </div> : false}
              {this.state.yourEquityEstimate ? <div>
                <div className="mb-3 row">
                  <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                  <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                    <NumericTextBox
                      name="equity_estimate_total_equity_estimate"
                      onChange={this.onChange}
                      value={this.state.equity_estimate_total_equity_estimate}
                      label="Total Equity Estimate ($)"
                      width='100%'
                      spinners={false}
                    />
                  </div>
                  <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                  <div className="mb-3 col-md-5 col-sm-11 col-xs-11 col-lg-5">
                    <NumericTextBox
                      name="equity_estimate_years_to_vest"
                      onChange={this.onChange}
                      min={0}
                      max={5}
                      step={1}
                      spinners={true}
                      value={this.state.equity_estimate_years_to_vest}
                      label="Years to Vest"
                      width='100%'
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                  <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                    <NumericTextBox
                      name="equity_estimate_annual_equity_estimate"
                      onChange={this.onChange}
                      value={(this.state.equity_estimate_total_equity_estimate) / (this.state.equity_estimate_years_to_vest) || 0}
                      label="$ Annual equity estimate"
                      format="n2"
                      width='100%'
                      disabled="disabled"
                    />
                  </div>
                  <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                  <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5"></div>
                </div>
              </div> : false}

              <div >
                <h2 style={{ fontSize: "18px", fontWeight: "500", marginTop: "0px" }}>COMPENSATION RANGE </h2>
              </div>

              <div className="mb-3 row">
                <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                <div className="col-md-3 col-sm-11 col-xs-11 col-lg-3" style={{ fontSize: "16px" }}>
                  Base $
                </div>
                <div className="col-md-3 col-sm-1 col-xs-1 col-lg-3">
                  <NumericTextBox
                    className=""
                    width="100%"
                    name="compensation_base_low"
                    placeholder="LOW *"
                    onChange={this.valueChange}
                    value={this.state.compensation_base_low || null}
                    min={0}
                    step={1}
                  />
                  <div style={{
                    color: "red"
                  }}>
                    {validation.compensation_base_low.message}
                  </div>
                </div>
                <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                <div className="col-md-3 col-sm-1 col-xs-1 col-lg-3">
                  <NumericTextBox
                    className=""
                    name="compensation_base_high"
                    width="100%"
                    onChange={this.valueChange}
                    value={this.state.compensation_base_high}
                    placeholder="HIGH *"
                    min={0}
                    step={1}
                  />
                  <div style={{
                    color: "red"
                  }}>
                    {validation.compensation_base_high.message}
                    {this.state.compensation_base_error_message}
                  </div>
                </div>
              </div>
              <div className="mb-3 row">
                <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                <div className="col-md-3 col-sm-11 col-xs-11 col-lg-3" style={{ fontSize: "16px" }}>
                  Bonus %
                </div>
                <div className="col-md-3 col-sm-1 col-xs-1 col-lg-3">
                  <NumericTextBox
                    className=""
                    width="100%"
                    name="compensation_bonus_percentage_low"
                    placeholder="LOW"
                    onChange={this.valueChange}
                    value={this.state.compensation_bonus_percentage_low}
                    min={0}
                    step={1}
                  />
                </div>
                <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                <div className="col-md-3 col-sm-1 col-xs-1 col-lg-3">
                  <NumericTextBox
                    className="numericTextBoxrole4"
                    name="compensation_bonus_percentage_high"
                    width="100%"
                    onChange={this.valueChange}
                    value={this.state.compensation_bonus_percentage_high}
                    placeholder="HIGH"
                    min={0}
                    step={1}
                  />
                </div>
              </div>
              <div className="mb-3 row">
                <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                <div className="col-md-3 col-sm-11 col-xs-11 col-lg-3" style={{ fontSize: "16px" }}>
                  Bonus $
                </div>
                <div className="col-md-3 col-sm-1 col-xs-1 col-lg-3">
                  <NumericTextBox
                    className=""
                    width="100%"
                    name="compensation_bonus_low"
                    placeholder="LOW"
                    onChange={this.valueChange}
                    value={this.state.compensation_bonus_low}
                    min={0}
                    step={1}
                  />
                </div>
                <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                <div className="col-md-3 col-sm-1 col-xs-1 col-lg-3">
                  <NumericTextBox
                    className="numericTextBoxrole4"
                    name="compensation_bonus_high"
                    width="100%"
                    onChange={this.valueChange}
                    value={this.state.compensation_bonus_high}
                    placeholder="HIGH"
                    min={0}
                    step={1}
                  />
                  <div style={{ color: "red" }}>
                    {this.state.message}
                    {this.state.compensation_bonus_error_message}
                  </div>
                </div>
              </div>
              <div className="mb-3 row">
                <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                <div className="col-md-3 col-sm-11 col-xs-11 col-lg-3" style={{ fontSize: "16px" }}>
                  Commission $
                </div>
                <div className="col-md-3 col-sm-1 col-xs-1 col-lg-3">
                  <NumericTextBox
                    className=""
                    width="100%"
                    name="compensation_comission_low"
                    placeholder="LOW"
                    onChange={this.valueChange}
                    value={this.state.compensation_comission_low}
                    min={0}
                    step={1}
                  />
                </div>
                <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                <div className="col-md-3 col-sm-1 col-xs-1 col-lg-3">
                  <NumericTextBox
                    className=""
                    name="compensation_comission_high"
                    width="100%"
                    placeholder="HIGH"
                    onChange={this.valueChange}
                    value={this.state.compensation_comission_high}
                    min={0}
                    step={1}
                  />
                  <div style={{ color: "red" }}>
                    {this.state.message}
                    {this.state.compensation_comission_error_message}
                  </div>
                </div>
              </div>
              {this.state.role_stage === 'PUBLIC' &&
                this.state.equity_type === "Stock Options" ?
                <div className="mb-3 row">
                  <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                  <div className="col-md-3 col-sm-11 col-xs-11 col-lg-3" style={{ fontSize: "16px" }}>
                    Equity $
                </div>
                  <div className="col-md-3 col-sm-1 col-xs-1 col-lg-3">
                    <NumericTextBox
                      className=""
                      width="100%"
                      name="compensation_equity_low"
                      placeholder="LOW"
                      onChange={this.valueChange}
                      value={this.state.compensation_equity_low}
                      min={0}
                      step={1}
                      disabled="disabled"
                    />
                  </div>
                  <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                  <div className="col-md-3 col-sm-1 col-xs-1 col-lg-3">
                    <NumericTextBox
                      className=""
                      name="compensation_equity_high"
                      width="100%"
                      placeholder="HIGH"
                      onChange={this.valueChange}
                      value={this.state.compensation_equity_high}
                      min={0}
                      step={1}
                      disabled="disabled"
                    />
                    <div style={{ color: "red" }}>
                      {this.state.message}
                      {this.state.compensation_equity_error_message}
                    </div>
                  </div>
                </div> : false}
              <div className="mb-3 row">
                <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                <div className="col-md-3 col-sm-11 col-xs-11 col-lg-3" style={{ fontSize: "16px" }}>
                  Sign-On $
                </div>
                <div className="col-md-3 col-sm-1 col-xs-1 col-lg-3">
                  <NumericTextBox
                    className=""
                    width="100%"
                    name="compensation_sign_on_low"
                    placeholder="LOW"
                    min={0}
                    step={1}
                    onChange={this.valueChange}
                    value={this.state.compensation_sign_on_low}
                  />
                </div>
                <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                <div className="col-md-3 col-sm-1 col-xs-1 col-lg-3">
                  <NumericTextBox
                    className=""
                    name="compensation_sign_on_high"
                    width="100%"
                    placeholder="HIGH"
                    min={0}
                    step={1}
                    onChange={this.valueChange}
                    value={this.state.compensation_sign_on_high}
                  />
                  <div style={{ color: "red" }}>
                    {this.state.message}
                    {this.state.compensation_sign_on_error_message}
                  </div>
                </div>
              </div>
              <div className="mb-3 row">
                <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                <div className="col-md-3 col-sm-11 col-xs-11 col-lg-3" style={{ fontSize: "16px" }}>
                  Total
                </div>
                <div className="col-md-3 col-sm-1 col-xs-1 col-lg-3">
                  <NumericTextBox
                    className=""
                    width="130%"
                    name="compensation_total_low"
                    placeholder="LOW"
                    step={1}
                    onChange={this.valueChange}
                    required={true}
                    format="c2"
                    value={this.state.compensation_total_low}
                    disabled="disabled"
                  />
                </div>
                <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                <div className="col-md-3 col-sm-1 col-xs-1 col-lg-3">
                  <NumericTextBox
                    className=""
                    name="compensation_total_high"
                    format="c2"
                    width="130%"
                    placeholder="HIGH"
                    step={1}
                    required={true}
                    onChange={this.valueChange}
                    value={this.state.compensation_total_high}
                    disabled="disabled"
                  />
                  <div style={{ color: "red" }}>
                    {this.state.message}
                    {this.state.compensation_total_error_message}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4"></div>
                <div className="col-md-2 col-xs-6 col-sm-6 col-lg-2">
                  <Button className="mt-3 col-md-12 col-xs-12 col-sm-12 col-lg-12" type="submit" primary={true} >Save</Button>
                </div>
                <div className="col-md-2 col-xs-6 col-sm-6 col-lg-2" style={{ border: "1px slid red" }}>
                  <Link type="button" to="/home/role" className="k-button col-md-12 col-xs-12 col-sm-12 col-lg-12" style={{ marginTop: "16px" }}>CANCEL</Link>
                </div>
              </div>

            </div>

          </form>
        </div>

      </div>
    );
  }
}

UpdateRole.propTypes = {
  auth: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  role: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  role: state.role,
  lookups: state.lookups,
  estimateDashboard: state.estimateDashboard
});
export default connect(mapStateToProps, {
  fnGetUpdateHires,
  getCompanyLookUpData,
  getTitleLookUpData,
  getFunctionLookUpData,
  getStageLookUpData,
  getgraphAPIListMarketSpaceLookUpData,
  getCompanyLocationRegionLookUpData,
  fnEstimateDashboard,
  fnComparisonCompensationEstimateDashboard
}
)(UpdateRole);