import axios from "axios";
import { SET_SCHOOL_PAY, 
         GET_SCHOOL_PAY, 
         CLEAR_ERRORS 
       } from "./types";

export const fnGetLookupAllSchoolpay = (id) => dispatch => {
  dispatch(clearErrors());

  axios
    .get("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-lookup-admin-education-school-pay-get-grid")
    .then(res => {
      const SchoolData = res.data.data;
      const SchoolList = []
      for (let i = 0; i < SchoolData.length; i++) {
        SchoolList.push(SchoolData[i])
      }
      dispatch(setGetAllSchoolpay(SchoolList));
    })
    .catch(err => {
      console.log(err)
    }
    );
};
//Insert
export const fnInsertLookupAllSchoolpay = (userData) => dispatch => {
  dispatch(clearErrors());
  axios
    .post("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-lookup-admin-education-school-pay-post-insert", userData)
    .then(res => {
      console.log(res.data.message)
    })
    .catch(err => { console.log(err) });
};
// //update
export const fnUpdateLookupAllSchoolpay = (userData) => dispatch => {
  dispatch(clearErrors());
  axios
    .post("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-lookup-admin-education-school-pay-post-update", userData)
    .then(res => {
      console.log(res.data.message)
    })
    .catch(err => { console.log(err) });
};

export const fnDeleteLookupAllSchoolpay = (userData) => dispatch => {
  dispatch(clearErrors());
  
  axios
    .delete("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-lookup-admin-education-school-pay-del?education_school_pay_id="+ userData)
    .then(res => {
      console.log(res.data.message)
    })
    .catch(err => { console.log(err) });
};




//GetAllProfile
export const setGetAllSchoolpay = (Schoolpay) => {
  return {
    type: GET_SCHOOL_PAY,
    payload: Schoolpay
  };
};

// clear errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS
  };
};
      
