import React from 'react'
import '../../../../css/dashboard.css';

import { Link } from "react-router-dom";
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { DropDownButton, DropDownButtonItem } from '@progress/kendo-react-buttons'
import OfferDashCandidateGridAddButton from './offerDashboardCandidateGridAddButton';
import { fnGetAllCandidatesFromOfferDashboard } from "../../../../actions/roleCandidateAction"
import FileUpload from './OfferUpload';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { candidateDataAction } from "../../../../actions/candidateOffersAction";
import MyOffergridbutttons from './Offerdashgrid_editdeletebuttons';
import {
  fnGetAllRoleCandidate,
  fnDeleteRoleCandidate,
  fnDeleteCandidateFromOfferDashboard
} from "../../../../actions/roleCandidateAction";
import { CSVLink } from "react-csv";
import OfferDashUpdateCandidateAddButton from './offerDashboardUpdateCandidate';
import { Button } from '@progress/kendo-react-buttons';
import * as XLSX from 'xlsx';
import { FnInsertRoleCandidates } from "../../../../actions/roleCandidateAction";
import { fnFetRecommendedValue, fnFetOfferStrengthValue } from "../../../../actions/roleHiringTeamMemberAction";
import Spinner from "../../../spinner/loading"

class OfferDashboardCandidateGrid extends React.Component {
  anchor = null;
  editField = "inEdit";
  OffergridButtons;

  constructor(props) {
    super(props);

    this.state = {
      selected: 0,
      CandidateList: [],
      success: false,
      skip: 0, take: 10, sort: [
        { field: 'role_title', dir: 'asc' }
      ],
      selectedFile: null,
      uploadErrorMessage: "",
      gridFlag: 0,
      offer_strength_recommended_ids: 1,
      offer_strength_recommended_null: 1,
      loading: true,
    }
    this.OffergridButtons = MyOffergridbutttons("inEdit", this.props);
  }
  handleSelect = (e) => {
    this.setState({ selected: e.selected })
  }
  state = { skip: 0, take: 10 }

  pageChange = (event) => {
    this.setState({
      skip: event.page.skip,
      take: event.page.take
    });
  }

  componentDidMount() {
    if (this.props.location.search !== "") {
      let role_id = this.props.location.search.split('?user_id=')[1];
      let hr_id = this.props.auth.user._id
      let query = {
        new_hire_id: role_id,
        hr_id: hr_id
      }
      this.props.fnGetAllCandidatesFromOfferDashboard(query);
    }
  }

  onIconFlipOpen = (event) => {
    this.setState({
      popup_event: event,
      visible: false
    })
  }

  inputFileSave(event) {
    if (this.props.location.search !== "") {
      const file = event.target.files[0];
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;
      reader.onload = (e) => {
        const uploadCandidateData = e.target.result;
        const uploadCandidateDataWorkBook = XLSX.read(uploadCandidateData, { type: rABS ? 'binary' : 'array', bookVBA: true });
        /* Get first worksheet */
        const uploadCandidateWorkSheetName = uploadCandidateDataWorkBook.SheetNames[0];
        const uploadCandidateWorkSheet = uploadCandidateDataWorkBook.Sheets[uploadCandidateWorkSheetName];
        /* Convert array of arrays */
        const uploadCandidateJsondata = XLSX.utils.sheet_to_json(uploadCandidateWorkSheet);

        for (let i = 0; i < uploadCandidateJsondata.length; i++) {
          const uploadCandidateWorkSheetData = {
            hr_id: this.props.auth.user._id,
            new_hire_id: this.props.location.search.slice(9),
            first_name: uploadCandidateJsondata[i]['name'].split(" ")[0],
            last_name: uploadCandidateJsondata[i]['name'].split(" ")[1],
            email: uploadCandidateJsondata[i]["Email"],
            location_state: uploadCandidateJsondata[i]['Location (city, state)'] ? uploadCandidateJsondata[i]['Location (city, state)'].split(",")[1] : "",
            location_city: uploadCandidateJsondata[i]['Location (city, state)'] ? uploadCandidateJsondata[i]['Location (city, state)'].split(",")[0] : "",
            experience: {
              company: uploadCandidateJsondata[i]['Company'],
              market_space: uploadCandidateJsondata[i]['Market Space'],
              stage: uploadCandidateJsondata[i]['Stage'],
              employees_count: 0,
              region: ""
            },
            overall_experience: {
              title_level: uploadCandidateJsondata[i]['Title'],
              job_function: uploadCandidateJsondata[i]['Function'],
            },
            salary: {
              base_salary: uploadCandidateJsondata[i]['Base (thousands)'] * 1000,
              annual_bonus: uploadCandidateJsondata[i]['Bonus (thousands)'] * 1000,
              commission: uploadCandidateJsondata[i]['Equity %'],
              equity_percent: uploadCandidateJsondata[i]['Equity %'],
              equity_dollar_value: uploadCandidateJsondata[i]['Annual Equity ($) (thousands)'] * 1000,
              total_dollar_value: uploadCandidateJsondata[i]['Total Package (thousands)'] * 1000,
              candidate_accepted: 0,
              equity_type_rsu: false,
              equity_type_stock: false,
            }
          };
          this.props.FnInsertRoleCandidates(uploadCandidateWorkSheetData, this.props.history, this.props.location.search.slice(9))
          setTimeout(() => {
            let role_id = this.props.location.search.split('?user_id=')[1];
            let hr_id = this.props.auth.user._id
            let query = {
              new_hire_id: role_id,
              hr_id: hr_id
            }
            this.props.fnGetAllCandidatesFromOfferDashboard(query);
          }, 1000)

        }
      }
      reader.readAsBinaryString(file);
    } else {
      this.setState({
        uploadErrorMessage: "Please select a role to upload candidates"
      })
    }
  }
  render() {

    const items = [
      { text: 'UPLOAD REPORT', value: 'upload_report', click: "upload" },
      { text: 'DOWNLOAD REPORT', click: "CSVLink" }
    ];

    if (this.props.candidates.offerCandidatesData === null) {
      this.state.CandidateList = []
      this.state.csvData =
        [{
          name: '',
          Title: '',
          Function: '',
          Company: '',
          "Base (thousands)": '',
          "Bonus (thousands)": '',
          "Cash (thousands)": '',
          "Annual Equity ($) (thousands)": '',
          "Equity %": '',
          "Total Package (thousands)": '',
          "Location (city, state)": '',
          "Stage": '',
          "Market Space": '',
          "Estimated Valuation (millions)": ''
        }]

    } else {
      this.state.CandidateList = this.props.candidates.offerCandidatesData
      this.state.loading = false

      if (this.props.location.search !== "") {
        if (this.props.candidates.offerCandidatesData[0] !== undefined) {

          var selected_candidate_total = this.props.candidates.offerCandidatesData[0]['salary'] ? this.props.candidates.offerCandidatesData[0]['salary']['total_dollar_value'] : 0

          var offer_finalist = this.props.candidates.offerCandidatesData[0]['salary'] ? this.props.candidates.offerCandidatesData[0]['salary']['total_dollar_value'] : 0;

          var offer_total_dollar_value = new Intl.NumberFormat({ maximumSignificantDigits: 3 }).format(this.props.candidates.offerCandidatesData[0]['salary'] ? this.props.candidates.offerCandidatesData[0]['salary']['total_dollar_value'] : 0)

          var offer_base = this.props.candidates.offerCandidatesData[0]['salary'] ? this.props.candidates.offerCandidatesData[0]['salary']['base_salary'] : 0

          var offer_annual_bonus = this.props.candidates.offerCandidatesData[0]['salary'] ? this.props.candidates.offerCandidatesData[0]['salary']['annual_bonus'] : 0

          var offer_equity_dollar_value = this.props.candidates.offerCandidatesData[0]['salary'] ? this.props.candidates.offerCandidatesData[0]['salary']['equity_dollar_value'] : 0

          var candidate_pool = []
          var candidate_pool_base = []
          var candidate_pool_bonus = []
          var candidate_pool_Equity = []
          var candidate_pool_total_value = 0;
          var candidatePoolBaseValueTotal = 0;
          var candidatePoolBonusValueTotal = 0;
          var candidatePoolEquityValueTotal = 0;
          var csvdata_firstname_lastname = []
          var csvDataDone = []

          for (let i = 0; i < this.props.candidates.offerCandidatesData.length; i++) {
            candidate_pool = this.props.candidates.offerCandidatesData[i]['salary'] ? this.props.candidates.offerCandidatesData[i]['salary']['total_dollar_value'] : 0

            candidate_pool_base = this.props.candidates.offerCandidatesData[i]['salary'] ?
              this.props.candidates.offerCandidatesData[i]['salary']['base_salary'] : 0

            candidate_pool_bonus = this.props.candidates.offerCandidatesData[i]['salary'] ?
              this.props.candidates.offerCandidatesData[i]['salary']['annual_bonus'] : 0

            candidate_pool_Equity = this.props.candidates.offerCandidatesData[i]['salary'] ?
              this.props.candidates.offerCandidatesData[i]['salary']['equity_dollar_value'] : 0

            csvdata_firstname_lastname = this.props.candidates.offerCandidatesData[i]['first_name']
            candidate_pool_total_value += parseInt(candidate_pool);
            candidatePoolBaseValueTotal += parseInt(candidate_pool_base);
            candidatePoolBonusValueTotal += parseInt(candidate_pool_bonus);
            candidatePoolEquityValueTotal += parseInt(candidate_pool_Equity);

            // csv data
            var csvHoleData =
            {
              name: csvdata_firstname_lastname,
              // Email: this.props.candidates.offerCandidatesData[i]['email'],
              Title: this.props.candidates.offerCandidatesData[i]['overall_experience'] ? this.props.candidates.offerCandidatesData[i]['overall_experience']['title_level'] : null,

              Function: this.props.candidates.offerCandidatesData[i]['overall_experience'] ? this.props.candidates.offerCandidatesData[i]['overall_experience']['job_function'] : null,

              Company: this.props.candidates.offerCandidatesData[i]['experience'] ? this.props.candidates.offerCandidatesData[i]['experience']['company'] : "",

              "Base (thousands)": this.props.candidates.offerCandidatesData[i]['salary'] ? parseInt(this.props.candidates.offerCandidatesData[i]['salary']['base_salary']) / 1000 : 0,

              "Bonus (thousands)": this.props.candidates.offerCandidatesData[i]['salary'] ? parseInt(this.props.candidates.offerCandidatesData[i]['salary']['annual_bonus']) / 1000 : 0,

              "Cash (thousands)": this.props.candidates.offerCandidatesData[i]['salary'] ? (parseInt(this.props.candidates.offerCandidatesData[i]['salary']['base_salary']) + parseInt(this.props.candidates.offerCandidatesData[i]['salary']['annual_bonus'])) / 1000 : 0,

              "Annual Equity ($) (thousands)": this.props.candidates.offerCandidatesData[i]['salary'] ? parseInt(this.props.candidates.offerCandidatesData[i]['salary']['equity_dollar_value']) / 1000 : 0,

              "Equity %": this.props.candidates.offerCandidatesData[i]['salary'] ? parseInt(this.props.candidates.offerCandidatesData[i]['salary']['equity_dollar_value']) : 0,

              "Total Package (thousands)": this.props.candidates.offerCandidatesData[i]['salary'] ? parseInt(this.props.candidates.offerCandidatesData[i]['salary']['total_dollar_value']) / 1000 : 0,

              "Location (city, state)": this.props.candidates.offerCandidatesData[i]['location_city'] + "," + this.props.candidates.offerCandidatesData[i]['location_state'],
              "Stage": this.props.candidates.offerCandidatesData[i]['experience'] ? this.props.candidates.offerCandidatesData[i]['experience']['stage'] : "",
              "Market Space": this.props.candidates.offerCandidatesData[i]['experience'] ? this.props.candidates.offerCandidatesData[i]['experience']['market_space'] : "",

              "Estimated Valuation (millions)": this.props.candidates.offerCandidatesData[i]['salary'] ? parseInt(this.props.candidates.offerCandidatesData[i]['salary']['equity_dollar_value']) : 0

            }
            csvDataDone.push(csvHoleData)
          }
          var candidate_pool_line_chart_cash = candidatePoolBaseValueTotal + candidatePoolBonusValueTotal
          var candidate_pool_line_chart_Equity = candidatePoolEquityValueTotal
          var candidate_pool_line_chart_Total = candidate_pool_line_chart_cash + candidate_pool_line_chart_Equity
          var offer_base_percentile = '75'
          var offer_bonus_percentile = '75'
          var offer_Equity_percentile = '75'
          var offer_offerStrength = selected_candidate_total / (candidate_pool_total_value / this.props.candidates.offerCandidatesData.length)

          if (offer_offerStrength < 0.9) {
            offer_offerStrength = offer_offerStrength * 0.6 * 100;
          } else if (0.9 <= offer_offerStrength < 1.0) {
            offer_offerStrength = offer_offerStrength * 0.9 * 100;
          } else if (1 <= offer_offerStrength <= 1.2) {
            offer_offerStrength = 92;
          } else if (offer_offerStrength > 1.2) {
            offer_offerStrength = 95;
          }


          var offer_recommended_total = 0

          if (selected_candidate_total >= candidate_pool_total_value) {
            offer_recommended_total = 1.10 * selected_candidate_total;
          } else {
            offer_recommended_total = 1.15 * selected_candidate_total;
          }

          var candidate_pool_Average_value = candidate_pool_total_value / this.props.candidates.offerCandidatesData.length
          if (this.props.candidates.changeCandidateLkpFlag === 0) {
            this.props.candidateDataAction(candidate_pool_Average_value, offer_total_dollar_value, offer_base, offer_annual_bonus, offer_equity_dollar_value, candidate_pool_line_chart_cash, candidate_pool_line_chart_Equity, candidate_pool_line_chart_Total, offer_finalist, offer_offerStrength, offer_recommended_total)
          }

          this.state.csvData = csvDataDone
        }
        if (this.props.candidates.offerCandidatesData.length === 0) {
          this.props.candidateDataAction('0', '0', null, null, null, '', '', '', '0', 'offer_offerStrength', '0', 0, 0, 0)
        }

      } else {
        this.state.CandidateList = []
        this.state.csvData =
          [{
            name: '',
            Title: '',
            Function: '',
            Company: '',
            "Base (thousands)": '',
            "Bonus (thousands)": '',
            "Cash (thousands)": '',
            "Annual Equity ($) (thousands)": '',
            "Equity %": '',
            "Total Package (thousands)": '',
            "Location (city, state)": '',
            "Stage": '',
            "Market Space": '',
            "Estimated Valuation (millions)": ''
          }]
        this.props.candidateDataAction('0', '0', null, null, null, '', '', '', '0', 'offer_offerStrength', '0', 0, 0, 0)
      }
    }

    const itemRender = (props) => {
      return (
        <div style={{ width: "150px" }}>
          <span role="presentation" />
          {`${props.item.text}`}
        </div>
      );
    };
    if (this.props.location.search !== "") {
      var AddButtonURL = this.props.location.pathname + this.props.location.search
    } else {
      var AddButtonURL = "#"
    }
    if (this.state.csvData === undefined) {
      this.state.csvData =
        [{
          name: '',
          Title: '',
          Function: '',
          Company: '',
          "Base (thousands)": '',
          "Bonus (thousands)": '',
          "Cash (thousands)": '',
          "Annual Equity ($) (thousands)": '',
          "Equity %": '',
          "Total Package (thousands)": '',
          "Location (city, state)": '',
          "Stage": '',
          "Market Space": '',
          "Estimated Valuation (millions)": ''
        }]
    }
    console.log("this.state.csvData")
    console.log(this.state.csvData)
    console.log("this.state.csvData")
    const itemRender1 = (props) => {
      return (
        <div className="col-md-12" >
          {
            props.item.click === "CSVLink" ? <CSVLink data={this.state.csvData} filename="candidateList.xlsx" style={{ color: "#212529", textDecoration: "none" }}>{`${props.item.text}`}</CSVLink> : <div > <Button style={{ paddingLeft: "0px" }} onClick={(e) => this.upload.click()}>Upload Report</Button>
            </div>
          }
        </div >
      );
    };

    return (
      <React.Fragment>
        <div>
          {this.state.popup_event === "upload_report" ?
            <div>{<FileUpload />}</div> : null
          }

          <input id="myInput" type="file" ref={(ref) => this.upload = ref} onChange={this.inputFileSave.bind(this)} style={{ display: 'none' }} />
          <div className="row " style={{ paddingTop: "0px", marginTop: "30px", borderTop: "1px solid #d9d9d9" }}>
            <div className="col-md-12 div-offer-candidate-grid">
              <h3 className="offer-candidate-h3"> Candidates <small className="offer-candidate-h3-small">profiles</small>
                <div className="col-md-2 float-right offer-candidate-popup-button" style={{ paddingRight: "0px" }} title="more">
                  <DropDownButton icon="k-icon k-i-more-vertical" dir="rtl" itemRender={itemRender1}
                    items={items}
                    title="more" />
                </div>

                <Link to={AddButtonURL}>
                  <span data-toggle="modal" data-target="#myModal" title="Add" className="k-icon k-i-plus k-icon-0 float-right offer-candidate-h3-icon-plus">

                  </span>
                </Link>
                <div className="col-md-12" style={{ color: "red", textAlign: "right", fontSize: "16px" }}>
                  {this.state.uploadErrorMessage}
                </div>
              </h3>

            </div>

            <OfferDashCandidateGridAddButton data-target="#myModal" location={this.props.location} history={this.props.history} />
            <OfferDashUpdateCandidateAddButton data-target="#myModal1" location={this.props} candidateData={this.props.location} history={this.props.history} />

            <div className="col-md-12">
              {this.state.loading === true ? <Spinner /> : null}
              <Grid
                style={{ border: "0px" }}
                className=""
                data={this.state.CandidateList.slice(this.state.skip, this.state.take + this.state.skip)}
                skip={this.state.skip}
                take={this.state.take}
                total={this.state.CandidateList.length}
                pageable={true}
                onPageChange={this.pageChange}
                sortable
                sort={this.state.sort}
                onSortChange={this.sortChange}
              >
                <Column field="experience.company" title="COMPANY" />
                <Column field="first_name" filter="numeric" format="" title="NAME" />
                <Column field="overall_experience.title_level" filter="numeric" title="TITLE" />
                <Column field="overall_experience.job_function" filter="numeric" title="FUNCTION" />
                <Column field="salary.total_dollar_value_mul_1000" filter="numeric" format="{0:c}" title="TOTAL" />
                <Column field="salary.base_salary_mul_1000" filter="numeric" format="{0:c}" title="BASE ($)" />
                <Column field="salary.annual_bonus_mul_1000" filter="numeric" format="{0:c}" title="BONUS ($)" />
                <Column field="salary.equity_dollar_value_mul_1000" filter="numeric" format="{0:c}" title="EQUITY ($)" />
                <Column field="salary.equity_percent" filter="numeric" title="EQUITY (%)" />
                <Column cell={this.OffergridButtons} title="" />

              </Grid>

            </div>
            {/* ---- */}

            {/* ---- */}
          </div>
        </div>
      </React.Fragment>

    )
  }
}

OfferDashboardCandidateGrid.propTypes = {
  auth: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  offerCandidatesData: PropTypes.object.isRequired
};

const mapStateToProps = state =>
  ({
    auth: state.auth,
    offerCandidatesData: state.offerCandidatesData,
    candidates: state.candidates,
    hiringMembers: state.hiringMembers,
    recommendedValue: state.recommendedValue
  });

export default connect(mapStateToProps, {
  fnGetAllCandidatesFromOfferDashboard,
  candidateDataAction,
  FnInsertRoleCandidates,
  fnFetRecommendedValue,
  fnFetOfferStrengthValue
}
)(OfferDashboardCandidateGrid);
