import {
  SET_CURRENT_CANDIDATE_OFFERS,
  GET_CANDIDATE_OFFERS_LOADING,
  GET_SELECTED_CANDIDATES_DATA,
  GET_CANDIDATE_OFFERS,
  SET_CURRENT_ADD_UPDATE_CREATE_OFFER_DASHBOARD,
  SET_ROLE_VALUES_RIGHT_SIDE_OFFER_DASHBOARD,
  SET_CURRENT_MATCH_SEARCH_FLAG_OFFER_DASHBOARD
} from "../actions/types";

const initialState = {
  candidateOffers: {},
  candidateOffersData: null,
  candidate_pool_total_value: null,
  offer_total_dollar_value: null,
  offer_base: null,
  offer_annual_bonus: null,
  offer_equity_dollar_value: null,
  offer_base_percentile: null,
  offer_bonus_percentile: null,
  offer_Equity_percentile: null,
  offer_finalist: null,
  offer_offerStrength: null,
  offer_recommended_total: null,
  create_offer_chart_data: null,
  role_base: null, role_bonus: null, role_equity: null,
  rightSideDashboardData: null,
  matchSearchAverageFlag: null,
  loading: false
};

export default function (state = initialState, action) {

  switch (action.type) {
    case GET_CANDIDATE_OFFERS_LOADING:
      return {
        ...state,
        loading: true
      };
    case SET_CURRENT_CANDIDATE_OFFERS:
      return {
        ...state,
        candidateOffers: action.payload,
        loading: true
      };
    case GET_CANDIDATE_OFFERS:
      return {
        ...state,
        candidateOffersData: action.payload,
        loading: true
      };
    case GET_SELECTED_CANDIDATES_DATA:
      return {
        ...state,
        candidate_pool_total_value: action.candidate_pool_total_value,
        offer_total_dollar_value: action.offer_total_dollar_value,
        offer_base: action.offer_base,
        offer_annual_bonus: action.offer_annual_bonus,
        offer_equity_dollar_value: action.offer_equity_dollar_value,
        offer_base_percentile: action.offer_base_percentile, offer_bonus_percentile: action.offer_bonus_percentile, offer_Equity_percentile: action.offer_Equity_percentile,
        offer_finalist: action.offer_finalist,
        offer_offerStrength: action.offer_offerStrength,
        offer_recommended_total: action.offer_recommended_total,
        role_base: action.role_base, role_bonus: action.role_bonus, role_equity: action.role_equity,
        loading: true
      };
    case SET_CURRENT_ADD_UPDATE_CREATE_OFFER_DASHBOARD:
      return {
        ...state,
        create_offer_chart_data: action.payload,
        loading: true
      };

    case SET_ROLE_VALUES_RIGHT_SIDE_OFFER_DASHBOARD:
      return {
        ...state,
        rightSideDashboardData: action.payload,
        loading: true
      };

    case SET_CURRENT_MATCH_SEARCH_FLAG_OFFER_DASHBOARD:
      return {
        ...state,
        matchSearchAverageFlag: action.value,
        loading: true
      };
    
    default:
      return state;
  }
}
