import axios from "axios";
import {
  GET_COMPANY_LOOKUP_DETAILS,
  GET_COMPANY_INSERT_UPDATE_DATA,
  CLEAR_ERRORS
} from "./types";

export const fnGetLookupAllLookupCompanyDetails = (id) => dispatch => {
  dispatch(clearErrors());

  axios
    .get("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-lookup-admin-company-get-grid")
    .then(res => {
      const CompanyDetailsData = res.data.data;
      const CompanyDetails = []
      for (let i = 0; i < CompanyDetailsData.length; i++) {
        CompanyDetails.push(CompanyDetailsData[i])
      }
      dispatch(setGetAllLookupCompanyDetails(CompanyDetails));
    })
    .catch(err => {
      console.log(err)
    }
    );
};



export const fnInsertLookupCompany = (userData, history,screen) => dispatch => {
  dispatch(clearErrors());
  axios
    .post("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-lookup-admin-company-post-insert", userData)
    .then(res => {

      dispatch(setGetAllInsertUpdateData(res));
      if(screen === "admin_company"){
        history.push("/home/lookup-company")
      }
      if(screen === "profile"){
        history.push("/home/my-profile?add_company")
        setTimeout(() => {
          history.push("/home/my-profile")
        }, 1000)
      }
    })
    .catch(err => { console.log(err) });
};

export const fnUpdateLookupCompany = (userData, history) => dispatch => {
  dispatch(clearErrors());
  axios
    .post("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-lookup-admin-company-post-update", userData)
    .then(res => {
      dispatch(setGetAllInsertUpdateData(res));
      history.push("/home/lookup-company")
    })
    .catch(err => { console.log(err) });
};
export function fnDeleteCompanyLookup(id) {
  return axios
    .delete("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-lookup-admin-company-del?admin_lookup_company_name_id=" + id)
}

export function fnGetCompanyLookupDetail(userData) {
  return axios
    .get("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-lookup-admin-company-get-form?admin_lookup_company_name_id=" + userData)
}

//GetAllProfile
export const setGetAllLookupCompanyDetails = (CompanyDetails) => {
  return {
    type: GET_COMPANY_LOOKUP_DETAILS,
    payload: CompanyDetails
  };
};

//GetAllProfile
export const setGetAllInsertUpdateData = (CompanyDetails) => {
  return {
    type: GET_COMPANY_INSERT_UPDATE_DATA,
    payload: CompanyDetails
  };
};
// clear errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS
  };
};

