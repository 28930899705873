import React from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Grid, GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
import { orderBy } from '@progress/kendo-data-query';
import { MyCommandCell } from './actioneditbutton.js';
import { filterBy } from '@progress/kendo-data-query';
import { 
  fnGetLookupAllTitleAndFunctions,
  fnInsertLookupAllTitleandFunction,
  fnUpdateLookupAllTitleAndFunction,
  fnDeleteLookupAllTitleandFunction,
} from "../../actions/adminLookupTitleAndFunction";
import Spinner from "../spinner/loading";

class LookupTitlefunction extends React.Component {
  editField = "inEdit";
  CommandCell;


  constructor(props) {
    super(props);

    this.state = {
      data: [],
      action: "",
      filter: undefined,
      skip: 0,
      take: 8,
      loading: true,
    };

    this.CommandCell = MyCommandCell({
      edit: this.enterEdit,
      remove: this.remove,

      add: this.add,
      discard: this.discard,

      update: this.update,
      cancel: this.cancel,

      editField: this.editField
    });

  }
  filterChange = (event) => {
    this.setState({
      logic: "and",
        data: filterBy(this.props.adminLookupTitleAndFunction.adminLookupTitleAndFunction, event.filter),
        filter: event.filter
    });
}

  enterEdit = (dataItem) => {
    this.state.action = "action"

    this.setState({
      data: this.state.data.map(item =>
        item._id === dataItem._id ?
          { ...item, inEdit: true } : item
      )
    });
  }

  add = (dataItem) => {
    this.state.action = "actionsss"
    dataItem.inEdit = undefined;
    let insertTitleFunctionActive = 0
    if (dataItem["active"] === true) {
      insertTitleFunctionActive = 1
    }
    let insertDupeActive = 0
    if (dataItem["dupe"] === true) {
      insertDupeActive = 1
    }
    let insertDefaultActive = 0
    if (dataItem["default_flag"] === true) {
      insertDefaultActive = 1
    }
    const insertTitleAndFunctionData = {
      function: dataItem["function"],
      title: dataItem["title"],
      equivalent_title: dataItem["equivalent_title"],
      associated_functions: dataItem["associated_functions"],
      default_flag:insertDefaultActive,
      source: dataItem["source"],
      dupe: insertDupeActive,
      active: insertTitleFunctionActive
    }
    this.props.fnInsertLookupAllTitleandFunction(insertTitleAndFunctionData)
    this.props.adminLookupTitleAndFunction.adminLookupTitleAndFunction.unshift(dataItem);
    this.props.fnGetLookupAllTitleAndFunctions();
    
    this.setState({
      data: [...this.state.data]
    });

  }

  remove = (dataItem) => {
    this.state.action = "actionssssss"
    const data = [...this.state.data]
     this.props.fnDeleteLookupAllTitleandFunction(dataItem["_id"])
    //  this.props.fnGetLookupAllMarketSpace();
     this.removeItem(data, dataItem);
     this.removeItem(this.props.adminLookupTitleAndFunction.adminLookupTitleAndFunction, dataItem);
    
    this.setState({ data});
    
}
discard = (dataItem) => {
  const data = [ ...this.state.data ];
  this.removeItem(data, dataItem);

  this.setState({ data });
}

  update = (dataItem) => {
    this.state.action = "action"
    const data = [...this.state.data];
    const updatedItem = { ...dataItem, inEdit: undefined };
    let updateTitleFunctionActive = 0
    if (dataItem["active"] === true) {
      updateTitleFunctionActive = 1
    }
    // active
    let updateDupeTitleFunctionActive = 0
    if (dataItem["dupe"] === true) {
      updateDupeTitleFunctionActive = 1
    }
    let updateDefaultTitleFunctionActive = 0
    if (dataItem["default_flag"] === true) {
      updateDefaultTitleFunctionActive = 1
    }

    // active

    const updateTitleAndFunctionData = {
      admin_lookp_title_function_id: dataItem["_id"],
      function: dataItem["function"],
      title: dataItem["title"],
      equivalent_title: dataItem["equivalent_title"],
      associated_functions: dataItem["associated_functions"],
      default_flag:updateDefaultTitleFunctionActive,
      source: dataItem["source"],
      dupe: updateDupeTitleFunctionActive,
      active: updateTitleFunctionActive
    }

    this.props.fnUpdateLookupAllTitleAndFunction(updateTitleAndFunctionData)
    this.updateItem(data, updatedItem);
    this.updateItem(this.props.adminLookupTitleAndFunction.adminLookupTitleAndFunction, updatedItem);

    this.setState({ data });
  }

  cancel = (dataItem) => {
    this.state.action = "action"
    const originalItem = this.props.adminLookupTitleAndFunction.adminLookupTitleAndFunction.find(p => p._id === dataItem._id);
    const data = this.state.data.map(item => item._id === originalItem._id ? originalItem : item);

    this.setState({ data });
  }

  updateItem = (data, item) => {
    this.state.action = "action"
    let index = data.findIndex(p => p === item || (item._id && p._id === item._id));
    if (index >= 0) {
      data[index] = { ...item };
    }
  }

  itemChange = (event) => {
    this.state.action = "action"
    const data = this.state.data.map(item =>
      item._id === event.dataItem._id ?
        { ...item, [event.field]: event.value } : item
    );
    this.setState({ data });
  }

  addNew = () => {
    const newDataItem = { inEdit: true, active: true };
    var daaa = [newDataItem, ...this.state.data]
    this.state.action = "action"
    this.setState({
      data: [newDataItem, ...this.state.data]
    });
  }

  cancelCurrentChanges = () => {
    this.state.action = "action"
    this.setState({ data: this.props.adminLookupTitleAndFunction.adminLookupTitleAndFunction });
  }
  componentDidMount() {
    if (this.props.location.pathname === "/home/lookup-titlefunction") {
      this.props.fnGetLookupAllTitleAndFunctions();
        }

  }
  pageChange = (event) => {
    this.setState({
      skip: event.page.skip,
      take: event.page.take
    });
  }

  sortChange = (event) => {
    this.setState({
      data: this.getProducts(event.sort),
      sort: event.sort
    });
  }

  getProducts = (sort) => {
    this.state.action = "action"
    return orderBy(this.state.data, sort);
  }

  render() {
    if (this.state.action === "action") {
      this.state.data = this.state.data
    } else {
      if (this.state.filter != undefined) {
        this.state.data = this.state.data
            } else { 
              if (this.props.adminLookupTitleAndFunction.adminLookupTitleAndFunction != null) {
                const TitleAndFunctionsList = this.props.adminLookupTitleAndFunction.adminLookupTitleAndFunction
        
                const TitleAndFunctionsData  = []
                for (let i = 0; i < TitleAndFunctionsList.length; i++) {
                  if (TitleAndFunctionsList[i]['active'] === 1) {
                    TitleAndFunctionsList[i]['active'] = true
                  }
                  if (TitleAndFunctionsList[i]['active'] === 0) {
                    TitleAndFunctionsList[i]['active'] = false
                  }
                  TitleAndFunctionsData.push(TitleAndFunctionsList[i])
                }
                this.state.data = TitleAndFunctionsData 
                this.state.loading = false
              } else {
                this.state.data = this.state.data
              }
            }
      
    }

    const { data } = this.state;
    const hasEditedItem = data.some(p => p.inEdit);
    return (
      <div className="container-fluid role-main-div">
        {this.state.loading === true ? <Spinner /> : null}
        <Grid
          className="col-md-12 col-xs-12 col-lg-12 col-sm-12"
          // style={{ height: '420px' }}
          data={data}
          data={data.slice(this.state.skip, this.state.take + this.state.skip)}
          skip={this.state.skip}
          take={this.state.take}
          total={data.length}
          pageable={true}
          onPageChange={this.pageChange}
          onItemChange={this.itemChange}
          editField={this.editField}
          filterable={true}
          filter={this.state.filter}
          onFilterChange={this.filterChange}
            >
          <GridToolbar>
            <div className="row">
              <div className="col-md-6 col-xs-12 col-lg-6 col-sm-12" style={{ fontSize: "23px", color: "black", marginTop: "-10px" }}>             Level</div>
              <div className="col-md-6 col-xs-12 col-lg-6 col-sm-12">
                <button
                  title="Add new"
                  className="k-button k-danger"
                  onClick={this.addNew}
                  style={{ float: "right", border: "1px solid #3A4158" }}
                >
                  <span className="k-icon k-i-plus"></span>
                </button>
                {hasEditedItem && (
                  <button
                    title="Cancel current changes"
                    className="k-button"
                    onClick={this.cancelCurrentChanges}
                    style={{ float: "right" }}
                  >
                    Cancel current changes
                        </button>
                )}
              </div>


            </div>
          </GridToolbar>
          <Column width="250px" field="title"  title="LEVEL"  />
          <Column width="200px" field="equivalent_title"  filterable={false} title="EQUIVALENT TITLE" />
          <Column width="200px" field="function" filterable={false} title="FUNCTION"   />
          <Column width="200px" field="associated_functions" filterable={false} title="ASSOCIATED FUNCTION"  />
          <Column width="200px" field="default_flag" filter="boolean"  editor="boolean" title="DEFAULT" />
          <Column width="200px" field="source"  filterable={true} title="SOURCE" />
          <Column width="200px" field="dupe" title="DUPE"   filter="boolean" editor="boolean"  />
          <Column width="200px" field="active" title="ACTIVE" filterable={false} editor="boolean" />
          <Column width="200px" title="ACTION" filterable={false} cell={this.CommandCell} />
        </Grid>
      </div>
    );
  }
  generateId = data => data.reduce((acc, current) => Math.max(acc, current._id), 0) + 1;

  removeItem(data, item) {
    let index = data.findIndex(p => p === item || item._id && p._id === item._id);
    if (index >= 0) {
        data.splice(index, 1);
    }
}


}



LookupTitlefunction.propTypes = {
  auth: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  adminLookupTitleAndFunction: state.adminLookupTitleAndFunction
});

export default connect(mapStateToProps, { 
  fnGetLookupAllTitleAndFunctions,
  fnInsertLookupAllTitleandFunction,
  fnUpdateLookupAllTitleAndFunction,
  fnDeleteLookupAllTitleandFunction
 }
)(LookupTitlefunction);
