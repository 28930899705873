import {
  SET_SCHOOL_PAY,
  ROLES_LOADING,
  GET_SCHOOL_PAY
} from "../actions/types";

const initialState = {
  role: {},
  adminLookupSchoolPay: null,
  loading: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ROLES_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_SCHOOL_PAY:
      return {
        ...state,
        adminLookupSchoolPay: action.payload,
        loading: true
      };

    default:
      return state;
  }
}
