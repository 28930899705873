import { combineReducers } from "redux";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import roleReducer from "./roleReducer";
import profileReducer from "./profileReducer";
import lookUpReducer from "./lookUpReducer";
import estimateDashboardReducer from "./estimateDashboardReducer";
import hiringMembersReducer from "./roleHiringTeamMemberReducer";
import candidatesReducer from "./roleCandidateReducer";
import candidateProfileReducer from "./candidateProfileReducer";
import candidateOffersReducer from "./candidateOffersReducer";
import TitleAndFunctionsReducer from "./adminLookupTitleAndFunctionsReducer";
import AdminLookupCompanyReducer from "./adminLookupCompanyDataReducer";
import AdminLookupMarketSpaceReducer from "./adminLookupMarketSpaceReducer";
import AdminLookupFunctionReducer from "./adminLookupFunctionDataReducer";
import AdminLookupSchoolPayReducer from "./adminLookupSchoolPay";
import AdminLookupEducationReducer from "./adminLookupEducation";

export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  role: roleReducer,
  profile: profileReducer,
  lookups: lookUpReducer,
  estimateDashboard: estimateDashboardReducer,
  hiringMembers: hiringMembersReducer,
  candidates: candidatesReducer,
  candidateProfile: candidateProfileReducer,
  candidateOffers: candidateOffersReducer,
  adminLookupTitleAndFunction: TitleAndFunctionsReducer,
  adminLookupCompanyDetails: AdminLookupCompanyReducer,
  adminLookupMarketSpace: AdminLookupMarketSpaceReducer,
  adminLookupFunction: AdminLookupFunctionReducer,
  adminLookupSchoolPay: AdminLookupSchoolPayReducer,
  adminLookupEducation:AdminLookupEducationReducer
});
