import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import logo from "../../img/menu-logo-white.png";
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { Link } from "react-router-dom";
import { Input } from '@progress/kendo-react-inputs';
import { registeruser } from "../../actions/authAction";
import '../../css/registerWithEmail.css';
import FormValidator from '../../validation/FormValidator';
import validationParams from '../../validation/validationParams.json'

class RegisterClass extends Component {
  constructor(props) {
    super(props);
    this.validator = new FormValidator(validationParams.registerValidations);
    this.state = {
      HiringRole: "",
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      pwd_message: "",
      errors: {},
      validation: this.validator.valid()
    };
    this.onChange = this.onChange.bind(this);
    this.registerSubmit = this.registerSubmit.bind(this);
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.name === "HiringRole") {
      if (e.target.value == null) {
        this.setState({
          HiringRole: ""
        })
      }
    }
    if (e.target.name === "password") {
      if (e.target.value.length >= 8) {
        this.state.pwd_message = ""
      } else {
        this.state.pwd_message = "Password must contain at least Eight characters"
      }
    }
  };

  registerSubmit = (event) => {
    event.preventDefault();
    const newUser = {
      role: this.state.HiringRole,
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      email: this.state.email,
      password: this.state.password
    };
    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;
    if (validation.isValid) {
      if (this.state.password.length >= 8) {
        this.props.registeruser(newUser, this.props.history);
      }
    }
  }
  render() {
    let validation = this.submitted ?
      this.validator.validate(this.state) :
      this.state.validation
    const countries = [
      'Recruiter',
      'Investor',
      'Candidate',
      'Hiring Team',
      'Power User'
    ];
    var copySuccess = null;
    if (this.props.auth.isAuthenticated === false) {
      if (this.props.auth.userName !== "") {
        copySuccess = (
          <p className="heading" style={{ padding: "0px", color: "red" }}>{this.props.auth.userName}</p>
        );
      }
    }

    return (
      <div className="container-fluid landing">
        <div className="row">
          <div className="col-md-12">
            <div className="col-md-8 offset-md-1">
              <div className="signup-card-box" style={{marginTop: "4%"}}>
                <p className="register-with-email-paragraph">
                  Sign up to personalize Apollo to you 
                </p>
                <div className="col-md-12">
                  <div className="row">
                    <div className="signup-with-email-logo">
                      <img
                        ref={(image) => this.image = image}
                        src={logo}
                        alt="Empty"
                        width="100%" height="82px"
                        style={{ backgroundColor: "#3A4158" }}
                      />
                    </div>
                  </div>
                </div>   
                <div className="col-md-12">
                  <div className="row">
                    <div className="signup-with-email-logo">
                      <form className="k-form" onSubmit={this.registerSubmit}>
                        <fieldset style={{ marginBottom: "20px" }}>
                          
                          <ComboBox
                            name="HiringRole"
                            onChange={this.onChange}
                            className="signup-with-email-compboBox-field"
                            data={countries} Register
                            placeholder="Your Role"
                          />
                          <div style={{
                            color: "red",
                            paddingLeft: "30px"
                          }}>
                            {validation.HiringRole.message}
                          </div>
                          <div className="mb-3 ">
                            <Input
                              className="signup-with-email-input-field"
                              name="first_name"
                              type="text"
                              onChange={this.onChange}
                              placeholder="First Name"
                              minLength={3}
                            />
                            <div style={{
                              color: "red",
                              paddingLeft: "30px"
                            }}>
                              {validation.first_name.message}
                            </div>

                          </div>

                          <div className="mb-3">
                            <Input
                              name="last_name"
                              type="text"
                              className="signup-with-email-input-field"
                              onChange={this.onChange}
                              placeholder="Last Name"
                              minLength={3}
                            />
                            <div style={{
                              color: "red",
                              paddingLeft: "30px"
                            }}>
                              {validation.last_name.message}
                            </div>
                          </div>
                          <div className="mb-3">
                            <Input
                              name="email"
                              className="signup-with-email-input-field"
                              type="email"
                              onChange={this.onChange}
                              placeholder="Email"
                            />
                            <div style={{
                              color: "red",
                              paddingLeft: "30px"
                            }}>
                              {validation.email.message}
                            </div>

                          </div>

                          <div className="mb-3">
                            <Input
                              name="password"
                              className="signup-with-email-input-field"
                              type="password"
                              placeholder="Password"
                              onChange={this.onChange}
                              minLength={0}
                              maxLength={18}
                            />
                            <div style={{
                              color: "red",
                              paddingLeft: "30px"
                            }}>
                              {validation.password.message}
                              {this.state.pwd_message}
                            </div>

                          </div>
                          {copySuccess}
                    </fieldset>
                    <div style={{
                      marginTop: " ",
                      marginLeft: "25px"
                    }}>
                      <input type="submit" className="k-button k-primary LoginButton" value="Sign Up" style={{ width: "100%", textTransform: "none", backgroundColor: "#4a89dc", fontSize: "16px", marginLeft: "-10px", marginTop: "-10px" }} />
                    </div>
                    <div>
                      <p style={{ fontSize: "16px", textAlign: "center", marginLeft: "-15px" }}
                        className="">
                        Already have an account?
                        <Link className=""
                          to="/login"
                          style={{
                            fontSize: "16px",
                            color: "#22aae4",
                            marginLeft: "5px"
                          }}>
                          Sign in
                        </Link>
                      </p>
                    </div>

                  </form>
                    </div>
                  </div>
                </div>             
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
RegisterClass.propTypes = {
  registeruser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});
// export default Register;
export default connect(
  mapStateToProps,
  { registeruser }
)(withRouter(RegisterClass));