import { filterBy } from '@progress/kendo-data-query';

export function titlesDataSort(value, lookupDataTitle) {
  const titleLookupData = lookupDataTitle;
  const filter = {
    value: value,
    operator: 'startswith',
    ignoreCase: true
  };
  return value ? filterBy(titleLookupData, filter) : titleLookupData;
}

export function revenueDataSort(value, lookupDatarevenue) {
  const revenueLookupData = lookupDatarevenue;
  const filter = {
    value: value,
    operator: 'startswith',
    ignoreCase: true
  };
  return value ? filterBy(revenueLookupData, filter) : revenueLookupData;
}

export function functionalityDataSort(value, lookupDatafunctionality) {
  const functionalityLookupData = lookupDatafunctionality;
  const filter = {
    value: value,
    operator: 'startswith',
    ignoreCase: true
  };
  return value ? filterBy(functionalityLookupData, filter) : functionalityLookupData;
}

export function cityDataSort(value, lookupDataCompanyLocation) {
  const cityLookupData = lookupDataCompanyLocation;
  const filter = {
    value: value,
    operator: 'startswith',
    ignoreCase: true
  };
  return value ? filterBy(cityLookupData, filter) : cityLookupData;
}

export function regionDataSort(value, lookupDataCompanyRegion) {
  const regionLookupData = lookupDataCompanyRegion;
  const filter = {
    value: value,
    operator: 'contains',
    ignoreCase: true
  };
  return value ? filterBy(regionLookupData, filter) : regionLookupData;
}

export function stateDataSort(value, lookupDataCompanyState) {
  const stateLookupData = lookupDataCompanyState;
  const filter = {
    value: value,
    operator: 'startswith',
    ignoreCase: true
  };
  return value ? filterBy(stateLookupData, filter) : stateLookupData;
}

export function degreeDataSort(value, lookupDataDegree) {
  const degreeLookupData = lookupDataDegree;
  const filter = {
    value: value,
    operator: 'startswith',
    ignoreCase: true
  };
  return value ? filterBy(degreeLookupData, filter) : degreeLookupData;
}

export function graduationYearDataSort(value, lookupDataGraduationYear) {
  const graduationYearLookupData = lookupDataGraduationYear;
  const filter = {
    value: value,
    operator: 'startswith',
    ignoreCase: true
  };
  return value ? filterBy(graduationYearLookupData, filter) : graduationYearLookupData;
}

export function stageDataSort(value, lookupDataStage) {
  const stageLookupData = lookupDataStage;
  const filter = {
    value: value,
    operator: 'contains',
    ignoreCase: true
  };
  return value ? filterBy(stageLookupData, filter) : stageLookupData;
}

export function marketSpaceDataSort(value, lookupDataMarketSpace) {
  const marketSpaceLookupData = lookupDataMarketSpace;
  const filter = {
    value: value,
    operator: 'contains',
    ignoreCase: true
  };
  return value ? filterBy(marketSpaceLookupData, filter) : marketSpaceLookupData;
}

export function employeesDataSort(value, lookupDataEmployee) {
  const employeeLookupData = lookupDataEmployee;
  const filter = {
    value: value,
    operator: 'startswith',
    ignoreCase: true
  };
  return value ? filterBy(employeeLookupData, filter) : employeeLookupData;
}

export function companyForEmployessDataSort(value, lookupDataCompanyForEmployees) {
  const companyForEmployeeLookupData = lookupDataCompanyForEmployees;
  const filter = {
    value: value,
    operator: 'startswith',
    ignoreCase: true
  };
  return value ? filterBy(companyForEmployeeLookupData, filter) : companyForEmployeeLookupData;
}

export function companyDataSort(value, lookupDataCompany) {
  const companyLookupData = lookupDataCompany;
  const filter = {
    value: value,
    operator: 'startswith',
    ignoreCase: true
  };
  return value ? filterBy(companyLookupData, filter) : companyLookupData;
}