import React from 'react'
import '../../../../css/dashboard.css';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { NumericTextBox } from '@progress/kendo-react-inputs';
import { AutoComplete } from '@progress/kendo-react-dropdowns';
class rateOfferDashboardBody extends React.Component {
  anchor = null;
  constructor(props) {
    super(props);
    this.state = {
      selected: 0,
      base_percentile: 64,
      Company: "Google",
      Title: "VP Software Engineering",
      Location: "Boston",
      Base: "168200",
      Bonus: "0.2",
      annual_equity: "100000"
    };
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  render() {
    return (
      <div className="row col-md-12 rate_offer_circle_div">
        <div className="col-md-10 col-lg-10 col-sm-12 col-xs-12">
          <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12" style={{ height: "25px", paddingTop: "15px" }}>
            <div className="rate_offer_dashboard_icon_save" onClick={this.onClick} style={{ cursor: "pointer" }}>
              <i title="Add Power User" className="fa fa-floppy-o rate_offer_dashboard_floppy_icon" aria-hidden="true">
              </i>
            </div>
          </div>
          <div className="row" style={{ paddingLeft: "20px", marginBottom: "20px", marginTop: "15px" }}>
            <div className="col-md-1"></div>
            <div className="row col-md-11 paddingRight">
              <div className="col-md-3 paddingRight" >
                <AutoComplete
                  name="Company"
                  onChange={this.onChange}
                  label="Company"
                  value={this.state.Company}
                  style={{ width: '100%' }}
                  className="inputDotClassRateOffer"
                />
              </div>
              <div className="col-md-6 paddingRight" >
                <AutoComplete
                  name="Title"
                  onChange={this.onChange}
                  label="Title"
                  value={this.state.Title}
                  style={{ width: '100%' }}
                  className="inputDotClassRateOffer"
                />
              </div>
              <div className="col-md-3 paddingRight" >
                <AutoComplete
                  name="Location"
                  onChange={this.onChange}
                  label="Location"
                  value={this.state.Location}
                  style={{ width: '100%' }}
                  className="inputDotClassRateOffer"
                />
              </div>
            </div>
            {/* <div className="float-right col-md-1"></div> */}
          </div>
          <div className="rate_offer_circle_div2">
            <div style={{ color: "#fff", fontSize: "15px" }}>
              <b style={{ fontSize: "28px" }}>{this.state.base_percentile}</b>/100
          </div>
          </div>
          <div style={{ color: "rgba(0,0,0,0.65)" }}>Target Rating</div>
          <div className="row col-md-12">
            <div className="col-md-3 col-sm-12 col-xs-12 col-lg-3" style={{ marginLeft: "15px" }}></div>
            <div className="col-md-3 col-sm-12 col-xs-12 col-lg-3 pull-right" style={{ display: "flex", paddingRight: "10px" }}>
              <div className="rate_offer_circle_div3" >
                <div className="target_pay_rating_circle_inside_div1">
                  Total
              </div>
                <div className="target_pay_rating_circle_inside_div2">$300,000</div>
              </div>

            </div>
            <div className="col-md-3 col-sm-12 col-xs-12 col-lg-3" style={{ display: "flex", paddingLeft: "19px" }}>
              <div className="rate_offer_circle_div4">
                <div className="target_pay_rating_circle_inside_div1">
                  Cash
              </div>
                <div className="target_pay_rating_circle_inside_div2">$200,000</div>
              </div>
            </div>
          </div>

          <div className="row col-md-12" style={{ marginTop: "50px", marginBottom: "30px" }}>
            <div className="col-md-1"></div>
            <div className="row col-md-10" style={{ marginLeft: "15px" }}>
              <div className="col-md-4 col-sm-12 col-xs-12 col-lg-4" style={{ paddingRight: "0px" }}>
                <NumericTextBox
                  className="rate_offer_numeric_text_box"
                  format="c0"
                  name="Base"
                  width="100%"
                  onChange={this.onChange}
                  value={this.state.Base}
                  label="Base"
                />
              </div>
              <div className="col-md-4 col-sm-12 col-xs-12 col-lg-4" style={{ paddingRight: "0px" }}>
                <NumericTextBox
                  className="rate_offer_numeric_text_box"
                  format="p"
                  name="Bonus"
                  width="100%"
                  min={0}
                  max={1}
                  step={0.01}
                  // type="number"
                  onChange={this.onChange}
                  value={this.state.Bonus}
                  label="Bonus"
                />
              </div>
              <div className="col-md-4 col-sm-12 col-xs-12 col-lg-4" style={{ paddingRight: "0px" }}>
                <NumericTextBox
                  className="rate_offer_numeric_text_box"
                  format="c0"
                  name="annual_equity"
                  width="100%"
                  onChange={this.onChange}
                  value={this.state.annual_equity}
                  label="Annual Equity %"
                />
              </div>
            </div>
            <div className="col-md-1"></div>
          </div>

        </div>
        <div className="col-md-2 col-lg-2 col-sm-12 col-xs-12 rate_offer_dashboard_right_side_layout">
          <div className="rate_offer_dashboard_right_side_saved_offer">
            Saved Offers
        </div>
          <div className="rate_offer_dashboard_right_side_first_layout">
            <div style={{ display: "flex" }}>
              <div className="rate_offer_dashboard_right_side_rating">
                74
                Rating
            </div>
              <div className="rate_offer_dashboard_right_side_candidate_data">
                LinkedIn <br />
                VP Social Media
                Boston
            </div>
            </div>
            <div className="rate_offer_dashboard_right_side_date_div">10/03/18</div>
          </div>
          <div className="rate_offer_dashboard_right_side_second_layout">
            <div style={{ display: "flex" }}>
              <div className="rate_offer_dashboard_right_side_candidate_data">
                LinkedIn <br />
                VP Social Media<br />
                Boston <br />
                Rating 93
              </div>
            </div>
            <div className="rate_offer_dashboard_right_side_date_div2">10/03/18</div>
          </div>
          <div className="rate_offer_dashboard_right_side_second_layout">
            <div style={{ display: "flex" }}>
              <div className="rate_offer_dashboard_right_side_candidate_data">
                LinkedIn <br />
                VP Social Media<br />
                Boston <br />
                Rating 93
              </div>
            </div>
            <div className="rate_offer_dashboard_right_side_date_div2">10/03/18</div>
          </div>
          <div className="rate_offer_dashboard_right_side_second_layout">
            <div style={{ display: "flex" }}>
              <div className="rate_offer_dashboard_right_side_candidate_data">
                LinkedIn <br />
                VP Social Media<br />
                Boston <br />
                Rating 93
              </div>
            </div>
            <div className="rate_offer_dashboard_right_side_date_div2">10/03/18</div>
          </div>
        </div>
      </div>
    )
  }
}

rateOfferDashboardBody.propTypes = {
  auth: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  hiringMembers: PropTypes.object.isRequired
};

const mapStateToProps = state =>
  ({
    lookups: state.lookups,
    auth: state.auth,
    estimateDashboard: state.estimateDashboard
  });
export default connect(mapStateToProps, {}
)(rateOfferDashboardBody);
