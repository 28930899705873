import axios from "axios";
import { GET_FUNCTION_LOOKUP_DETAILS, 
         CLEAR_ERRORS 
       } from "./types";

export const fnGetLookupAllLookupFunctions = (id) => dispatch => {
  dispatch(clearErrors());

  axios
    .get("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-lookup-admin-function-get-grid")
    .then(res => {
      const FunctionsData = res.data.data;
      const FunctionsList = []
      for (let i = 0; i < FunctionsData.length; i++) {
        FunctionsList.push(FunctionsData[i])
      }
      
      dispatch(setGetAllLookUpFunctions(FunctionsList));
    })
    .catch(err => {
      console.log(err)
    }
    );
};
export const fnInsertLookupAllFunction = (userData) => dispatch => {
    dispatch(clearErrors());
    axios
      .post("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-lookup-admin-function-post-insert", userData)
      .then(res => {
        console.log(res.data.message)
      })
      .catch(err => { console.log(err) });
  };
  export const fnUpdateLookupAllFunction = (userData) => dispatch => {
    dispatch(clearErrors());
    axios
      .post("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-lookup-admin-function-post-update", userData)
      .then(res => {
        console.log(res.data.message)
      })
      .catch(err => { console.log(err) });
  };
  export const fnDeleteLookupAllFunction = (userData) => dispatch => {
    dispatch(clearErrors());
    
    axios
      .delete("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-lookup-admin-function-del?admin_lookp_function_id="+ userData)
      .then(res => {
        console.log(res.data.message)
      })
      .catch(err => { console.log(err) });
  };
  

//GetAllProfile
export const setGetAllLookUpFunctions = (CompanyDetails) => {
    return {
      type: GET_FUNCTION_LOOKUP_DETAILS,
      payload: CompanyDetails
    };
  };


// clear errors
export const clearErrors = () => {
    return {
      type: CLEAR_ERRORS
    };
  };
        