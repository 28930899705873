import React from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { orderBy } from '@progress/kendo-data-query';
import { Grid, GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
import { Link } from "react-router-dom";
import MyRoleButtons from "../admin/company_grid_buttons";
import { filterBy } from '@progress/kendo-data-query';
import { fnGetLookupAllLookupCompanyDetails, fnDeleteCompanyLookup } from "../../actions/adminLookupCompanyDetails";
import Spinner from "../spinner/loading";

class LookupCompanygrid extends React.Component {
  editField = "inEdit";
  RoleButtons;

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      filter: undefined,
      success: false,
      skip: 0, take: 10, sort: [
        { field: 'role_title', dir: 'asc' }
      ],
      filter: undefined,
      loading: true,
      delete: 0
    };

    this.RoleButtons = MyRoleButtons("inEdit");

  }


  filterChange = (event) => {
    this.setState({
      data: filterBy(this.props.adminLkpCompanyDetails.adminLookupCompanyDetails, event.filter),
      filter: event.filter
    });
  }

  pageChange = (event) => {
    this.setState({
      skip: event.page.skip,
      take: event.page.take
    });
  }

  componentDidMount() {
    if (this.props.adminLkpCompanyDetails.success !== null) {
      this.state.success = this.props.adminLkpCompanyDetails.success.message;

      if (this.props.adminLkpCompanyDetails.success.message !== undefined) {
        this.setState({
          success: this.props.adminLkpCompanyDetails.success.message
        })
        setTimeout(() => {
          this.props.adminLkpCompanyDetails.success.message = false
          this.setState({
            success: false
          });
        }, 1000)
      }
    }

    if (this.props.location.pathname === "/home/lookup-company") {
      this.props.fnGetLookupAllLookupCompanyDetails();
    }
    if (this.props.location.pathname === "/home/delete-lookup-companyform") {
      this.state.loading = true;
      this.state.delete = 1
      fnDeleteCompanyLookup(this.props.location.search.slice(4)).then(response => {
        this.setState({
          loading: false
        })
        setTimeout(() => {
          this.props.history.push("/home/lookup-company")
          this.setState({
            success: false
          });
        }, 1000)
      }
      ).catch(error => console.log(error));
    }
  }
  filterChange = (event) => {
    this.setState({
      data: filterBy(this.props.adminLkpCompanyDetails.adminLookupCompanyDetails, event.filter),
      filter: event.filter
    });

  }

  render() {

    if (this.props.adminLkpCompanyDetails.adminLookupCompanyDetails === null) {
      this.state.data = []
    }
    else {
      if (this.state.filter != undefined) {
        this.state.data = this.state.data
      } else {
        this.state.data = this.props.adminLkpCompanyDetails.adminLookupCompanyDetails
      }
    }
    if (this.props.adminLkpCompanyDetails.adminLookupCompanyDetails !== null) {
      if (this.state.delete === 0) {
        this.state.loading = false
      } else {
        this.state.loading = true
      }

    } else {
      this.state.loading = true
    }
    return (
      <div className="container-fluid role-main-div">
        <div className="role-main-plus-button"> Company
                <Link
            title="Add" type="button" to="/home/lookup-companyform" className="k-button role-main-Link-plus-button" ><span className="k-icon k-i-plus"></span></Link>
        </div>
        {this.state.success && (
          <div
            className="alert alert-success role-alert-message"
            style={{
              bottom: "0px",
              right: "10px",
              position: "fixed"
            }}
          >
            {this.state.success}
          </div>)}
        {this.state.loading === true ? <Spinner /> : null}
        <Grid
          onItemChange={this.itemChange}
          editField={this.editField}
          data={this.state.data}
          data={orderBy(this.state.data.slice(this.state.skip, this.state.take + this.state.skip), this.state.sort)}
          skip={this.state.skip}
          take={this.state.take}
          total={this.state.data.length}
          pageable={true}
          onPageChange={this.pageChange}
          filterable={true}
          filter={this.state.filter}
          onFilterChange={this.filterChange}
        >
          <Column width="250px" field="company" title="COMPANY" />
          <Column field="location_city" filterable={false} title="CITY" />
          <Column field="location_state" filterable={false} title="STATE" />
          <Column field="active" filterable={false} title="ACTIVE" />
          <Column field="admin_approval" filterable={false} title="REQUIRED APPROVAL" />
          <Column cell={this.RoleButtons} filterable={false} title="ACTIONS" />
        </Grid>
      </div>
    );
  }

}



LookupCompanygrid.propTypes = {
  auth: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  adminLkpCompanyDetails: state.adminLookupCompanyDetails
});

export default connect(mapStateToProps, { fnGetLookupAllLookupCompanyDetails }
)(LookupCompanygrid);

