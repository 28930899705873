import axios from "axios";
import { SET_EDUCATION, 
         GET_EDUCATION, 
         CLEAR_ERRORS 
       } from "./types";

export const fnGetLookupAllEducation = (id) => dispatch => {
  dispatch(clearErrors());

  axios
    .get("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-lookup-admin-education-major-pay-get-grid")
    .then(res => {
      const EducationData = res.data.data;
      const EducationList = []
      for (let i = 0; i < EducationData.length; i++) {
        EducationList.push(EducationData[i])
      }
       dispatch(setGetAllEducation(EducationList));
    })
    .catch(err => {
      console.log(err)
    }
    );
};
//Insert
export const fnInsertLookupAllEducation = (userData) => dispatch => {
  dispatch(clearErrors());
  axios
    .post("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-lookup-admin-education-major-pay-post-insert", userData)
    .then(res => {
      console.log(res.data.message)
    })
    .catch(err => { console.log(err) });
};
// //update
export const fnUpdateLookupAllEducation = (userData) => dispatch => {
  dispatch(clearErrors());
  axios
    .post("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-lookup-admin-education-major-pay-post-update", userData)
    .then(res => {
      console.log(res.data.message)
    })
    .catch(err => { console.log(err) });
};

export const fnDeleteLookupAllEducation = (userData) => dispatch => {
  dispatch(clearErrors());
  
  axios
    .delete("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-lookup-admin-education-major-pay-del?education_major_pay_id="+ userData)
    .then(res => {
      console.log(res.data.message)
    })
    .catch(err => { console.log(err) });
};




//GetAllProfile
export const setGetAllEducation = (Education) => {
  return {
    type: GET_EDUCATION,
    payload: Education
  };
};

// clear errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS
  };
};
      
