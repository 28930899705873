import React from 'react'
import '../../../../css/dashboard.css';
import { connect } from "react-redux";
import { DropDownList } from '@progress/kendo-react-dropdowns';
import {
    Chart,
    ChartSeries,
    ChartSeriesItem,
    ChartSeriesLabels,
    ChartLegend,
    ChartTooltip,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartAxisDefaults,
    ChartValueAxis,
    ChartValueAxisItem,
} from '@progress/kendo-react-charts';
import 'hammerjs';
import { Button } from '@progress/kendo-react-buttons';
import { fnGetAllCandidatesFromOfferDashboard } from "../../../../actions/roleCandidateAction";
import { candidateDataAction, candidateLkpChangeFlagAction } from "../../../../actions/candidateOffersAction";
import { fnGetNewHires } from "../../../../actions/roleActions";
import { addUpdateCreateOfferDashboard } from "../../../../actions/dashboard";
import { fnFetRecommendedValue, fnFetOfferStrengthValue } from "../../../../actions/roleHiringTeamMemberAction";

export class OfferLineChartComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            seriesVisible: true,
            graph_type: 'Line',
            candidateLookUpsData: [],
            graph_class_name: 'fa fa-line-chart',
            offerCandidatesGetData: {},
            Date: new Date().getFullYear(),
            visible: false
        }

    }
    componentDidMount() {

        if (this.props.location.search !== "") {
            fnGetNewHires(this.props.location.search.slice(9)).then(response => {
                this.setState({
                    CityState: response.data.response.role_city + "," + response.data.response.role_state || "",
                    Company: response.data.response.role_company,
                    MarketSpace: response.data.response.role_market_space,
                    Region: response.data.response.role_region,
                    Stage: response.data.response.role_stage,
                    Title: response.data.response.role_title,
                    Function: response.data.response.role_function,
                    current_offer: new Intl.NumberFormat({ maximumSignificantDigits: 0 }).format(response.data.response.compensation_total_high),
                    Level: response.data.response.role_title,
                    role_base: response.data.response.compensation_base_high,
                    role_bonus: response.data.response.compensation_bonus_high,
                    role_equity: response.data.response.compensation_equity_high
                })
                var userData = {
                    CityState: response.data.response.role_city + "," + response.data.response.role_state,
                    Company: response.data.response.role_company,
                    Date: this.state.Date,
                    Employees: "NaN",
                    Founder: "No",
                    Function: response.data.response.role_function,
                    Headquarters: "NaN",
                    Industry: "NaN",
                    Level: response.data.response.role_title,
                    MarketSpace: response.data.response.role_market_space,
                    Region: response.data.response.role_region,
                    Revenue: "NaN",
                    Stage: response.data.response.role_stage,
                    Title: response.data.response.role_title,
                    annual_equity_percentile: 0.75,
                    base_percentile: 0.75,
                    bonus_all_percentile: 0.75,
                    cash_percentile: 0.75,
                    commissions_percentile: ""
                }
                this.props.addUpdateCreateOfferDashboard(userData)
            }).catch(error => console.log(error));
        }
        this.props.fnGetAllCandidatesFromOfferDashboard(this.props.auth.user._id);
    }
    onChange = e => {

        this.setState({
            [e.target.name]: e.target.value
        })
        if (e.target.name === "candidate_lookup") {
            var candidate_pool_total_value = 0;
            var candidate_pool = []
            for (let i = 0; i < this.props.candidates.offerCandidatesData.length; i++) {
                if (this.props.candidates.offerCandidatesData[i]["first_name"] === e.target.value) {
                    let queryData = {
                        login_user_id: this.props.candidates.offerCandidatesData[i]['hr_id'],
                        offer_id: this.props.candidates.offerCandidatesData[i]['new_hire_id'],
                        finalist_candidate_id : this.props.candidates.offerCandidatesData[i]['_id']
                    }                    
                    if (queryData) {
                        this.props.fnFetRecommendedValue(queryData);
                        this.props.fnFetOfferStrengthValue(queryData);
                    }
                    var selected_candidate_total = this.props.candidates.offerCandidatesData[i]['salary']['total_dollar_value']
                    var offer_total_dollar_value = new Intl.NumberFormat({ maximumSignificantDigits: 3 }).format(this.props.candidates.offerCandidatesData[i]['salary']['total_dollar_value'])
                    var offer_base = this.props.candidates.offerCandidatesData[i]['salary']['base_salary']
                    var offer_annual_bonus = this.props.candidates.offerCandidatesData[i]['salary']['annual_bonus']
                    var offer_equity_dollar_value = this.props.candidates.offerCandidatesData[i]['salary']['equity_dollar_value']
                    var offer_finalist = this.props.candidates.offerCandidatesData[i]['salary']['total_dollar_value'];
                }
                candidate_pool = this.props.candidates.offerCandidatesData[i]['salary']['total_dollar_value']
                candidate_pool_total_value += parseInt(candidate_pool);

            }
            var offer_base_percentile = '75'
            var offer_bonus_percentile = '75'
            var offer_Equity_percentile = '75'
            var offer_offerStrength = selected_candidate_total / (candidate_pool_total_value / this.props.candidates.offerCandidatesData.length)

            if (offer_offerStrength < 0.9) {
                offer_offerStrength = offer_offerStrength * 0.6 * 100;
            } else if (0.9 <= offer_offerStrength < 1.0) {
                offer_offerStrength = offer_offerStrength * 0.9 * 100;
            } else if (1 <= offer_offerStrength <= 1.2) {
                offer_offerStrength = 92;
            } else if (offer_offerStrength > 1.2) {
                offer_offerStrength = 95;
            }
            var offer_recommended_total = 0
            if (selected_candidate_total >= candidate_pool_total_value) {
                offer_recommended_total = 1.10 * selected_candidate_total;
            } else {
                offer_recommended_total = 1.15 * selected_candidate_total;
            }
            this.props.candidateLkpChangeFlagAction(1)
            var candidate_pool_Average_value = candidate_pool_total_value / this.props.candidates.offerCandidatesData.length
            this.props.candidateDataAction(candidate_pool_Average_value, offer_total_dollar_value, offer_base, offer_annual_bonus, offer_equity_dollar_value, this.props.candidateOffers.offer_base_percentile, this.props.candidateOffers.offer_bonus_percentile, this.props.candidateOffers.offer_Equity_percentile, offer_finalist, offer_offerStrength, offer_recommended_total, this.state.role_base, this.state.role_bonus, this.state.role_equity)
        }

    }

    render() {
        let candidateOffers = this.props.candidateOffers.create_offer_chart_data;
        let { estimateDashboard } = this.props.estimateDashboard;

        let candidateLookupData = []
        if (this.props.candidates.offerCandidatesData === null || undefined) {
            candidateLookupData = []
        } else {
            for (let i = 0; i < this.props.candidates.offerCandidatesData.length; i++) {

                candidateLookupData.push(this.props.candidates.offerCandidatesData[i]['first_name'] ? this.props.candidates.offerCandidatesData[i]['first_name'] : null)
            }
        }
        if (this.props.location.search !== "") {
            if (estimateDashboard.message) {
                if (estimateDashboard.data.comparison['cash'] !== -10) {
                    var competition_cash = estimateDashboard.data.compensation['cash'] || "";
                    var competition_equity = estimateDashboard.data.compensation['equity'] || "";
                    var competition_total = estimateDashboard.data.compensation['total'] || "";
                }

            }

            if (this.props.candidateOffers.rightSideDashboardData !== null) {
                if (this.props.candidateOffers.rightSideDashboardData.offer_base !== -5) {
                    var offer_cash = (this.props.candidateOffers.rightSideDashboardData.offer_base + this.props.candidateOffers.rightSideDashboardData.offer_bonus) || "";
                    var offer_equity = this.props.candidateOffers.rightSideDashboardData.offer_equity || "";
                    var offer_total = (offer_cash + offer_equity) || "";
                }

            }
            if (candidateOffers !== null) {
                // candidate chart data
                if (this.props.candidateOffers.offer_base) {
                    var candidte_cash = parseInt(this.props.candidateOffers.offer_base) + parseInt(this.props.candidateOffers.offer_annual_bonus)
                    var candidte_equity = parseInt(this.props.candidateOffers.offer_equity_dollar_value)
                    var candidate_total = parseInt(candidte_cash) + parseInt(candidte_equity)


                } else {
                    var candidte_cash = 0
                    var candidte_equity = 0
                    var candidate_total = parseInt(candidte_cash) + parseInt(candidte_equity)
                }
                // candidate pool  chart data
                if (this.props.candidateOffers.offer_base_percentile !== undefined) {
                    var candidte_pool_cash = this.props.candidateOffers.offer_base_percentile
                    var candidte_pool_equity = this.props.candidateOffers.offer_bonus_percentile
                    var candidate_pool_total = this.props.candidateOffers.offer_Equity_percentile
                }
            }
        }



        var Candidate = [candidte_cash, candidte_equity, candidate_total];
        var Candidate_pool = [candidte_pool_cash, candidte_pool_equity, candidate_pool_total];
        var Offer = [offer_cash, offer_equity, offer_total];
        var Competition = [competition_cash, competition_equity, competition_total];

        const { seriesVisible } = this.state;
        const categories = ['Cash', 'Equity', 'Total'];
        const label = {
            font: "16px sans-serif"
        };
        const defaultTooltipRender =
            ({ point }) => (<div>
                <div style={{ textAlign: "center" }}><b> {point.category}</b></div>
                <div>{point.series.name} : {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD', maximumSignificantDigits: 10
                }).format(point.value)}</div>
            </div>);
        
        return (
            <div className="">
                <div className="row fixed-dashboards dashboard-input-wrapper" style={{ paddingLeft: "5px" }}>
                    <div className="row col-md-6 paddingRight">
                        <div className="col-md-3">
                            <h3 style={{ fontSize: "18px", paddingTop: "6px" }}>Candidate:</h3></div>
                        <div className="col-md-3">
                            <DropDownList
                                name="candidate_lookup"
                                onChange={this.onChange}
                                value={this.state.candidate_lookup}
                                style={{ width: "100%", fontSize: "14PX" }}
                                data={candidateLookupData}
                                defaultValue={candidateLookupData[0]}
                            />
                        </div>
                    </div>
                    <div className="col-md-4"></div>


                </div>

                <Chart style={{ padding: "10px 15px 10px 10px" }} pannable={{ lock: 'y' }} zoomable={false}>
                    <ChartTooltip format="c0" render={defaultTooltipRender} />
                    <ChartAxisDefaults labels={{ format: 'c0' }} />
                    <ChartCategoryAxis>
                        <ChartCategoryAxisItem categories={categories} />
                    </ChartCategoryAxis >
                    <ChartSeries>
                        <ChartSeriesItem type="line" data={Candidate} visible={seriesVisible} style="smooth" name="Candidate" color="#63afff"></ChartSeriesItem>
                        <ChartSeriesItem type="line" data={Candidate_pool} style="smooth" name="Candidate Pool" color="#a6abaf">
                            {/* <ChartSeriesLabels format="c0" /> */}
                        </ChartSeriesItem >
                        <ChartSeriesItem type="bar" data={Offer} style="smooth" name="Offer" color="#fec391">
                            <ChartSeriesLabels format="c0" />
                        </ChartSeriesItem>
                        <ChartSeriesItem type="line" data={Competition} style="smooth" name="Competition" color="#809eb0"></ChartSeriesItem>
                    </ChartSeries>
                    <ChartLegend position="bottom" labels={label} orientation="horizontal" />
                </Chart>


            </div >
        )
    }

}


const mapStateToProps = state =>
    ({
        auth: state.auth,
        offerCandidatesGetData: state.offerCandidatesGetData,
        candidates: state.candidates,
        candidateOffers: state.candidateOffers,
        estimateDashboard: state.estimateDashboard,
    });
export default connect(mapStateToProps, {                                         fnGetAllCandidatesFromOfferDashboard, 
    candidateDataAction, fnGetNewHires, 
    addUpdateCreateOfferDashboard, 
    fnFetRecommendedValue,
    fnFetOfferStrengthValue,candidateLkpChangeFlagAction
})(OfferLineChartComponent);
