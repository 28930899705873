import React from 'react'
// import '../../css/dashboard.css';
import '../../../css/dashboard.css';
import { connect } from "react-redux";
import { ComboBox } from '@progress/kendo-react-dropdowns';
import Progressbar from 'react-progressbar';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';

class PersonalDashboardHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      totalSelected: 0,
      cashSelected: 0,
      equitySelected: 0
    }

  }
  totalSelect = (e) => {
    this.setState({ totalSelected: e.selected });
  }
  cashSelect = (e) => {
    this.setState({ cashSelected: e.selected });
  }
  equitySelect = (e) => {
    this.setState({ equitySelected: e.selected });
  }
  render() {

    return (
      <div className="personal_dashboard_header_div">
        <div className="col-md-12 personal_dashboard_header_bootstrap_div" >
          <div className="col-md-3">
            Current
          </div>
        </div>
        <div className="row personal_dashboard_header_layout1_first_div">
          <div className="col-md-3 personal_dashboard_header_layout1_shadow_div">
            <div style={{ display: "flex" }}>
              <div className="col-md-2 personal_dashboard_header_layout1_user_icon_div">
                <div className="personal_dashboard_header_user_icon">
                  <span className="k-icon k-i-user personal_dashboard_header_icon" title="Edit" ></span></div>
              </div>
              <div className="col-md-10" style={{ paddingTop: "20px" }}>
                <h2 className="personal_dashboard_header_layout1_h2">Tito Clemete</h2>
                <p style={{ marginBottom: "0px" }}>Facebook</p>
                <p className="personal_dashboard_header_layout1_p">Director of Engineering</p>
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div className="col-md-2" style={{ paddingLeft: "2px" }}>
                <div className="personal_dashboard_header_user_icon">
                  <span className="k-icon" title="Edit" ></span></div>
              </div>
              <div className="col-md-10" >
                <p style={{ marginBottom: "0px" }}>Stanford University</p>
                <p className="personal_dashboard_header_layout1_p">BS Engineering</p>
              </div>
            </div>
            <div>
              <div className="personal_dashboard_header_layout2_Total_circle">
                93
              </div>
              <div className="personal_dashboard_header_layout1_third_circle_char" style={{ textAlign: "center" }}>
                Human Capital
              </div>
            </div>
          </div>
          <div className="col-md-3 paddingRight personal_dashboard_header_layout">
            <TabStrip selected={this.state.totalSelected} onSelect={this.totalSelect}>
              <TabStripTab title="SUMMARY">
                <div className="tabstrip_div_main_personal_dashboard">
                  <div className="row tabstrip-div-row-offer-dashboard" style={{ display: "flex" }}>
                    <div className="col-md-1 col-lg-1 col-sm-1 tabstrip-div-row-column1-offer-dashboard">
                      Total
                    </div>
                    <div className="col-md-1 col-lg-1 col-xs-6 col-sm-6" style={{ paddingLeft: "6px" }}>
                      <div className="personal_dashboard_header_layout1_third_circle">
                        43
                      </div>
                      <div className="personal_dashboard_header_layout2_total_circle_char">
                        Percentile
                       </div>
                    </div >
                    <div className="col-md-7 col-lg-7 col-xs-4 col-sm-4" style={{ paddingRight: "0px" }}>
                      <div style={{ fontSize: "23px", textAlign: "right" }}>
                        $500,000
                    </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12 progress-linear-style">
                      <Progressbar className="personal_dashboard_progress_bar_color" completed={60} max={10000} style={{ marginBottom: "5px", backgroundColor: "rgb(197,202,233)", height: "5px" }} />
                      <div class="apollo-progress-markers" style={{ display: "flex", flexDirection: "row" }}>
                        <div class="left col-xs-4" style={{ width: "33.33333%", color: "white" }}>$ 250k </div>
                        <div class="center col-xs-4" style={{ width: "33.33333%", color: "white" }}>$ 500k</div>
                        <div class="right col-xs-4" style={{ width: "33.33333%", color: "white" }}>$ 1000k</div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabStripTab>
              <TabStripTab title="DETAILS">

              </TabStripTab>
            </TabStrip>
          </div>
          <div className="col-md-3 paddingRight personal_dashboard_header_layout">
            <TabStrip selected={this.state.cashSelected} onSelect={this.cashSelect}>
              <TabStripTab title="SUMMARY">
                <div className="tabstrip_div_main_personal_dashboard">
                  <div className="row tabstrip-div-row-offer-dashboard" style={{ display: "flex" }}>
                    <div className="col-md-1 col-lg-1 col-sm-1 tabstrip-div-row-column1-offer-dashboard">
                      Cash
                    </div>
                    <div className="col-md-1 col-lg-1 col-xs-6 col-sm-6" style={{ paddingLeft: "6px" }}>
                      <div className="personal_dashboard_header_layout1_third_circle">
                        56
                      </div>
                      <div className="personal_dashboard_header_layout2_total_circle_char">
                        Percentile
                       </div>
                    </div >
                    <div className="col-md-7 col-lg-7 col-xs-4 col-sm-4" style={{ paddingRight: "0px" }}>
                      <div style={{ fontSize: "23px", textAlign: "right" }}>
                        $350,000
                    </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12 progress-linear-style">
                      <Progressbar className="personal_dashboard_progress_bar_color" completed={80} max={10000} style={{ marginBottom: "5px", backgroundColor: "rgb(197,202,233)", height: "5px" }} />
                      <div class="apollo-progress-markers" style={{ display: "flex", flexDirection: "row" }}>
                        <div class="left col-xs-4" style={{ width: "33.33333%", color: "white" }}>$ 250k</div>
                        <div class="center col-xs-4" style={{ width: "33.33333%", color: "white" }}>$ 500k</div>
                        <div class="right col-xs-4" style={{ width: "33.33333%", color: "white" }}>$ 1000k</div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabStripTab>
              <TabStripTab title="DETAILS">

              </TabStripTab>
            </TabStrip>

          </div>
          <div className="col-md-3 paddingRight personal_dashboard_header_layout">
            <TabStrip selected={this.state.equitySelected} onSelect={this.equitySelect}>
              <TabStripTab title="SUMMARY">
                <div className="tabstrip_div_main_personal_dashboard">
                  <div className="row tabstrip-div-row-offer-dashboard" style={{ display: "flex" }}>
                    <div className="col-md-1 col-lg-1 col-sm-1 tabstrip-div-row-column1-offer-dashboard">
                      Equity
                    </div>
                    <div className="col-md-2 col-lg-2 col-xs-6 col-sm-6" style={{ paddingLeft: "12px" }}>
                      <div className="personal_dashboard_header_layout1_third_circle">
                        30
                      </div>
                      <div className="personal_dashboard_header_layout2_total_circle_char">
                        Percentile
                       </div>
                    </div >
                    <div className="col-md-6 col-lg-6 col-xs-4 col-sm-4" style={{ paddingRight: "0px" }}>
                      <div style={{ fontSize: "23px", textAlign: "right" }}>
                        $150,000
                    </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12 progress-linear-style">
                      <Progressbar className="personal_dashboard_progress_bar_color" completed={80} max={10000} style={{ marginBottom: "5px", backgroundColor: "rgb(197,202,233)", height: "5px" }} />
                      <div class="apollo-progress-markers" style={{ display: "flex", flexDirection: "row" }}>
                        <div class="left col-xs-4" style={{ width: "33.33333%", color: "white" }}>$ 250k</div>
                        <div class="center col-xs-4" style={{ width: "33.33333%", color: "white" }}>$ 500k</div>
                        <div class="right col-xs-4" style={{ width: "33.33333%", color: "white" }}>$ 1000k</div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabStripTab>
              <TabStripTab title="DETAILS">

              </TabStripTab>
            </TabStrip>


          </div>
        </div>
      </div >
    )
  }
}


const mapStateToProps = state =>
  ({
    lookups: state.lookups,
    auth: state.auth,
    role: state.role,
    estimateDashboard: state.estimateDashboard,
  });
export default connect(mapStateToProps, {}
)(PersonalDashboardHeader);
