import {
  SET_COMPANY_LOOKUP_DETAILS,
  ROLES_LOADING,
  GET_COMPANY_LOOKUP_DETAILS,
  GET_COMPANY_INSERT_UPDATE_DATA
} from "../actions/types";

const initialState = {
  role: {},
  adminLookupCompanyDetails: null,
  success: null,
  loading: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ROLES_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_COMPANY_LOOKUP_DETAILS:
      return {
        ...state,
        adminLookupCompanyDetails: action.payload,
        loading: true
      };
    case GET_COMPANY_INSERT_UPDATE_DATA:
      return {
        ...state,
        success: action.payload.data,
        loading: true
      };
    default:
      return state;
  }
}
