import React from 'react'
import '../../../../css/dashboard.css';
import { connect } from "react-redux";
import 'hammerjs';
import { inputValueAction, offerDashDialogBoxAction } from "../../../../actions/dashboard";
import {
  fnSaveOfferFromCreateOfferDashboard,
  fnGetSavedCandidateOffers,
  offerCandidateFlagAction
} from "../../../../actions/roleHiringTeamMemberAction";
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Input } from '@progress/kendo-react-inputs';
import FormValidator from '../../../../validation/FormValidator';
import validationParams from '../../../../validation/validationParams.json';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';

export class OfferDashOfferSavedOffers extends React.Component {
  constructor(props) {
    super(props);
    this.validator = new FormValidator(validationParams.saveOfferFromCandidateDashboard);
    this.state = {
      visible: true,
      name: '',
      message: "",
      validation: this.validator.valid(),
      offeredCandidates: []
    }
    this.submitted = false;
  }

  toggleDialog = () => {
    this.setState({
      visible: !this.state.visible
    });
    this.props.offerDashDialogBoxAction(this.state.visible, false)
  }
  toggleDialogStable = () => {
    this.setState({
      visible: this.state.visible
    });
  }

  onChange = e => {
    let { value, name } = e.target;

    this.setState({
      [name]: value
    });
  }

  render() {
    if (this.props.estimateDashboard.togglename === "saved_offers") {
      this.state.visible = true
    } else {
      this.state.visible = false
    }

    if (this.props.hiringMembers.offersData) {
      const OffersDataList = this.props.hiringMembers.offersData
      const offeredCandidates = []
      if (this.props.location.search !== "") {
        for (let i = 0; i < OffersDataList.length; i++) {
          console.log(OffersDataList[i])
          offeredCandidates.push(OffersDataList[i])
        }
      }
      this.state.offeredCandidates = offeredCandidates
    }

    return (
      <div>
        {
          this.state.visible && <Dialog className="offer-saved-offer-div-modal">
            <div className="offer-save-dialog-div" onClick={this.toggleDialogStable}>
              <div className="col-md-11 col-lg-11 col-sm-8 col-xs-8">
                Saved Offer
            </div>
              <div className="col-md-1 col-lg-1 col-sm-4 col-xs-4" style={{ textAlign: "right" }}>
                <button className="offer-dash-offer-save-dialog-button" onClick={this.toggleDialog}>X</button>
              </div>
            </div>
            <div className="">
              <Grid
                style={{ border: "0px" }}
                className=""
                data={this.state.offeredCandidates}
                sortable
                sort={this.state.sort}
                onSortChange={this.sortChange}
              >
                <Column className="saved_offers_name" field="name" filter="numeric" format="" title="NAME" />
                <Column className="saved_offers_name" format="{0:c0}" field="base_salary" filter="numeric" title="BASE" />
                <Column className="saved_offers_name" format="{0:c0}" field="bonus" filter="numeric" title="BONUS" />
                <Column className="saved_offers_name" format="{0:c0}" field="annual_equity_value" filter="numeric" title="EQUITY" />
                <Column className="saved_offers_name" format="{0:c0}" field="total_dollar_value" filter="numeric" title="TOTAL" />
                <Column className="saved_offers_name" format="{0:p}" field="offer_strength" filter="numeric" title="OFFER STRENGTH" />
                <Column
                  field=""
                  cell={props => (
                    <td onClick={this.toggleDialog}>
                      <input disabled type="checkbox" checked onClick={this.toggleDialog} />
                    </td>
                  )} />
              </Grid>

            </div>
            <div className="offer-save-buttons-div">
              <DialogActionsBar >
                <button className="k-button col-xs-6 col-sm-6" onClick={this.toggleDialog}>CANCEL</button>
              </DialogActionsBar>
            </div>
          </Dialog>
        }
      </div >

    )
  }
}
const mapStateToProps = state => ({
  auth: state.auth,
  estimateDashboard: state.estimateDashboard,
  candidateOffers: state.candidateOffers,
  hiringMembers: state.hiringMembers,
  errors: state.errors
});
export default connect(mapStateToProps, {
  inputValueAction,
  offerDashDialogBoxAction,
  fnGetSavedCandidateOffers,
  offerCandidateFlagAction
}
)(OfferDashOfferSavedOffers);
