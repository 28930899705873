import React from 'react'
import '../../../../css/dashboard.css';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { DropDownButton } from '@progress/kendo-react-buttons'
import { Link } from "react-router-dom";
import { Button } from '@progress/kendo-react-buttons';
import {
    getTitleLookUpData,
    getFunctionLookUpData,
    getCompanyLookUpData,
    getgraphAPIListMarketSpaceLookUpData,
    getCompanyLocationRegionLookUpData,
    getStageLookUpData,
    getcompanyLocationNoOfEmployeesLookUpData,
    getCompanyMarketStageRegionLookUp
} from "../../../../actions/lookupAction";
import { inputValueAction, offerDashDialogBoxAction, getHRDetails, addUpdateCreateOfferDashboard, offerDashMatchSearchAvgAction } from "../../../../actions/dashboard";
import constValues from '../../dashboard.json';
import { FnInsertRole } from "../../../../actions/roleActions";
import { NumericTextBox, Switch } from '@progress/kendo-react-inputs';
import { Slider } from '@progress/kendo-react-inputs';
import OfferDashOfferMoreMatchSearchButton from './offerDashboardOfferMorePopupMatchSearchButton';
import OfferDashOfferMoreResetOfferButton from './offerDashboardOfferMorePopupresetOfferButton';
import OfferDashOfferSaveButton from './offerDashboardOfferSaveButton';
import OfferDashInviteButton from './OfferDashCreateRoleButton';
import { fnGetNewHires } from "../../../../actions/roleActions";
import OfferDashbBoardAddHiringTeamMember from "./OfferDashboardAddHiringTeamMemberButton";
import OfferDashbBoardAddCandidate from "./OfferDashboardAddCandidateButton";
import { candidateDataAction } from "../../../../actions/candidateOffersAction";
import { fnComparisonCompensationEstimateDashboard, offerDashRightSideDashboardAction } from "../../../../actions/dashboard";
import { fnGetSavedCandidateOffers } from "../../../../actions/roleHiringTeamMemberAction";
import { fnGetAllCandidatesFromOfferDashboard } from "../../../../actions/roleCandidateAction"
import { fnFetRecommendedValue, fnFetOfferStrengthValue } from "../../../../actions/roleHiringTeamMemberAction";
import Spinner from "../../../spinner/loading";
import OfferDashOfferSavedOffers from './offerDashboardOfferSavedOffersPopup';

class offerDashboardInputs extends React.Component {
    anchor = null;
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            offer_lkp_loading: true,
            Title: this.props.value.Title,
            Function: this.props.value.Function,
            Company: this.props.value.Company,
            Region: this.props.value.Region,
            checked: true,
            visible: true,
            Date: new Date().getFullYear(),
            candidate_pool_total_value: 0,
            offer_candidate_pool_visible: false,
            current_offer: 0,
            base_bonus_percentage: 0,
            call: 1,
            offer_strength_recommended_ids: 1,
            offer_strength_recommended_null: 1,
            current_value_call: 1,
            offer_selected_name: false,
            change_bonus_percentage_value: false,
            offeredCandidates_default_name: "Estimate",
            change_base_bonus_percentage: 0
        };
    }

    componentDidMount() {
        this.state.role_id = this.props.location.search.split('?user_id=')[1]
        if (this.props.location.search !== "") {
            fnGetNewHires(this.props.location.search.slice(9)).then(response => {
                this.state.base = response.data.response.compensation_base_high
                this.state.bonus = response.data.response.compensation_bonus_high
                this.state.annual_equity = response.data.response.compensation_equity_high
                this.state.Slider1 = this.state.base
                this.state.Slider2 = this.state.bonus
                this.state.Slider3 = this.state.annual_equity

                this.state.offer_base_percentile = Math.round((response.data.response.compensation_base_high / offer) * 100)
                this.state.offer_bonus_percentile = Math.round((response.data.response.compensation_bonus_high / offer) * 100)
                this.state.offer_Equity_percentile = Math.round((response.data.response.compensation_equity_high / offer) * 100)
                if (response.data.response.compensation_total_high) {
                    var offer = response.data.response.compensation_total_high;
                } else {
                    var offer = 0;
                }
                if (this.props.estimateDashboard.roleInsertFlag === null) {
                    var TitleData = this.props.value.Title
                    var FunctionData = this.props.value.Function

                    this.state.base = response.data.response.compensation_base_high
                    this.state.bonus = response.data.response.compensation_bonus_high
                    this.state.annual_equity = response.data.response.compensation_equity_high
                    this.state.Slider1 = this.state.base
                    this.state.Slider2 = this.state.bonus
                    this.state.Slider3 = this.state.annual_equity

                    this.state.offer_base_percentile = Math.round((response.data.response.compensation_base_high / offer) * 100)
                    this.state.offer_bonus_percentile = Math.round((response.data.response.compensation_bonus_high / offer) * 100)
                    this.state.offer_Equity_percentile = Math.round((response.data.response.compensation_equity_high / offer) * 100)
                }

                if (this.props.estimateDashboard.roleInsertFlag === 0) {
                    this.props.value.Title = response.data.response.role_title
                    this.props.value.Function = response.data.response.role_title
                    var TitleData = response.data.response.role_title
                    var FunctionData = response.data.response.role_title
                }
                if (this.props.estimateDashboard.roleInsertFlag === 1) {
                    var offer = this.props.estimateDashboard.estimateDashboard.data.compensation["total"]
                    this.state.base = this.props.estimateDashboard.estimateDashboard.data.offer.base
                    this.state.bonus = this.props.estimateDashboard.estimateDashboard.data.offer.bonuses
                    this.state.annual_equity = this.props.estimateDashboard.estimateDashboard.data.offer.equity
                    this.state.Slider1 = this.state.base
                    this.state.Slider2 = this.state.bonus
                    this.state.Slider3 = this.state.annual_equity

                    this.state.offer_base_percentile = Math.round(this.props.estimateDashboard.estimateDashboard.data.offer.percent_base / 100)
                    this.state.offer_bonus_percentile = Math.round(this.props.estimateDashboard.estimateDashboard.data.offer.percent_bonuses / 100)
                    this.state.offer_Equity_percentile = Math.round(this.props.estimateDashboard.estimateDashboard.data.offer.percent_equity / 100)
                }
                if (this.props.estimateDashboard.roleInsertFlag === 2) {
                    this.state.Title = response.data.response.role_title
                    this.state.Function = response.data.response.role_function
                    var TitleData = this.state.Title
                    var FunctionData = this.state.Function
                    this.state.base = response.data.response.compensation_base_high
                    this.state.bonus = response.data.response.compensation_bonus_high
                    this.state.annual_equity = response.data.response.compensation_equity_high
                    this.state.Slider1 = this.state.base
                    this.state.Slider2 = this.state.bonus
                    this.state.Slider3 = this.state.annual_equity

                    this.state.offer_base_percentile = Math.round((response.data.response.compensation_base_high / offer) * 100)
                    this.state.offer_bonus_percentile = Math.round((response.data.response.compensation_bonus_high / offer) * 100)
                    this.state.offer_Equity_percentile = Math.round((response.data.response.compensation_equity_high / offer) * 100)
                }
                this.setState({
                    CityState: response.data.response.role_city + "," + response.data.response.role_state,
                    Company: response.data.response.role_company,
                    MarketSpace: response.data.response.role_market_space,
                    Region: response.data.response.role_region,
                    Stage: response.data.response.role_stage,
                    current_offer: new Intl.NumberFormat({ maximumSignificantDigits: 0 }).format(offer),
                    Level: response.data.response.role_title,
                    role_base: response.data.response.compensation_base_high,
                    role_bonus: response.data.response.compensation_bonus_high,
                    role_equity: response.data.response.compensation_equity_high
                })

                const userJobIdDashBoardData = {
                    Title: TitleData,
                    Function: FunctionData,
                    role_years: this.props.value.role_years,
                    base_percentile: (75 / 100).toString(),
                    MarketSpace: response.data.response.role_market_space,
                    Company: response.data.response.role_company,
                    Region: response.data.response.role_region,
                    Stage: response.data.response.role_stage
                }
                this.props.offerDashRightSideDashboardAction(this.state.base, this.state.bonus, this.state.annual_equity, this.state.offer_base_percentile, this.state.offer_bonus_percentile, this.state.offer_Equity_percentile)
                this.props.fnComparisonCompensationEstimateDashboard(userJobIdDashBoardData)
                this.state.loading = false
                this.state.base_bonus_percentage = Math.round((this.state.bonus / this.state.base) * 100);
            }).catch(error => console.log(error));
        } else {
            getHRDetails(this.props.auth.user._id).then(response => {
                if (this.props.estimateDashboard.roleInsertFlag === 1) {
                    this.state.current_offer = new Intl.NumberFormat({ maximumSignificantDigits: 0 }).format(this.props.estimateDashboard.estimateDashboard.data.offer.total)

                    this.state.target_compensation = new Intl.NumberFormat({ maximumSignificantDigits: 0 }).format(this.props.estimateDashboard.estimateDashboard.data.offer.total)

                    this.state.base = this.props.estimateDashboard.estimateDashboard.data.offer.base
                    this.state.bonus = this.props.estimateDashboard.estimateDashboard.data.offer.bonuses
                    this.state.annual_equity = this.props.estimateDashboard.estimateDashboard.data.offer.equity
                    this.state.Slider1 = this.state.base
                    this.state.Slider2 = this.state.bonus
                    this.state.Slider3 = this.state.annual_equity

                    this.state.offer_base_percentile = Math.round(this.props.estimateDashboard.estimateDashboard.data.offer.percent_base / 100)
                    this.state.offer_bonus_percentile = Math.round(this.props.estimateDashboard.estimateDashboard.data.offer.percent_bonuses / 100)
                    this.state.offer_Equity_percentile = Math.round(this.props.estimateDashboard.estimateDashboard.data.offer.percent_equity / 100)
                    this.props.offerDashRightSideDashboardAction(this.state.base, this.state.bonus, this.state.annual_equity, this.state.offer_base_percentile, this.state.offer_bonus_percentile, this.state.offer_Equity_percentile)
                    this.state.base_bonus_percentage = Math.round((this.state.bonus / this.state.base) * 100);
                }

                this.setState({
                    Company: response.data.msg.company_name,
                    Region: response.data.msg.role_region

                })
                this.state.loading = false
            }).catch(error => console.log(error));
        }
    }

    onChange = e => {
        if (e.target.props.name === "Slider1" || e.target.props.name === "Slider2" || e.target.props.name === "Slider3") {
            this.setState({
                [e.target.props.name]: e.value
            })

        } else {
            this.setState({
                [e.target.name]: e.target.value
            })
        }

        if (e.target.props.name === "offer_candidate_pool") {
            if (e.target.value === "RECOMMENDED") {
                // this.state.offer_candidate_pool_visible:false
                this.setState({
                    offer_candidate_pool_visible: false
                })
            } else {
                // this.state.offer_candidate_pool_visible:true
                this.setState({
                    offer_candidate_pool_visible: true
                })
            }
        }
        if (e.target.props.name === "offer_base_percentile") {
            this.state.offer_base_percentile = e.value;
        }
        if (e.target.props.name === "offer_bonus_percentile") {
            this.state.offer_bonus_percentile = e.value;
        }
        if (e.target.props.name === "offer_Equity_percentile") {
            this.state.offer_Equity_percentile = e.value;
        }
        if (e.target.props.name === "base") {
            this.state.base = e.value;
            this.state.base_bonus_percentage = Math.round((this.state.bonus / e.value) * 100);
            this.setState({
                Slider1: Math.round(e.value)
            })
        }
        if (e.target.props.name === "bonus") {
            this.state.bonus = e.value;
            this.state.base_bonus_percentage = Math.round((e.value / this.state.base) * 100);
            this.setState({
                Slider2: Math.round(e.value)
            })
        }
        if (e.target.name === "bonus") {
            this.state.bonus = e.target.value;
            this.state.base_bonus_percentage = Math.round((e.value / this.state.base) * 100);
            this.setState({
                Slider2: Math.round(e.value)
            })
        }
        if (e.target.props.name === "annual_equity") {
            this.state.annual_equity = e.value;
            this.setState({
                Slider3: Math.round(e.value)
            })
        }

        if (e.target.props.name === "Slider1") {
            this.state.Slider1 = e.value;
            this.state.base_bonus_percentage = Math.round((this.state.bonus / e.value) * 100);
            this.setState({
                base: Math.round(e.value)
            })
        }
        if (e.target.props.name === "Slider2") {
            this.state.Slider2 = e.value;
            this.state.base_bonus_percentage = Math.round((e.value / this.state.base) * 100);
            this.setState({
                bonus: Math.round(e.value)
            })
        }
        if (e.target.props.name === "Slider3") {
            this.state.Slider3 = e.value;
            this.setState({
                annual_equity: Math.round(e.value)
            })
        }

        if (this.props.location.search !== "") {

            var userData = {
                CityState: this.state.CityState,
                Company: this.state.Company,
                Date: this.state.Date,
                Employees: "NaN",
                Founder: "No",
                Function: this.state.Function,
                Headquarters: "NaN",
                Industry: "NaN",
                Level: this.state.Level,
                MarketSpace: this.state.MarketSpace,
                Region: this.state.Region,
                Revenue: "NaN",
                Stage: this.state.Stage,
                Title: this.state.Title,
                annual_equity_percentile: this.state.offer_Equity_percentile / 100,
                base_percentile: this.state.offer_base_percentile / 100,
                bonus_all_percentile: this.state.offer_bonus_percentile / 100,
                cash_percentile: this.state.offer_base_percentile / 100,
                commissions_percentile: this.state.offer_base_percentile / 100
            }
            var current_offer_value = this.state.base + this.state.bonus + this.state.annual_equity
            this.state.current_offer = new Intl.NumberFormat({ maximumSignificantDigits: 0 }).format(current_offer_value)
            this.props.offerDashRightSideDashboardAction(this.state.base, this.state.bonus, this.state.annual_equity, this.state.offer_base_percentile, this.state.offer_bonus_percentile, this.state.offer_Equity_percentile)
            this.props.addUpdateCreateOfferDashboard(userData)
        } else {
            this.props.offerDashRightSideDashboardAction(this.state.base, this.state.bonus, this.state.annual_equity, this.state.offer_base_percentile, this.state.offer_bonus_percentile, this.state.offer_Equity_percentile)
        }

        if (e.target.name === "offeredCandidates") {
            if (this.state.loading === false) {
                this.state.loading = true
            }
            setTimeout(
                function () {
                    this.setState({ loading: false });
                }
                    .bind(this),
                1000
            );
            if (this.props.hiringMembers.offersData) {
                // this.setState({ offer_lkp_loading: false });
                const offersData = this.props.hiringMembers.offersData

                for (let i = 0; i < offersData.length; i++) {
                    if (offersData[i]["name"] === e.target.value) {
                        this.setState({
                            offeredCandidates_default_name: e.target.value
                        })
                        this.props.offerDashRightSideDashboardAction(offersData[i]["base_salary"], offersData[i]["bonus"], offersData[i]["annual_equity_value"], offersData[i]["base_pctl"], offersData[i]["bonus_pctl"], offersData[i]["annual_equity_pctl"])
                    }
                }
            }

        }

        if (e.target.name === "base_bonus_percentage") {
            this.state.change_base_bonus_percentage = e.target.value
            this.state.bonus = Math.round((e.target.value / 100) * this.state.base)
            this.state.Slider2 = this.state.bonus
            this.state.change_bonus_percentage_value = true;
        }
        this.props.offerDashMatchSearchAvgAction(0)

    }
    toggleSwitch = (event) => {
        this.setState({ checked: !this.state.checked });
    }
    onIconFlipOpen = (event) => {
        this.setState({
            popup_event: event,
            visible: true
        })

        this.props.offerDashDialogBoxAction(event, true)
    }
    toggleDialog = () => {
        this.setState({
            visible: !this.state.visible
        });
    }

    componentWillUpdate(e) {

        this.state.candidate_pool_total_value =
            new Intl.NumberFormat({ maximumSignificantDigits: 3 }).format(e.candidateOffers.candidate_pool_total_value)

        this.state.offer_finalist = new Intl.NumberFormat({ maximumSignificantDigits: 0 }).format(e.candidateOffers.offer_finalist)
        this.state.offer_offerStrength = parseInt(e.candidateOffers.offer_offerStrength)
        this.state.offer_recommended_total = new Intl.NumberFormat({ maximumSignificantDigits: 0 }).format(parseInt(e.candidateOffers.offer_recommended_total))

        if (e.candidateOffers.rightSideDashboardData) {
            this.state.base = e.candidateOffers.rightSideDashboardData.offer_base;
            this.state.bonus = e.candidateOffers.rightSideDashboardData.offer_bonus; this.state.annual_equity = e.candidateOffers.rightSideDashboardData.offer_equity;
            this.state.Slider1 = this.state.base;
            this.state.Slider2 = this.state.bonus;
            this.state.Slider3 = this.state.annual_equity;
            this.state.offer_base_percentile = e.candidateOffers.rightSideDashboardData.offer_base_percentile; this.state.offer_bonus_percentile = e.candidateOffers.rightSideDashboardData.offer_bonus_percentile; this.state.offer_Equity_percentile = e.candidateOffers.rightSideDashboardData.offer_equity_percentile;
            this.state.base_bonus_percentage = Math.round((this.state.bonus / this.state.base) * 100);
            var current_offer_value2 = this.state.base + this.state.bonus + this.state.annual_equity
            this.state.current_offer = new Intl.NumberFormat({ maximumSignificantDigits: 0 }).format(current_offer_value2)

        }



    }

    percChangeBase = (value) => {
        let baseBonusPercentage = this.state.base_bonus_percentage;

        if (this.state.change_bonus_percentage_value === true) {
            baseBonusPercentage = this.state.change_base_bonus_percentage
        }
        this.state.change_bonus_percentage_value = false
        if (this.props.location.search !== "") {
            if (value === "offer_down") {
                if (this.state.base_bonus_percentage > 0) {
                    this.state.base_bonus_percentage = parseInt(baseBonusPercentage) - 1

                    this.props.inputValueAction("baseBonusPercentageIconClick", parseInt(baseBonusPercentage) - 1);

                    this.state.bonus = Math.round((this.state.base_bonus_percentage / 100) * this.state.base)
                }

            }
            if (value === "offer_up") {
                this.state.base_bonus_percentage = parseInt(baseBonusPercentage) + 1
                this.props.inputValueAction("baseBonusPercentageIconClick", parseInt(baseBonusPercentage) + 1);
                this.state.bonus = Math.round((this.state.base_bonus_percentage / 100) * this.state.base)
            }
            var current_offer_value = this.state.base + this.state.bonus + this.state.annual_equity
            this.state.current_offer = new Intl.NumberFormat({ maximumSignificantDigits: 0 }).format(current_offer_value)
            this.state.Slider2 = this.state.bonus
            this.props.offerDashRightSideDashboardAction(this.state.base, this.state.bonus, this.state.annual_equity, this.state.offer_base_percentile, this.state.offer_bonus_percentile, this.state.offer_Equity_percentile)
        }
        this.props.offerDashMatchSearchAvgAction(0)
    }
    offerCandidateChange = e => {
        const value = e.target.value;
        this.setState({
            [e.target.name]: value
        })
        this.props.offerDashMatchSearchAvgAction(0)
    }
    render() {

        if (this.props.hiringMembers != undefined) {
            if (this.props.candidates.offerCandidatesData != null) {
                if (this.props.candidates.offerCandidatesData.length === 0 && this.props.location.search.split('?user_id=')[1]) {
                    let queryData = {
                        login_user_id: this.props.auth.user._id,
                        offer_id: this.props.location.search.split('?user_id=')[1],
                        finalist_candidate_id: null
                    }

                    if (this.state.offer_strength_recommended_null === 1) {
                        if (queryData) {
                            this.props.fnFetRecommendedValue(queryData);
                            this.props.fnFetOfferStrengthValue(queryData);
                        }
                    }
                    this.state.offer_strength_recommended_null = 0
                } else if (this.props.candidates.offerCandidatesData[0] != null) {
                    let queryData = {
                        login_user_id: this.props.auth.user._id,
                        offer_id: this.props.location.search.split('?user_id=')[1],
                        finalist_candidate_id: this.props.candidates.offerCandidatesData[0]['_id']
                    }

                    if (this.state.offer_strength_recommended_ids === 1) {
                        if (queryData) {
                            this.props.fnFetRecommendedValue(queryData);
                            this.props.fnFetOfferStrengthValue(queryData);
                        }
                    }
                    this.state.offer_strength_recommended_ids = 0
                }

            }
            this.state.offer_recommended_total = 0;
            if (this.props.location.search !== "") {
                this.state.offer_recommended_total = new Intl.NumberFormat({ maximumSignificantDigits: 0 }).format(parseInt(this.props.hiringMembers.recommendedValue))
            }

            this.state.offer_offerStrength = parseInt(this.props.hiringMembers.offerStrengthValue)
        }
        if (this.props.location.search.split('?user_id')[1] !== undefined) {
            this.state.hiring_role_ids = this.props.location.search.split('?user_id=')[1]
            if (this.state.hiring_role_ids && this.state.call === 1) {
                const queryData = {
                    hiring_role_id: this.state.hiring_role_ids,
                    hr_id: this.props.auth.user._id
                }
                this.props.fnGetSavedCandidateOffers(queryData);
                const candidatesQueryData = {
                    new_hire_id: this.state.hiring_role_ids,
                    hr_id: this.props.auth.user._id
                }
                this.props.fnGetAllCandidatesFromOfferDashboard(candidatesQueryData);
                this.state.call = 0
            }

        }
        if (this.props.hiringMembers.offersData) {
            const OffersDataList = this.props.hiringMembers.offersData
            const offeredCandidates = []
            if (this.props.location.search !== "") {
                for (let i = 0; i < OffersDataList.length; i++) {
                    offeredCandidates.push(OffersDataList[i].name)
                }
            }

            this.state.offeredCandidates = offeredCandidates


        }

        const itemRender = (props) => {
            return (

                <div style={{ width: "150px" }} onClick={() => this.onIconFlipOpen(`${props.item.value}`)}>
                    <span role="presentation" />
                    {`${props.item.text}`}
                </div >
            );
        };
        const items = [
            { text: 'Saved Offers', value: 'saved_offers' },
            { text: 'Match Search Avg', value: 'match_search_avg' },
            { text: 'Reset Offer', value: 'reset_offer' }
        ];
        if (this.props.location.search !== "") {
            if (this.props.estimateDashboard.roleInsertFlag === 1 && this.state.current_value_call === 1) {
                this.state.current_offer = this.state.target_compensation
                this.state.current_value_call = 0
            }
            if (this.props.estimateDashboard.roleInsertFlag === 1 && this.state.current_value_call === 0) {
                this.state.current_offer = this.state.current_offer
            }
            if (this.props.candidateOffers.matchSearchAverageFlag === 1) {
                this.state.current_offer = this.state.candidate_pool_total_value
            }
        }
        else {
            this.state.current_offer = 0
        }

        if (this.props.location.search === "") {
            this.state.base_bonus_percentage = 0
        }
        return (
            <div className="" >
                {this.state.loading === true ? <Spinner /> : null}
                {this.state.popup_event === "match_search_avg" ?
                    <div>{this.state.visible && <OfferDashOfferMoreMatchSearchButton />}</div> : null
                }
                {this.state.popup_event === "create_role" ?
                    <div>{this.state.visible && <OfferDashInviteButton company={this.state.Company} history={this.props.history} />}</div> : null
                }
                {this.state.popup_event === "reset_offer" ? <div>
                    {this.state.visible && <OfferDashOfferMoreResetOfferButton location={this.props.location} value={this.props.value} offers_name={this.state.offeredCandidates_default_name} />}
                </div> : null
                }
                {this.state.popup_event === "floppy_icon" ? <div>
                    {this.state.visible && <OfferDashOfferSaveButton parentData={this.state} history={this.props.history} />}
                </div> : null
                }
                {this.state.popup_event === "add_hiring_team_member" ? <div>
                    {this.state.visible && <OfferDashbBoardAddHiringTeamMember history={this.props.history} location={this.props.location} company={this.state.Company} />}
                </div> : null
                }
                {this.state.popup_event === "add_Candidate" ? <div>
                    {this.state.visible && <OfferDashbBoardAddCandidate history={this.props.history} location={this.props.location} />}
                </div> : null
                }
                {this.state.popup_event === "saved_offers" ? <div>
                    {this.state.visible && <OfferDashOfferSavedOffers parentData={this.state} history={this.props.history} location={this.props.location} offers_name={this.state.offeredCandidates_default_name} offer_offerStrength={this.state.offer_offerStrength} />}
                </div> : null
                }
                <div className="row fixed-dashboards offer-fixed-dashboard" style={{ marginRight: "0px", width: "106%" }}>

                    {this.props.location.search !== "" ? <div className="col-md-9  col-sm-6 col-xs-6 col-lg-9 paddingRight" style={{ fontSize: "18px" }} >
                        <h3 style={{ fontWeight: "300", fontSize: "18px" }}>
                            Talent Market: {this.state.Title}, {this.state.Function} - {this.props.hiringMembers.roleData.role_company} - {this.state.Region}</h3>
                    </div> : <div className="col-md-9  col-sm-6 col-xs-6 col-lg-9 paddingRight" style={{ fontSize: "18px" }} > <Button style={{ backgroundColor: "white" }}
                        onClick={() => this.onIconFlipOpen("create_role")}>
                        Create Role
                        </Button></div>
                    }
                    {this.props.location.search !== "" ?
                        <div className="col-md-3 col-sm-6 col-xs-6 col-lg-3 paddingRight" >
                            <div className="float-right power_user_dashboard-icon-save" style={{ cursor: "pointer" }} onClick={() => this.onIconFlipOpen("add_hiring_team_member")}>
                                <i title="Invite Hiring Team Member" className="k-icon k-i-user" aria-hidden="true"
                                    style={{
                                        margin: "auto", color: "rgba(255,255,255,0.87)", fontSize: "24px", lineHeight: "1", marginLeft: "8px", marginTop: "8px"
                                    }}
                                >
                                </i>
                            </div>
                            <div className="float-right power_user_dashboard-icon-save" style={{ cursor: "pointer" }} onClick={() => this.onIconFlipOpen("add_Candidate")}>
                                <i title="Invite Candidate" className="k-icon k-i-flip-vertical" aria-hidden="true"
                                    style={{
                                        margin: "auto", color: "rgba(255,255,255,0.87)", fontSize: "24px", lineHeight: "1", marginLeft: "8px", marginTop: "8px"
                                    }}
                                >
                                </i>
                            </div>
                        </div> : null}

                </div>
                <div className="row fixed-dashboards dashboard-input-wrapper" style={{ paddingLeft: "5px" }}>
                    <div className="col-md-3 paddingRight"  >
                        <div className="div-currentOffer">
                            <div className="font-currentOffer">CURRENT OFFER</div>
                            <div className="font-currentOffer-dollar"><small style={{ fontSize: "60%" }}>$</small>  {this.state.current_offer || 0}</div>
                        </div>
                    </div>
                    <div className="col-md-3 paddingRight">
                        < div className="div-finalist">
                            <div className="font-finalist">FINALIST</div>
                            <div className="font-finalist-dollar"><small style={{ fontSize: "60%" }}>$</small>  {this.state.offer_finalist || 0}</div>
                        </div>
                    </div>
                    <div className="col-md-3 paddingRight">
                        <div className="div-candidatepool">
                            <div style={{ marginBottom: "5px" }}>
                                <DropDownList
                                    data={constValues.offerdashboard}
                                    name="offer_candidate_pool"
                                    onChange={this.onChange}
                                    value={this.state.offer_candidate_pool}
                                    defaultValue="RECOMMENDED"
                                    style={{ width: "100%", fontSize: "14PX", borderStyle: "none" }}
                                />
                            </div>
                            {this.state.offer_candidate_pool_visible === true ?
                                <div className="font-candidatepool-offerstrength-dollar"><small style={{ fontSize: "60%" }}>$</small>  {this.state.candidate_pool_total_value}</div> : <div className="font-candidatepool-offerstrength-dollar"><small style={{ fontSize: "60%" }}>$</small>  {this.state.offer_recommended_total}</div>}
                        </div>
                    </div>
                    <div className="col-md-3 paddingRight">
                        <div className="div-offerstrength">
                            <div className="font-offerstrength" >OFFER STRENGTH</div>
                            <div className="font-candidatepool-offerstrength-dollar"> {this.state.offer_offerStrength || 0}%</div>
                        </div>
                    </div>

                </div>
                <div className="row fixed-dashboards dashboard-input-wrapper" style={{ paddingLeft: "5px" }}>
                    <div className="row col-md-6 col-sm-12 col-xs-12 col-lg-6 paddingRight">
                        <div className="col-md-2 col-sm-6 col-xs-6 col-lg-2">
                            <h3 style={{ fontSize: "18px", paddingTop: "3px" }}>Offers:</h3></div>
                        <div className="col-md-4 col-sm-6 col-xs-6 col-lg-4" style={{ marginBottom: "15px" }}>
                            <DropDownList
                                name="offeredCandidates"
                                style={{ width: "100%", fontSize: "14PX", paddingLeft: "10px" }}
                                data={this.state.offeredCandidates}
                                onChange={this.onChange}
                                // value={this.state.offeredCandidates_default_name}
                                defaultValue={this.state.offeredCandidates[0]}
                            />
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-12 col-xs-12 col-lg-3 paddingRight">

                    </div>
                    <div className="col-md-3 col-sm-12 col-xs-12 col-lg-3  paddingRight" style={{ marginTop: "0px", marginLeft: "10px", display: "flex" }}>

                        <div className="col-md-9 col-sm-4 col-xs-4 col-lg-9">
                            <div onClick={() => this.onIconFlipOpen("floppy_icon")}><span title="Save" className="fa fa-floppy-o offer-offerlayout-icon" style={{ marginRight: "0px", fontSize: "21px", cursor: "pointer" }}></span></div>
                        </div>
                        <div className="col-md-2 col-sm-6 col-xs-6 col-lg-2 offer-popup-button float-right" style={{ paddingRight: "0px", width: "50%" }} title="more">
                            <DropDownButton icon="k-icon k-i-more-vertical" dir="rtl" itemRender={itemRender}
                                items={items}
                                title="more"
                            />
                        </div>
                    </div>

                </div>
                <div className="row fixed-dashboards dashboard-input-wrapper" style={{ paddingBottom: "0px" }}>
                    <div className="row col-md-4 col-lg-4 col-sm-12 col-xs-12 paddingRight" style={{ marginRight: "30px", paddingLeft: "0px" }}>

                        <div className="col-md-8 numeric-input-textbox-width" style={{ marginTop: "10px" }}>
                            <NumericTextBox
                                name="base"
                                onChange={this.onChange}
                                spinners={false}
                                format="c0"
                                min={0}
                                max={1000000}
                                maxLength={2}
                                data={this.props.lookups.stageLookUp.data}
                                label="Base"
                                value={this.state.base}
                                placeholder="$XXXX"
                                style={{ width: '10px' }}
                            />
                        </div>
                        <div className="col-md-4 numeric-textbox-width" style={{ marginTop: "10px" }}>
                            <NumericTextBox
                                name="offer_base_percentile"
                                onChange={this.onChange}
                                data={this.props.lookups.stageLookUp.data}
                                label="PCTL"
                                spinners={false}
                                value={this.state.offer_base_percentile || null}
                                placeholder="XX"
                                style={{ width: '10px' }}
                            />

                        </div>
                        <div className="col-md-12" style={{ paddingTop: "25px", paddingRight: "10px" }}>
                            <Slider
                                min={1}
                                name="Slider1"
                                max={1000000}
                                step={1}
                                style={{ width: "100%" }}
                                value={this.state.Slider1}
                                onChange={this.onChange}
                            >
                            </Slider>
                        </div>
                    </div>
                    <div className="row col-md-4 col-lg-4 col-sm-12 col-xs-12 paddingRight" style={{ marginRight: "30px", paddingLeft: "0px" }}>
                        {/* <div className="col-md-2 numeric-input-textbox-width" style={{
                            marginTop: "-28px", float: "right", padding: "0px", textAlign: "center"
                        }}>
                            <i className="fa fa-caret-up offer_arrow-up-down" aria-hidden="true" onClick={() => this.percChangeBase('offer_up')} style={{ marginTop: "10px" }}></i>
                            <div style={{ color: "rgba(0,0,0,0.65)", fontWeight: "300", fontSize: "12px", width: "50px", paddingBottom: "9px", float: "center" }}>
                                Bonus %
                            </div>
                            <div style={{ color: "rgba(0,0,0,0.87)", fontSize: "16px", borderBottom: "1px solid rgba(0,0,0,0.42)", paddingBottom: "5px" }}>{this.state.base_bonus_percentage || 0}%</div>
                            <i className="fa fa-caret-down offer_arrow-up-down" aria-hidden="true" onClick={() => this.percChangeBase('offer_down')}></i>
                        </div> */}
                        <div className="col-md-3 numeric-textbox-width-bonus" style={{ marginTop: "-23px", marginLeft: "-10px" }}>
                            <i className="fa fa-caret-up offer_arrow-up-down" aria-hidden="true" onClick={() => this.percChangeBase('offer_up')} style={{ marginTop: "10px" }}></i>
                            <NumericTextBox
                                name="base_bonus_percentage"
                                onChange={this.onChange}
                                label="Bonus%"
                                spinners={false}
                                value={this.state.change_bonus_percentage_value === true ? this.state.change_base_bonus_percentage : this.state.base_bonus_percentage}
                                min={0}
                                placeholder="XX%"
                                style={{ width: '10px' }}
                            />
                            <i className="fa fa-caret-down offer_arrow-up-down" aria-hidden="true" onClick={() => this.percChangeBase('offer_down')}></i>
                        </div>

                        <div className="col-md-5 numeric-input-textbox-width" style={{ marginTop: "10px" }}>
                            <NumericTextBox
                                name="bonus"
                                spinners={false}
                                format="c0"
                                min={0}
                                max={1000000}
                                maxLength={2}
                                onChange={this.onChange}
                                data={this.props.lookups.stageLookUp.data}
                                label="Bonus"
                                value={this.state.change_bonus !== undefined ? this.state.change_bonus : this.state.bonus}
                                placeholder="$XXXX"
                                style={{ width: '100%' }}
                            />
                        </div>
                        <div className="col-md-4 numeric-textbox-width" style={{ marginTop: "10px" }}>
                            <NumericTextBox
                                name="offer_bonus_percentile"
                                onChange={this.onChange}
                                data={this.props.lookups.stageLookUp.data}
                                label="PCTL"
                                spinners={false}
                                value={this.state.offer_bonus_percentile || null}
                                placeholder="XX"
                                style={{ width: '100%' }}
                            />
                        </div>
                        <div className="col-md-12" style={{ paddingTop: "5px", paddingRight: "10px" }}>
                            <Slider
                                name="Slider2"
                                min={1}
                                max={1000000}
                                step={1}
                                style={{ width: "100%" }}
                                value={this.state.Slider2}
                                onChange={this.onChange}
                            >
                            </Slider>
                        </div>
                    </div>
                    <div className="row col-md-4 col-lg-4 col-sm-12 col-xs-12 paddingRight" style={{ paddingLeft: "0px", }} >
                        <div className="col-md-8 numeric-input-textbox-width" style={{ marginTop: "10px", marginBottom: "18px" }}>
                            {this.state.checked === true ? <NumericTextBox
                                name="annual_equity"
                                spinners={false}
                                onChange={this.onChange}
                                data={this.props.lookups.stageLookUp.data}
                                label="Annual Equity"
                                format="c0"
                                min={0}
                                max={10000000}
                                maxLength={2}
                                value={this.state.annual_equity}
                                placeholder="$XXXX"
                                style={{ width: '100%' }}
                            /> : <div className="numeric2-textbox-width"><NumericTextBox
                                name="equity"
                                onChange={this.onChange}
                                data={this.props.lookups.stageLookUp.data}
                                label="Equity %"
                                value={this.state.equity}
                                spinners={false}
                                format="p"
                                min={0}
                                max={1}
                                step={0.1}
                                maxLength={2}
                                placeholder="XX%"
                                style={{ width: '100%' }}
                            /></div>}
                            <Switch onChange={this.toggleSwitch} /> <p className="offer-dashboard-paragraph">%</p>
                        </div>
                        <div className="col-md-4 numeric-textbox-width" style={{ marginTop: "10px" }}>
                            <NumericTextBox
                                name="offer_Equity_percentile"
                                onChange={this.onChange}
                                data={this.props.lookups.stageLookUp.data}
                                label="PCTL"
                                spinners={false}
                                value={this.state.offer_Equity_percentile || null}
                                placeholder="XX"
                                style={{ width: '100%' }}
                            />
                        </div>
                        <div className="col-md-12" style={{ paddingTop: "25px", paddingRight: "10px" }}>
                            <Slider
                                name="Slider3"
                                min={1}
                                max={10000000}
                                step={1}
                                style={{ width: "100%" }}
                                value={this.state.Slider3}
                                onChange={this.onChange}
                            >
                            </Slider>
                        </div>
                    </div>

                </div>


            </div >
        )
    }
}

offerDashboardInputs.propTypes = {
    auth: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    hiringMembers: PropTypes.object.isRequired
};

const mapStateToProps = state =>
    ({
        lookups: state.lookups,
        auth: state.auth,
        estimateDashboard: state.estimateDashboard,
        powerUserDashBoard: state.powerUserDashBoard,
        candidateOffers: state.candidateOffers,
        candidates: state.candidates,
        hiringMembers: state.hiringMembers,
        recommendedValue: state.recommendedValue
    });
export default connect(mapStateToProps, {
    getTitleLookUpData,
    getFunctionLookUpData,
    getCompanyLookUpData,
    getgraphAPIListMarketSpaceLookUpData,
    getCompanyLocationRegionLookUpData,
    getcompanyLocationNoOfEmployeesLookUpData,
    getStageLookUpData,
    getCompanyMarketStageRegionLookUp,
    inputValueAction,
    offerDashDialogBoxAction,
    FnInsertRole,
    addUpdateCreateOfferDashboard,
    candidateDataAction,
    fnComparisonCompensationEstimateDashboard,
    offerDashRightSideDashboardAction,
    fnGetSavedCandidateOffers,
    fnGetAllCandidatesFromOfferDashboard,
    fnFetRecommendedValue,
    fnFetOfferStrengthValue,
    offerDashMatchSearchAvgAction
}
)(offerDashboardInputs);
