import React from 'react'
import '../../css/dashboard.css';
import { NumericTextBox } from '@progress/kendo-react-inputs';
import { connect } from "react-redux";
import {
  Chart,
  ChartArea,
  ChartSeries,
  ChartSeriesItem,
  ChartSeriesLabels,
  ChartLegend,
  ChartTooltip
} from '@progress/kendo-react-charts';
import 'hammerjs';
import { fnEstimateDashboard, inputValueAction, fnComparisonCompensationEstimateDashboard } from "../../actions/dashboard";
import { Button } from '@progress/kendo-react-buttons';

export class RightSide extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      base_percentile: "75",
      selected: 0,
      compensationBase: "",
      compensationBonuses: "",
      baselock: false,
      bonuslock: false,
      equitylock: true,
      compensationEquity: 0,
      isDisabledBase: false,
      isDisabledBonus: false,
      isDisabledEquity: false,
      compensationPercentBase: "",
      compensationPercentBonuses: "",
      compensationPercentEquity: "",
      compensationBase: "",
      compensationBonuses: "",
      compensationEquity: "",
      compensationTotalValue: "",
      bonus_percentage_value: 10,
      bonusPercentageChange: false
    }
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.estimateDashboard.estimateDashboard.message) {
      var name = nextProps.estimateDashboard.estimateDashboard.base_percentile;
    }
    return {
      name: name
    }
  }

  percChange = (value) => {
    let donutPercent = this.state.base_percentile;

    if (value === "up") {
      if (this.state.base_percentile < 100) {
        this.setState({
          base_percentile: parseInt(donutPercent) + 1
        });
        this.props.value.base_percentile = (parseInt(donutPercent) + 1) / 100;
        this.props.inputValueAction("donutarrow", parseInt(donutPercent) + 1);
      }

    } else if (value === "down") {
      if (this.state.base_percentile > 0) {
        this.setState({
          base_percentile: parseInt(donutPercent) - 1
        });
        this.props.value.base_percentile = (parseInt(donutPercent) - 1) / 100;
        this.props.inputValueAction("donutarrow", parseInt(donutPercent) - 1);
      }


    }


    const userDashBoardData = {
      Title: this.props.value.Title,
      Function: this.props.value.Function,
      role_years: this.props.value.role_years,
      base_percentile: (this.state.base_percentile / 100).toString(),
      MarketSpace: this.props.inputValues.MarketSpace,
      Company: this.props.inputValues.Company,
      Region: this.props.inputValues.Region,
      Stage: this.props.estimateDashboard.hiddenName
    }
    this.props.fnComparisonCompensationEstimateDashboard(userDashBoardData);
    this.state.bonusPercentageChange = "base_percentile_change"
    // this.props.fnEstimateDashboard(userDashBoardData);

  }
  percChangeBase = (value) => {
    let bonusPercentage = this.state.bonus_percentage_value;

    if (this.state.bonuslock === false) {
      if (value === "left") {
        if (this.state.bonus_percentage_value !== 0) {
          this.state.bonus_percentage_value = parseInt(bonusPercentage) - 1
          this.state.bonus_percentage_onchange_value = this.state.bonus_percentage_value
          this.props.inputValueAction("bonusPercentageIconClick", parseInt(bonusPercentage) - 1);
          this.props.estimateDashboard.estimateDashboard.data.offer['bonuses'] = (this.state.bonus_percentage_value / 100) * this.props.estimateDashboard.estimateDashboard.data.offer['base']
          if (this.state.baselock === false) {
            this.props.estimateDashboard.estimateDashboard.data.offer['base'] = this.props.estimateDashboard.estimateDashboard.data.offer['total'] - (this.props.estimateDashboard.estimateDashboard.data.offer['bonuses'] + this.props.estimateDashboard.estimateDashboard.data.offer['equity'])
          } else {
            this.props.estimateDashboard.estimateDashboard.data.offer['equity'] = this.props.estimateDashboard.estimateDashboard.data.offer['total'] - (this.props.estimateDashboard.estimateDashboard.data.offer['bonuses'] + this.props.estimateDashboard.estimateDashboard.data.offer['base'])
          }
          this.props.estimateDashboard.estimateDashboard.data.offer['base'] = this.props.estimateDashboard.estimateDashboard.data.offer['total'] - (this.props.estimateDashboard.estimateDashboard.data.offer['bonuses'] + this.props.estimateDashboard.estimateDashboard.data.offer['equity'])
          this.props.estimateDashboard.estimateDashboard.data.offer['cash'] = this.props.estimateDashboard.estimateDashboard.data.offer['bonuses'] + this.props.estimateDashboard.estimateDashboard.data.offer['base']

          this.props.estimateDashboard.estimateDashboard.data.offer['total'] =
            this.props.estimateDashboard.estimateDashboard.data.offer['equity'] + this.props.estimateDashboard.estimateDashboard.data.offer['cash']
        }

      }
      if (value === "right") {
        if (this.state.bonus_percentage_value !== 100) {
          this.state.bonus_percentage_value = parseInt(bonusPercentage) + 1
          this.state.bonus_percentage_onchange_value = this.state.bonus_percentage_value
          this.props.inputValueAction("bonusPercentageIconClick", parseInt(bonusPercentage) + 1);
          this.props.estimateDashboard.estimateDashboard.data.offer['bonuses'] = (this.state.bonus_percentage_value / 100) * this.props.estimateDashboard.estimateDashboard.data.offer['base']

          if (this.state.baselock === false) {
            this.props.estimateDashboard.estimateDashboard.data.offer['base'] = this.props.estimateDashboard.estimateDashboard.data.offer['total'] - (this.props.estimateDashboard.estimateDashboard.data.offer['bonuses'] + this.props.estimateDashboard.estimateDashboard.data.offer['equity'])
          } else {
            this.props.estimateDashboard.estimateDashboard.data.offer['equity'] = this.props.estimateDashboard.estimateDashboard.data.offer['total'] - (this.props.estimateDashboard.estimateDashboard.data.offer['bonuses'] + this.props.estimateDashboard.estimateDashboard.data.offer['base'])
          }

          this.props.estimateDashboard.estimateDashboard.data.offer['cash'] = this.props.estimateDashboard.estimateDashboard.data.offer['bonuses'] + this.props.estimateDashboard.estimateDashboard.data.offer['base']

          this.props.estimateDashboard.estimateDashboard.data.offer['total'] =
            this.props.estimateDashboard.estimateDashboard.data.offer['equity'] + this.props.estimateDashboard.estimateDashboard.data.offer['cash']
        }

      }
    }


  }

  handleSelect = (e) => {
    this.setState({ selected: e.selected })
  }
  toggleClass = (value) => {

    if (value === 'base') {
      if (!this.state.baselock) {
        this.setState({
          baselock: true,
          bonuslock: false,
          equitylock: false,
          isDisabledBase: true,
          isDisabledBonus: false,
          isDisabledEquity: false
        });
      } else {
        this.setState({
          baselock: false,
          bonuslock: false,
          equitylock: true,
          isDisabledBase: false,
          isDisabledBonus: false,
          isDisabledEquity: true
        });
      }
    } else if (value === 'bonus') {
      if (!this.state.bonuslock) {
        this.setState({
          baselock: false,
          bonuslock: true,
          equitylock: false,
          isDisabledBase: false,
          isDisabledBonus: true,
          isDisabledEquity: false
        });
      } else {
        this.setState({
          baselock: false,
          bonuslock: false,
          equitylock: true,
          isDisabledBase: false,
          isDisabledBonus: false,
          isDisabledEquity: true
        });
      }
    } else if (value === 'equity') {
      if (!this.state.equitylock) {
        this.setState({
          baselock: false,
          bonuslock: false,
          equitylock: true,
          isDisabledBase: false,
          isDisabledBonus: false,
          isDisabledEquity: true
        });
      } else {
        this.setState({
          baselock: false,
          bonuslock: false,
          equitylock: true,
          isDisabledBase: false,
          isDisabledBonus: false,
          isDisabledEquity: true
        });
      }
    }
  }

  componentDidUpdate(e) {
    const { estimateDashboard } = this.props.estimateDashboard

    if (estimateDashboard.message) {
      // if ((estimateDashboard.data.compensation.length) > 0) {
      this.state.compensationPercentBase = (estimateDashboard.data.offer['percent_base']) / 100;
      this.state.compensationPercentBonuses = (estimateDashboard.data.offer['percent_bonuses']) / 100;
      this.state.compensationPercentEquity = (estimateDashboard.data.offer['percent_equity']) / 100;

      // Get Cash, Bonus, Equity Values 
      this.state.compensationBase = estimateDashboard.data.offer['base'];
      this.state.compensationBonuses = estimateDashboard.data.offer['bonuses'];
      this.state.compensationEquity = estimateDashboard.data.offer['equity'];
      this.state.compensationTotalValue = estimateDashboard.data.offer['total'];
      // }

    }
  }

  percentChange = (e) => {
    let { value, name } = e.target;
    this.setState({
      [name]: value
    });
    console.log(e.target.name)
    const { estimateDashboard } = this.props.estimateDashboard;

    let compensationTotalAmount = estimateDashboard.data.offer['total']

    if (e.target.name === 'compensationPercentBase') {

      if (this.state.bonuslock === false) {
        let bonusPercent = 1 - (e.target.value + this.state.compensationPercentEquity);

        estimateDashboard.data.offer['percent_bonuses'] = e.target.value;

        // For dount percentage
        estimateDashboard.data.offer['percent_base'] = Math.round(e.target.value * 100);
        estimateDashboard.data.offer['percent_bonuses'] = Math.round(bonusPercent * 100);

        // For Amount based on percentage
        estimateDashboard.data.offer['base'] = Math.round(e.target.value * compensationTotalAmount);
        estimateDashboard.data.offer['bonuses'] = Math.round(bonusPercent * compensationTotalAmount);

        this.props.estimateDashboard.value = e.target.value;
        this.setState({
          compensationPercentBonuses: bonusPercent
        });

      } else {
        let equityPercent = 1 - (e.target.value + this.state.compensationPercentBonuses);

        // For dount percentage
        estimateDashboard.data.offer['percent_base'] = Math.round(e.target.value * 100);
        estimateDashboard.data.offer['percent_equity'] = Math.round(equityPercent * 100);

        // For Amount based on percentage
        estimateDashboard.data.offer['base'] = Math.round(e.target.value * compensationTotalAmount);
        estimateDashboard.data.offer['equity'] = Math.round(equityPercent * compensationTotalAmount);
        this.setState({
          compensationPercentEquity: equityPercent,
        });
      }

    } else if (e.target.name === 'compensationPercentBonuses') {
      if (this.state.baselock === false) {
        let basePercent = 1 - (e.target.value + this.state.compensationPercentEquity);

        // For dount percentage
        estimateDashboard.data.offer['percent_bonuses'] = Math.round(e.target.value * 100);
        estimateDashboard.data.offer['percent_base'] = Math.round(basePercent * 100);

        // For Amount based on percentage
        estimateDashboard.data.offer['base'] = Math.round(basePercent * compensationTotalAmount);
        estimateDashboard.data.offer['bonuses'] = Math.round(e.target.value * compensationTotalAmount);

        this.props.estimateDashboard.value = e.target.value;

        this.state.compensationPercentBase = basePercent;
      } else {
        let equityPercent = 1 - (e.target.value + this.state.compensationPercentBase);

        // For dount percentage
        estimateDashboard.data.offer['percent_bonuses'] = Math.round(e.target.value * 100);
        estimateDashboard.data.offer['percent_equity'] = Math.round(equityPercent * 100);

        // For Amount based on percentage
        estimateDashboard.data.offer['equity'] = Math.round(equityPercent * compensationTotalAmount);
        estimateDashboard.data.offer['bonuses'] = Math.round(e.target.value * compensationTotalAmount);

        this.setState({
          compensationPercentEquity: equityPercent,
        });
      }

    } else if (e.target.name === 'compensationPercentEquity') {
      if (this.state.baselock === false) {
        let basePercent = 1 - (e.target.value + this.state.compensationPercentBonuses);

        // For dount percentage
        estimateDashboard.data.offer['percent_equity'] = Math.round(e.target.value * 100);
        estimateDashboard.data.offer['percent_base'] = Math.round(basePercent * 100);

        // For Amount based on percentage
        estimateDashboard.data.offer['base'] = Math.round(basePercent * compensationTotalAmount);
        estimateDashboard.data.offer['equity'] = Math.round(e.target.value * compensationTotalAmount);

        this.state.compensationPercentBase = basePercent;
        this.props.estimateDashboard.value = e.target.value;
        this.setState({
          compensationPercentBase: basePercent,
        });
      } else {
        let bonusPercent = 1 - (e.target.value + this.state.compensationPercentBase);

        // For dount percentage
        estimateDashboard.data.offer['percent_equity'] = Math.round(e.target.value * 100);
        estimateDashboard.data.offer['percent_bonuses'] = Math.round(bonusPercent * 100);

        // For Amount based on percentage
        estimateDashboard.data.offer['bonuses'] = Math.round(bonusPercent * compensationTotalAmount);
        estimateDashboard.data.offer['equity'] = Math.round(e.target.value * compensationTotalAmount);
        this.setState({
          compensationPercentBonuses: bonusPercent,
        });
      }

    }
    estimateDashboard.data.offer['cash'] = estimateDashboard.data.offer['base'] + estimateDashboard.data.offer['bonuses']
    this.state.bonus_percentage_value = (estimateDashboard.data.offer['bonuses'] / estimateDashboard.data.offer['base']) * 100;
    this.state.bonusPercentageChange = false;
    this.props.inputValueAction(name, value);
  }

  valueChange = (e) => {
    let { value, name } = e.target;
    this.setState({
      [name]: value
    });

    const { estimateDashboard } = this.props.estimateDashboard;

    let compensationTotalAmount = estimateDashboard.data.offer['total'];
    let compensationBaseAmount = estimateDashboard.data.offer['base'];
    let compensationBonusesAmount = estimateDashboard.data.offer['bonuses'];
    let compensationEquityAmount = estimateDashboard.data.offer['equity'];

    if (e.target.name === 'compensationBase') {

      if (this.state.bonuslock === false) {
        let bonusValue = compensationTotalAmount - (e.target.value + compensationEquityAmount);
        estimateDashboard.data.offer['bonuses'] = bonusValue;

        let basePercent = Math.round((e.target.value / compensationTotalAmount) * 100);
        let bonusPercent = Math.round((bonusValue / compensationTotalAmount) * 100);
        let equityPercent = Math.round(100 - (basePercent + bonusPercent));

        estimateDashboard.data.offer['percent_base'] = basePercent;
        estimateDashboard.data.offer['percent_bonuses'] = bonusPercent;
        estimateDashboard.data.offer['percent_equity'] = equityPercent;

      } else {
        let equityValue = compensationTotalAmount - (e.target.value + compensationBonusesAmount);
        estimateDashboard.data.offer['equity'] = equityValue;

        let basePercent = Math.round((e.target.value / compensationTotalAmount) * 100);
        let equityPercent = Math.round((equityValue / compensationTotalAmount) * 100);
        let bonusPercent = Math.round(100 - (basePercent + equityPercent));

        estimateDashboard.data.offer['percent_base'] = basePercent;
        estimateDashboard.data.offer['percent_bonuses'] = bonusPercent;
        estimateDashboard.data.offer['percent_equity'] = equityPercent;
      }
      estimateDashboard.data.offer['base'] = e.target.value
      this.state.bonus_percentage_value = (estimateDashboard.data.offer['bonuses'] / estimateDashboard.data.offer['base']) * 100;
    } else if (e.target.name === 'compensationBonuses') {

      if (this.state.baselock === false) {
        let baseValue = compensationTotalAmount - (e.target.value + compensationEquityAmount);
        estimateDashboard.data.offer['base'] = baseValue;

        let basePercent = Math.round((baseValue / compensationTotalAmount) * 100);
        let bonusPercent = Math.round((e.target.value / compensationTotalAmount) * 100);
        let equityPercent = Math.round(100 - (basePercent + bonusPercent));

        estimateDashboard.data.offer['percent_base'] = basePercent;
        estimateDashboard.data.offer['percent_bonuses'] = bonusPercent;
        estimateDashboard.data.offer['percent_equity'] = equityPercent;

      } else {
        let equityValue = compensationTotalAmount - (e.target.value + compensationBaseAmount);
        estimateDashboard.data.offer['equity'] = equityValue;

        let bonusPercent = Math.round((e.target.value / compensationTotalAmount) * 100);
        let equityPercent = Math.round((equityValue / compensationTotalAmount) * 100);
        let basePercent = Math.round(100 - (bonusPercent + equityPercent));

        estimateDashboard.data.offer['percent_base'] = basePercent;
        estimateDashboard.data.offer['percent_bonuses'] = bonusPercent;
        estimateDashboard.data.offer['percent_equity'] = equityPercent;
      }
      estimateDashboard.data.offer['bonuses'] = e.target.value

      this.state.bonus_percentage_value = parseFloat((estimateDashboard.data.offer['bonuses'] / estimateDashboard.data.offer['base']) * 100).toFixed(3);
    } else if (e.target.name === 'compensationEquity') {

      if (this.state.baselock === false) {
        let baseValue = compensationTotalAmount - (e.target.value + compensationBonusesAmount);
        estimateDashboard.data.offer['base'] = baseValue;

        let basePercent = Math.round((baseValue / compensationTotalAmount) * 100);
        let equityPercent = Math.round((e.target.value / compensationTotalAmount) * 100);
        let bonusPercent = Math.round(100 - (basePercent + equityPercent));

        estimateDashboard.data.offer['percent_base'] = basePercent;
        estimateDashboard.data.offer['percent_bonuses'] = bonusPercent;
        estimateDashboard.data.offer['percent_equity'] = equityPercent;

      } else {
        let bonusValue = compensationTotalAmount - (e.target.value + compensationBaseAmount);
        estimateDashboard.data.offer['bonuses'] = bonusValue;

        let bonusPercent = Math.round((bonusValue / compensationTotalAmount) * 100);
        let equityPercent = Math.round((e.target.value / compensationTotalAmount) * 100);
        let basePercent = Math.round(100 - (bonusPercent + equityPercent));

        estimateDashboard.data.offer['percent_base'] = basePercent;
        estimateDashboard.data.offer['percent_bonuses'] = bonusPercent;
        estimateDashboard.data.offer['percent_equity'] = equityPercent;
      }
      estimateDashboard.data.compensation['equity'] = e.target.value
    }
    estimateDashboard.data.offer['cash'] = estimateDashboard.data.offer['base'] + estimateDashboard.data.offer['bonuses']
    this.state.bonus_percentage_onchange_value = (estimateDashboard.data.offer['bonuses'] / estimateDashboard.data.offer['base']) * 100;
    this.state.bonusPercentageChange = true
    this.props.inputValueAction(name, value);
  }


  render() {

    if (this.state.equitylock === true) {
      this.state.isDisabledEquity = true;
    }
    if (this.props.estimateDashboard.name === "pick_benchMark" || this.props.estimateDashboard.name === "base_percentile") {
      this.state.base_percentile = this.props.estimateDashboard.value
    }

    const { estimateDashboard } = this.props.estimateDashboard;

    const donutCenterRenderer = () => (<div className="apollo-percentile-circle percentile-dashboard-up-down Cash text-center" style={{ background: "#58628F", borderRadius: "100%", lineHeight: "20px", width: "60px", border: "1px solid white" }} >
      <i className="fa fa-caret-up arrow-up-down" aria-hidden="true" onClick={() => this.percChange('up')}></i>
      <div style={{ color: "white", fontSize: "20px" }}>{this.state.base_percentile}</div>
      <i className="fa fa-caret-down arrow-up-down" aria-hidden="true" onClick={() => this.percChange('down')}></i>
    </div>);
    const renderTooltip = (context) => {
      const { category, value } = context.point || context;
      return (<div>{category}: {value}%</div>);
    };
    if (estimateDashboard.message) {
      // if ((estimateDashboard.data.compensation.length) > 0) {
      var compensationCash = parseFloat(estimateDashboard.data.offer['cash']).toFixed(0);
      var compensationTotal = parseFloat(estimateDashboard.data.offer['total']).toFixed(0);
      var compensationBase = estimateDashboard.data.offer['base'];
      if (this.props.estimateDashboard.name === "compensationBase") {
        var compensationBase = this.props.estimateDashboard.value;
      }
      var compensationBonuses = estimateDashboard.data.offer['bonuses'];
      if (this.props.estimateDashboard.name === "compensationBonuses") {
        var compensationBonuses = this.props.estimateDashboard.value;
      }

      var compensationEquity = estimateDashboard.data.offer['equity'];
      if (this.props.estimateDashboard.name === "compensationEquity") {
        var compensationEquity = this.props.estimateDashboard.value;
      }
      var compensationPercentEquityCompany = estimateDashboard.data.offer['percent_equity_company'];

      var compensationPercentBase = estimateDashboard.data.offer['percent_base'] / 100;
      var compensationPercentBonuses = estimateDashboard.data.offer['percent_bonuses'] / 100;
      var compensationPercentEquity = estimateDashboard.data.offer['percent_equity'] / 100;

      if (this.props.estimateDashboard.name === "compensationPercentBase") {
        var compensationPercentBase = this.props.estimateDashboard.value;
        var compensationPercentBonuses = this.state.compensationPercentBonuses;
        var compensationPercentEquity = this.state.compensationPercentEquity;
      }
      if (this.props.estimateDashboard.name === "compensationPercentBonuses") {
        var compensationPercentBase = this.state.compensationPercentBase;
        var compensationPercentBonuses = this.props.estimateDashboard.value;
        var compensationPercentEquity = this.state.compensationPercentEquity;
      }
      if (this.props.estimateDashboard.name === "compensationPercentEquity") {
        var compensationPercentBase = this.state.compensationPercentBase;
        var compensationPercentBonuses = this.state.compensationPercentBonuses;
        var compensationPercentEquity = this.props.estimateDashboard.value;
      }
      var donutPercentBase = Math.round(estimateDashboard.data.offer['percent_base']);
      var donutPercentBonuses = Math.round(estimateDashboard.data.offer['percent_bonuses']);
      var donutPercentEquity = Math.round(estimateDashboard.data.offer['percent_equity']);
      this.state.bonus_percentage_value = (compensationBonuses / compensationBase) * 100;

      if (this.props.estimateDashboard.name === "bonusPercentageIconClick") {
        if (this.state.bonus_percentage_onchange_value === undefined) {
          this.state.bonus_percentage_value = (compensationBonuses / compensationBase) * 100;
        } else {
          this.state.bonus_percentage_value = this.state.bonus_percentage_onchange_value
        }
      }
      if (this.state.bonusPercentageChange === true) {
        this.state.bonus_percentage_value = this.state.bonus_percentage_onchange_value
      }

    }
    const DOnutChartGetData =
      [
        {
          "kind": "Base",
          "share": donutPercentBase,
          "color": "#809EB0"
        },
        {
          "kind": "Bonus",
          "share": donutPercentBonuses,
          "color": "#81878D"
        },
        {
          "kind": "Equity",
          "share": donutPercentEquity,
          "color": "#FEAA62"
        }
      ]

    const labelContent = (e) => (e.category);
    return (
      <div className=""  >
        <div className="row">
          <div className="col-md-12" style={{ paddingRight: "28px" }}>
            <p className="toggleParagraph">
              <Button className="button right-side-toggle-button" togglable={true} onClick={() => this.forceUpdate()} ref={el => this.button = el}>{this.button && this.button.selected ? 'Total' : 'Cash'}
              </Button>
            </p>
          </div>
          <div className="col-md-12" style={{ textAlign: "center", marginTop: "0px" }}>
            <label style={{ fontSize: "30px", color: "white" }}>{this.button && this.button.selected ? "$" + new Intl.NumberFormat({ maximumSignificantDigits: 3 }).format(compensationTotal || 0) : "$" + new Intl.NumberFormat({ maximumSignificantDigits: 3 }).format(compensationCash || 0)}</label>

          </div>

        </div>
        <div className="chart-donut">
          <Chart donutCenterRender={donutCenterRenderer} className="donutChart" style={{ height: "170px", padding: "0px", marginTop: "-16px" }} >
            <ChartTooltip render={renderTooltip} />
            <ChartArea background="#3b4156" height="200px" padding="0px" />
            <ChartSeries color="#ffb3b3" >
              <ChartSeriesItem type="donut" data={DOnutChartGetData} background="none" categoryField="kind" field="share" width="90px">
                <ChartSeriesLabels color="#fff" background="none" content={labelContent} />
              </ChartSeriesItem>
            </ChartSeries>
            <ChartLegend visible={false} />
          </Chart>
        </div>
        <div className="row text-center" style={{ display: "flex", flexDirection: "row", paddingLeft: "0px" }}>
          <div className="col-md-4 col-sm-4 col-xs-4 col-lg-4">
            <NumericTextBox
              name="compensationPercentBase"
              className="dashboard-input-box"
              type="number"
              format="p"
              spinners={false}
              onChange={this.percentChange}
              required={true}
              min={0}
              max={100}
              disabled={(this.state.isDisabledBase) ? true : false}
              value={compensationPercentBase || 0}
            />
            <br />
            <div
              className={this.state.baselock ? "input-wrapper-lock-dashboard col-md-12 col-lg-12 col-sm-12 col-xs-12" : "input-wrapper-unlock-dashboard col-md-12 col-lg-12 col-sm-12 col-xs-12"}
              onClick={() => this.toggleClass('base')}
              title="Lock/Unlock"
              style={{
                paddingLeft: "5px",
                color: "#9dacdd",
                fontSize: "14px",
                width: "80px",
                paddingLeft: "11px"
              }}>
              Base
                      </div>
          </div>
          <div className="col-md-4 col-sm-4 col-xs-4 col-lg-4">
            <NumericTextBox
              name="compensationPercentBonuses"
              className="dashboard-input-box"
              type="text"
              format="p"
              spinners={false}
              onChange={this.percentChange}
              required={true}
              disabled={(this.state.isDisabledBonus) ? true : false}
              value={compensationPercentBonuses || 0}
            />
            <br />
            <div
              className={this.state.bonuslock ? "input-wrapper-lock-bonus-equity-dashboard col-md-12 col-sm-12 col-xs-12 col-lg-12" : "input-wrapper-unlock-bonus-equity-dashboard col-md-12 col-sm-12 col-xs-12 col-lg-12"}
              onClick={() => this.toggleClass('bonus')}
              title="Lock/Unlock"
              style={{
                paddingLeft: "15px",
                color: "#9dacdd",
                fontSize: "14px",
                width: "80px",
                marginLeft: "3px"
              }}>
              Bonus
                      </div>
          </div>
          <div className="col-md-4 col-sm-4 col-xs-4 col-lg-4">
            <NumericTextBox
              name="compensationPercentEquity"
              className="dashboard-input-box"
              type="text"
              format="p"
              spinners={false}
              onChange={this.percentChange}
              required={true}
              value={compensationPercentEquity || 0}
              disabled={(this.state.isDisabledEquity) ? true : false}
              style={{ color: "white", width: "100%", textAlign: "center", fontSize: "24px" }}
            />
            <br />
            <div
              className={this.state.equitylock ? "input-wrapper-lock-bonus-equity-dashboard col-md-12 col-sm-12 col-xs-12 col-lg-12" : "input-wrapper-unlock-bonus-equity-dashboard col-md-12 col-sm-12 col-xs-12 col-lg-12"}
              onClick={() => this.toggleClass('equity')}
              title="Lock/Unlock"
              style={{
                paddingLeft: "15px",
                color: "#9dacdd",
                fontSize: "14px",
                width: "80px",
                marginLeft: "4px",
              }}>
              Equity
                      </div>
          </div>

        </div>
        <hr style={{ borderBottom: "1px groove white", width: "113%", marginLeft: "-15px" }} />
        <div className="percentile-column percentile-center-donut">
          <div className="apollo-percentile-circle-block text-center" style={{ textAlign: "center", color: "white", fontSize: "18px", marginTop: "20px", marginBottom: "10px" }}>
            Cash
                    </div>
        </div>
        <div className="row text-center" style={{ display: "flex", flexDirection: "row" }}>

          <div className="col-md-8 col-sm-5 col-xs-5 col-lg-8">

          </div>
          <div className="col-md-4 col-sm-6 col-xs-6 col-lg-4" style={{ display: "flex", flexDirection: "row", paddingLeft: "0px" }}>
            <i className="fa fa-caret-left arrow-left-right" aria-hidden="true" onClick={() => this.percChangeBase('left')}></i>
            <div style={{ color: "white", fontSize: "20px" }}>{Math.round(this.state.bonus_percentage_value) + "%"}</div>
            <i className="fa fa-caret-right arrow-left-right" aria-hidden="true" onClick={() => this.percChangeBase('right')}></i>
          </div>
        </div>

        <div className="row text-center" style={{ display: "flex", flexDirection: "row" }}>

          <div className="col-md-6 col-sm-12 col-xs-12 col-lg-6">
            <div
              className={this.state.baselock ? "input-wrapper-lock-cash-base-equity-dashboard col-md-6 col-sm-6 col-xs-6" : "input-wrapper-unlock-cash-base-equity-dashboard col-md-6 col-sm-6 col-xs-6"}
              onClick={() => this.toggleClass('base')}
              title="Lock/Unlock"
              style={{
                paddingLeft: "22px",
                color: "#9dacdd",
                fontSize: "14px",
                width: "80px",
                marginLeft: "10px"
              }}>
              Base
                      </div>
            <div className="col-md-12" style={{ paddingLeft: "0px" }}>
              <NumericTextBox
                name="compensationBase"
                className="data-input-cash-box"
                format="c0"
                spinners={false}
                type="text"
                onChange={this.valueChange}
                required={true}
                value={compensationBase || 287308}
                disabled={(this.state.isDisabledBase) ? true : false}
                style={{ color: "white", width: "100%", fontSize: "24px" }}
              />
            </div>
          </div>
          <div className="col-md-6 col-sm-12 col-xs-12" >
            <div
              className={this.state.bonuslock ? "input-wrapper-lock-cash-bonus-equity-dashboard col-md-6 col-sm-4 col-xs-4" : "input-wrapper-unlock-cash-bonus-equity-dashboard col-md-6 col-sm-4 col-xs-4"}
              onClick={() => this.toggleClass('bonus')}
              title="Lock/Unlock"
              style={{
                paddingLeft: "15px",
                color: "#9dacdd",
                fontSize: "14px",
                width: "80px",
                marginLeft: "10px"
              }}>
              Bonus
                      </div>
            <div className="col-md-12" style={{ paddingLeft: "0px" }}>
              <NumericTextBox
                name="compensationBonuses"
                className="data-input-cash-box"
                format="c0"
                spinners={false}
                type="text"
                onChange={this.valueChange}
                required={true}
                value={compensationBonuses || 0}
                disabled={(this.state.isDisabledBonus) ? true : false}
              /></div>
          </div>
        </div>
        <hr style={{ borderBottom: "1px groove white", width: "113%", marginLeft: "-15px" }} />
        <div className="col-md-12 apollo-section add-15-padding padding-fill-to-bottom" style={{ textAlign: "center", marginTop: "-15px" }}>
          <div className="row left-block-section">
            <div className="apollo-percentile-circle-block text-center" style={{ width: "100%", color: "white", fontSize: "18px", marginTop: "20px" }}>
              Equity
            </div>
            <div className="apollo-percentile-circle-block text-center" style={{ width: "100%", color: "white", fontSize: "18px", marginBottom: "0px" }}>
              <div
                className={this.state.equitylock ? "input-wrapper-lock-equity-annualvalue-dashboard" : "input-wrapper-unlock-equity-annualvalue-dashboard"}
                onClick={() => this.toggleClass('equity')}
                title="Lock/Unlock"
                style={{
                  marginRight: "5px",
                  color: "#9dacdd",
                  fontSize: "14px",
                  textAlign: "center",
                  paddingLeft: "15px",
                  width: "120px"
                }}>
                Annual Value *
                      </div>
            </div>
            <div className="col-md-12 text-center" style={{ marginBottom: "20px" }}>
              <div className="col-md-12" style={{ paddingLeft: "0px", paddingRight: "0px" }} >

                <NumericTextBox
                  name="compensationEquity"
                  className="data-input-annaul-field"
                  type="text"
                  format="c0"
                  spinners={false}
                  onChange={this.valueChange}
                  required={true}
                  value={compensationEquity || 43220}
                  disabled={(this.state.isDisabledEquity) ? true : false}
                  style={{ color: "white", width: "100%", textAlign: "center", fontSize: "24px" }}
                />
              </div>
            </div>
            {this.props.estimateDashboard.hiddenName !== "PUBLIC" ?
              <div className="col-md-12 text-center" style={{ marginBottom: "40px" }}>
                <div
                  style={{
                    marginRight: "5px",
                    color: "#9dacdd",
                    fontSize: "14px",
                    marginTop: "0px",
                    marginLeft: "10px"
                  }}>
                  Percent *
                      </div>
                <div className="col-md-12" style={{ marginLeft: "0px", textAlign: "center", color: "white", width: "100%", textAlign: "center", fontSize: "24px" }} >
                  {parseFloat(compensationPercentEquityCompany).toFixed(2) + "%" || 0}
                </div>
              </div> : null}
          </div>
        </div>

      </div >
    )
  }
}



const mapStateToProps = state =>
  ({
    estimateDashboard: state.estimateDashboard,
  });
export default connect(mapStateToProps, { fnEstimateDashboard, inputValueAction, fnComparisonCompensationEstimateDashboard }
)(RightSide);
