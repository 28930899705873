import React from 'react'
import '../../../../css/dashboard.css';
import { connect } from "react-redux";
import 'hammerjs';
import { inputValueAction, offerDashDialogBoxAction, offerDashMatchSearchAvgAction } from "../../../../actions/dashboard";
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

export class OfferDashOfferSave extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true
    }
  }

  toggleDialog = () => {
    this.setState({
      visible: !this.state.visible
    });
    this.props.offerDashMatchSearchAvgAction(1)
    this.props.offerDashDialogBoxAction(this.state.visible, false)
  }
  toggleDialogNo = () => {
    this.setState({
      visible: !this.state.visible
    });
    this.props.offerDashDialogBoxAction(this.state.visible, false)
  }
  render() {
    if (this.props.estimateDashboard.togglename === "match_search_avg") {
      this.state.visible = true
    } else {
      this.state.visible = false
    }
    return (
      <div>
        {this.state.visible && <Dialog className="div-modal" title={"Are you sure..."} onClose={this.toggleDialog}>
          <p style={{ margin: "25px", fontSize: "16px" }}>This will override the present offer that you have been working on. Do you wish to proceed?</p>
          <div>
            <DialogActionsBar className="modal-button">
              <button className="k-button col-xs-6 col-sm-6" onClick={this.toggleDialogNo}>No</button>
              <button className="k-button col-xs-6 col-sm-6" onClick={this.toggleDialog}>Yes</button>
            </DialogActionsBar>
          </div>
        </Dialog>}
      </div >

    )
  }
}
const mapStateToProps = state =>
  ({
    estimateDashboard: state.estimateDashboard
  });
export default connect(mapStateToProps, { inputValueAction, offerDashDialogBoxAction, offerDashMatchSearchAvgAction }
)(OfferDashOfferSave);
