import React from 'react';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { orderBy } from '@progress/kendo-data-query';
import MyRoleButtons from './GridData.js';
import MyRoleAnchorTag from './GridAnchorTag.js';
import { fnGetAllNewHires, fnDeleteHires } from "../../actions/roleActions";
import "../../css/roleGrid.css"
import PropTypes from "prop-types";
import { offerDashAddMemeberIconAction, offerDashRoleInsertFlagAction } from "../../actions/dashboard";
import Spinner from "../spinner/loading";
import { candidateLkpChangeFlagAction } from "../../actions/candidateOffersAction";

class RolesGrid extends React.Component {
  RoleButtons;
  AnchorTag;
  constructor(props) {
    super(props);
    this.state = {
      RolesList: [],
      success: false,
      skip: 0, take: 10, sort: [
        { field: 'role_title', dir: 'asc' }
      ],
      delete: 0
    };
    this.props.offerDashRoleInsertFlagAction(2)
    this.props.offerDashAddMemeberIconAction(2)
    this.props.candidateLkpChangeFlagAction(0)
    this.RoleButtons = MyRoleButtons("inEdit");
    this.AnchorTag = MyRoleAnchorTag("inEdit");
  }
  pageChange = (event) => {
    this.setState({
      skip: event.page.skip,
      take: event.page.take
    });
  }
  componentDidMount() {
    this.state.success = this.props.role.role.message;

    if (this.props.role.role.message !== undefined) {
      this.setState({
        success: this.props.role.role.message
      })
      setTimeout(() => {
        this.props.role.role.message = false
        this.setState({
          success: false
        });
      }, 1000)
    }
    if (this.props.location.pathname === "/home/delete-role") {
      this.state.loading = true;
      this.state.delete = 1
      fnDeleteHires(this.props.location.search.slice(4)).then(response => {
        this.setState({
          marketSpaces: response.data.message,
          success: response.data.message
        })
        setTimeout(() => {
          this.props.history.push("/home/role")
          this.setState({
            success: false
          });
        }, 1000)
      }
      ).catch(error => console.log(error));
    } else {
      this.props.fnGetAllNewHires(this.props.auth.user._id);
    }
  }
  render() {
    if (this.props.role.rolesData === null) {
      this.state.RolesList = []
    } else {
      this.state.RolesList = this.props.role.rolesData
    }
    if (this.props.role.rolesData !== null) {
      if (this.state.delete === 0) {
        this.state.loading = false
      } else {
        this.state.loading = true
      }
    } else {
      this.state.loading = true
    }
    return (
      <div className="container-fluid role-main-div">
        <div className="role-main-plus-button"> Active Roles
                <Link
            title="Add" type="button" to="/home/new-role" className="k-button role-main-Link-plus-button" ><span className="k-icon k-i-plus"></span></Link>
        </div>
        {this.state.loading === true ? <Spinner /> : null}
        <div>
          {this.state.success && (
            <div
              className="alert alert-success role-alert-message"
              style={{
                bottom: "0px",
                right: "10px",
                position: "fixed"
              }}
            >
              {this.state.success}
            </div>)}
        </div>
        <Grid
          className="role-grid"
          data={orderBy(this.state.RolesList.slice(this.state.skip, this.state.take + this.state.skip), this.state.sort)}
          skip={this.state.skip}
          take={this.state.take}
          total={this.state.RolesList.length}
          pageable={true}
          onPageChange={this.pageChange}
          sortable={true}
          sort={this.state.sort}
          onSortChange={(e) => {
            this.setState({
              sort: e.sort
            });
          }}
        >
          <Column cell={this.AnchorTag} title="TITLE" />
          <Column field="role_function" title="FUNCTION" />
          <Column field="compensation_total_high" filter="numeric" format="{0:c}" title="TARGET COMPENSATION" />
          <Column field="candidates_list.length" filter="numeric" title="CANDIDATES" />
          <Column field="role_status" filter="numeric" title="STATUS" />
          <Column cell={this.RoleButtons} title="ACTION" />
        </Grid>
      </div>
    );
  }
}

RolesGrid.propTypes = {
  auth: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  role: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  role: state.role
});

export default connect(mapStateToProps, { fnGetAllNewHires, offerDashAddMemeberIconAction, offerDashRoleInsertFlagAction, candidateLkpChangeFlagAction }
)(RolesGrid);