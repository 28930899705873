
import React from 'react';
import { GridCell } from '@progress/kendo-react-grid';

export function MyCommandCell({ edit, remove, add, update, discard, cancel, editField }) {
    return class extends GridCell {
        render() {
            const { dataItem } = this.props;
            const inEdit = dataItem[editField];
            const isNewItem = dataItem._id === undefined;
            return inEdit ? (
                <td className="k-command-cell">
                    <button
                        className="k-button k-grid-save-command"
                        onClick={() => isNewItem ? add(dataItem) : update(dataItem)}
                    >
                        {isNewItem ? 'Add' : 'Update'}
                    </button>
                    <button
                        className="k-button k-grid-cancel-command"
                        onClick={() => isNewItem ? discard(dataItem) : cancel(dataItem)}
                    >
                        {isNewItem ? 'Cancel' : 'Cancel'}
                    </button>
                </td>
            ) : (
                    <td className="k-command-cell">
                        <button
                            className="k-button k-grid-edit-command"
                            onClick={() => edit(dataItem)}
                        >
                            <span className="k-icon k-i-edit .k-i-pencil" title="Edit"></span>
                        </button>
                        <button
                        className="k-button k-grid-remove-command"
                        onClick={() => window.confirm('Confirm deleting:') &&remove(dataItem)
                        }
                    >
                         <span className="k-icon k-i-delete .k-i-trash" title="Delete"></span>
                    </button>
                    
                    </td>
                );
        }
    }
};
