import React from 'react'
import '../../../../css/dashboard.css';
import { connect } from "react-redux";
import 'hammerjs';

import { inputValueAction, offerDashDialogBoxAction } from "../../../../actions/dashboard";
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout'
import { Input, NumericTextBox } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import {
  getTitleLookUpData,
  getFunctionLookUpData,
  getCompanyLocationRegionLookUpData,
  getStageLookUpData,
  getgraphAPIListMarketSpaceLookUpData,
  getcompanyLocationNoOfEmployeesLookUpData,
  getCompanyRelatedData,
} from "../../../../actions/lookupAction";
import {
  titlesDataSort,
  functionalityDataSort,
  cityDataSort,
  stateDataSort,
  regionDataSort,
  stageDataSort,
  marketSpaceDataSort,
  employeesDataSort,
  companyForEmployessDataSort
} from "../../../dropdown/lookupSortingData";
import PropTypes from "prop-types";
import { AutoComplete } from '@progress/kendo-react-dropdowns';
import FormValidator from '../../../../validation/FormValidator'
import validationParams from '../../../../validation/validationParams.json'
import {
  FnInsertRoleCandidates,
  FnGetCandidateDetailsInOfferDashboard,
  FnUpdateRoleCandidateMembers,
  FnUpdateOfferedCandidate
} from "../../../../actions/roleCandidateAction"

export class OfferDashUpdateCandidateAddButton extends React.Component {
  constructor(props) {
    super(props);
    this.validator = new FormValidator(validationParams.UpdateOfferDashboardCandidate);
    this.state = {
      visible: true,
      selected: 0,
      success: false,
      first_name: "",
      company: "",
      base_salary: null,
      annual_bonus: null,
      commission: null,
      equity_percent: null,
      equity_dollar_value: null,
      total_dollar_value: null,
      role_candidate_id: null,
      new_hire_id: null,
      candidate_id: null,
      validation: this.validator.valid()
    };
    this.submitted = false;
    this.CandidateFormSubmit = this.CandidateFormSubmit.bind(this);

    this.props.getTitleLookUpData();
    this.props.getFunctionLookUpData();
    this.props.getCompanyLocationRegionLookUpData();
    this.props.getStageLookUpData();
    this.props.getCompanyRelatedData();
    this.props.getgraphAPIListMarketSpaceLookUpData();
    this.props.getcompanyLocationNoOfEmployeesLookUpData();

  }

  onChange = e => {
    const value = e.target.value;
    this.setState({
      [e.target.name]: value,
      titleLookupData: titlesDataSort(value, this.props.lookups.titleLookUp.data),
      functionalityLookupData: functionalityDataSort(value, this.props.lookups.functionLookUp.data),
      companyForEmployesLookupData: companyForEmployessDataSort(value, this.props.lookups.companyForEmployeesLookUp)
    })

    if (e.target.name === "company") {
      const companyRelatedDataLookup = this.props.lookups.companyRelatedData;
      for (let i = 0; i < companyRelatedDataLookup.length; i++) {
        if (companyRelatedDataLookup[i]["company"] === e.target.value) {
          this.setState({
            company: companyRelatedDataLookup[i]["company"]
          });
        }
      }
    }

    if (e.target.name === "base_salary") { 
      this.state.base_salary_mul_1000  = e.target.value
      this.state.total_dollar_value_mul_1000 = e.target.value +                                                  this.state.annual_bonus_mul_1000  +                                     this.state.commission_mul_1000  +                                    this.state.equity_dollar_value_mul_1000 
    }

    if (e.target.name === "annual_bonus") {
      this.state.annual_bonus_mul_1000  = e.target.value 

      this.state.total_dollar_value_mul_1000 =                                                                this.state.base_salary_mul_1000 + 
                                      e.target.value + 
                                      this.state.commission_mul_1000 + this.state.equity_dollar_value_mul_1000

    }

    if (e.target.name === "commission") { 
      this.state.commission_mul_1000  = e.target.value

      this.state.total_dollar_value_mul_1000 =                                                                      this.state.base_salary_mul_1000 +                                   this.state.annual_bonus_mul_1000 +                                               e.target.value +                                             this.state.equity_dollar_value_mul_1000
      
    }

    if (e.target.name === "equity_dollar_value") { 
      this.state.equity_dollar_value_mul_1000  = e.target.value

      this.state.total_dollar_value_mul_1000 =                                                      this.state.base_salary_mul_1000 +                                       this.state.annual_bonus_mul_1000 +                                      this.state.commission_mul_1000 + 
                            e.target.value 
    }

  };
  
  buttonClicked = (text) => {

    this.setState({ selected: this.state.selected + 1 });
    if (text === "selected-zero") {
      this.setState({
        selected: 0
      })
    }
  }

  CandidateFormSubmit = (event) => {
    event.preventDefault();

    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;
    if (validation.isValid) {
      if (this.state.new_hire_id) {
        const updateData = {
          candidate_id: this.state.candidate_id,
          first_name: this.state.first_name,

          company: this.state.company,
          title_level: this.state.title_level,
          job_function: this.state.job_function,

          base_salary: this.state.base_salary,
          annual_bonus: this.state.annual_bonus,
          commission: this.state.commission,
          equity_dollar_value: this.state.equity_dollar_value,
          total_dollar_value: this.state.total_dollar_value,

          base_salary_mul_1000: this.state.base_salary_mul_1000,
          annual_bonus_mul_1000: this.state.annual_bonus_mul_1000,
          commission_mul_1000: this.state.commission_mul_1000,
          equity_dollar_value_mul_1000: this.state.equity_dollar_value_mul_1000,
          total_dollar_value_mul_1000: this.state.total_dollar_value_mul_1000
        };
        console.log("updateData", updateData)
        this.props.FnUpdateOfferedCandidate(updateData, this.props.history, this.state.new_hire_id, this.props.auth.user._id)
      }


    }
    setTimeout(() => {
      this.setState({
        message: ""
      });
    }, 2000);
  }

  componentWillReceiveProps() {
    if (this.props.location.location.search.split("&candidate_id=")[1] !== "") {
      this.state.new_hire_id = this.props.location.location.search.split("&user_id=")[0].split('?user_id=')[1]

      let OfferedCandidateId = {
        candidate_id: this.props.history.location.search.split("&candidate_id=")[1]
      }
      if (this.props.history.location.search.split("&candidate_id=")[1]) {
        FnGetCandidateDetailsInOfferDashboard(OfferedCandidateId).then(response => {
          let candidate_details = response.data.response[0]
          this.setState({
            candidate_id: candidate_details._id,
            first_name: candidate_details.first_name,
            company: candidate_details.experience ? candidate_details.experience.company : "",
            title_level: candidate_details.overall_experience ? candidate_details.overall_experience.title_level : "",
            job_function: candidate_details.overall_experience ? candidate_details.overall_experience.job_function : "",
            base_salary: candidate_details.salary ? candidate_details.salary.base_salary : null,
            annual_bonus: candidate_details.salary ? candidate_details.salary.annual_bonus : null,
            commission: candidate_details.salary ? candidate_details.salary.commission : null,
            equity_dollar_value: candidate_details.salary ? candidate_details.salary.equity_dollar_value : null,
            total_dollar_value: candidate_details.salary ? candidate_details.salary.total_dollar_value: null,
            base_salary_mul_1000: candidate_details.salary ? candidate_details.salary.base_salary_mul_1000 : null,
            annual_bonus_mul_1000: candidate_details.salary ? candidate_details.salary.annual_bonus_mul_1000 : null,
            commission_mul_1000: candidate_details.salary ? candidate_details.salary.commission_mul_1000 : null,
            equity_dollar_value_mul_1000: candidate_details.salary ? candidate_details.salary.equity_dollar_value_mul_1000 : null,
            total_dollar_value_mul_1000: candidate_details.salary ? candidate_details.salary.total_dollar_value_mul_1000: null
          });
        });
      }
    }
  }

  render() {

    return (
      <div>
        <div class="container mt-3" style={{ width: "100%" }}>

          <div class="modal fade" id="myModal1" >
            <div class="modal-dialog modal-lg" style={{ left: "60px" }}>
              <div class="modal-content" style={{ width: "100%" }}>
                <div class="modal-header">
                  <h2 style={{ fontSize: "22px", color: "#666666", fontWeight: "300" }}>Add Candidate</h2>
                  <button type="button" class="close" data-dismiss="modal" onClick={() => { this.buttonClicked("selected-zero") }}>×</button>
                </div>
                <div className="mb-3"
                  style={{
                    marginTop: "15px",
                    marginLeft: "4%", padding: "2px 2px 1px"
                  }}>

                  <Input
                    name="first_name"
                    type="text"
                    style={{ width: '45%' }}
                    onChange={this.onChange}
                    label="First Name "
                    value={this.state.first_name}
                  />

                  <AutoComplete
                    name="company"
                    type="text"
                    style={{ width: '45%' }}
                    onChange={this.onChange}
                    label="Company"
                    data={this.state.companyForEmployesLookupData}
                    value={this.state.company}
                  />
                </div>
                <div className="mb-3" style={{ marginTop: "15px", marginLeft: "4%" }}>
                  <AutoComplete
                    name="title_level"
                    type="text"
                    style={{ width: '45%' }}
                    onChange={this.onChange}
                    label="Title"
                    data={this.state.titleLookupData}
                    value={this.state.title_level}
                  />
                  <AutoComplete
                    name="job_function"
                    type="text"
                    style={{ width: '45%' }}
                    onChange={this.onChange}
                    label="Function"
                    value={this.state.job_function}
                    data={this.state.functionalityLookupData}
                  />
                </div>
                <div className="mb-3"
                  style={{ marginTop: "15px", marginLeft: "4%" }}>
                  <NumericTextBox
                    name="base_salary"
                    width='45%'
                    label="Base$('000)"
                    defaultValue="0"
                    max={1000}
                    // required="true"
                    onChange={this.onChange}
                    value={this.state.base_salary_mul_1000}
                  />
                  <NumericTextBox
                    name="annual_bonus"
                    width='45%'
                    label="Bonus$('000)"
                    defaultValue="0"
                    max={1000}
                    // required="true"
                    onChange={this.onChange}
                    value={this.state.annual_bonus_mul_1000}
                  />
                </div>
                <div className="mb-3"
                  style={{ marginTop: "15px", marginLeft: "4%" }}>
                  <NumericTextBox
                    name="commission"
                    width='45%'
                    label="Commission$('000)"
                    defaultValue="0"
                    max={1000}
                    // required="true"
                    onChange={this.onChange}
                    value={this.state.commission_mul_1000}
                  />
                </div>
                <div className="mb-3"
                  style={{ marginTop: "15px", marginLeft: "4%" }}>
                  <NumericTextBox
                    name="equity_dollar_value"
                    width='45%'
                    label="Equity$('000)*"
                    defaultValue="0"
                    max={1000}
                    // required="true"
                    onChange={this.onChange}
                    value={this.state.equity_dollar_value_mul_1000}
                  />
                  <NumericTextBox
                    name="total_dollar_value"
                    width='45%'
                    label="Total$('000)"
                    defaultValue="0"
                    onChange={this.onChange}
                    value={this.state.total_dollar_value_mul_1000}
                    disabled="true"
                  />

                </div>


                <div class="modal-footer"
                  style={{ marginTop: "5px" }}>
                  <Button type="button"
                    data-dismiss="modal"
                    onClick={() => { this.buttonClicked("selected-zero") }}>
                    CANCEL
          </Button>
                  <Button type="button"
                    data-dismiss="modal"
                    onClick={this.CandidateFormSubmit}>
                    SAVE
          </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

OfferDashUpdateCandidateAddButton.propTypes = {
  auth: PropTypes.object.isRequired,
  // FnInsertRoleCandidates: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  // AddnewCandiadateform: state.AddnewCandiadateform,
  // roleCandidate: state.roleCandidate,
  lookups: state.lookups,
  // errors: state.errors,
  // candidates: state.candidates
});

export default connect(mapStateToProps,
  {
    getTitleLookUpData,
    getFunctionLookUpData,
    getStageLookUpData,
    getCompanyLocationRegionLookUpData,
    FnInsertRoleCandidates,
    FnGetCandidateDetailsInOfferDashboard,
    FnUpdateRoleCandidateMembers,
    getgraphAPIListMarketSpaceLookUpData,
    getcompanyLocationNoOfEmployeesLookUpData,
    getCompanyRelatedData,
    FnUpdateOfferedCandidate
  })(OfferDashUpdateCandidateAddButton);

