import {
  ROLES_LOADING,
  GET_FUNCTION_LOOKUP_DETAILS
} from "../actions/types";

const initialState = {
  role: {},
  adminLookupFuncitonDetails: null,
  loading: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ROLES_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_FUNCTION_LOOKUP_DETAILS:
      return {
        ...state,
        adminLookupFuncitonDetails: action.payload,
        loading: true
      };

    default:
      return state;
  }
}
