import React from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Grid, GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
import { orderBy } from '@progress/kendo-data-query';
import { MyCommandCell } from './actioneditbutton.js';
import { filterBy } from '@progress/kendo-data-query';
import { 
  fnGetLookupAllLookupFunctions,
  fnInsertLookupAllFunction,
  fnUpdateLookupAllFunction,
  fnDeleteLookupAllFunction
} from "../../actions/adminLookupFunctionAction";
import Spinner from "../spinner/loading";

class LookupFunctiongrid extends React.Component {
  editField = "inEdit";
  CommandCell;


  constructor(props) {
    super(props);

    this.state = {
      data: [],
      action: "",
      filter: undefined,
      skip: 0,
      take: 10,
      loading: true,
    };
    this.CommandCell = MyCommandCell({
      edit: this.enterEdit,
      remove: this.remove,

      add: this.add,
      discard: this.discard,

      update: this.update,
      cancel: this.cancel,

      editField: this.editField
    });

  }
  filterChange = (event) => {
    this.setState({
        data: filterBy(this.props.adminLookupFunction.adminLookupFuncitonDetails, event.filter),
        filter: event.filter
    });
}
  enterEdit = (dataItem) => {
    this.state.action = "action"

    this.setState({
      data: this.state.data.map(item =>
        item._id === dataItem._id ?
          { ...item, inEdit: true } : item
      )
    });
  }

  remove = (dataItem) => {
    this.state.action = "actionssssss"
    const data = [...this.state.data];
    this.props.fnDeleteLookupAllFunction(dataItem["_id"])
    this.removeItem(data, dataItem);
    this.removeItem(this.props.adminLookupFunction.adminLookupFuncitonDetails, dataItem);
    this.setState({ data });
  }
  discard = (dataItem) => {
    const data = [ ...this.state.data ];
    this.removeItem(data, dataItem);
  
    this.setState({ data });
  }

  add = (dataItem) => {
    this.state.action = "actionssss"
    dataItem.inEdit = undefined;
    let insertFunctionActive = 0
    if (dataItem["active"] === true) {
      insertFunctionActive = 1
    }
    let insertDupeActive = 0
    if (dataItem["dupe"] === true) {
      insertDupeActive = 1
    }
    
    const insertFunctionData = {
      function_name: dataItem["function_name"],
      associated_functions: dataItem["associated_functions"],
      source: dataItem["source"],
      dupe: insertDupeActive,
      active: insertFunctionActive
    }
    this.props.fnInsertLookupAllFunction(insertFunctionData)
    this.props.fnGetLookupAllLookupFunctions();

    this.props.adminLookupFunction.adminLookupFuncitonDetails.unshift(dataItem);
    this.setState({
      data: [...this.state.data]
    });
  }


  update = (dataItem) => {
    this.state.action = "action"
    const data = [...this.state.data];
    const updatedItem = { ...dataItem, inEdit: undefined };
    let updateFunctionActive = 0
    if (dataItem["active"] === true) {
      updateFunctionActive = 1
    }
    // active
    let updateDupeActive = 0
    if (dataItem["dupe"] === true) {
      updateDupeActive = 1
    }

    const updateFunctionData = {
      admin_lookp_function_id: dataItem["_id"],
      function_name: dataItem["function_name"],
      associated_functions: dataItem["associated_functions"],
      source: dataItem["source"],
      dupe: updateDupeActive,
      active: updateFunctionActive
    }

    this.props.fnUpdateLookupAllFunction(updateFunctionData)
    this.updateItem(data, updatedItem);
    this.updateItem(this.props.adminLookupFunction.adminLookupFuncitonDetails, updatedItem);

    this.setState({ data });
  }

  cancel = (dataItem) => {
    this.state.action = "action"
    const originalItem = this.props.adminLookupFunction.adminLookupFuncitonDetails.find(p => p._id === dataItem._id);
    const data = this.state.data.map(item => item._id === originalItem._id ? originalItem : item);

    this.setState({ data });
  }

  updateItem = (data, item) => {
    this.state.action = "action"
    let index = data.findIndex(p => p === item || (item._id && p._id === item._id));
    if (index >= 0) {
      data[index] = { ...item };
    }
  }

  itemChange = (event) => {
    this.state.action = "action"
    const data = this.state.data.map(item =>
      item._id === event.dataItem._id ?
        { ...item, [event.field]: event.value } : item
    );
    this.setState({ data });
  }

  addNew = () => {
    const newDataItem = { inEdit: true, active: true };
    var daaa = [newDataItem, ...this.state.data]
    this.state.action = "action"
    this.setState({
      data: [newDataItem, ...this.state.data]
    });
  }

  cancelCurrentChanges = () => {
    this.state.action = "action"
    this.setState({ data: this.props.adminLookupFunction.adminLookupFuncitonDetails });
  }
  componentDidMount() {
     if (this.props.location.pathname === "/home/lookup-function") {
      this.props.fnGetLookupAllLookupFunctions();
    }    

  }
  pageChange = (event) => {
    this.setState({
      skip: event.page.skip,
      take: event.page.take
    });
  }

  render() {
    if (this.state.action === "action") {
      this.state.data = this.state.data
    } else {  
      if (this.state.filter != undefined) {
        this.state.data = this.state.data
            } else { 
              if (this.props.adminLookupFunction.adminLookupFuncitonDetails != null) {
                const FunctionList = this.props.adminLookupFunction.adminLookupFuncitonDetails
        
                const FunctionListData = []
                for (let i = 0; i < FunctionList.length; i++) {
                  if (FunctionList[i]['active'] === 1) {
                    FunctionList[i]['active'] = true
                  }
                  if (FunctionList[i]['active'] === 0) {
                    FunctionList[i]['active'] = false
                  }
                 FunctionListData.push(FunctionList[i])
                }
                this.state.data = FunctionListData
                this.state.loading = false
              } else {
                this.state.data = this.state.data
              }    
            }
      }

    const { data } = this.state;
    const hasEditedItem = data.some(p => p.inEdit);
    console.log("this.props")
    console.log(this.state.data)
    console.log("this.props")
    return (
      <div className="container-fluid role-main-div">
        {this.state.loading === true ? <Spinner /> : null}
        <Grid
          className="col-md-12 col-xs-12 col-lg-12 col-sm-12"
          // style={{ height: '420px' }}
          data={data}
          data={data.slice(this.state.skip, this.state.take + this.state.skip)}
          skip={this.state.skip}
          take={this.state.take}
          total={data.length}
          pageable={true}
          onPageChange={this.pageChange}
          onItemChange={this.itemChange}
          editField={this.editField}
          filterable={true}
          filter={this.state.filter}
          onFilterChange={this.filterChange}
        >
          <GridToolbar>
            <div className="row">
              <div className="col-md-6 col-xs-12 col-lg-6 col-sm-12" style={{ fontSize: "23px", color: "black", marginTop: "-10px" }}>             Function</div>
              <div className="col-md-6 col-xs-12 col-lg-6 col-sm-12">
                <button
                  title="Add new"
                  className="k-button k-danger"
                  onClick={this.addNew}
                  style={{ float: "right", border: "1px solid #3A4158" }}
                >
                  <span className="k-icon k-i-plus"></span>
                </button>
                {hasEditedItem && (
                  <button
                    title="Cancel current changes"
                    className="k-button"
                    onClick={this.cancelCurrentChanges}
                    style={{ float: "right" }}
                  >
                    Cancel current changes
                        </button>
                )}
              </div>


            </div>
          </GridToolbar>

          <Column width="250px"  editable="false" field="function_name"  title="FUNCTION" />
          <Column field="associated_functions" filterable={false} title="ASSOCIATED FUNCTION" />
          <Column width="180px" field="source" filterable={true} title="SOURCE" />
          <Column width="180px" field="dupe" title="DUPE"  filter="boolean" editor="boolean"  />
          <Column field="active" title="ACTIVE"  filterable={false} editor="boolean" />
          <Column title="ACTION" filterable={false} cell={this.CommandCell} />
        </Grid>
      </div>
    );
  }
  generateId = data => data.reduce((acc, current) => Math.max(acc, current._id), 0) + 1;

  removeItem(data, item) {
    let index = data.findIndex(p => p === item || item._id && p._id === item._id);
    if (index >= 0) {
      data.splice(index, 1);
    }
  }

}





LookupFunctiongrid.propTypes = {
    auth: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };
      
  const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors,
    adminLookupFunction: state.adminLookupFunction
  });
  
  export default connect(mapStateToProps, 
    { fnGetLookupAllLookupFunctions,
      fnInsertLookupAllFunction,
      fnUpdateLookupAllFunction,
      fnDeleteLookupAllFunction 
    }
  )(LookupFunctiongrid);