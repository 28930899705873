import React from 'react'
import ReactDOM from 'react-dom';
import '../../../css/dashboard.css';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import PersonalDashboardHeader from '../personalDashboard/personalDasboardHeader';
import TargetPayDashboard from '../personalDashboard/targetPayDashboard/TargetPayDashboard';
import RateOfferDashboard from '../personalDashboard/rateOfferDashboard/RateOfferDashboard';
import { offerDashAddMemeberIconAction } from "../../../actions/dashboard";
export class DashBoard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: 0,
      year: new Date().getFullYear()
    }
  }
  handleSelect = (e) => {
    this.setState({ selected: e.selected })
    this.props.offerDashAddMemeberIconAction(e.selected)
  }
  render() {
    if (this.props.estimateDashboard.addMemberInviteButton === 0) {
      this.state.selected = 0
    }
    if (this.props.estimateDashboard.addMemberInviteButton === 1) {
      this.state.selected = 1
    }
    return (
      <div className="analytics-dashboard">
        <PersonalDashboardHeader />
        <TabStrip selected={this.state.selected} onSelect={this.handleSelect} className="col-sm-12 col-xs-12 col-md-12 col-lg-12 example-col personal_dashboard_Tabstripcontent" style={{ margin: "2px", marginLeft: "0px", paddingLeft: "10px", marginTop: "0px" }
        }>
          <TabStripTab title="Target Pay" >
            <div className="" style={{ padding: "0px", paddingTop: "0px", backgroundColor: "white" }}>
              <div className="row" style={{ border: "1px solid #d9d9d9", margin: "0px" }}>
                <TargetPayDashboard />
              </div>
            </div>
          </TabStripTab>
          <TabStripTab title="Rate Offer">
            <div className="" style={{ padding: "0px", paddingTop: "0px", backgroundColor: "white" }}>
              <div className="row" style={{ border: "1px solid #d9d9d9", margin: "0px" }}>
                <RateOfferDashboard />
              </div>
            </div>
          </TabStripTab>
          <TabStripTab title="Explore Talent">

          </TabStripTab>
          <TabStripTab title="Compare Offers">
            <p>Tab 4 Content</p>
          </TabStripTab>
        </TabStrip >
        <div class="col-md-12">
          <div class="p20 text-center footer-color">
            <small>Copyright © {this.state.year} - ApolloGroup, Inc.</small>
          </div>
        </div>
      </div>

    )
  }
}

DashBoard.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state =>
  ({
    auth: state.auth,
    errors: state.errors,
    estimateDashboard: state.estimateDashboard,
    lookups: state.lookups,
    profile: state.profile
  });
export default connect(mapStateToProps, { offerDashAddMemeberIconAction }
)(DashBoard);
