import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Input, NumericTextBox } from '@progress/kendo-react-inputs';
import { AutoComplete, MultiSelect, ComboBox, DropDownList } from '@progress/kendo-react-dropdowns';
import { Button } from '@progress/kendo-react-buttons';
import { filterBy } from '@progress/kendo-data-query';
import {
  getHRDetails
} from "../dropdown/lookups";
import '../../css/Profile.css'
import FormValidator from '../../validation/FormValidator';
import validationParams from '../../validation/validationParams.json';
import { HRProfile } from "../../actions/authAction";
import {
  getHiringRoleLookUpData,
  getStageLookUpData,
  getCompanyLocationRegionLookUpData, getgraphAPIListMarketSpaceLookUpData, getcompanyLocationNoOfEmployeesLookUpData,
  getRevenueLookUpData,
  getCompanyRelatedData,
  getTitleLookUpData,
  getFunctionLookUpData,
  getCompanyLookUpData,
  getCompanyMarketStageRegionLookUp,
  getAwsLambdaCompanyLookUpData

} from "../../actions/lookupAction"
import { cityDataSort, stateDataSort, stageDataSort, marketSpaceDataSort, employeesDataSort, companyForEmployessDataSort, revenueDataSort } from "../dropdown/lookupSortingData";
import PropTypes from "prop-types";
import { addUpdateTmpPowerUserDashboard, offerDashDialogBoxAction } from "../../actions/dashboard";

import PowerUserAddNewCompany from '../dashboard/powerUserDashboard/DashboardPowerAddNewCompany';
import Spinner from "../spinner/loading"

class MyProfile extends Component {

  constructor(props) {
    super(props);
    this.validator = new FormValidator(validationParams.myProfileValidation);
    this.state = {
      values: [],
      company_competitors: "",
      getHRDetails: {},
      first_name: "",
      last_name: "",
      email: "",
      hiring_role: "",
      company_name: "",
      company_stage: "",
      company_city: "",
      company_state: "",
      role_region: "",
      company_revenue: "",
      company_title: "",
      company_market_space: "",
      company_employees_count: "",
      company_competitors01: "",
      compensation_mix_base: 75,
      compensation_mix_equity: 10,
      compensation_mix_total_bonus: 15,
      compensation_percentile_low: 50,
      compensation_percentile_high: 65,
      equity_type_stock: false,
      equity_type_rsu: false,
      success: false,
      email_verified: false,
      sessionCheck: {},
      errors: {},
      total_value: '',
      hidden: true,
      loading: true,
      validation: this.validator.valid()
    };
    this.submitted = false;
    this.myProfileSubmit = this.myProfileSubmit.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
  }

  handlePasswordChange(e) {
    this.setState({ password: e.target.value });
  }

  toggleShow() {
    this.setState({ hidden: !this.state.hidden });
  }
  addNewButton = (e) => {
    e.preventDefault();
    this.setState({
      addNewButton: "new_company",
      visible: true
    })
    this.props.offerDashDialogBoxAction("new_company", true)
  };
  listNoDataRender = (element) => {

    const noData = (
      <div>
        <a href="#" onClick={this.addNewButton} style={{ textTransform: "none" }}>Add company"{this.state.company_name}"</a>
      </div>
    );

    return React.cloneElement(element, { ...element.props }, noData);
  }


  componentDidMount() {
    this.props.getHiringRoleLookUpData();
    this.props.getStageLookUpData();
    this.props.getCompanyLocationRegionLookUpData();
    this.props.getCompanyRelatedData();
    this.props.getTitleLookUpData();
    this.props.getgraphAPIListMarketSpaceLookUpData();
    this.props.getRevenueLookUpData();
    this.props.getcompanyLocationNoOfEmployeesLookUpData();
    this.props.getAwsLambdaCompanyLookUpData();
    this.props.getTitleLookUpData();
    this.props.getFunctionLookUpData();
    this.props.getgraphAPIListMarketSpaceLookUpData();
    this.props.getCompanyLocationRegionLookUpData();
    this.props.getCompanyRelatedData();
    this.props.getcompanyLocationNoOfEmployeesLookUpData();
    this.props.getStageLookUpData();
    this.props.getCompanyMarketStageRegionLookUp();
    this.props.getAwsLambdaCompanyLookUpData();
    

    getHRDetails(this.props.auth.user._id).then(response => {
      if (!response.data.msg) {

        this.setState({
          getHRDetails: response.data.msg,
          first_name: response.data.user.first_name,
          last_name: response.data.user.last_name,
          email: response.data.user.email,
          company_name: response.data.user.company_name,
          password: response.data.password,
          loading: false
        });
      } else {
        this.setState({
          getHRDetails: response.data.msg,
          first_name: response.data.user.first_name,
          last_name: response.data.user.last_name,
          email: response.data.user.email,
          password: response.data.password,
          hiring_role: response.data.msg.hiring_role,
          company_name: response.data.msg.company_name,
          company_stage: response.data.msg.company_stage,
          company_revenue: response.data.msg.company_revenue,
          company_title: response.data.msg.company_title,
          company_city: response.data.msg.company_city,
          company_state: response.data.msg.company_state,
          role_region: response.data.msg.role_region,
          company_market_space: response.data.msg.company_market_space,
          company_employees_count: response.data.msg.company_employees_count,
          compensation_mix_base: response.data.msg.compensation_mix_base,
          compensation_mix_equity: response.data.msg.compensation_mix_equity,
          compensation_mix_total_bonus: response.data.msg.compensation_mix_total_bonus,
          compensation_percentile_low: response.data.msg.compensation_percentile_low,
          compensation_percentile_high: response.data.msg.compensation_percentile_high,
          user_id: response.data.msg.user_id,
          equity_type_rsu: response.data.msg.equity_type_rsu,
          equity_type_stock: response.data.msg.equity_type_stock,
          company_competitors: response.data.msg.company_competitors,
          company_competitors01: response.data.msg.company_competitors,
          loading: false
        });
      }

    }).catch(error => this.state.loading = false);
  }
  handleChange = (event) => {
    const company_competitors = event.target.value;
    const lastItem = company_competitors[company_competitors.length - 1];
    if (lastItem) {
      company_competitors.pop();
      const sameItem = company_competitors.find(value => value === lastItem);
      if (sameItem === undefined) {
        company_competitors.push(lastItem);
      }
    }
    this.setState({
      company_competitors: company_competitors,
      company_competitors01: event.target.value
    });
  }
  onChange = e => {
    const value = e.target.value;
    if (this.props.lookups.companyLocationLookUp.length !== undefined || this.props.lookups.companyRegionLookUp.length !== undefined || this.props.lookups.companyStatesLookUp.length !== undefined || this.props.lookups.marketSpaceLookUp.length !== undefined || this.props.lookups.revenueLookUp.length !== undefined || this.props.lookups.noOfEmployeesLookUp.length !== undefined) {
      this.setState({
        [e.target.name]: value,

        titleLookupData: this.titlesDataSort(value),
        stageLookupData: this.props.lookups.stageLookUp.data,
        cityLookupData: cityDataSort(value, this.props.lookups.companyLocationLookUp),
        regionLookupData:  this.props.lookups.companyRegionLookUp,
        // stateLookupData: stateDataSort(value, this.props.lookups.companyStatesLookUp),
        marketSpaceLookupData: marketSpaceDataSort(value, this.props.lookups.marketSpaceLookUp.data),
        revenueLookUpData: this.props.lookups.revenueLookUp,
        employeeLookupData: employeesDataSort(value, this.props.lookups.noOfEmployeesLookUp),
        companyForEmployesLookupData: companyForEmployessDataSort(value, this.props.lookups.companyForEmployeesLookUp)
      });
    }

    if (e.target.name === "company_city") {
      const locationregioncity = this.props.lookups.locationRegiongCityLookUp;
      for (let i = 0; i < locationregioncity.length; i++) {
        if (locationregioncity[i]["location_city"] === e.target.value) {
          this.setState({
            company_state: locationregioncity[i]["location_state"],
            role_region: locationregioncity[i]["region"]
          });
        }
      }
    }

    if (e.target.name === "company_name") {
      const companyRelatedDataLookup = this.props.lookups.companyRelatedData;
      for (let i = 0; i < companyRelatedDataLookup.length; i++) {
        if (companyRelatedDataLookup[i]["company"] === e.target.value) {
          this.setState({
            company_name: companyRelatedDataLookup[i]["company"],
            company_market_space: companyRelatedDataLookup[i]["marketspace"],
            company_city: companyRelatedDataLookup[i]["location_city"],
            company_state: companyRelatedDataLookup[i]["location_state"],
            role_region: companyRelatedDataLookup[i]["region"],
            company_employees_count: companyRelatedDataLookup[i]["employees"],
            company_stage: companyRelatedDataLookup[i]["stage"]
          });
          this.state.company_competitors01 = companyRelatedDataLookup[i].Competitors01
        }
      }
    }

    let total_value = this.state.compensation_mix_base + this.state.compensation_mix_total_bonus + this.state.compensation_mix_equity;
    if (total_value > 0) {
      this.setState({
        total_value: ""
      });
    }
  };

  titlesDataSort(value) {
    const titleLookupData = this.props.lookups.titleLookUp.data;
    const filter = {
      value: value,
      operator: 'startswith',
      ignoreCase: true
    };
    return value ? filterBy(titleLookupData, filter) : titleLookupData;
  }

  myProfileSubmit = (event) => {
    event.preventDefault();
    const userData = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      email: this.state.email,
      hiring_role: this.state.hiring_role,
      company_name: this.state.company_name,
      company_stage: this.state.company_stage,
      company_revenue: this.state.company_revenue,
      company_title: this.state.company_title,
      company_city: this.state.company_city,
      company_state: this.state.company_state,
      role_region: this.state.role_region,
      company_market_space: this.state.company_market_space,
      company_employees_count: this.state.company_employees_count,
      company_competitors: this.state.company_competitors,
      compensation_percentile_low: this.state.compensation_percentile_low,
      compensation_percentile_high: this.state.compensation_percentile_high,
      equity_type_stock: this.state.equity_type_stock,
      equity_type_rsu: this.state.equity_type_rsu,
      compensation_mix_base: this.state.compensation_mix_base,
      compensation_mix_equity: this.state.compensation_mix_equity,
      compensation_mix_total_bonus: this.state.compensation_mix_total_bonus,
      user_id: this.props.auth.user._id,
    };
    const userTemporaryDashboardData = {
      user_id: this.props.auth.user._id,
      company: this.state.company_name,
      market_space: this.state.company_market_space,
      stage: this.state.company_stage,
      location: this.state.role_region,
      title: this.state.company_title,
      function: this.state.hiring_role,
      role_years: "",
      pick_benchmark: "",
      comparison_benchmark: "",
      flag: 0
    }

    let total_value = parseInt(this.state.compensation_mix_base) +
      parseInt(this.state.compensation_mix_total_bonus) +
      parseInt(this.state.compensation_mix_equity);
    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;
    if (total_value !== 100) {
      this.setState({
        total_value: "Total must be 100"
      });
    } else if (validation.isValid) {
      this.props.addUpdateTmpPowerUserDashboard(userTemporaryDashboardData)
      this.props.HRProfile(userData, this.props.history);
    }
  }

  checkBox = (key) => {
    this.setState({
      [key]: !this.state[key]
    });
  }

  filterChange = (event) => {
    this.setState({
      competitorSortingData: filterBy(this.props.lookups.companyForEmployeesLookUp.slice(), event.filter)
    });
  }

  render() {
    if(this.props.location.search === "?add_company"){
      this.state.success = true
      if (this.props.adminLkpCompanyDetails.success.message !== undefined) {
        this.state.message= this.props.adminLkpCompanyDetails.success.message  
      }
    }
    else{
      this.state.success = false      
    }
    if (this.props.profile.profile.message) {
      this.state.message = this.props.profile.profile.message
    }
    
    const { lookups } = this.props;
    let validation = this.submitted ?
      this.validator.validate(this.state) :
      this.state.validation
    
    return (
      <div>
        {this.state.addNewButton === "new_company" ?
          <div>{this.state.visible && <PowerUserAddNewCompany companyForEmployesLookupData={this.state.companyForEmployesLookupData} marketSpaceLookupData={this.props.lookups.marketSpaceLookUp.data} stageLookupData={this.state.stageLookupData} regionLookupData={this.state.regionLookupData} companyValue={this.state.company_name} history={this.props.history} />}</div> : null
        }

        {this.state.loading === true ? <Spinner /> : null}
        <div className="row example-wrapper profile-main-div" id="top-scroll">

          <div className="col-sm-12 col-md-12 col-xs-12 col-lg-12" style={{ paddingLeft: "10px" }}>
            {this.state.success && (
              <div
                className="alert alert-success"
                style={{
                  position: 'fixed',
                  bottom: "10px",
                  right: "10px",
                  backgroundColor: "#3b4156",
                  animation: "cssAnimation 0s 1s forwards",
                  padding: "10px",
                  width: "25%",
                  textAlign: "center",
                  color: "white"
                }}
              >
                {this.state.message}
              </div>)}
          </div>
          <form className="k-form" onSubmit={this.myProfileSubmit} >

            <fieldset>
              <h1 className="h1style">My Profile</h1>
              <h3 className="h3style">ACCOUNT </h3>
              <div className="mb-3 row">
                <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                <div className="col-md-4 col-sm-11 col-xs-11 col-lg-4">
                  <Input
                    name="first_name"
                    type="text"
                    style={{ width: '100%', color: "rgba(0,0,0,0.87)" }}
                    onChange={this.onChange}
                    label="First Name *"
                    value={this.state.first_name}
                  /></div>
                <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                <div className="col-md-4 col-sm-11 col-xs-11 col-lg-4">
                  <Input
                    name="last_name"
                    type="text"
                    onChange={this.onChange}
                    value={this.state.last_name}
                    style={{ width: '100%' }}
                    label="Last Name *"
                  />
                </div>
                <div className="col-md-2 col-sm-1 col-xs-1 col-lg-2"></div>
              </div>
              <div className="mb-3 row">
                <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                <div className="col-md-4 col-sm-11 col-xs-11 col-lg-4">
                  <Input
                    name="email"
                    type="email"
                    onChange={this.onChange}
                    value={this.state.email}
                    style={{ width: '100%' }}
                    label="Email *"
                  />
                </div>
                <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                <div className="col-md-3">
                  <Input
                    name="password"
                    type={this.state.hidden ? "password" : "text"}
                    onChange={this.handlePasswordChange}
                    value={this.state.password}
                    style={{ width: '100%' }}
                    label="Password"
                  />
                </div>
                <div className="col-md-1">
                  {this.state.hidden ?
                    <i className="fa fa-eye-slash" aria-hidden="true" onClick={this.toggleShow}></i> :
                    <i className="fa fa-eye" aria-hidden="true" onClick={this.toggleShow}></i>
                  }
                </div>
              </div>
              <h3 className="h3style" > ROLE(hiring role)</h3>
              <div className="mb-3 row">
                <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                <div className="col-md-4 col-sm-11 col-xs-11 col-lg-4">
                  <ComboBox
                    name="hiring_role"
                    value={this.state.hiring_role}
                    onChange={this.onChange}
                    data={lookups.hiringRoleLookUp.data}
                    defaultValue="Select role... *"
                    style={{ width: '100%' }}
                    label="Role *"
                  />
                  <div style={{
                    color: "red",
                    paddingLeft: "0px"
                  }}>
                    {validation.hiring_role.message}
                  </div></div>
                <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                <div className="col-md-4 col-sm-11 col-xs-11 col-lg-4">
                  <AutoComplete
                    name="company_title"
                    onChange={this.onChange}
                    onSelect={this.onChange}
                    data={this.state.titleLookupData}
                    value={this.state.company_title}
                    label="Title/Level *"
                    style={{ width: '100%' }}
                  />
                </div>
              </div>
              <h3 className="h3style" > COMPANY </h3>

              <div className="mb-3 row">
                <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                <div className="col-md-4 col-sm-11 col-xs-11 col-lg-4">
                  <AutoComplete
                    name="company_name"
                    onChange={this.onChange}
                    data={this.state.companyForEmployesLookupData}
                    listNoDataRender={this.listNoDataRender}
                    value={this.state.company_name}
                    label="Company *"
                    style={{ width: '100%' }}
                  />
                  <div style={{ color: "red", paddingLeft: "0px" }}>
                    {validation.company_name.message}
                  </div>
                </div>
                <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                <div className="col-md-4 col-sm-11 col-xs-11 col-lg-4">
                  <DropDownList
                    name="company_stage"
                    onChange={this.onChange}
                    value={this.state.company_stage}
                    data={this.props.lookups.stageLookUp.data}
                    label="Stage *"
                    style={{ width: '100%' }}
                  />
                  <div style={{ color: "red", paddingLeft: "0px" }}>
                    {validation.company_stage.message}
                  </div>
                </div>
                <div className="col-md-2 col-sm-1 col-xs-1 col-lg-2"></div>
              </div>

              <div className="mb-3 row">
                <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                <div className="col-md-4 col-sm-11 col-xs-11 col-lg-4">
                  <AutoComplete
                    name="company_city"
                    onChange={this.onChange}
                    data={this.state.cityLookupData}
                    value={this.state.company_city}
                    onFilterChange={this.onFilterChange}
                    label="City *"
                    style={{ width: '100%' }}
                  />
                  <div style={{ color: "red", paddingLeft: "0px" }}>
                    {validation.company_city.message}
                  </div>
                </div>
                <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                <div className="col-md-4 col-sm-11 col-xs-11 col-lg-4">
                  <AutoComplete
                    name="company_state"
                    onChange={this.onChange}
                    data={this.state.stateLookupData}
                    value={this.state.company_state}
                    label="State *"
                    style={{ width: '100%' }}
                    disabled
                  />
                  <div style={{ color: "red", paddingLeft: "0px" }}>
                    {validation.company_state.message}
                  </div>
                </div>
                <div className="col-md-2 col-sm-1 col-xs-1 col-lg-2"></div>
              </div>

              <div className="mb-3 row">
                <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                <div className="col-md-4 col-sm-11 col-xs-11 col-lg-4">
                  <AutoComplete
                    name="role_region"
                    onChange={this.onChange}
                    required={true}
                    data={this.state.regionLookupData}
                    value={this.state.role_region}
                    disabled
                    label="Region"
                    style={{ width: '100%' }}
                  />
                </div>
                <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                <div className="col-md-4 col-sm-11 col-xs-11 col-lg-4">
                  <AutoComplete
                    name="company_market_space"
                    onChange={this.onChange}
                    value={this.state.company_market_space}
                    data={this.state.marketSpaceLookupData}
                    label="Market Space *"
                    style={{ width: '100%' }}
                  />
                  <div style={{ color: "red", paddingLeft: "0px" }}>
                    {validation.company_market_space.message}
                  </div>
                </div>
                <div className="col-md-2 col-sm-1 col-xs-1 col-lg-2"></div>
              </div>

              <div className="mb-3 row">
                <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                <div className="col-md-4 col-sm-11 col-xs-11 col-lg-4">
                  <AutoComplete
                    name="company_revenue"
                    onChange={this.onChange}
                    onOpen={this.onSelect}
                    value={this.state.company_revenue}
                    data={this.state.revenueLookUpData}
                    label="Revenue"
                    style={{ width: '100%' }}
                  />
                </div>
                <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                <div className="col-md-4 col-sm-11 col-xs-11 col-lg-4">
                  <AutoComplete
                    name="company_employees_count"
                    onChange={this.onChange}
                    value={this.state.company_employees_count}
                    data={this.state.employeeLookupData}
                    label="# of employees"
                    style={{ width: '100%' }}
                  />
                </div>
                <div className="col-md-2 col-sm-1 col-xs-1 col-lg-2"></div>
              </div>

              <h3 className="h3style1" > Competitors </h3>

              <div className="mb-3 row col-md-12" style={{ paddingLeft: "0px" }}>
                <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                <div className="col-md-11 col-sm-11 col-xs-11 col-lg-11">
                  {!this.state.competitorSortingData === false ? <MultiSelect
                    name="company_competitors"
                    data={this.state.competitorSortingData}
                    onChange={this.handleChange}
                    value={this.state.company_competitors01}
                    allowCustom={true}
                    style={{ width: "100%" }}
                    placeholder="competitors"
                    filterable={true}
                    onFilterChange={this.filterChange}
                  /> : <MultiSelect
                      name="company_competitors"
                      data={lookups.noOfEmployeesCompanyNameLookUp.data}
                      onChange={this.handleChange}
                      value={this.state.company_competitors01}
                      allowCustom={true}
                      style={{ width: "100%" }}
                      placeholder="competitors"
                      filterable={true}
                      onFilterChange={this.filterChange}
                    />}
                </div>
              </div>
              <br />
              <h3 className="h3style1" > POLICIES</h3>

              <div className="mb-3 row col-md-12" style={{ paddingLeft: "0px", fontSize: "16px" }}>
                <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                <div className="col-md-6 col-sm-11 col-xs-11 col-lg-6" style={{ padding: "0px" }}>
                  <li style={{ width: "130%" }}>
                    Company pays total compensation between the
                  </li>
                </div>
              </div>
              <div className="mb-3 row col-md-12" style={{ paddingLeft: "0px", fontSize: "16px" }}>
                <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                <div className="col-md-1 col-sm-11 col-xs-11 col-lg-1" style={{ padding: "0px", textAlign: "center" }}>

                </div>
                <div className="col-md-2 col-sm-1 col-xs-1 col-lg-2">
                  <NumericTextBox
                    onChange={this.onChange}
                    name="compensation_percentile_low"
                    value={this.state.compensation_percentile_low}
                    step={1}
                    width="100%"
                    min={0}
                    max={100}
                  />
                </div>
                <div className="col-md-1 col-sm-11 col-xs-11 col-lg-1" style={{ padding: "0px", textAlign: "center" }}>
                  and
                </div>
                <div className="col-md-2 col-sm-1 col-xs-1 col-lg-2">
                  <NumericTextBox
                    onChange={this.onChange}
                    name="compensation_percentile_high"
                    value={this.state.compensation_percentile_high}
                    step={1}
                    width="100%"
                    min={0}
                    max={100}
                  />

                </div>
                <div className="col-md-2 col-sm-11 col-xs-11 col-lg-2">
                  Percentile.
                </div>
              </div>
              <div className="mb-3 row col-md-12" style={{ paddingLeft: "0px", fontSize: "16px" }}>
                <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5" style={{ padding: "0px" }}>
                  <li>
                    Company offers equity in:
                  </li>
                </div>
                <div className="col-md-2 col-sm-1 col-xs-1 col-lg-2">
                  <input
                    className="k-checkbox"
                    onChange={() => this.checkBox('equity_type_stock')}
                    name="equity_type_stock"
                    type="checkbox"
                    checked={this.state.equity_type_stock}
                    id="auth-2fa" />
                  <label
                    className="k-checkbox-label"
                    style={{ width: "160px" }}
                    htmlFor="auth-2fa">
                    Stock options
                </label>
                </div>
                <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1">
                  <input
                    className="k-checkbox"
                    type="checkbox"
                    name="equity_type_rsu"
                    checked={this.state.equity_type_rsu}
                    onChange={() => this.checkBox('equity_type_rsu')}
                    id="auth-2fb" />
                  <label
                    className="k-checkbox-label"
                    htmlFor="auth-2fb">RSUs.
                          </label>
                </div>
              </div>

              <div className="mb-3 row col-md-12" style={{ paddingLeft: "0px", fontSize: "16px" }}>
                <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                <div className="col-md-6 col-sm-11 col-xs-11 col-lg-6" style={{ padding: "0px" }}>
                  <li>
                    Compensation mix is typically:
                  </li>
                </div>
              </div>

              <div className="mb-3 row col-md-12" style={{ paddingLeft: "0px", fontSize: "16px" }}>
                <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                <div className="col-md-1 col-sm-11 col-xs-11 col-lg-1" style={{ padding: "0px", textAlign: "center" }}>
                  BASE
                </div>
                <div className="col-md-2 col-sm-1 col-xs-1 col-lg-2">
                  <NumericTextBox
                    onChange={this.onChange}
                    name="compensation_mix_base"
                    value={this.state.compensation_mix_base}
                    step={1}
                    width="100%"
                    min={0}
                    max={100}
                  />
                </div>
                <div className="col-md-1 col-sm-11 col-xs-11 col-lg-1" style={{ padding: "0px", textAlign: "center" }}>
                  BONUS
                </div>
                <div className="col-md-2 col-sm-1 col-xs-1 col-lg-2">
                  <NumericTextBox
                    onChange={this.onChange}
                    name="compensation_mix_total_bonus"
                    value={this.state.compensation_mix_total_bonus}
                    step={1}
                    width="100%"
                    min={0}
                    max={100}

                  />
                </div>
                <div className="col-md-3 col-sm-11 col-xs-11 col-lg-3" style={{ padding: "0px", textAlign: "center" }}>
                  ANNUAL EQUITY
                </div>
                <div className="col-md-2 col-sm-1 col-xs-1 col-lg-2">
                  <NumericTextBox
                    onChange={this.onChange}
                    name="compensation_mix_equity"
                    value={this.state.compensation_mix_equity}
                    step={1}
                    width="100%"
                    min={0}
                    max={100}
                  />
                </div>
                <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1">
                </div>
                <div style={{
                  color: "red"
                }}>
                  {this.state.total_value}
                </div>
              </div>
            </fieldset>
            <div className="row" style={{ marginTop: "-30px" }}>
              <div className="col-md-4"></div>
              <div className="col-md-2 col-xs-6 col-sm-6 col-lg-2">
                <Button className="mt-3 col-md-12 col-xs-12 col-sm-12 col-lg-12" type="submit" primary={true} >Save</Button>
              </div>
              <div className="col-md-2 col-xs-6 col-sm-6 col-lg-2" style={{ border: "1px slid red" }}>
                <Link type="button" to="/home/estimate-dashboard" className="k-button col-md-12 col-xs-12 col-sm-12 col-lg-12" style={{ marginTop: "16px" }}>CANCEL</Link>
              </div>
            </div>

          </form>
        </div>
      </div>
    );
  }
}


MyProfile.propTypes = {
  auth: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  profile: state.profile,
  lookups: state.lookups,
  errors: state.errors,
  adminLkpCompanyDetails: state.adminLookupCompanyDetails
});
export default connect(mapStateToProps, {
  HRProfile,
  getHiringRoleLookUpData,
  getStageLookUpData,
  getCompanyLocationRegionLookUpData,
  getCompanyRelatedData,
  getgraphAPIListMarketSpaceLookUpData,
  getcompanyLocationNoOfEmployeesLookUpData,
  getRevenueLookUpData,
  getTitleLookUpData,
  addUpdateTmpPowerUserDashboard,
  offerDashDialogBoxAction,
  getFunctionLookUpData,
  getCompanyLookUpData,
  getcompanyLocationNoOfEmployeesLookUpData,
  getCompanyMarketStageRegionLookUp,
  getAwsLambdaCompanyLookUpData

}
)(MyProfile);