import React, { Component } from 'react';
import { linkedInAuthenticationAction } from "../../actions/authAction";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import '../../css/login.css';
class LinkedInAuthentication extends Component {
  constructor(props) {
    super(props);
    console.log(this.props)
    if (this.props.match.params.token) {
      this.props.linkedInAuthenticationAction(this.props.match.params.token, this.props.history, this.props.match.params.profile_updated, this.props.match.params.email_verified, this.props.match.params.first_name, this.props.match.params.last_name)
    }
  }



  render() {

    return (
      <div>
      </div >
    );
  }


}
LinkedInAuthentication.propTypes = {
  linkedInAuthenticationAction: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state =>
  ({
    auth: state.auth,
    errors: state.errors
  });
export default connect(mapStateToProps, { linkedInAuthenticationAction }
)(LinkedInAuthentication);