import React from 'react'
import '../../../css/dashboard.css';
import { connect } from "react-redux";
import { ComboBox, DropDownList, AutoComplete } from '@progress/kendo-react-dropdowns';
import { Input } from '@progress/kendo-react-inputs';
import {
    getTitleLookUpData,
    getFunctionLookUpData,
    getCompanyLookUpData,
    getgraphAPIListMarketSpaceLookUpData,
    getCompanyLocationRegionLookUpData,
    getCompanyRelatedData,
    getStageLookUpData,
    getcompanyLocationNoOfEmployeesLookUpData,
    getCompanyMarketStageRegionLookUp,
    getAwsLambdaCompanyLookUpData,
    getFunctionBasedOnTitle,
    getCompanyDetailsBasedOnCompanyName
} from "../../../actions/lookupAction";
import { inputValueAction, offerDashPercentageHiddenAction, offerDashAddMemeberIconAction, offerDashRoleInsertFlagAction } from "../../../actions/dashboard";
import { fnEstimateDashboard, offerDashDialogBoxAction, addUpdateTmpPowerUserDashboard, getTempDataPowerUserDashboardDetails, fnComparisonCompensationEstimateDashboard } from "../../../actions/dashboard";
import constValues from './dashboard.json';
import { titlesDataSort, functionalityDataSort, companyDataSort, regionDataSort } from "../../dropdown/lookupSortingData";
import { FnInsertRole } from "../../../actions/roleActions";
import PowerUserAddNewCompany from './DashboardPowerAddNewCompany';
import PowerUserAddNewMember from './PowerUserDashboardAddMemberButton';
import { Button } from '@progress/kendo-react-buttons';
import Spinner from "../../spinner/loading";

class DashPowerUserBoardInputs extends React.Component {
    constructor(props) {
        super(props);
        this.props.getTitleLookUpData();
        this.props.getFunctionLookUpData();
        this.props.getgraphAPIListMarketSpaceLookUpData();
        this.props.getCompanyLocationRegionLookUpData();
        this.props.getCompanyRelatedData();
        this.props.getcompanyLocationNoOfEmployeesLookUpData();
        this.props.getStageLookUpData();
        this.props.getCompanyMarketStageRegionLookUp();
        this.props.getAwsLambdaCompanyLookUpData();
        this.state = {
            Title: "",
            Function: "Engineering",
            role_years: "3 - 5", // this.props.value.role_years ? this.props.value.role_years: "3 - 5",
            base_percentile: "80", // this.props.value.base_percentile,
            Company: this.props.value.Company,
            MarketSpace: this.props.value.MarketSpace,
            Region: this.props.value.Region,
            Stage: this.props.value.Stage,
            isBasePercentileFieldVisible: false,
            pick_benchMark: "60th percentile",
            comparison_benchmark_hard_code_value: "",
            company_region_lookup_data: [],
            loading: true
            // employees: ""
        };
        this.props.offerDashPercentageHiddenAction(this.state.Stage)
        if (this.state.pick_benchMark === "60th percentile") {
            this.props.inputValueAction("pick_benchMark", 60);
        }
    }
    addNewButton = (e) => {
        e.preventDefault();
        this.setState({
            addNewButton: "new_company",
            visible: true
        })
        this.props.offerDashDialogBoxAction("new_company", true)
    };
    listNoDataRender = (element) => {

        const noData = (
            <div>
                <a href="#" onClick={this.addNewButton} style={{ textTransform: "none" }}>Add company"{this.state.Company}"</a>
            </div>
        );

        return React.cloneElement(element, { ...element.props }, noData);
    }
    componentDidMount() {

        if (this.props.estimateDashboard.addUpdateTmpPowerDashboard === "") {
            getTempDataPowerUserDashboardDetails(this.props.auth.user._id).then(response => {
                let companyName = response.data.response.company
                getCompanyDetailsBasedOnCompanyName(companyName)
                    .then(companyResponse => {
                        this.state.employees = (companyResponse.data.data.length !== 0) ? companyResponse.data.data[0].employees : ""
                        this.state.Company = response.data.response.company
                        this.state.MarketSpace = response.data.response.market_space
                        this.state.Region = response.data.response.location
                        this.state.Stage = response.data.response.stage
                        this.state.Title = response.data.response.title
                        this.state.Function = "Engineering"                           // response.data.response.function
                        this.state.role_years = "3 - 5" //response.data.response.role_years ? response.data.response.role_years: "3 - 5"
                        this.state.pick_benchMark = "60th percentile"
                        this.state.comparison_benchmark_hard_code_value = response.data.response.comparison_benchmark
                        if (response.data.response.comparison_benchmark === "") {
                            this.state.comparison_benchmark_hard_code_value = "50th Percentile"
                        }

                        this.state.marketSpaceLookupData = this.props.lookups.marketSpaceLookUp.data
                        this.state.regionLookupData = this.state.company_region_lookup_data
                        this.state.stageLookupData = this.props.lookups.stageLookUp.data
                        let userDashBoardData = {
                            Title: response.data.response.title,
                            Function: "Engineering",                                  // response.data.response.function,
                            role_years: "3 - 5", // this.props.value.role_years,
                            base_percentile: (this.state.base_percentile / 100).toString(),
                            MarketSpace: response.data.response.market_space,
                            Company: response.data.response.company,
                            Region: response.data.response.location,
                            Stage: response.data.response.stage,
                            employees: this.state.employees
                        }
                        let userTemporaryDashboardData = {
                            user_id: this.props.auth.user._id,
                            company: response.data.response.company,
                            market_space: response.data.response.market_space,
                            stage: response.data.response.stage,
                            location: response.data.response.location,
                            title: response.data.response.title,
                            function: "Engineering",                                  //response.data.response.function,
                            role_years: "3 - 5", // this.props.value.role_years,
                            pick_benchmark: this.state.pick_benchMark,
                            comparison_benchmark: this.state.comparison_benchmark_hard_code_value,
                            flag: 0
                        }

                        this.props.value.Title = response.data.response.title
                        this.props.value.Function = "Engineering"                     //response.data.response.function
                        this.props.inputValues.Stage = response.data.response.stage
                        this.props.offerDashPercentageHiddenAction(this.state.Stage)
                        this.props.addUpdateTmpPowerUserDashboard(userTemporaryDashboardData)
                        this.props.fnComparisonCompensationEstimateDashboard(userDashBoardData)
                        this.state.loading = false
                        if (this.state.pick_benchMark === "60th percentile") {
                            this.props.inputValueAction("pick_benchMark", 60);
                        }

                    });
            }).catch(error => console.log(error));

        } else {
            console.log("loading", this.state.loading)
            let temperorary_data = this.props.estimateDashboard.addUpdateTmpPowerDashboard.msg
            this.state.Company = temperorary_data.company
            this.state.Stage = temperorary_data.stage
            this.state.Region = temperorary_data.location
            this.state.MarketSpace = temperorary_data.
                market_space
            this.state.Title = temperorary_data.title
            this.state.Function = "Engineering" // temperorary_data.function
            this.state.role_years = "3 - 5" // temperorary_data.role_years
            this.state.pick_benchMark = temperorary_data.pick_benchmark
            this.state.comparison_benchmark_hard_code_value = temperorary_data.comparison_benchmark
            this.state.marketSpaceLookupData = this.props.lookups.marketSpaceLookUp.data
            this.state.regionLookupData = this.state.company_region_lookup_data
            this.state.stageLookupData = this.props.lookups.stageLookUp.data.sort().filter(function (el) {
                return el != "PUBLIC";
            })

            let userDashBoardData = {
                Title: temperorary_data.title,
                Function: "Engineering", // temperorary_data.function,
                role_years: "3 - 5", // temperorary_data.role_years,
                base_percentile: (this.state.base_percentile / 100).toString(),
                MarketSpace: temperorary_data.
                    market_space,
                Company: temperorary_data.company,
                Region: temperorary_data.location,
                Stage: temperorary_data.stage
            }

            this.props.value.Title = temperorary_data.title
            this.props.value.Function = "Engineering"                         //temperorary_data.function
            this.props.inputValues.Stage = temperorary_data.stage
            this.props.offerDashPercentageHiddenAction(this.state.Stage)
            this.props.fnComparisonCompensationEstimateDashboard(userDashBoardData)
            this.state.loading = false
            if (this.state.pick_benchMark === "60th percentile") {
                this.props.inputValueAction("pick_benchMark", 60);
            }
            if (this.state.pick_benchMark === "Custom percentile") {
                this.props.inputValueAction("pick_benchMark", 50);
            }
            if (this.state.pick_benchMark === "Your company rank") {
                this.props.inputValueAction("pick_benchMark", this.state.base_percentile);
                this.state.isBasePercentileFieldVisible = true
                // this.setState({
                //     isBasePercentileFieldVisible: true
                // });
                // this.state.loading = false
            }

        }


    }

    onChangeLineGraphData = e => {
        const value = e.target.value;
        this.state[e.target.name] = value;

        if (e.target.name === "comparison_benchmark_hard_code_value") {
            this.props.inputValueAction(e.target.name, 75);
            this.state.comparison_benchmark_hard_code_value = e.target.value
            if (e.target.value === "75th Percentile") {
                this.props.estimateDashboard.value = "75"
                this.state.comparison_benchmark_percentile = 75
                if (this.state.pick_benchMark === "Custom percentile") {
                    this.setState({
                        isBasePercentileFieldVisible: true
                    });
                } else if (this.state.pick_benchMark === "Your Company Rank") {
                    this.setState({
                        isBasePercentileFieldVisible: true
                    });
                } else if (this.state.pick_benchMark === "Competition") {
                    this.setState({
                        isBasePercentileFieldVisible: false
                    });
                } else if (this.state.pick_benchMark === "60th percentile") {

                    this.setState({
                        isBasePercentileFieldVisible: false
                    });
                } else if (this.state.pick_benchMark === "") {
                    this.setState({
                        isBasePercentileFieldVisible: true
                    });
                }
            }
            if (e.target.value === "60th Percentile") {
                this.props.estimateDashboard.value = "60"
                this.state.comparison_benchmark_percentile = 60
                this.props.inputValueAction(e.target.name, 60);
                if (this.state.pick_benchMark === "Custom percentile") {
                    this.setState({
                        isBasePercentileFieldVisible: true
                    });
                } else if (this.state.pick_benchMark === "Your Company Rank") {
                    this.setState({
                        isBasePercentileFieldVisible: true
                    });
                } else if (this.state.pick_benchMark === "Competition") {
                    this.setState({
                        isBasePercentileFieldVisible: false
                    });
                } else if (this.state.pick_benchMark === "60th percentile") {
                    this.setState({
                        isBasePercentileFieldVisible: false
                    });
                } else if (this.state.pick_benchMark === "") {
                    this.setState({
                        isBasePercentileFieldVisible: true
                    });
                }
            }
            if (e.target.value === "50th Percentile") {
                this.props.inputValueAction(e.target.name, 50);
                this.state.comparison_benchmark_percentile = 50
                this.props.estimateDashboard.value = "50"
                if (this.state.pick_benchMark === "Custom percentile") {
                    this.setState({
                        isBasePercentileFieldVisible: true
                    });
                } else if (this.state.pick_benchMark === "Your Company Rank") {
                    this.setState({
                        isBasePercentileFieldVisible: true
                    });
                } else if (this.state.pick_benchMark === "Competition") {
                    this.setState({
                        isBasePercentileFieldVisible: false
                    });
                } else if (this.state.pick_benchMark === "60th percentile") {
                    this.setState({
                        isBasePercentileFieldVisible: false
                    });
                } else if (this.state.pick_benchMark === "") {
                    this.setState({
                        isBasePercentileFieldVisible: true
                    });
                }
            }
            const userTemporaryDashboardData = {
                user_id: this.props.auth.user._id,
                company: this.state.Company,
                market_space: this.state.MarketSpace,
                stage: this.state.Stage,
                location: this.state.Region,
                title: this.state.Title,
                function: this.state.Function,
                role_years: this.state.role_years,
                pick_benchmark: this.state.pick_benchMark,
                comparison_benchmark: this.state.comparison_benchmark_hard_code_value,
                comparison_benchmark_percentile: this.state.comparison_benchmark_percentile,
                flag: 0
            }
            const userGetEstimateDashboardData = {
                Stage: this.state.Stage,
                Title: this.state.Title,
                Function: this.state.Function,
                comparison_benchmark_percentile: this.state.comparison_benchmark_percentile,
                employees: this.state.employees,
                base_percentile: (this.state.base_percentile / 100).toString(),
            }

            this.props.value.Title = this.state.Title
            this.props.value.Function = this.state.Function
            this.props.fnComparisonCompensationEstimateDashboard(userGetEstimateDashboardData);
            this.props.addUpdateTmpPowerUserDashboard(userTemporaryDashboardData)
        }
    }
    onChange = e => {
        if (this.state.loading === false) {
            this.state.loading = true
        }
        const value = e.target.value;
        this.state[e.target.name] = value;

        this.setState({
            [e.target.name]: value,
            titleLookupData: titlesDataSort(value, this.props.lookups.titleLookUp.data),
            functionalityLookupData: functionalityDataSort(value, this.props.lookups.functionLookUp.data),
            companyLookUpData: companyDataSort(value, this.props.lookups.companyLookUp),
            marketSpaceLookupData: this.props.lookups.marketSpaceLookUp.data,
            regionLookupData: this.state.company_region_lookup_data,
            stageLookupData: this.props.lookups.stageLookUp.data.sort().filter(function (el) {
                return el != "PUBLIC";
            })
        });


        if (e.target.name === "Company") {
            const locationregioncity = this.props.lookups.companyMarketStageRegionLookUp.data;
            for (let i = 0; i < locationregioncity.length; i++) {
                if (locationregioncity[i]["_id"]["company"] === e.target.value) {
                    this.setState({
                        MarketSpace: locationregioncity[i]["_id"]['marketspace'],
                        Stage: locationregioncity[i]["_id"]['stage'],
                        Region: locationregioncity[i]["_id"]['region']
                    });
                    this.state.MarketSpace = locationregioncity[i]["_id"]['marketspace']
                    this.state.Stage = locationregioncity[i]["_id"]['stage']
                    this.state.Region = locationregioncity[i]["_id"]['region']
                    this.props.offerDashPercentageHiddenAction(locationregioncity[i]["_id"]['stage'])
                }
            }

            getCompanyDetailsBasedOnCompanyName(e.target.value)
                .then(companyResponse => {
                    console.log("length", companyResponse.data.data.length !== 0)
                    if (companyResponse.data.data.length !== 0) {

                        this.state.employees = companyResponse.data.data[0].employees

                        console.log("if", this.state.employees)
                    }

                });
            console.log("employess", this.state.employees)
        }
        this.props.inputValueAction(e.target.name, e.target.value);

        if (e.target.name === "Title" || e.target.name === "Company") {
            let titleName = e.target.value
            getFunctionBasedOnTitle(titleName).then(response => {
                let titleResultData = response.data.data
                if (this.state.loading === false) {
                    this.state.loading = true
                }
                for (let i = 0; i < titleResultData.length; i++) {
                    if (titleResultData[i]["title"] === e.target.value) {

                        if (titleResultData[i]["function"] === undefined) {
                            this.setState({
                                Function: this.state.Function
                            });
                        } else {
                            this.setState({
                                Function: titleResultData[i]["function"]
                            });
                        }
                    }
                }

                const userGetEstimateDashboardData = {
                    Stage: this.state.Stage,
                    Title: this.state.Title,
                    Function: this.state.Function,
                    base_percentile: (this.state.base_percentile / 100).toString(),
                    MarketSpace: this.state.MarketSpace,
                    Company: this.state.Company,
                    Region: this.state.Region,
                    comparison_benchmark_percentile: this.state.comparison_benchmark_percentile,
                    role_years: this.state.role_years,
                    employees: this.state.employees
                }
                this.props.value.Title = this.state.Title
                this.props.value.Function = this.state.Function
                this.props.fnComparisonCompensationEstimateDashboard(userGetEstimateDashboardData);
                this.state.loading = false
            });
            if (this.state.Title === "") {
                this.setState({
                    Function: ""
                });
            }
        }

        if (e.target.name === "pick_benchMark") {
            this.state.pick_benchMark = e.target.value
        }
        if (e.target.value === "Custom percentile") {
            this.props.inputValueAction(e.target.name, 50);
            this.state.base_percentile = "50"
            this.setState({
                isBasePercentileFieldVisible: true
            });
        } else if (e.target.value === "Your company rank") {
            this.props.inputValueAction(e.target.name, 0);
            this.state.base_percentile = ""
            this.setState({
                isBasePercentileFieldVisible: true
            });
        } else if (e.target.value === "Competition") {

            this.props.inputValueAction(e.target.name, 64);
            this.state.base_percentile = "64"
            this.setState({
                isBasePercentileFieldVisible: false
            });
        } else if (e.target.value === "60th percentile") {
            this.props.inputValueAction(e.target.name, 60);
            this.state.base_percentile = "60"
            this.setState({
                isBasePercentileFieldVisible: false
            });
        }



        const userDashBoardData = {
            Title: this.state.Title,
            Function: this.state.Function,
            role_years: this.state.role_years,
            base_percentile: (this.state.base_percentile / 100).toString(),
            MarketSpace: this.state.MarketSpace,
            Company: this.state.Company,
            Region: this.state.Region,
            Stage: this.state.Stage,
            comparison_benchmark: this.state.comparison_benchmark_hard_code_value
        }
        const userTemporaryDashboardData = {
            user_id: this.props.auth.user._id,
            company: this.state.Company,
            market_space: this.state.MarketSpace,
            stage: this.state.Stage,
            location: this.state.Region,
            title: this.state.Title,
            function: this.state.Function,
            role_years: this.state.role_years,
            pick_benchmark: this.state.pick_benchMark,
            comparison_benchmark: this.state.comparison_benchmark_hard_code_value,
            flag: 0
        }

        this.props.value.Title = this.state.Title
        this.props.value.Function = this.state.Function
        this.props.addUpdateTmpPowerUserDashboard(userTemporaryDashboardData)
        if (e.target.name !== "MarketSpace" && e.target.name !== "role_years" && e.target.name !== "Company") {

            this.props.fnComparisonCompensationEstimateDashboard(userDashBoardData)
            setTimeout(
                function () {
                    this.setState({ loading: false });
                }
                    .bind(this),
                1000
            );
        }
        if (e.target.name === "MarketSpace" || e.target.name === "role_years" || e.target.name === "Company") {
            this.state.loading = false
        }
    }
    onClick = (e) => {
        e.preventDefault();
        this.setState({
            addNewButton: "invite_member",
            visible: true
        })
        this.props.offerDashDialogBoxAction("invite_member", true)
    }
    onInviteMemberClick = () => {
        const userData = {
            user_id: this.props.auth.user._id,
            role_company: this.state.Company,
            role_stage: this.state.Stage,
            role_market_space: this.state.MarketSpace,
            role_region: this.state.Region,
            role_status: "Open",
            role_title: this.state.Title,
            role_function: this.state.Function,
            compensation_total_high:
                this.props.estimateDashboard.estimateDashboard.data.offer['total'],
            compensation_base_high: this.props.estimateDashboard.estimateDashboard.data.offer['base'],
            compensation_bonus_high: this.props.estimateDashboard.estimateDashboard.data.offer['bonuses'],
            compensation_equity_high: this.props.estimateDashboard.estimateDashboard.data.offer['equity']
        }
        const userTemporaryDashboardData = {
            user_id: this.props.auth.user._id,
            company: this.state.Company,
            market_space: this.state.MarketSpace,
            stage: this.state.Stage,
            location: this.state.Region,
            title: this.state.Title,
            function: this.state.Function,
            role_years: this.state.role_years,
            pick_benchmark: this.state.pick_benchMark,
            comparison_benchmark: this.state.comparison_benchmark_hard_code_value,
            flag: 1
        }
        this.props.offerDashRoleInsertFlagAction(1)
        this.props.addUpdateTmpPowerUserDashboard(userTemporaryDashboardData)
        this.props.FnInsertRole(userData, this.props.history, "inviteMember");
        this.props.offerDashAddMemeberIconAction(2)
    }

    onFilterChange = (event) => { 
        var changeRegionLkpData = this.props.lookups.companyRegionLookUp.filter((el, index) =>
        this.props.lookups.companyRegionLookUp.indexOf(el) === index
        );
        this.setState({
        regionLookupData: regionDataSort(event.filter.value,
          changeRegionLkpData.sort())
      }) }
    render() {
        if (this.props.estimateDashboard.name === "pick_benchMark" || this.props.estimateDashboard.name === "donutarrow") {
            this.state.base_percentile = this.props.estimateDashboard.value
        }
        if (this.state.pick_benchMark === "Your company rank") {
            this.state.isBasePercentileFieldVisible = true
        }
        if (this.state.pick_benchMark === "Custom percentile") {
            this.state.isBasePercentileFieldVisible = true
        }
        if (this.props.lookups.companyRegionLookUp.length !== undefined) {
            // this.state.company_region_lookup_data = []
            const company_region_lookup_data = this.props.lookups.companyRegionLookUp.sort().filter(function (el) {
                return el != "";
            });
            company_region_lookup_data.map(regionLookupUniqueData => {
                if (this.state.company_region_lookup_data.indexOf(regionLookupUniqueData) === -1) {
                    this.state.company_region_lookup_data.push(regionLookupUniqueData)
                }
                let location_data = this.state.company_region_lookup_data
                this.state.company_region_lookup_data = location_data.filter(function (element) {
                    return element !== undefined;
                });
            });
        }
        if (this.props.lookups.stageLookUp.data !== undefined) {
            this.state.stageLookupData = this.props.lookups.stageLookUp.data.sort().filter(function (el) {
                return el != "PUBLIC";
            })
        }
        if (this.props.lookups.marketSpaceLookUp.data !== undefined) {
            this.state.marketSpaceLookupData = this.props.lookups.marketSpaceLookUp.data.sort().filter(function (el) {
                return el != "";
            });
        }
        if (this.state.Stage === "PUBLIC") {
            this.state.disabled = true
        } else {
            this.state.disabled = false
        }

        return (
            <div className="">
                {this.state.addNewButton === "new_company" ?
                    <div>{this.state.visible && <PowerUserAddNewCompany companyLookUpData={this.state.companyLookUpData} marketSpaceLookupData={this.state.marketSpaceLookupData} stageLookupData={this.state.stageLookupData} regionLookupData={this.state.regionLookupData} companyValue={this.state.Company} />}</div> : null
                }
                {this.state.addNewButton === "invite_member" ?
                    <div>{this.state.visible && <PowerUserAddNewMember company={this.state.Company} history={this.props.history} name="" />}</div> : null
                }
                {this.state.loading === true ? <Spinner /> : null}
                <div className="row fixed-dashboards" style={{
                    paddingTop: "0px", marginTop: "20px", paddingLeft: "5px",
                    paddingBottom: "10px"
                }}>
                    <div className="col-md-8  col-sm-6 col-xs-6 col-lg-8 paddingRight" style={{ fontSize: "18px" }} >
                        <h3 style={{ fontWeight: "300", fontSize: "18px" }}>
                            Talent Market: {this.state.Title}, {this.state.Function} - {this.state.Company}</h3>
                    </div>
                    <div className="col-md-4 col-sm-6 col-xs-6 col-lg-4 paddingRight">

                        <div className="float-right power_user_dashboard-icon-save" onClick={this.onClick} style={{ cursor: "pointer" }}>
                            <i title="Add Power User" className="fa fa-user-plus" aria-hidden="true"
                                style={{
                                    margin: "auto", color: "rgba(255,255,255,0.87)", fontSize: "24px", lineHeight: "1", marginLeft: "10px", marginTop: "6px"
                                }}
                            >
                            </i>
                        </div>
                        <Button className="buttons" style={{ color: "#3b3f4f", border: "2px solid #3b3f4f", borderRadius: "50px", fontWeight: "bold", marginLeft: "70px" }} onClick={this.onInviteMemberClick}>
                            Rate < i className="fa fa-hand-pointer-o" aria-hidden="true" style={{ fontSize: "25px", marginLeft: "10px" }}></i>
                        </Button>
                    </div>
                </div>

                <div className="row dashboard-input-wrapper" style={{
                    paddingTop: "0px", marginTop: "30px", paddingLeft: "5px"
                }}>
                    <div className="col-md-3 paddingRight" >
                        <AutoComplete
                            name="Company"
                            onChange={this.onChange}
                            data={this.state.companyLookUpData}
                            label="Company"
                            value={this.state.Company}
                            listNoDataRender={this.listNoDataRender}
                            style={{ width: '100%' }}
                            className="inputDotClass"
                        />
                    </div>
                    <div className="col-md-3 paddingRight">
                        <DropDownList
                            name="MarketSpace"
                            onChange={this.onChange}
                            data={this.state.marketSpaceLookupData}
                            label="Market Space"
                            value={this.state.MarketSpace}
                            style={{ width: '100%' }}
                            className="inputDotClass"
                        />
                    </div>
                    <div className="col-md-3 paddingRight">
                        <ComboBox
                            name="Stage"
                            onChange={this.onChange}
                            data={this.state.stageLookupData}
                            label="Stage"
                            disabled={this.state.disabled}
                            value={this.state.Stage}
                            style={{ width: '100%' }}
                            className="inputDotClass"
                        />
                    </div>
                    <div className="col-md-3 paddingRight">
                        <ComboBox
                            name="Region"
                            onChange={this.onChange}
                            data={this.state.regionLookupData}
                            label="Location"
                            value={this.state.Region}
                            style={{ width: '100%' }}
                            className="inputDotClass"
                            onFilterChange={this.onFilterChange}
                            filterable={true}
                        />

                    </div>
                </div>
                <div className="row dashboard-input-wrapper" style={{ marginTop: "-30px", paddingLeft: "5px" }}>
                    <div className="col-md-3 example-col paddingRight">
                        <AutoComplete
                            name="Title"
                            onChange={this.onChange}
                            data={this.state.titleLookupData}
                            label="Title/Level"
                            value={this.state.Title}
                            style={{ width: '100%' }}
                            className="inputDotClass"
                        />
                    </div>
                    <div className="col-md-3 paddingRight">
                        <AutoComplete
                            name="Function"
                            onChange={this.onChange}
                            data={this.state.functionalityLookupData}
                            label="Function"
                            value={this.state.Function}
                            style={{ width: '100%' }}
                            className="inputDotClass"
                        />
                    </div>
                    <div className="col-md-3 paddingRight">
                        <ComboBox
                            name="role_years"
                            onChange={this.onChange}
                            data={constValues.roleYears}
                            value={this.state.role_years}
                            label="Role Yrs"
                            style={{ width: '100%' }}
                            className="inputDotClass"
                        />
                    </div>
                    <div className="col-md-3 paddingRight">
                        <DropDownList
                            name="pick_benchMark"
                            data={constValues.benchMark}
                            label="Pick Benchmark"
                            onChange={this.onChange}
                            value={this.state.pick_benchMark}
                            style={{ width: '100%' }
                            }
                            className="inputDotClass"
                        />
                    </div>
                    <div className="col-md-3"></div>
                    <div className="col-md-3"></div>
                    <div className="col-md-3"></div>

                    {this.state.isBasePercentileFieldVisible ? <div className="col-md-3 paddingRight" style={{ marginTop: "20px" }}>
                        <Input
                            name="base_percentile"
                            type="text"
                            onChange={this.onChange}
                            label="Custom Percentile"
                            value={this.state.base_percentile}
                            style={{ width: '100%' }}
                            className="inputDotClass"
                        />
                    </div> : false}
                    <div className="row col-md-12" style={{ marginTop: "50px" }}>

                        <div className="col-md-6 col-sm-12 col-xs-12 col-lg-6 paddingRight" style={{ float: "right", textAlign: "right", paddingTop: "28px", fontSize: "16px" }}>Comparison Benchmark: </div>
                        <div className="col-md-2 col-sm-12 col-xs-12 col-lg-2  paddingRight" style={{ marginTop: "15px", paddingLeft: "5px" }}>
                            <DropDownList
                                name="comparison_benchmark_hard_code_value"
                                onChange={this.onChangeLineGraphData}
                                data={constValues.comparisonBenchmark}
                                value={this.state.comparison_benchmark_hard_code_value}
                                style={{ width: "120%" }}
                                className="dropdownDotClass"
                            />
                        </div>

                    </div>
                </div>
            </div >
        )
    }
}


const mapStateToProps = state =>
    ({
        lookups: state.lookups,
        auth: state.auth,
        estimateDashboard: state.estimateDashboard,
        powerUserDashBoard: state.powerUserDashBoard
    });
export default connect(mapStateToProps, {
    getTitleLookUpData,
    getFunctionLookUpData,
    getCompanyLookUpData,
    getgraphAPIListMarketSpaceLookUpData,
    getCompanyLocationRegionLookUpData,
    getcompanyLocationNoOfEmployeesLookUpData,
    getStageLookUpData,
    getCompanyMarketStageRegionLookUp,
    fnEstimateDashboard,
    inputValueAction,
    offerDashAddMemeberIconAction,
    offerDashPercentageHiddenAction,
    offerDashDialogBoxAction,
    FnInsertRole,
    getCompanyRelatedData,
    getAwsLambdaCompanyLookUpData,
    addUpdateTmpPowerUserDashboard,
    fnComparisonCompensationEstimateDashboard,
    offerDashRoleInsertFlagAction,
    getCompanyDetailsBasedOnCompanyName
}
)(DashPowerUserBoardInputs);
