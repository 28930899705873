import React from 'react'
import '../../../../css/dashboard.css';
import { connect } from "react-redux";
import 'hammerjs';
import { inputValueAction, offerDashDialogBoxAction, fnAddMemberPowerUserDashboard } from "../../../../actions/dashboard";
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import FormValidator from '../../../../validation/FormValidator';
import validationParams from '../../../../validation/validationParams.json';
import { DropDownList, AutoComplete } from '@progress/kendo-react-dropdowns';
import { getCompanyLookUpData, getTitleLookUpData, getFunctionLookUpData, getStageLookUpData, getgraphAPIListMarketSpaceLookUpData, getCompanyLocationRegionLookUpData, getRoleStatusLookUp, getCityLookUp, getStateLookUp, getRegionLookUp } from "../../../../actions/lookupAction";
import { titlesDataSort, functionalityDataSort, regionDataSort, marketSpaceDataSort, companyDataSort, stageDataSort, cityDataSort, stateDataSort } from "../../../dropdown/lookupSortingData";
import { FnInsertRole } from "../../../../actions/roleActions";
export class OfferDashCreateRoleButton extends React.Component {
  constructor(props) {
    super(props);
    this.validator = new FormValidator(validationParams.offerDashBoardCreateRoleValidations);
    this.state = {
      role_status: "",
      role_title: "",
      role_function: "",
      role_company: this.props.company,
      role_stage: "",
      role_market_space: "",
      role_city: "",
      role_state: "",
      role_region: "",
      visible: true,
      validation: this.validator.valid()
    }
    this.submitted = false;
    this.InvitePopupSubmit = this.InvitePopupSubmit.bind(this);
    this.props.getRoleStatusLookUp();
    this.props.getCityLookUp();
    this.props.getStateLookUp();
    this.props.getRegionLookUp();
  }
  onChange = e => {
    const value = e.target.value;
    this.setState({
      [e.target.name]: value,
      titleLookupData: titlesDataSort(value, this.props.lookups.titleLookUp.data),
      functionalityLookupData: functionalityDataSort(value, this.props.lookups.functionLookUp.data),
      // companyLookupData: companyDataSort(value, this.props.lookups.companyLookUp),
      stageLookupData: stageDataSort(value, this.props.lookups.stageLookUp.data),
      stateLookupData: stateDataSort(value, this.props.lookups.stateLookUp.data),
      marketSpaceLookupData: marketSpaceDataSort(value, this.props.lookups.marketSpaceLookUp.data),
      cityLookupData: cityDataSort(value, this.props.lookups.cityLookUp.data),
      regionLookupData: regionDataSort(value, this.props.lookups.regionLookUpData.data)
    })
    if (e.target.name === "role_city") {
      const locationregioncity = this.props.lookups.companyRelatedData;

      for (let i = 0; i < locationregioncity.length; i++) {
        if (locationregioncity[i]["location_city"] === e.target.value) {
          this.setState({
            role_region: locationregioncity[i]['region'],
            role_state: locationregioncity[i]['location_state'],
          });
          this.state.role_region = locationregioncity[i]['region']
          this.state.role_state = locationregioncity[i]['location_state']
        }
      }

    }
  }
  toggleDialog = () => {
    this.setState({
      visible: !this.state.visible
    });
    this.props.offerDashDialogBoxAction(this.state.visible, false)
  }
  toggleDialogStable = () => {
    this.setState({
      visible: this.state.visible
    });
  }
  InvitePopupSubmit = (event) => {

    event.preventDefault();
    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;
    if (validation.isValid) {
      const userData = {
        user_id: this.props.auth.user._id,
        role_company: this.state.role_company,
        role_stage: this.state.role_stage,
        role_market_space: this.state.role_market_space,
        role_region: this.state.role_region,
        role_status: "Open",
        role_title: this.state.role_title,
        role_function: this.state.role_function,
        role_city: this.state.role_city,
        role_state: this.state.role_state
      }
      this.props.FnInsertRole(userData, this.props.history, "inviteMember");
      this.props.estimateDashboard.togglename = ""
      this.state.role_status = ""
      this.state.role_title = ""
      this.state.role_function = ""
      this.state.role_company = ""
      this.state.role_market_space = ""
      this.state.role_stage = ""
      this.state.role_region = ""
      this.state.role_city = ""
      this.state.role_state = ""
    }
  }

  render() {
    if (this.props.estimateDashboard.togglename === "create_role") {
      this.state.visible = true
    } else {
      this.state.visible = false
    }
    if (this.props.lookups.stageLookUp.data.length !== undefined) {
      this.state.stageLookupData = this.props.lookups.stageLookUp.data
    }
    let validation = this.submitted ?
      this.validator.validate(this.state) :
      this.state.validation

    return (
      <div>
        {this.state.visible && <Dialog className="offer-save-div-modal">
          <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 offer-save-dialog-div" onClick={this.toggleDialogStable}>
            <div className="col-md-11 col-lg-11 col-sm-8 col-xs-8">
              Create Role
            </div>
            <div className="col-md-1 col-lg-1 col-sm-4 col-xs-4" style={{ textAlign: "right" }}>
              <button className="offer-dash-offer-save-dialog-button" onClick={this.toggleDialog}>X</button>
            </div>
          </div>
          <form action="" onSubmit={this.InvitePopupSubmit}>

            <div className="mb-3 row" style={{ width: "100%", paddingLeft: "45px" }}>
              <div className="col-md-5 col-sm-5 col-xs-5 col-lg-5 offer-autocomplete-field">
                <AutoComplete
                  label="Title/Level *"
                  name="role_title"
                  onChange={this.onChange}
                  value={this.state.role_title}
                  data={this.state.titleLookupData}
                  style={{ width: '100%' }}
                />
                <div style={{
                  color: "red",
                  paddingLeft: "0px"
                }}>
                  {validation.role_title.message}
                </div>
              </div>
              <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1">

              </div>
              <div className="col-md-5 col-sm-5 col-xs-5 col-lg-5 offer-autocomplete-field">
                <AutoComplete
                  label="Function *"
                  name="role_function"
                  onChange={this.onChange}
                  value={this.state.role_function}
                  data={this.state.functionalityLookupData}
                  style={{ width: '100%' }}
                />
                <div style={{
                  color: "red",
                  paddingLeft: "0px"
                }}>
                  {validation.role_function.message}
                </div>
              </div>
            </div>
            <div className="mb-3 row" style={{ width: "100%", paddingLeft: "45px" }}>
              <div className="col-md-5 col-sm-5 col-xs-5 col-lg-5 offer-autocomplete-field">
                <AutoComplete
                  label="Company *"
                  name="role_company"
                  onChange={this.onChange}
                  value={this.state.role_company}
                  data={this.state.companyLookupData}
                  style={{ width: '100%' }}
                  disabled
                />
                <div style={{
                  color: "red",
                  paddingLeft: "0px"
                }}>
                  {validation.role_company.message}
                </div>
              </div>
              <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1">

              </div>
              <div className="col-md-5 col-sm-5 col-xs-5 col-lg-5 offer-dropdown-field">
                <DropDownList
                  label="Stage *"
                  name="role_stage"
                  onChange={this.onChange}
                  value={this.state.role_stage}
                  data={this.state.stageLookupData}
                  style={{ width: '100%' }}
                />
                <div style={{
                  color: "red",
                  paddingLeft: "0px"
                }}>
                  {validation.role_stage.message}
                </div>
              </div>
            </div>
            <div className="mb-3 row" style={{ width: "100%", paddingLeft: "45px" }}>
              <div className="col-md-5 col-sm-5 col-xs-5 col-lg-5 offer-autocomplete-field">
                <AutoComplete
                  label="Market Space *"
                  name="role_market_space"
                  onChange={this.onChange}
                  value={this.state.role_market_space}
                  data={this.state.marketSpaceLookupData}
                  style={{ width: '100%' }}
                />
                <div style={{
                  color: "red",
                  paddingLeft: "0px"
                }}>
                  {validation.role_market_space.message}
                </div>
              </div>
              <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1">

              </div>
              <div className="col-md-5 col-sm-5 col-xs-5 col-lg-5 offer-autocomplete-field">
                <AutoComplete
                  label="City *"
                  name="role_city"
                  onChange={this.onChange}
                  value={this.state.role_city}
                  data={this.state.cityLookupData}
                  style={{ width: '100%' }}
                />
                <div style={{
                  color: "red",
                  paddingLeft: "0px"
                }}>
                  {validation.role_city.message}
                </div>
              </div>
            </div>
            <div className="mb-3 row" style={{ width: "100%", paddingLeft: "45px" }}>
              <div className="col-md-5 col-sm-5 col-xs-5 col-lg-5 offer-autocomplete-field">
                <AutoComplete
                  label="State *"
                  name="role_state"
                  onChange={this.onChange}
                  value={this.state.role_state}
                  data={this.state.stateLookupData}
                  style={{ width: '100%' }}
                  disabled
                />
                <div style={{
                  color: "red",
                  paddingLeft: "0px"
                }}>
                  {validation.role_state.message}
                </div>
              </div>
              <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1">

              </div>
              <div className="col-md-5 col-sm-5 col-xs-5 col-lg-5 offer-autocomplete-field">
                <AutoComplete
                  label="Region"
                  name="role_region"
                  onChange={this.onChange}
                  value={this.state.role_region}
                  data={this.state.regionLookupData}
                  style={{ width: '100%' }}
                  disabled
                />
                <div style={{
                  color: "red",
                  paddingLeft: "0px"
                }}>
                  {validation.role_region.message}
                </div>
              </div>
            </div>

            <div className="offer-save-buttons-div">
              <DialogActionsBar >
                <button className="k-button col-xs-6 col-sm-6" onClick={this.toggleDialog}>CANCEL</button>
                <button type="submit" className="k-button col-xs-6 col-sm-6" >SUBMIT</button>
              </DialogActionsBar>
            </div>
          </form>
        </Dialog>}
      </div >

    )
  }
}
const mapStateToProps = state =>
  ({
    estimateDashboard: state.estimateDashboard,
    auth: state.auth,
    lookups: state.lookups,
  });
export default connect(mapStateToProps, {
  inputValueAction, offerDashDialogBoxAction, fnAddMemberPowerUserDashboard, getCompanyLookUpData,
  getTitleLookUpData,
  getFunctionLookUpData,
  getStageLookUpData,
  getgraphAPIListMarketSpaceLookUpData,
  getCompanyLocationRegionLookUpData,
  getRoleStatusLookUp,
  getCityLookUp,
  getStateLookUp,
  getRegionLookUp,
  FnInsertRole
}
)(OfferDashCreateRoleButton);
