import * as React from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Input } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import initialStateParams from '../../validation/validationParams.json';
import { fnGetCompanyLookupDetail, fnInsertLookupCompany, fnUpdateLookupCompany } from "../../actions/adminLookupCompanyDetails";
import validationParams from '../../validation/validationParams.json'
import FormValidator from '../../validation/FormValidator';
import Spinner from "../spinner/loading";
export class LookupCompanyform extends React.Component {
  constructor(props) {
    super(props);
    this.validator = new FormValidator(validationParams.companyLookupFormValidATION);

    this.submitted = false;
    this.state = initialStateParams.companyLookupForm
    this.state.validation = this.validator.valid()
    this.state.loading = true
  }

  componentDidMount() {

    fnGetCompanyLookupDetail(this.props.location.search.slice(12)).then(response => {
      const LookupDetail = response.data.response
      this.setState({
        company: LookupDetail.company,
        location_city: LookupDetail.location_city,
        city_state: LookupDetail.city_state,
        location_state: LookupDetail.location_state,
        stage: LookupDetail.stage,
        base_average: LookupDetail.base_average,
        capital_raised: LookupDetail.capital_raised,
        investors_count: LookupDetail.investors_count,
        overview: LookupDetail.overview,
        tags_industry: LookupDetail.tags_industry,
        region: LookupDetail.region,
        employees: LookupDetail.employees,
        q_score: LookupDetail.q_score,
        unicorn: LookupDetail.unicorn,
        valuation_estimate: LookupDetail.valuation_estimate,
        revenue: LookupDetail.revenue,
        actual_revenue: LookupDetail.actual_revenue,
        market_cap: LookupDetail.market_cap,
        website: LookupDetail.website,
        sector: LookupDetail.sector,
        application: LookupDetail.application,
        sub_category: LookupDetail.sub_category,
        target_customer: LookupDetail.target_customer,
        ZIP: LookupDetail.ZIP,
        QFactor: LookupDetail.QFactor,
        competitors: LookupDetail.competitors,
        recent_funding: LookupDetail.recent_funding,
        acquired: LookupDetail.acquired,
        acquired_by: LookupDetail.acquired_by,
        acquired_date: LookupDetail.acquired_date,
        price_of_acquisition: LookupDetail.price_of_acquisition,
        symbol: LookupDetail.symbol,
        round: LookupDetail.round,
        investors: LookupDetail.investors,
        HQ_street_address: LookupDetail.HQ_street_address,
        founded: LookupDetail.founded,
        phone: LookupDetail.phone,
        profit: LookupDetail.profit,
        market_cap01: LookupDetail.market_cap01,
        annual_growth: LookupDetail.annual_growth,
        percentile_rank: LookupDetail.percentile_rank,
        recommend_GD: LookupDetail.recommend_GD,
        Competitors01: LookupDetail.Competitors01,
        quality_score: LookupDetail.quality_score,
        VC_firm: LookupDetail.VC_firm,
        partner: LookupDetail.partner,
        percentile_rank01: LookupDetail.percentile_rank01,
        CEO: LookupDetail.CEO,
        opperations_exec: LookupDetail.opperations_exec,
        marketing_exec: LookupDetail.marketing_exec,
        sales_exec: LookupDetail.sales_exec,
        engineering_exec: LookupDetail.engineering_exec,
        finance_exec: LookupDetail.finance_exec,
        products_exec: LookupDetail.products_exec,
        operations: LookupDetail.operations,
        marketing: LookupDetail.marketing,
        sales: LookupDetail.sales,
        engineering: LookupDetail.engineering,
        finance: LookupDetail.finance,
        products: LookupDetail.products,
        location: LookupDetail.location,
        Qfactor_01: LookupDetail.Qfactor_01,
        active: LookupDetail.active,
        marketspace: LookupDetail.marketspace,
        three_years_growth: LookupDetail.three_years_growth,
        admin_approval: LookupDetail.admin_approval,
        loading: false
      })

    }).catch(error => console.log(error));

  }

  onChange = e => {
    const value = e.target.value;
    this.setState({
      [e.target.name]: value

    })

    if (e.target.name === "Competitors01") {
      const competitor01Values = e.target.value;
      const competitor01LastItem = competitor01Values[competitor01Values.length - 1];
      if (competitor01LastItem) {
        competitor01Values.pop();
        const sameItem = competitor01Values.find(value => value === competitor01LastItem);
        if (sameItem === undefined) {
          competitor01Values.push(competitor01LastItem);
        }
      }
      this.setState({
        Competitors01: competitor01Values
      });
    }
    if (e.target.name === "competitors") {
      const competitorValues = e.target.value;
      const competitorLastItem = competitorValues[competitorValues.length - 1];
      if (competitorLastItem) {
        competitorValues.pop();
        const sameItem = competitorValues.find(value => value === competitorLastItem);
        if (sameItem === undefined) {
          competitorValues.push(competitorLastItem);
        }
      }
      this.setState({
        competitors: competitorValues
      });
    }
  }

  CompanyLookUpFormSubmit = (event) => {
    event.preventDefault();

    const userInsertUpdateData = this.state

    if (this.state.company === undefined) {
      this.state.company = ""
    }
    if (this.state.stage === undefined) {
      this.state.stage = ""
    }
    if (this.state.city_state === undefined) {
      this.state.city_state = ""
    }
    if (this.state.location_state === undefined) {
      this.state.location_state = ""
    }
    if (this.state.location_city === undefined) {
      this.state.location_city = ""
    }
    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;

    if (validation.isValid) {
      if (this.props.location.search !== "") {
        this.state.admin_lookup_company_name_id = this.props.location.search.slice(12)
        const userUpdateData = this.state
        this.setState({
          loading: true
        })
        this.props.fnUpdateLookupCompany(userUpdateData, this.props.history)
      } else {
        this.setState({
          loading: true
        })
        this.props.fnInsertLookupCompany(userInsertUpdateData, this.props.history,"admin_company")
      }
    }
  }

  adminApprovalChange = () => {
    this.setState({
      admin_approval: !this.state.admin_approval
    });
  }

  activeChange = () => {
    this.setState({
      active: !this.state.active
    });
  }
  render() {
    if (this.state.actual_revenue === null) {
      this.state.actual_revenue = ""
    }
    if (this.state.capital_raised === null) {
      this.state.capital_raised = ""
    }
    if (this.state.investors_count === null) {
      this.state.investors_count = ""
    }
    if (this.state.round === null) {
      this.state.round = ""
    }
    let validation = this.submitted ?
      this.validator.validate(this.state) :
      this.state.validation

    return (
      <div>
        {this.state.loading === true ? <Spinner /> : null}
        <div className="container-fluid card-layout">
          <div className="row card-block">
            <div className="col-sm-12 col-md-12 col-xs-12 col-lg-12">
              <form className="k-form" onSubmit={this.CompanyLookUpFormSubmit} >
                <fieldset style={{ marginBottom: "0px" }}>
                  <h1 className="h1s">Company Details</h1>
                  <h3 className="h3s"></h3>
                  <div className="mb-3 row">
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-2 col-sm-11 col-xs-11 col-lg-2">

                    </div>
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-2 col-sm-11 col-xs-11 col-lg-2">
                      <Input
                        className="k-checkbox"
                        data={this.state.companyLookupData}
                        onChange={this.activeChange}
                        type="checkbox"
                        name="active"
                        id="auth-2fa"
                        checked={this.state.active}
                        style={{ width: "100%" }}
                      />
                      <label className="k-checkbox-label" htmlFor="auth-2fa">  Active </label>
                    </div>
                    <div className="col-md-6 col-sm-11 col-xs-11 col-lg-6">
                      <Input
                        id="auth-2fb"
                        className="k-checkbox"
                        type="checkbox"
                        data={this.state.companyLookupData}
                        onChange={this.adminApprovalChange}
                        name="admin_approval"
                        checked={this.state.admin_approval}
                        style={{ width: "100%" }}
                      />
                      <label className="k-checkbox-label" htmlFor="auth-2fb">  Required Admin Approval </label>
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        name="company"
                        style={{ width: '100%' }}
                        onChange={this.onChange}
                        value={this.state.company}
                        label="Company *"
                      />
                      <div style={{
                        color: "red",
                        paddingLeft: "0px"
                      }}>
                        {validation.company.message}
                      </div>
                    </div>
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        name="location_city"
                        value={this.state.location_city}
                        style={{ width: '100%' }}
                        onChange={this.onChange}
                        label="Location City"
                      />
                      <div style={{
                        color: "red",
                        paddingLeft: "0px"
                      }}>
                        {validation.location_city.message}
                      </div>
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        name="city_state"
                        value={this.state.city_state}
                        style={{ width: '100%' }}
                        onChange={this.onChange}
                        label="City State "
                      />
                      <div style={{
                        color: "red",
                        paddingLeft: "0px"
                      }}>
                        {validation.city_state.message}
                      </div>
                    </div>
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        name="location_state"
                        value={this.state.location_state}
                        style={{ width: '100%' }}
                        onChange={this.onChange}
                        label="Location State *"
                      />
                      <div style={{
                        color: "red",
                        paddingLeft: "0px"
                      }}>
                        {validation.location_state.message}
                      </div>
                    </div>
                  </div>

                  <div className="mb-3 row">
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        name="stage"
                        value={this.state.stage}
                        style={{ width: '100%' }}
                        onChange={this.onChange}
                        label="Stage *"
                      />
                      <div style={{
                        color: "red",
                        paddingLeft: "0px"
                      }}>
                        {validation.stage.message}
                      </div>
                    </div>
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        name="base_average"
                        value={this.state.base_average}
                        style={{ width: '100%' }}
                        onChange={this.onChange}
                        label="Base average "
                      />
                    </div>
                  </div>

                  <div className="mb-3 row">
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        name="capital_raised"
                        label="Capital Raised "
                        type="number"
                        data={this.state.companyLookupData}
                        onChange={this.onChange}
                        value={this.state.capital_raised}
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        name="investors_count"
                        label="Investors Count "
                        type="number"
                        data={this.state.companyLookupData}
                        onChange={this.onChange}
                        value={this.state.investors_count}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        name="overview"
                        label="Overview "
                        data={this.state.companyLookupData}
                        onChange={this.onChange}
                        value={this.state.overview}
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        name="tags_industry"
                        label="Tags Industry "
                        data={this.state.companyLookupData}
                        onChange={this.onChange}
                        value={this.state.tags_industry}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        name="region"
                        label="Region "
                        data={this.state.companyLookupData}
                        onChange={this.onChange}
                        value={this.state.region}
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        name="employees"
                        label="No. Of Employees "
                        data={this.state.companyLookupData}
                        onChange={this.onChange}
                        value={this.state.employees}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        name="q_score"
                        label="q_score "
                        type="number"
                        data={this.state.companyLookupData}
                        onChange={this.onChange}
                        value={this.state.q_score}
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        name="unicorn"
                        label="Unicorn"
                        data={this.state.companyLookupData}
                        onChange={this.onChange}
                        value={this.state.unicorn}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        name="valuation_estimate"
                        label="Valuation Estimate "
                        type="number"
                        data={this.state.companyLookupData}
                        onChange={this.onChange}
                        value={this.state.valuation_estimate}
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        name="revenue"
                        label="Revenue"
                        type="number"
                        data={this.state.companyLookupData}
                        onChange={this.onChange}
                        value={this.state.revenue}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        name="actual_revenue"
                        label="Actual Revenue "
                        type="number"
                        data={this.state.companyLookupData}
                        onChange={this.onChange}
                        value={this.state.actual_revenue}
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        name="market_cap"
                        label="Market Cap"
                        data={this.state.companyLookupData}
                        onChange={this.onChange}
                        value={this.state.market_cap}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        name="website"
                        label="Website"
                        data={this.state.companyLookupData}
                        onChange={this.onChange}
                        value={this.state.website}
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        name="sector"
                        label="Sector"
                        data={this.state.companyLookupData}
                        onChange={this.onChange}
                        value={this.state.sector}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        name="application"
                        label="Application"
                        data={this.state.companyLookupData}
                        onChange={this.onChange}
                        value={this.state.application}
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        name="sub_category"
                        label="Sub Category"
                        data={this.state.companyLookupData}
                        onChange={this.onChange}
                        value={this.state.sub_category}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        name="target_customer"
                        label="Target Customer"
                        data={this.state.companyLookupData}
                        onChange={this.onChange}
                        value={this.state.target_customer}
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        name="ZIP"
                        label="ZIP"
                        // type="number"
                        data={this.state.companyLookupData}
                        onChange={this.onChange}
                        value={this.state.ZIP}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        name="QFactor"
                        label="QFactor"
                        data={this.state.companyLookupData}
                        onChange={this.onChange}
                        value={this.state.QFactor}
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <MultiSelect
                        label="Competitors"
                        data={this.state.companyLookupData}
                        onChange={this.onChange}
                        name="competitors"
                        value={this.state.competitors}
                        style={{ width: "100%" }}
                        allowCustom={true}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        name="recent_funding"
                        label="Recent Funding"
                        data={this.state.companyLookupData}
                        onChange={this.onChange}
                        value={this.state.recent_funding}
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        label="Acquired"
                        data={this.state.companyLookupData}
                        onChange={this.onChange}
                        name="acquired"
                        value={this.state.acquired}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        name="acquired_by"
                        label="Acquired By"
                        data={this.state.companyLookupData}
                        onChange={this.onChange}
                        value={this.state.acquired_by}
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        label="Acquired Date"
                        type="date"
                        defaultValue="dd/mm/yy"
                        data={this.state.companyLookupData}
                        onChange={this.onChange}
                        name="acquired_date"
                        value={this.state.acquired_date}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        name="price_of_acquisition"
                        label="Price Of Acquisition"
                        data={this.state.companyLookupData}
                        onChange={this.onChange}
                        value={this.state.price_of_acquisition}
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        label="Symbol"
                        data={this.state.companyLookupData}
                        onChange={this.onChange}
                        name="symbol"
                        value={this.state.symbol}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        label="Round"
                        data={this.state.companyLookupData}
                        onChange={this.onChange}
                        name="round"
                        value={this.state.round}
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        label="Investors"
                        data={this.state.companyLookupData}
                        onChange={this.onChange}
                        name="investors"
                        value={this.state.investors}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        label="HQ Street Address"
                        data={this.state.companyLookupData}
                        onChange={this.onChange}
                        name="HQ_street_address"
                        value={this.state.HQ_street_address}
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        label="Founded"
                        data={this.state.companyLookupData}
                        onChange={this.onChange}
                        name="founded"
                        value={this.state.founded}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        label="Phone"
                        data={this.state.companyLookupData}
                        onChange={this.onChange}
                        name="phone"
                        value={this.state.phone}
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        label="Profit"
                        data={this.state.companyLookupData}
                        onChange={this.onChange}
                        name="profit"
                        value={this.state.profit}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        label="Market Cap01"
                        data={this.state.companyLookupData}
                        onChange={this.onChange}
                        name="market_cap01"
                        value={this.state.market_cap01}
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        label="Annual Growth"
                        data={this.state.companyLookupData}
                        onChange={this.onChange}
                        name="annual_growth"
                        value={this.state.annual_growth}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        label="Percentile Rank"
                        data={this.state.companyLookupData}
                        onChange={this.onChange}
                        name="percentile_rank"
                        value={this.state.percentile_rank}
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        label="Recommend GD"
                        data={this.state.companyLookupData}
                        onChange={this.onChange}
                        name="recommend_GD"
                        value={this.state.recommend_GD}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <MultiSelect
                        label="Competitors01"
                        data={this.state.companyLookupData}
                        onChange={this.onChange}
                        name="Competitors01"
                        value={this.state.Competitors01}
                        style={{ width: "100%" }}
                        allowCustom={true}
                      />
                    </div>
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        label="Quality Score"
                        data={this.state.companyLookupData}
                        onChange={this.onChange}
                        name="quality_score"
                        value={this.state.quality_score}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        label="VC_firm"
                        data={this.state.companyLookupData}
                        onChange={this.onChange}
                        name="VC_firm"
                        value={this.state.VC_firm}
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        label="Partner"
                        data={this.state.companyLookupData}
                        onChange={this.onChange}
                        name="partner"
                        value={this.state.partner}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        label="Percentile Rank01"
                        data={this.state.companyLookupData}
                        onChange={this.onChange}
                        name="percentile_rank01"
                        value={this.state.percentile_rank01}
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        label="CEO"

                        data={this.state.companyLookupData}
                        onChange={this.onChange}
                        name="CEO"
                        value={this.state.CEO}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        label="Operations Exec"
                        data={this.state.companyLookupData}
                        onChange={this.onChange}
                        name="opperations_exec"
                        value={this.state.opperations_exec}
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        label="Marketing Exec"
                        data={this.state.companyLookupData}
                        onChange={this.onChange}
                        name="marketing_exec"
                        value={this.state.marketing_exec}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        label="Sales Exec"
                        data={this.state.companyLookupData}
                        onChange={this.onChange}
                        name="sales_exec"
                        value={this.state.sales_exec}
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        label="Engineering Exec"
                        data={this.state.companyLookupData}
                        onChange={this.onChange}
                        name="engineering_exec"
                        value={this.state.engineering_exec}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        label="Finance Exec"
                        data={this.state.companyLookupData}
                        onChange={this.onChange}
                        name="finance_exec"
                        value={this.state.finance_exec}
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        label="Products Exec"
                        data={this.state.companyLookupData}
                        onChange={this.onChange}
                        name="products_exec"
                        value={this.state.products_exec}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        label="Operations"
                        data={this.state.companyLookupData}
                        onChange={this.onChange}
                        name="operations"
                        value={this.state.operations}
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        label="Marketspace"
                        data={this.state.companyLookupData}
                        onChange={this.onChange}
                        name="marketspace"
                        value={this.state.marketspace}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        label="Marketing"
                        data={this.state.companyLookupData}
                        onChange={this.onChange}
                        name="marketing"
                        value={this.state.marketing}
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        label="Sales"
                        data={this.state.companyLookupData}
                        onChange={this.onChange}
                        name="sales"
                        value={this.state.sales}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        label="Engineering"
                        data={this.state.companyLookupData}
                        onChange={this.onChange}
                        name="engineering"
                        value={this.state.engineering}
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        label="Finance"
                        data={this.state.companyLookupData}
                        onChange={this.onChange}
                        name="finance"
                        value={this.state.finance}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        label="Products"
                        data={this.state.companyLookupData}
                        onChange={this.onChange}
                        name="products"
                        value={this.state.products}
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        label="Location"
                        data={this.state.companyLookupData}
                        onChange={this.onChange}
                        name="location"
                        value={this.state.location}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        label="Qfactor 01"
                        data={this.state.companyLookupData}
                        onChange={this.onChange}
                        name="Qfactor_01"
                        value={this.state.Qfactor_01}
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        label="Three Years Growth"
                        data={this.state.companyLookupData}
                        onChange={this.onChange}
                        name="three_years_growth"
                        value={this.state.three_years_growth}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>

                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>

                  </div>
                </fieldset>
                <div className="row">
                  <div className="col-md-4"></div>
                  <div className="col-md-2 col-xs-6 col-sm-6 col-lg-2">
                    <Button className="mt-3 col-md-12 col-xs-12 col-sm-12 col-lg-12" type="submit" primary={true} >Submit</Button>
                  </div>
                  <div className="col-md-2 col-xs-6 col-sm-6 col-lg-2" style={{ border: "1px slid red" }}>
                    <Link type="button" to="/home/lookup-company" className="k-button col-md-12 col-xs-12 col-sm-12 col-lg-12" style={{ marginTop: "16px" }}>CANCEL</Link>
                  </div>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = state => ({
  auth: state.auth,
  lookups: state.lookups
});
export default connect(mapStateToProps,
  {
    fnGetCompanyLookupDetail,
    fnInsertLookupCompany,
    fnUpdateLookupCompany
  })(LookupCompanyform);
