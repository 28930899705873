import React from 'react'
import '../../../../css/dashboard.css';
import { connect } from "react-redux";
import 'hammerjs';
import { inputValueAction, offerDashDialogBoxAction } from "../../../../actions/dashboard";
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { fnGetNewHires } from "../../../../actions/roleActions";
import { offerDashRightSideDashboardAction, fnComparisonCompensationEstimateDashboard } from "../../../../actions/dashboard";
export class OfferDashOfferResetOfferButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true
    }
  }

  toggleDialog = () => {
    this.setState({
      visible: !this.state.visible
    });
    this.props.offerDashDialogBoxAction(this.state.visible, false)
  }
  toggleDialog2 = () => {
    this.setState({
      visible: !this.state.visible
    });

    if (this.props.offers_name === "Estimate") {
      fnGetNewHires(this.props.location.search.slice(9)).then(response => {
        this.state.base = response.data.response.compensation_base_high
        this.state.bonus = response.data.response.compensation_bonus_high
        this.state.annual_equity = response.data.response.compensation_equity_high
        this.state.Slider1 = this.state.base
        this.state.Slider2 = this.state.bonus
        this.state.Slider3 = this.state.annual_equity
        var TitleData = response.data.response.role_title
        var FunctionData = response.data.response.role_title
        if (response.data.response.compensation_total_high) {
          var offer = response.data.response.compensation_total_high;
        } else {
          var offer = 0;
        }
        this.state.offer_base_percentile = Math.round((response.data.response.compensation_base_high / offer) * 100)
        this.state.offer_bonus_percentile = Math.round((response.data.response.compensation_bonus_high / offer) * 100)
        this.state.offer_Equity_percentile = Math.round((response.data.response.compensation_equity_high / offer) * 100)

        const userJobIdDashBoardData = {
          Title: TitleData,
          Function: FunctionData,
          role_years: this.props.value.role_years,
          base_percentile: (75 / 100).toString(),
          MarketSpace: response.data.response.role_market_space,
          Company: response.data.response.role_company,
          Region: response.data.response.role_region,
          Stage: response.data.response.role_stage
        }
        this.props.offerDashRightSideDashboardAction(this.state.base, this.state.bonus, this.state.annual_equity, this.state.offer_base_percentile, this.state.offer_bonus_percentile, this.state.offer_Equity_percentile)
        this.props.fnComparisonCompensationEstimateDashboard(userJobIdDashBoardData)
        this.state.loading = false
        this.state.base_bonus_percentage = Math.round((this.state.bonus / this.state.base) * 100);
      }).catch(error => console.log(error));
    } else {
      if (this.props.hiringMembers.offersData) {
        const offersData = this.props.hiringMembers.offersData

        for (let i = 0; i < offersData.length; i++) {
          if (offersData[i]["name"] === this.props.offers_name) {
            this.props.offerDashRightSideDashboardAction(offersData[i]["base_salary"], offersData[i]["bonus"], offersData[i]["annual_equity_value"], offersData[i]["base_pctl"], offersData[i]["bonus_pctl"], offersData[i]["annual_equity_pctl"])
          }
        }
      }
    }

    this.props.offerDashDialogBoxAction(this.state.visible, false)
  }
  render() {
    if (this.props.estimateDashboard.togglename === "reset_offer") {
      this.state.visible = true
    } else {
      this.state.visible = false
    }
    return (
      <div>
        {this.state.visible && <Dialog className="div-modal" title={"Reset Offer..."} onClose={this.toggleDialog}>
          <p style={{ margin: "25px", fontSize: "16px" }}>Do you want to reset the offer, to last saved state. You will loose the changes made. Are you sure you wish to proceed?</p>
          <div>
            <DialogActionsBar className="modal-button">
              <button className="k-button col-xs-6 col-sm-6" onClick={this.toggleDialog}>No</button>
              <button className="k-button col-xs-6 col-sm-6" onClick={this.toggleDialog2}>Yes</button>
            </DialogActionsBar>
          </div>
        </Dialog>}
      </div >

    )
  }
}
const mapStateToProps = state =>
  ({
    estimateDashboard: state.estimateDashboard,
    candidateOffers: state.candidateOffers,
    candidates: state.candidates,
    hiringMembers: state.hiringMembers
  });
export default connect(mapStateToProps, { inputValueAction, offerDashDialogBoxAction, fnGetNewHires, offerDashRightSideDashboardAction, fnComparisonCompensationEstimateDashboard }
)(OfferDashOfferResetOfferButton);
