import axios from "axios";
import {
  SET_CURRENT_CANDIDATE_MEMBERS,
  GET_ROLE_CANDIDATE,
  GET_CANDIDATE_FROM_OFFER_DASHBOARD,
  CLEAR_ERRORS
} from "./types";

export const fnGetAllRoleCandidate = (id) => dispatch => {
  dispatch(clearErrors());

  axios
    .get("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-hrt-role-candidates-get-grid?id=" + id)
    .then(res => {
      const CandidateListData = res.data.candidates;
      const CandidateNewHireData = res.data.new_hire;
      const CandidateList = []
      for (let i = 0; i < CandidateListData.length; i++) {
        CandidateList.push(CandidateListData[i])
      }
      dispatch(setGetAllRoleCandidates(CandidateList, CandidateNewHireData));
    })
    .catch(err => {
      console.log(err)
    }
    );
};

export const fnGetAllCandidatesFromOfferDashboard = (data) => dispatch => {
  dispatch(clearErrors());
  console.log("data", data)
  axios
    .get('https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-hrt-dashboard-create-offer-candidates-get?new_hire_id=' + data.new_hire_id + '&hr_id=' + data.hr_id)
    .then(res => {
      const CandidateListData = res.data.response;
      const CandidateList = []
      for (let i = 0; i < CandidateListData.length; i++) {
        CandidateList.push(CandidateListData[i])
      }
      dispatch(setGetAllCandidatesFromOfferDashboard(CandidateList));
    })
    .catch(err => {
      console.log(err)
    }
    );
};

export function fnDeleteRoleCandidate(id) {
  return axios
    .delete("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-hrt-role-candidate-del?id=" + id)
}

export function fnDeleteCandidateFromOfferDashboard(data) {
  return axios
    .delete('https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-hrt-dashboard-create-offer-candidates-del?new_hire_id=' + data.new_hire_id + '&offer_candidate_id=' + data.offer_candidate_id)
}

export function FnGetCandidateDetailsInOfferDashboard(data) {
  return axios
    .get('https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-hrt-dashboard-create-offer-candidates-get-form?candidate_id=' + data.candidate_id)
}

export const FnUpdateOfferedCandidate = (updateRoleCandidate, history, new_hire_id, hr_id) => dispatch => {
  dispatch(clearErrors());
  axios
    .post("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-hrt-dashboard-create-offer-candidates-post-update", updateRoleCandidate)
    .then(res => {
      const roleCandidateInsertData = res.data;
      if (res.data) {
        axios
          .get('https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-hrt-dashboard-create-offer-candidates-get?new_hire_id=' + new_hire_id + '&hr_id=' + hr_id)
          .then(res => {
            const CandidateListData = res.data.response;
            const CandidateList = []
            for (let i = 0; i < CandidateListData.length; i++) {
              CandidateList.push(CandidateListData[i])
            }
            dispatch(setGetAllCandidatesFromOfferDashboard(CandidateList));
          })
          .catch(err => {
            console.log(err)
          });
      }
      dispatch(setInsertCandidates(roleCandidateInsertData));
      if (history.location.pathname === '/home/estimate-dashboard') {
        history.push("/home/estimate-dashboard?user_id=" + new_hire_id);
      }
    })
    .catch(err => {
      console.log(err)
    }
    );
};


export const FnInsertRoleCandidates = (insertRoleCandidate, history, new_hire_id) => dispatch => {
  dispatch(clearErrors());
  axios
    .post("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-hrt-role-candidate-post-insert", insertRoleCandidate)
    .then(res => {
      const roleCandidateInsertData = res.data;
      if (res.data) {
        axios
          .get('https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-hrt-dashboard-create-offer-candidates-get?new_hire_id=' + insertRoleCandidate.new_hire_id + '&hr_id=' + insertRoleCandidate.hr_id)
          .then(res => {
            const CandidateListData = res.data.response;
            const CandidateList = []
            for (let i = 0; i < CandidateListData.length; i++) {
              CandidateList.push(CandidateListData[i])
            }
            dispatch(setGetAllCandidatesFromOfferDashboard(CandidateList));
          })
          .catch(err => {
            console.log(err)
          });

      }
      dispatch(setInsertCandidates(roleCandidateInsertData));
      if (history.location.pathname === '/home/estimate-dashboard') {
        history.push("/home/estimate-dashboard?user_id=" + new_hire_id);
      } else {
        history.push("/home/add-candidate?id=" + new_hire_id)
      }
    })
    .catch(err => {
      console.log(err)
    }
    );
};

export function fnGetRoleCandidateMember(gerRoleCandidateData) {
  return axios
    .get('https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-hrt-role-candidate-get-form?role_candidate_id=' + gerRoleCandidateData.role_candidate_id + '&new_hire_id=' + gerRoleCandidateData.new_hire_id)
}


export const FnUpdateRoleCandidateMembers = (updateRoleCandidate, history, new_hire_id) => dispatch => {
  dispatch(clearErrors());

  axios
    .post("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-hrt-role-candidate-post-update", updateRoleCandidate)
    .then(res => {
      const roleCandidateUpdateData = res.data;
      dispatch(setInsertCandidates(roleCandidateUpdateData));
      history.push("/home/add-candidate?id=" + new_hire_id)
    })
    .catch(err => {
      console.log(err)
    }
    );
};
//UserProfile
export const setInsertCandidates = (users) => {
  return {
    type: SET_CURRENT_CANDIDATE_MEMBERS,
    payload: users
  };
};

export const setGetAllRoleCandidates = (users, roleInfo) => {
  return {
    type: GET_ROLE_CANDIDATE,
    payload: users,
    roleInfo: roleInfo
  };
};

export const setGetAllCandidatesFromOfferDashboard = (users) => {
  return {
    type: GET_CANDIDATE_FROM_OFFER_DASHBOARD,
    payload: users
  };
};

// clear errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS
  };
};