import React, { Component } from 'react';
import logo from "../../img/menu-logo-white.png";
import { loginUser } from "../../actions/authAction";
import { Link } from "react-router-dom";
import { Input } from '@progress/kendo-react-inputs';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import '../../css/login.css';
import FormValidator from '../../validation/FormValidator';
import validationParams from '../../validation/validationParams.json';
import Carousel from './carousel'
import Spinner from "../spinner/loading"

class LoginClass extends Component {
  constructor(props) {
    super(props);
    this.validator = new FormValidator(validationParams.loginValidations);
    this.state = {
      email: "",
      password: "",
      pwd_message: "",
      loading: false,
      validation: this.validator.valid()
    };
    this.onChange = this.onChange.bind(this);
    this.loginSubmit = this.loginSubmit.bind(this);
  }
  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
    this.props.auth.userName = ""

    if (e.target.name === "password") {
      if (e.target.value.length >= 8) {
        this.state.pwd_message = ""
      } else {
        this.state.pwd_message = "Password must contain at least Eight characters"
      }
    }
  };


  loginSubmit = (event) => {
    event.preventDefault();
    const userData = {
      email: this.state.email,
      password: this.state.password
    };
    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;
    if (validation.isValid) {
      this.state.loading = true
      this.props.loginUser(userData, this.props.history);
    }

  }
  linkedinRequest = () => {
    window.location.replace('https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/linkedin', 'linkedIn',
      'width=500,height=500,scrollbars=no');

  }

  render() {
    let validation = this.submitted ?
      this.validator.validate(this.state) :
      this.state.validation

      if (this.props.auth.isAuthenticated === false && this.props.auth.userName === "Please enter correct password") {
        this.state.loading = false
      }

      if (this.props.auth.isAuthenticated === false && this.props.auth.userName === "This email is not existing") {
        this.state.loading = false
      }

    let copySuccess = null;
    if (this.props.auth.isAuthenticated === false) {
      if (this.props.auth.userName !== "") {
        copySuccess = (
          <p className="heading" style={{ paddingLeft: "60px", color: "red" }}>{this.props.auth.userName}</p>
        );
      }
    }

    return (
      <div className="container-fluid landing">
        <div className="row">
          <div className="col-sm-9 col-md-9 col-xs-9 col-lg-9">
            {/* <Carousel /> */}
          </div>
          <div className="col-sm-3 col-md-3 col-xs-3 col-lg-3"
            style={{ backgroundColor: "rgba(255,255,255,.85)" }}>

            <div className="">
              <div className="">
                <img
                  ref={(image) => this.image = image}
                  src={logo}
                  alt="Empty"
                  style={{
                    backgroundColor: "#3A4158",
                    marginBottom: "51px",
                    width: "80%",
                    margin: "40px 15px 15px 38px"
                  }}
                />
              </div>
              <div className="row">
                <div className="col-md-12 col-xs-12 col-sm-6">
                  <div className="">
                    <div className="card-block">
                      <form className="k-form" onSubmit={this.loginSubmit} >
                        <fieldset >
                          <span style={{
                            fontSize: "18px",
                            fontWeight: "400",
                            color: "#3c3b3b",
                            marginBottom: "-10px",
                            marginLeft: "-10px"
                          }}>Login:</span>
                          <div className="mb-3 input-wrapper-envelope">
                            <Input
                              name="email"
                              type="email"
                              style={{
                                borderStyle: "none",
                                paddingLeft: "40px",
                                borderRadius: "5px",
                                marginTop: "10px",
                                width: "105%",
                                height: "35px",
                                backgroundColor: "rgb(255, 255, 255)"
                              }}
                              onChange={this.onChange}
                              placeholder="Email address"

                            />
                            <div style={{
                              color: "red",
                            }}>
                              {validation.email.message}
                            </div>

                          </div>
                          <br />
                          <div className="mb-3 input-wrapper-lock" style={{ marginTop: "10px" }}>
                            <Input
                              name="password"
                              type="password"
                              style={{
                                borderStyle: "none",
                                paddingLeft: "40px",
                                borderRadius: "5px",
                                width: "105%",
                                height: "35px",
                                backgroundColor: "rgb(255, 255, 255)"
                              }}
                              placeholder="Password"
                              onChange={this.onChange}
                              minLength={8}
                              maxLength={18}
                            />
                            <div style={{
                              color: "red",
                              paddingLeft: "5px"
                            }}>
                              {validation.password.message}
                            </div>

                          </div>
                          {copySuccess}
                        </fieldset>
                        <div style={{ marginTop: "-65px" }}>
                          {this.state.loading === true ? <Spinner /> : null}
                          <input
                            type="submit"
                            className="react-new-login-btn angular-new-login-btn-primary"
                            style={{ width: "105%" }} value="LOGIN" />
                        </div>
                        <Link
                          onClick={this.linkedinRequest}
                          to="#"
                          className="k-button"
                          style={{
                            height: "40px",
                            width: "105%",
                            borderRadius: "4px",
                            textTransform: "capitalize"
                          }}
                          id="linked-in-button">
                          <span className="k-icon k-i-linkedin" id="linked-in-icon"
                            style={{ marginLeft: "-40px" }} >
                          </span>
                          <div style={{
                            borderLeft: "1px solid #fff",
                            height: "40px",
                            marginBottom: "0px"
                          }}>
                          </div>
                          <span className="linked-in-font-name"
                            style={{
                              marginLeft: "10px",
                              fontWeight: "500", verticalAlign: "middle",
                            }} >
                            Login with LinkedIn
                    </span>
                        </Link>
                        <div className="" >
                          <Link to="/forgot-password" className="forgot-password">Forgot Password?</Link>
                        </div>
                        <div> <p className="paragraph-account" style={{ color: "#777", fontWeight: "lighter" }}> Need an account?<Link style={{ color: "#22aae4", textDecoration: "none", fontWeight: "500" }} to="/register">   Sign up</Link></p></div>

                        <p className="text-muted"> Apollo is committed to securing and protecting your information. Find our
          privacy policy <Link to="/register" style={{ color: "#22aae4" }}> here</Link>. </p>
                        <p className="text-muted"> © Apollo, 2019. </p>
                      </form>
                    </div>
                  </div>
                </div>
              </div >
              {
                this.state.success && (
                  <div
                    className="alert alert-success"
                    style={{ position: 'absolute' }}
                  >
                    Form submitted!
                </div>)
              }
            </div >
          </div>
        </div>
      </div >
    );
  }


}
LoginClass.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state =>
  ({
    auth: state.auth,
    errors: state.errors
  });
export default connect(mapStateToProps, { loginUser }
)(LoginClass);