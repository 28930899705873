import React from 'react'
import Sidebar from 'react-sidebar';
import Header from './Header.js'
import DashBoard from "../dashboard/Dashboard.js"
import SidebarContent from './SidebarContent';
import RolesGrid from '../roles/ListRoles.js';
import HRGrid from '../hiringteam/HR/AddHRGrid'
import AddnewHRform from '../hiringteam/HR/AddHRForm';
import CandidateGrid from '../hiringteam/Candidate/AddCandidateGrid';
import OfferCandidateGrid from '../../components/dashboard/companyDashboard/offerdashboard/offerDashboardCandidateGrid';
import AddnewCandiadateform from '../hiringteam/Candidate/AddCandidateForm';
import MyJobProfileform from '../hiringteam/Candidate/MyJobProfile';
import DesireOffer from '../hiringteam/Candidate/OfferUpdateform';
import NewRole from '../roles/CreateRoles.js';
import MyProfile from '../profile/Profile.js';
import UpdateRole from '../roles/UpdateRole.js';
import changePassword from '../authentication/ChangePassword.js';
import PrivateRoute from "../privateroute/PrivateRouter";
import PowerUserDashboard from "../dashboard/powerUserDashboard/powerUserDashBoard.js"
import MyOfferGrid from '../hiringteam/Candidate/MyOffers.js';
import CandidateDashboard from '../dashboard/CandidateDashboard.js';
import RateMyOfferDashBoard from "../dashboard/rateMyOfferDashboard/rateMyOfferDashboard.js"
import ExploreDashBoard from "../dashboard/exploreDashboard/ExploreDashboard.js"
import LookupTitlefunction from "../admin/lookup_title_function";
import LookupMarketSpacegrid from "../admin/lookup_market_space";
import LookupCompanygrid from '../admin/lookup_company.js';
import LookupCompanyform from '../admin/company_lookup_form';
import LookupTitleform from '../admin/lookup_title_form';
import LookupFunctiongrid from '../admin/lookup_function.js';
import LookupEducationSchoolPay from '../admin/lookup_education_school_pay'
import LookupEducation from '../admin/lookup_education'
import PersonalDashboard from "../dashboard/personalDashboard/personalDashboard.js"

export class SidebarToggle extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sidebarOpen: false
        };
        this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
    }

    onSetSidebarOpen() {
        this.setState({ sidebarOpen: !this.state.sidebarOpen });
    }

    render() {

        return (
            <Sidebar
                sidebar={<SidebarContent openSideBar={this.onSetSidebarOpen} />}
                open={this.state.sidebarOpen}
                docked={this.state.sidebarOpen}
                styles={{ sidebar: { background: "rgb(59, 65, 86)" } }}
                sidebarClassName="sidebar"
            >
                <Header openSideBar={this.onSetSidebarOpen} sidebarOpen={this.state.sidebarOpen} />
                <PrivateRoute exact path='/home/estimate-dashboard' component={DashBoard} />
                <PrivateRoute exact path='/home/role' component={RolesGrid} />
                <PrivateRoute exact path='/home/add-hr' component={HRGrid} />
                <PrivateRoute exact path='/home/add-hr-form/:id' component={AddnewHRform} />
                <PrivateRoute exact path='/home/update-hr-form/:new_hire_id/:id' component={AddnewHRform} />
                <PrivateRoute exact path="/home/delete-hiring-member" component={HRGrid} />
                <PrivateRoute exact path='/home/add-candidate' component={CandidateGrid} />
                <PrivateRoute exact path='/home/add-candidate-form/:id' component={AddnewCandiadateform} />
                <PrivateRoute exact path='/home/update-candidate-form/:new_hire_id/:role_candidate_id' component={AddnewCandiadateform} />
                <PrivateRoute exact path="/home/delete-role-candidate" component={CandidateGrid} />
                <PrivateRoute exact path="/home/offer-dashboard-del" component={OfferCandidateGrid} />
                <PrivateRoute exact path='/home/my-job-profile' component={MyJobProfileform} />
                <PrivateRoute exact path='/home/my-offers' component={MyOfferGrid} />
                <PrivateRoute exact path='/home/candidate-dashboard' component={CandidateDashboard} />
                <PrivateRoute exact path='/home/add-offer/:id/:companyOfferId' component={DesireOffer} />
                <PrivateRoute exact path='/home/offer-accept/:id/:companyOfferId' component={MyOfferGrid} />
                <PrivateRoute exact path='/home/offer-reject/:id/:companyOfferId' component={MyOfferGrid} />
                <PrivateRoute exact path='/home/new-role' component={NewRole} />
                <PrivateRoute exact path='/home/update-role' component={UpdateRole} />
                <PrivateRoute exact path="/home/delete-role" component={RolesGrid} />
                <PrivateRoute exact path='/home/change-password' component={changePassword} />
                <PrivateRoute exact path='/home/my-profile' component={MyProfile} />
                <PrivateRoute exact path='/home/power-user-dashboard' component={PowerUserDashboard} />
                <PrivateRoute exact path='/home/my-offer-dashboard' component={RateMyOfferDashBoard} />
                <PrivateRoute exact path='/home/explore-dashboard' component={ExploreDashBoard} />
                <PrivateRoute exact path='/home/lookup-titlefunction' component={LookupTitlefunction} />
                <PrivateRoute exact path='/home/lookup-marketspace' component={LookupMarketSpacegrid} />
                <PrivateRoute exact path='/home/lookup-company' component={LookupCompanygrid} />
                <PrivateRoute exact path='/home/delete-lookup-companyform' component={LookupCompanygrid} />
                <PrivateRoute exact path='/home/lookup-companyform' component={LookupCompanyform} />
                <PrivateRoute exact path='/home/update-lookup-companyform' component={LookupCompanyform} />
                <PrivateRoute exact path='/home/lookup-titleform' component={LookupTitleform} />
                <PrivateRoute exact path='/home/lookup-function' component={LookupFunctiongrid} />
                <PrivateRoute exact path='/home/lookup-school' component={LookupEducationSchoolPay} />
                <PrivateRoute exact path='/home/lookup-education' component={LookupEducation} />
                <PrivateRoute exact path='/home/personal-dashboard' component={PersonalDashboard} />
            </Sidebar>
        )
    }
}
