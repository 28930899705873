import {
  SET_CURRENT_HIRING_TEAM_MEMBERS,
  HIRING_TEAM_MEMBERS_LOADING,
  SET_INSERT_UPDATE_HIRING_TEAM_MEMBERS,
  GET_HIRING_TEAM_MEMBERS,
  GET_SAVED_OFFERS_FROM_CREATE_OFFER_DASHBOARD,
  GET_RECOMMENDED_OFFER_VALUE,
  SET_SAVE_OFFER_FLAG_OFFER_DASHBOARD,
  GET_OFFER_STRENGTH_VALUE
} from "../actions/types";

const initialState = {
  hiringMembers: {},
  offersData: {},
  recommendedValue: null,
  offerStrengthValue: {},
  roleData: {},
  hiringMembersData: null,
  RoleHrSuccessData: null,
  saveOfferFlag: null,
  loading: false
};

export default function (state = initialState, action) {

  switch (action.type) {
    case HIRING_TEAM_MEMBERS_LOADING:
      return {
        ...state,
        loading: true
      };
    case SET_CURRENT_HIRING_TEAM_MEMBERS:
      return {
        ...state,
        hiringMembers: action.payload,
        loading: true
      };
    case GET_HIRING_TEAM_MEMBERS:
      return {
        ...state,
        hiringMembersData: action.payload,
        loading: true
      };
    case SET_INSERT_UPDATE_HIRING_TEAM_MEMBERS:
      return {
        ...state,
        RoleHrSuccessData: action.payload,
        loading: true
      };
    case GET_SAVED_OFFERS_FROM_CREATE_OFFER_DASHBOARD:
      return {
        ...state,
        offersData: action.payload,
        roleData: action.roleData,
        loading: true
      };
      case GET_RECOMMENDED_OFFER_VALUE:
      return {
        ...state,
        recommendedValue: action.payload,
        loading: true
      };
      case GET_OFFER_STRENGTH_VALUE:
      return {
        ...state,
        offerStrengthValue: action.payload,
        loading: true
      };
      case SET_SAVE_OFFER_FLAG_OFFER_DASHBOARD:

      return {
        ...state,
        saveOfferFlag: action.payload,
        loading: false
      };
    default:
      return state;
  }
}
