import React from 'react'
import '../../../../css/dashboard.css';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { NumericTextBox } from '@progress/kendo-react-inputs';

class targetPatDashboardBody extends React.Component {
  anchor = null;
  constructor(props) {
    super(props);
    this.state = {
      selected: 0,
      base_percentile: 64
    };
  }

  targetRatingChanges = (value) => {
    let donutPercent = this.state.base_percentile;
    if (donutPercent === 101) {
      donutPercent = 100
    }
    if (donutPercent >= 1 && donutPercent <= 100) {
      if (value === "target_up") {
        console.log(donutPercent)
        if (donutPercent === 100) {
          this.setState({
            base_percentile: parseInt(donutPercent)
          });
        } else {
          this.setState({
            base_percentile: parseInt(donutPercent) + 1
          });
        }

      } else if (value === "target_down") {
        console.log(donutPercent)
        if (donutPercent === 1) {
          this.setState({
            base_percentile: parseInt(donutPercent)
          });
        } else {
          this.setState({
            base_percentile: parseInt(donutPercent) - 1
          });
        }
      }
    }

  }



  render() {
    return (
      <div className="col-md-12 target_pay_rating_circle_div">
        <div className="target_pay_rating_circle_div2">
          <i className="fa fa-caret-up personal_dashboard_up_down" aria-hidden="true" onClick={() => this.targetRatingChanges('target_up')}></i>
          <div style={{ color: "#fff", fontSize: "15px" }}>
            <b style={{ fontSize: "28px" }}>{this.state.base_percentile}</b>/100
          </div>
          <i className="fa fa-caret-down personal_dashboard_up_down" aria-hidden="true" onClick={() => this.targetRatingChanges('target_down')}></i>
        </div>
        <div style={{ color: "rgba(0,0,0,0.65)" }}>Target Rating</div>
        <div className="row col-md-12">
          <div className="col-md-4 col-sm-12 col-xs-12 col-lg-4" style={{ marginLeft: "15px" }}></div>
          <div className="col-md-2 col-sm-12 col-xs-12 col-lg-2 pull-right" style={{ display: "flex", paddingRight: "10px" }}>
            <div className="target_pay_rating_circle_div3" style={{ margin: "0px", paddingRight: "0px" }}>
              <div className="target_pay_rating_circle_inside_div1">
                Total
              </div>
              <div className="target_pay_rating_circle_inside_div2">$300,000</div>
            </div>
            <div className="target_pay_rating_circle_arrow_div">
              <div className="target_pay_rating_circle_arrow_div2" >
              </div>
              <i className="fa fa-caret-down" aria-hidden="true" style={{ color: "red", fontSize: "25px", marginTop: "0px", paddingTop: "0px" }}></i>
            </div>
          </div>
          <div className="col-md-2 col-sm-12 col-xs-12 col-lg-2" style={{ display: "flex", paddingLeft: "19px" }}>
            <div className="target_pay_rating_circle_div4" style={{ margin: "0px" }}>
              <div className="target_pay_rating_circle_inside_div1">
                Cash
              </div>
              <div className="target_pay_rating_circle_inside_div2">$300,000</div>
            </div>
            <div style={{ marginTop: "10px", marginLeft: "6px", width: "10px" }}>
              <i className="fa fa-caret-up" aria-hidden="true" style={{ color: "green", fontSize: "25px", paddingTop: "0px", marginTop: "10px" }}></i>
              <div className="target_pay_rating_circle_right_arrow_div">
              </div>

            </div>
          </div>
        </div>

        <div className="row col-md-12" style={{ marginTop: "50px", marginBottom: "30px" }}>
          <div className="col-md-3"></div>
          <div className="row col-md-6" style={{ marginLeft: "15px" }}>
            <div className="col-md-4 col-sm-12 col-xs-12 col-lg-4" style={{ paddingRight: "0px" }}>
              <NumericTextBox
                name="Base"
                width="100%"
                onChange={this.onChange}
                value={this.state.equity_rsu_total}
                label="Base"
              />
            </div>
            <div className="col-md-4 col-sm-12 col-xs-12 col-lg-4" style={{ paddingRight: "0px" }}>
              <NumericTextBox
                name="Bonus"
                width="100%"
                onChange={this.onChange}
                value={this.state.equity_rsu_total}
                label="Bonus"
              />
            </div>
            <div className="col-md-4 col-sm-12 col-xs-12 col-lg-4" style={{ paddingRight: "0px" }}>
              <NumericTextBox
                name="annual_equity"
                width="100%"
                onChange={this.onChange}
                value={this.state.equity_rsu_total}
                label="Annual Equity %"
              />
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>
    )
  }
}

targetPatDashboardBody.propTypes = {
  auth: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  hiringMembers: PropTypes.object.isRequired
};

const mapStateToProps = state =>
  ({
    lookups: state.lookups,
    auth: state.auth,
    estimateDashboard: state.estimateDashboard
  });
export default connect(mapStateToProps, {}
)(targetPatDashboardBody);
