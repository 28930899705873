import React from 'react'
import '../../../css/dashboard.css';
import { connect } from "react-redux";
import 'hammerjs';
import { inputValueAction, offerDashDialogBoxAction } from "../../../actions/dashboard";
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Input } from '@progress/kendo-react-inputs';
import FormValidator from '../../../validation/FormValidator';
import validationParams from '../../../validation/validationParams.json';
import { DropDownList, ComboBox, AutoComplete } from '@progress/kendo-react-dropdowns';
import { marketSpaceDataSort, stageDataSort, regionDataSort} from "../../dropdown/lookupSortingData";
import { fnInsertLookupCompany } from "../../../actions/adminLookupCompanyDetails";

export class OfferDashOfferSave extends React.Component {
  constructor(props) {
    super(props);
    this.validator = new FormValidator(validationParams.powerUserAddCompanyNameValidations);
    var regionLkpData = this.props.regionLookupData.filter((el, index) =>
    this.props.regionLookupData.indexOf(el) === index
    );
    this.state = {
      visible: true,
      company_name: this.props.companyValue,
      last_name: "",
      email: "",
      marketSpaceLookupData:this.props.marketSpaceLookupData,
      stageLookupData: this.props.stageLookupData,
      regionLookupData: regionLkpData.sort().filter(function (el) {
        return el != "";
    }),
      validation: this.validator.valid()
      
    }
    this.submitted = false;
    var names = [];
    console.log("this.props.regionLookupData")
    console.log(this.props.regionLookupData.filter((el, index) =>
    this.props.regionLookupData.indexOf(el) === index
    ))
  console.log(names)
    console.log("this.props.regionLookupData")
  }
  AddNewCompanySubmit = (event) => {
    event.preventDefault();
    const companyDetail = {
      company: this.state.company_name,
      stage: this.state.company_stage,
      region: this.state.company_region,
      location: this.state.company_region,
      location_state: this.state.company_region,
      location_city: this.state.company_region,
      city_state: this.state.company_region,
      marketspace: this.state.company_market_space
    }
    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;
    if (validation.isValid) {
      this.props.fnInsertLookupCompany(companyDetail, this.props.history, "profile")
      this.props.offerDashDialogBoxAction(this.state.visible, false)
    }
  }
  toggleDialog = () => {
    this.setState({
      visible: !this.state.visible
    });
    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    if (this.state.validation.company_name.message === "Company Name is required.") {
      this.state.validation.company_name.message = ""
    }
    this.setState({
      change_field: false,
      company_name: this.props.companyValue
    })
    this.submitted = false;
    this.props.offerDashDialogBoxAction(this.state.visible, false)
    
  }
  onChange = e => {
    const value = e.target.value;

    this.setState({
      [e.target.name]: value
    });
    if (e.target.name === "company_name") {
      this.setState({
        change_field: true
      })
    }
  }
  onFilterChange = (event) => { 
    var changeRegionLkpData = this.props.regionLookupData.filter((el, index) =>
    this.props.regionLookupData.indexOf(el) === index
    );
    this.setState({
    marketSpaceLookupData: marketSpaceDataSort(event.filter.value, this.props.marketSpaceLookupData),
    stageLookupData: stageDataSort(event.filter.value, this.props.stageLookupData),
    regionLookupData: regionDataSort(event.filter.value,
      changeRegionLkpData.sort())
  }) }
  render() {
    if (this.props.estimateDashboard.togglename === "new_company") {
      this.state.visible = true
    } else {
      this.state.visible = false
    }

    let validation = this.submitted ?
      this.validator.validate(this.state) :
      this.state.validation

    return (
      <div>
        {this.state.visible && <Dialog className="div-modal dialog-font-size" title={"Add a Company"} onClose={this.toggleDialog}>
          <p style={{ margin: "25px", fontSize: "16px" }}>Please complete the form to add a new company!</p>
          <form action="" onSubmit={this.AddNewCompanySubmit} >
            <div className="offer-save-dialog-input-div" style={{ paddingLeft: "25px", marginBottom: "0px" }}>

              <div className="col-md-12 col-sm-11 col-xs-11 col-lg-12 offer-save-input-field" style={{ marginBottom: "0px" }}>
                <Input
                  name="company_name"
                  type="text"
                  style={{ width: '100%', color: "rgba(0,0,0,0.87)" }}
                  onChange={this.onChange}
                  label="Company Name*"
                  value={this.state.company_name}
                /> 

                <div style={{ color: "red", paddingLeft: "0px" }}>
                  {validation.company_name.message}
                </div>
              </div>

              <div className="mb-5 col-md-12 col-sm-11 col-xs-11 col-lg-12 offer-save-input-field" style={{ marginTop: "10px", paddingTop: "30px" }}>
                <ComboBox
                  name="company_market_space"
                  onChange={this.onChange}
                  data={this.state.marketSpaceLookupData}
                  value={this.state.company_market_space}
                  style={{ width: '100%' }}
                  label="MarketSpace"
                  onFilterChange={this.onFilterChange}
                  filterable={true}
                />

              </div>
              <div style={{ display: "flex", paddingBottom: "0px" }}>
                <div className="mb-3 col-md-6 col-sm-11 col-xs-11 col-lg-6 offer-save-input-field" style={{ marginTop: "10px", marginBottom: "0px" }}>
                  <ComboBox
                    type="text"
                    name="company_stage"
                  value={this.state.company_stage}
                    style={{ width: '100%' }}
                    onChange={this.onChange}
                    label="Stage"
                    data={this.state.stageLookupData}
                    onFilterChange={this.onFilterChange}
                    filterable={true}
                  />
                </div>
                <div className="mb-3 col-md-6 col-sm-11 col-xs-11 col-lg-6 offer-save-input-field" style={{ marginTop: "10px", marginBottom: "0px" }}>
                  <ComboBox
                    type="text"
                    name="company_region"
                    value={this.state.company_region}
                    style={{ width: '100%' }}
                    onChange={this.onChange}
                    label="Location"
                    data={this.state.regionLookupData}
                    onFilterChange={this.onFilterChange}
                    filterable={true}
                    
                  />
                </div>
              </div>
              <div> <p className="md-caption"> We'll review and notify if we need more information</p></div>
            </div>

            <div className="power-dash-save-buttons-div">
              <DialogActionsBar >
                <button className="k-button col-xs-6 col-sm-6" onClick={this.toggleDialog}>CANCEL</button>
                <button type="submit" className="k-button col-xs-6 col-sm-6" >SAVE</button>
              </DialogActionsBar>
            </div>
          </form>


        </Dialog>}
      </div >

    )
  }
}
const mapStateToProps = state =>
  ({
    estimateDashboard: state.estimateDashboard,
  });
export default connect(mapStateToProps, { inputValueAction, offerDashDialogBoxAction, fnInsertLookupCompany }
)(OfferDashOfferSave);
