import axios from "axios";
import {
  SET_CURRENT_HIRING_TEAM_MEMBERS,
  SET_INSERT_UPDATE_HIRING_TEAM_MEMBERS,
  GET_HIRING_TEAM_MEMBERS,
  GET_SAVED_OFFERS_FROM_CREATE_OFFER_DASHBOARD,
  GET_RECOMMENDED_OFFER_VALUE,
  GET_OFFER_STRENGTH_VALUE,
  SET_SAVE_OFFER_FLAG_OFFER_DASHBOARD,
  CLEAR_ERRORS
} from "./types";


export const fnGetAllHiringTeamMembers = (data) => dispatch => {
  dispatch(clearErrors());

  axios
    .get("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-hrt-role-hr-members-get-grid?hr_id=" + data.hr_id + '&new_hire_id=' + data.new_hire_id)
    .then(res => {
      const HiringMembersListData = res.data.response;
      const HiringMembersList = []
      for (let i = 0; i < HiringMembersListData.length; i++) {
        HiringMembersList.push(HiringMembersListData[i])
      }
      dispatch(setGetAllHiringTeamMembers(HiringMembersList));
    })
    .catch(err => {
      console.log(err)
    }
    );
};

export const FnInsertHiringTeamMembers = (insertRoleHr, history, new_hire_id) => dispatch => {
  dispatch(clearErrors());

  axios
    .post("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-hrt-role-hr-member-post-insert", insertRoleHr)
    .then(res => {
      const roleHrInsertData = res.data;
      dispatch(setInsertHiringTeamMember(roleHrInsertData));
      if(history === "offer_dashboard_invite"){
        console.log(history)
      }else{
        history.push("/home/add-hr?id=" + new_hire_id)
      }
      
    })
    .catch(err => {
      console.log(err)
    }
    );
};


export function fnGetRoleHiringTeamMember(gerRoleHRData) {
  return axios
    .get('https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-hrt-role-hr-members-get-form?id=' + gerRoleHRData.id + '&new_hire_id=' + gerRoleHRData.new_hire_id)
}

export function fnDeleteHiringTeamMember(data) {
  return axios
    .delete("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-hrt-role-hiring-team-del?invitee_hiring_member_id="+data.invitee_hiring_member_id+"&new_hire_id="+data.new_hire_id)
}


export const FnUpdateHiringTeamMembers = (insertRoleHr, history, new_hire_id) => dispatch => {
  dispatch(clearErrors());

  axios
    .post("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-hrt-role-hiring-team-post-update", insertRoleHr)
    .then(res => {
      const roleHrUpdateData = res.data;
      dispatch(setInsertHiringTeamMember(roleHrUpdateData));
      history.push("/home/add-hr?id=" + new_hire_id)
    })
    .catch(err => {
      console.log(err)
    }
    );
};
//UserProfile
export const setCurrentHiringTeamMember = (users) => {
  return {
    type: SET_CURRENT_HIRING_TEAM_MEMBERS,
    payload: users
  };
};

//Insert Role HR
export const setInsertHiringTeamMember = (users) => {
  return {
    type: SET_INSERT_UPDATE_HIRING_TEAM_MEMBERS,
    payload: users
  };
};
//GetAllProfile
export const setGetAllHiringTeamMembers = (users) => {
  return {
    type: GET_HIRING_TEAM_MEMBERS,
    payload: users
  };
};

// Save offers from create offer dashboard
export function fnSaveOfferFromCreateOfferDashboard(userData) {
  return axios
    .post("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-hrt-role-create-offer-post-insert", userData)
}

// roleInsertFlag action
export const offerCandidateFlagAction = (name) => dispatch => {
  dispatch(clearErrors());
  dispatch(setOfferCandidateFlagAction(name));
};

// Get Recommended Value
export const fnFetRecommendedValue = (queryData) => dispatch => {
  return axios
  .get("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-hrt-dashboard-create-offer-recommended-get?login_user_id=" + queryData.login_user_id + "&offer_id=" + queryData.offer_id + "&finalist_candidate_id=" + queryData.finalist_candidate_id)
  .then(response => {
    const RecommendedOfferValue = response.data.recommended;
    dispatch(setGetRecommendedOfferValue(RecommendedOfferValue));
  })
  .catch(err => {
    console.log(err)
  }
  );
};

// Get Offer strength Value
export const fnFetOfferStrengthValue = (queryData) => dispatch => {
  return axios
  .get("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-hrt-dashboard-create-offer-strength-get?login_user_id=" + queryData.login_user_id + "&offer_id=" + queryData.offer_id + "&finalist_candidate_id=" + queryData.finalist_candidate_id)
  .then(response => {
    const OfferStrengthValue = response.data.offer_strength;
    dispatch(setGetOfferStrengthValue(OfferStrengthValue));
  })
  .catch(err => {
    console.log(err)
  }
  );
};

// Get 

export const fnGetSavedCandidateOffers = (data) => dispatch => {
  dispatch(clearErrors());

  axios
    .get("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-hrt-role-create-offer-candidates-get?hiring_role_id=" + data.hiring_role_id + "&hr_id=" + data.hr_id)
    .then(res => {
      const OffersDataList = res.data.response;
      const HiringRoleData = res.data.hiring_role;
      const OffersList = []
      for (let i = 0; i < OffersDataList.length; i++) {
        OffersList.push(OffersDataList[i])
      }
      dispatch(setGetAllSavedOffers(OffersList, HiringRoleData));
    })
    .catch(err => {
      console.log(err)
    }
    );
};

//GetAllProfile
export const setGetAllSavedOffers = (OffersList, HiringRoleData) => {
  return {
    type: GET_SAVED_OFFERS_FROM_CREATE_OFFER_DASHBOARD,
    payload: OffersList,
    roleData: HiringRoleData
  };
};


//GetAllProfile
export const setGetRecommendedOfferValue = (RecommendedOfferValue) => {
  return {
    type: GET_RECOMMENDED_OFFER_VALUE,
    payload: RecommendedOfferValue
  };
};

//Get Offer Strength value
export const setGetOfferStrengthValue = (OfferStrengthValue) => {
  return {
    type: GET_OFFER_STRENGTH_VALUE,
    payload: OfferStrengthValue
  };
};

// set flag for saved offer candidate name
export const setOfferCandidateFlagAction = (payload) => {

  return {
    type: SET_SAVE_OFFER_FLAG_OFFER_DASHBOARD,
    payload: payload
  };
};

// clear errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS
  };
};