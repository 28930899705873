import React from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Grid, GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
import { orderBy } from '@progress/kendo-data-query';
import { MyCommandCell } from './actioneditbutton.js';
import { filterBy } from '@progress/kendo-data-query';
import { 
    fnGetLookupAllEducation,
    fnInsertLookupAllEducation,
    fnUpdateLookupAllEducation,
    fnDeleteLookupAllEducation
} from "../../actions/adminLookupEducation";
import Spinner from "../spinner/loading";

class LookupEducation extends React.Component {
  editField = "inEdit";
  CommandCell;


  constructor(props) {
    super(props);

    this.state = {
      data: [],
      action: "",
      filter: undefined,
      skip: 0,
      take: 8,
      loading: true,
    };
    this.CommandCell = MyCommandCell({
      edit: this.enterEdit,
      remove: this.remove,

      add: this.add,
      discard: this.discard,

      update: this.update,
      cancel: this.cancel,

      editField: this.editField
    });

  }
  filterChange = (event) => {
    this.setState({
        data: filterBy(this.props.adminLookupEducation.adminLookupEducationdata, event.filter),
        filter: event.filter
    });
}

  enterEdit = (dataItem) => {
    this.state.action = "action"

    this.setState({
      data: this.state.data.map(item =>
        item._id === dataItem._id ?
          { ...item, inEdit: true } : item
      )
    });
  }

  remove = (dataItem) => {
    this.state.action = "action"
    const data = [...this.state.data];
    this.props.    fnDeleteLookupAllEducation(dataItem["_id"])

    this.removeItem(data, dataItem);
   this.removeItem(this.props.adminLookupEducation.adminLookupEducationdata, dataItem);

    this.setState({ data });
  }

  add = (dataItem) => {
    this.state.action = "actionssss"
    dataItem.inEdit = undefined;

    const insertEducationData = {
        major:  dataItem["major"],
        Experience_0_5_years:  dataItem["Experience_0_5_years"],
        Experience_10_20_years: dataItem["Experience_10_20_years"],
        experience_20_plus_years: dataItem["experience_20_plus_years"],
        Experience_5_10_years: dataItem["Experience_5_10_years"],
        degree_type: dataItem["degree_type"],
        early_career_median_pay: dataItem["early_career_median_pay"],
        mid_career_median_pay: dataItem["mid_career_median_pay"],
        relative_capital_score: dataItem["relative_capital_score"],
        experience_score_0_5: dataItem["experience_score_0_5"],
        experience_score_5_10: dataItem["experience_score_5_10"],
        experience_score_10_20: dataItem["experience_score_10_20"],
        experience_score_20_plus: dataItem["experience_score_20_plus"],
        source: dataItem["source"]
    }
    this.props.fnInsertLookupAllEducation(insertEducationData)
    this.props.fnGetLookupAllEducation();
    dataItem._id = this.generateId(this.props.adminLookupEducation.adminLookupEducationdata);

    this.props.adminLookupEducation.adminLookupEducationdata.unshift(dataItem);
    this.setState({
      data: [...this.state.data]
    });
  }

  discard = (dataItem) => {
    this.state.action = "action"
    const data = [...this.state.data];
    this.removeItem(data, dataItem);
    this.setState({ data });
  }

  update = (dataItem) => {
    this.state.action = "action"
    const data = [...this.state.data];
    const updatedItem = { ...dataItem, inEdit: undefined };

    const updateEducationData = {
      education_major_pay_id: dataItem["_id"],
      major:  dataItem["major"],
      Experience_0_5_years:  dataItem["Experience_0_5_years"],
      Experience_10_20_years: dataItem["Experience_10_20_years"],
      experience_20_plus_years: dataItem["experience_20_plus_years"],
      Experience_5_10_years: dataItem["Experience_5_10_years"],
      degree_type: dataItem["degree_type"],
      early_career_median_pay: dataItem["early_career_median_pay"],
      mid_career_median_pay: dataItem["mid_career_median_pay"],
      relative_capital_score: dataItem["relative_capital_score"],
      experience_score_0_5: dataItem["experience_score_0_5"],
      experience_score_5_10: dataItem["experience_score_5_10"],
      experience_score_10_20: dataItem["experience_score_10_20"],
      experience_score_20_plus: dataItem["experience_score_20_plus"],
      source: dataItem["source"]

    }
    console.log("updateEducationData")
    console.log(updateEducationData)
    console.log("updateEducationData")
    this.props.fnUpdateLookupAllEducation(updateEducationData)
    this.updateItem(data, updatedItem);
    this.updateItem(this.props.adminLookupEducation.adminLookupEducationdata, updatedItem);
    this.setState({ data });
  }

  

  updateItem = (data, item) => {
    this.state.action = "action"
    let index = data.findIndex(p => p === item || (item._id && p._id === item._id));
    if (index >= 0) {
      data[index] = { ...item };
    }
  }
  cancel = (dataItem) => {
    this.state.action = "action"
    const originalItem = this.props.adminLookupEducation.adminLookupEducationdata.find(p => p._id === dataItem._id);
    const data = this.state.data.map(item => item._id === originalItem._id ? originalItem : item);

    this.setState({ data });
  }

  itemChange = (event) => {
    this.state.action = "action"
    const data = this.state.data.map(item =>
      item._id === event.dataItem._id ?
        { ...item, [event.field]: event.value } : item
    );
    this.setState({ data });
  }

  addNew = () => {
    const newDataItem = { inEdit: true, active: true };
    var daaa = [newDataItem, ...this.state.data]
    this.state.action = "action"
    this.setState({
      data: [newDataItem, ...this.state.data]
    });
  }

  cancelCurrentChanges = () => {
    this.state.action = "action"
    this.setState({ data: this.props.adminLookupEducation.adminLookupEducationdata });
  }


    pageChange = (event) => {
        this.setState({
          skip: event.page.skip,
          take: event.page.take
        });
      }

    componentDidMount() { 
    
        if (this.props.location.pathname === "/home/lookup-education") {
          this.props.fnGetLookupAllEducation();
        }
 
      }

    render() 
    {
    
      if (this.state.action === "action") {
        this.state.data = this.state.data
      } else {  
        
        if (this.state.filter != undefined) {
          this.state.data = this.state.data
              } else { 
                if (this.props.adminLookupEducation.adminLookupEducationdata != null) {
                  
                  const EducationList = this.props.adminLookupEducation.adminLookupEducationdata
          
                  const EducationData  = []
                  for (let i = 0; i <EducationList.length; i++) {
                    if (EducationList[i]['active'] === 1) {
                      EducationList[i]['active'] = true
                    }
                    if (EducationList[i]['active'] === 0) {
                      EducationList[i]['active'] = false
                    }
                    EducationData .push(EducationList[i])
                  }
                  this.state.data = EducationList
                  this.state.loading = false
                } else {
                  this.state.data = this.state.data
                }    
              }
            }
      const { data } = this.state;
      const hasEditedItem = data.some(p => p.inEdit);

  
            
        return (
          <div className="container-fluid role-main-div">
          {this.state.loading === true ? <Spinner /> : null}
          <Grid
            className="col-md-12 col-xs-12 col-lg-12 col-sm-12"
            // style={{ height: '420px' }}
            data={data}
            data={data.slice(this.state.skip, this.state.take + this.state.skip)}
            skip={this.state.skip}
            take={this.state.take}
            total={data.length}
            pageable={true}
            onPageChange={this.pageChange}
            onItemChange={this.itemChange}
            editField={this.editField}
            filterable={true}
            filter={this.state.filter}
            onFilterChange={this.filterChange}
          >
            <GridToolbar>
              <div className="row">
                <div className="col-md-6 col-xs-12 col-lg-6 col-sm-12" style={{ fontSize: "23px", color: "black", marginTop: "-10px" }}>             Degree</div>
                <div className="col-md-6 col-xs-12 col-lg-6 col-sm-12">
                  <button
                    title="Add new"
                    className="k-button k-danger"
                    onClick={this.addNew}
                    style={{ float: "right", border: "1px solid #3A4158" }}
                  >
                    <span className="k-icon k-i-plus"></span>
                  </button>
                  {hasEditedItem && (
                    <button
                      title="Cancel current changes"
                      className="k-button"
                      onClick={this.cancelCurrentChanges}
                      style={{ float: "right" }}
                    >
                      Cancel current changes
                          </button>
                  )}
                </div>
  
  
              </div>
              </GridToolbar>
  
          <Column width="250px" field="major"  title="MAJOR"  />
          <Column width="200px" field="Experience_0_5_years"  filterable={false} title="EXPERIENCE 0-5years" />
          <Column width="200px" field="Experience_10_20_years" filterable={false} title="EXPERIENCE 10-20years"   />
          <Column width="200px" field="experience_20_plus_years" filterable={false} title="EXPERIENCE 20plusyears"  />
          <Column width="200px" field="Experience_5_10_years" filterable={false} title="EXPERIENCE 5-10plusyears"  />
          <Column width="200px" field="degree_type"  filterable={false} title="DEGREE-TYPE" />
          <Column width="200px" field="early_career_median_pay" title="EARLY CAREER MEDIANPAY"  filterable={false}  />
          <Column width="200px" field="mid_career_median_pay" title="MID CAREER MEDIANPAY" filterable={false}  />
          <Column width="200px" field="relative_capital_score" title="RELATIVE CAPITAL SCORE"  filterable={false}  />
          <Column width="200px" field="experience_score_0_5" title="EXPERIENCE SCORE 0-5years"  filterable={false}  />
          <Column width="200px" field="experience_score_5_10" title="EXPERIENCE SCORE 5-10years"  filterable={false}  />
          <Column width="200px" field="experience_score_10_20" title="EXPERIENCE SCORE 10-20years"  filterable={false}  />
          <Column width="200px" field="experience_score_20_plus" title="EXPERIENCE SCORE 20plusyears"  filterable={false}  />
          {/* <Column width="200px" field="source" title="SOURCE"  filterable={false}  /> */}
          <Column width="200px" title="ACTION" filterable={false} cell={this.CommandCell} />
        </Grid>
      </div>          
        );
    }
    generateId = data => data.reduce((acc, current) => Math.max(acc, current._id), 0) + 1;

    removeItem(data, item) {
      let index = data.findIndex(p => p === item || item._id && p._id === item._id);
      if (index >= 0) {
        data.splice(index, 1);
  
      }
    }
  
}




LookupEducation.propTypes = {
    auth: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };
    
  const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors,
    adminLookupEducation: state.adminLookupEducation
  });
  
  export default
  connect(mapStateToProps, 
    { fnGetLookupAllEducation,
        fnInsertLookupAllEducation,
        fnUpdateLookupAllEducation,
        fnDeleteLookupAllEducation
      }
  ) (LookupEducation);
  