import React from 'react'
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout'
import '../../css/sidebar.css';
import { Link } from 'react-router-dom';
import menuLogo from "../../img/menu-logo-white.png";
import { withRouter } from 'react-router-dom';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authAction";
import { offerDashAddMemeberIconAction, offerDashRoleInsertFlagAction, offerDashRightSideDashboardAction } from "../../actions/dashboard";

class SidebarContent extends React.Component {
  onSetSidebarOpen() {
    this.props.openSideBar();

  }


  onSelect = (event) => {
    if (event.target.props.title === "Logout") {
      this.props.history.push(this.props.logoutUser(this.props.auth.user._id, this.props.history));
    }
    if (event.target.props.title === "Power USER") {
      this.props.offerDashRoleInsertFlagAction(0)
      this.props.offerDashAddMemeberIconAction(1)
    }
    if (event.target.props.title === "ESTIMATE PAY") {
      this.props.offerDashRoleInsertFlagAction(0)
      this.props.offerDashAddMemeberIconAction(0)
    }
    if (event.target.props.title === "CREATE OFFER") {
      this.props.offerDashRoleInsertFlagAction(0)
      this.props.offerDashAddMemeberIconAction(2)
      this.props.offerDashRightSideDashboardAction(0, 0, 0, '', '', '')
    }

    if (event.target.props.title === "TARGET PAY") {
      this.props.offerDashAddMemeberIconAction(0)
    }
    if (event.target.props.title === "RATE OFFER") {
      this.props.offerDashAddMemeberIconAction(1)
    }
    if (event.target.props.route && event.target.props.route !== "login") {
      this.props.history.push(event.target.props.route);
      if (event.target.props.title === "ESTIMATE PAY" ||
        event.target.props.title === "EXPLORE" ||
        event.target.props.title === "Power USER" ||
        event.target.props.title === "Candidate Dashboard" ||
        event.target.props.title === "CREATE OFFER" ||
        event.target.props.title === "ROLES" ||
        event.target.props.title === "PROFILE" ||
        event.target.props.title === "TARGET PAY" ||
        event.target.props.title === "RATE OFFER"
      ) {
        this.props.openSideBar();
      }
    } else if (event.target.props.route) {
      this.props.history.push(this.props.logoutUser(this.props.auth.user._id, this.props.history));
    }
  };
  render() {
    return (
      <React.Fragment>
        <div className="left-menu" >
          <a className="active" onClick={() => this.onSetSidebarOpen()}>
            <span className="k-icon k-i-reorder reorder-icon" style={{ marginTop: ".25em", color: "white", color: "#999" }} />
          </a>
          <Link to="/home/estimate-dashboard" className="active apollo-image">
            <img src={menuLogo} alt="Empty" width="150px" height="52px" />
          </Link>
        </div>
        <PanelBar animation={true} onSelect={this.onSelect}>
          <PanelBarItem title="Hiring Team" >
            <PanelBarItem className="left-menu-sub-items" title={"ESTIMATE PAY"} route="/home/estimate-dashboard" />
            <PanelBarItem className="left-menu-sub-items" title={"CREATE OFFER"} route="/home/estimate-dashboard" />
            <PanelBarItem className="left-menu-sub-items" title={"ROLES"} route="/home/role" />
            <PanelBarItem className="left-menu-sub-items" title={"PROFILE"} route="/home/my-profile" />
            {/* <PanelBarItem className="left-menu-sub-items" title={"Change Password"} route="/home/change-password" /> */}
          </PanelBarItem>
          <PanelBarItem title="Personal Dash" animation={true} >
            <PanelBarItem className="left-menu-sub-items" title={"TARGET PAY"} route="/home/personal-dashboard" />
            <PanelBarItem className="left-menu-sub-items" title={"RATE OFFER"} route="/home/personal-dashboard" />
            <PanelBarItem className="left-menu-sub-items" title={"COMPANY OFFERS"} route="/home/my-offers" />
            <PanelBarItem className="left-menu-sub-items" title={"EXPLORE"} route="/home/explore-dashboard" />
            {/* <PanelBarItem className="left-menu-sub-items" title={"Candidate Dashboard"} route="/home/candidate-dashboard" /> */}
            <PanelBarItem className="left-menu-sub-items" title={"PROFILE"} route="/home/my-job-profile" />
          </PanelBarItem>
          <PanelBarItem title={"Power USER"} animation={true} route="/home/estimate-dashboard" >
          </PanelBarItem>
          <PanelBarItem title="Admin" >
            <PanelBarItem className="left-menu-sub-items" title={"COMPANY"} route="/home/lookup-company" />
            <PanelBarItem className="left-menu-sub-items" title={"MARKET SPACE"} route="/home/lookup-marketspace" />
            <PanelBarItem className="left-menu-sub-items" title={"LEVEL"} route="/home/lookup-titlefunction" />
            <PanelBarItem className="left-menu-sub-items" title={"FUNCTION"} route="/home/lookup-function" />
            <PanelBarItem className="left-menu-sub-items" title={"SCHOOL"} route="/home/lookup-school" />
            <PanelBarItem className="left-menu-sub-items" title={"DEGREE"} route="/home/lookup-education" />
          </PanelBarItem>
          <PanelBarItem title={"Logout"} >
          </PanelBarItem>
        </PanelBar>
      </React.Fragment>
    )
  }
}

SidebarContent.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

const mapStateToProps = state =>
  ({
    auth: state.auth,
    estimateDashboard: state.estimateDashboard
  });
export default connect(
  mapStateToProps,
  { logoutUser, offerDashAddMemeberIconAction, offerDashRoleInsertFlagAction, offerDashRightSideDashboardAction }
)(withRouter(SidebarContent));

// export default withRouter(SidebarContent);
