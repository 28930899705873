import {
  SET_EDUCATION,
  ROLES_LOADING,
  GET_EDUCATION
} from "../actions/types";

const initialState = {
  role: {},
  adminLookupEducationdata: null,
  loading: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ROLES_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_EDUCATION:
      return {
        ...state,
        adminLookupEducationdata: action.payload,
        loading: true
      };

    default:
      return state;
  }
}
