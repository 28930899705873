import React from 'react'
import '../../css/dashboard.css';
import { connect } from "react-redux";
import { ComboBox, DropDownList, AutoComplete } from '@progress/kendo-react-dropdowns';
import { Input } from '@progress/kendo-react-inputs';
import {
    getTitleLookUpData,
    getFunctionLookUpData,
    getgraphAPIListMarketSpaceLookUpData,
    getCompanyLocationRegionLookUpData,
    getFunctionBasedOnTitle,
    getCompanyDetailsBasedOnCompanyName

} from "../../actions/lookupAction";
import { inputValueAction, offerDashPercentageHiddenAction, offerDashAddMemeberIconAction } from "../../actions/dashboard";
import { fnEstimateDashboard, fnComparisonCompensationEstimateDashboard, offerDashRoleInsertFlagAction } from "../../actions/dashboard";
import constValues from './dashboard.json';
import { fnGetNewHires } from "../../actions/roleActions";
import { FnInsertRole } from "../../actions/roleActions";
import {
    getHRDetails
} from "../dropdown/lookups";
import { titlesDataSort, functionalityDataSort, regionDataSort } from "../dropdown/lookupSortingData";
import { Button } from '@progress/kendo-react-buttons';
import Spinner from "../spinner/loading";

class DashBoardInputs extends React.Component {
    constructor(props) {
        super(props);
        this.props.getTitleLookUpData();
        this.props.getFunctionLookUpData();
        this.props.getgraphAPIListMarketSpaceLookUpData();
        this.props.getCompanyLocationRegionLookUpData();
        this.state = {
            loading: true,
            Title: this.props.value.Title,
            Function: "Engineering", // this.props.value.Function,
            role_years: this.props.value.role_years ? this.props.value.role_years : "3 - 5",
            base_percentile: "75", // this.props.value.base_percentile,
            comparison_benchmark_percentile: "75",
            isBasePercentileFieldVisible: false,
            pick_benchMark: "60th percentile",
            Company: this.props.value.Company,
            MarketSpace: this.props.value.MarketSpace,
            Region: this.props.value.Region,
            Stage: this.props.value.Stage,
            comparison_benchmark_hard_code_value: "50th Percentile",
            company_region_lookup_data: []
        };
        this.props.offerDashPercentageHiddenAction(this.state.Stage)
        if (this.state.pick_benchMark === "60th percentile") {
            this.props.inputValueAction("pick_benchMark", 64);
        }
    }
    componentDidMount() {
        if (this.props.location.search !== "") {
            fnGetNewHires(this.props.location.search.slice(9)).then(response => {
                this.state.Company = response.data.response.role_company
                this.state.MarketSpace = response.data.response.role_market_space
                this.state.Region = response.data.response.role_region
                this.state.Stage = response.data.response.role_stage
                this.state.Title = response.data.response.role_title
                this.state.Function = response.data.response.role_function
                if (response.data) {
                    this.setState({
                        Company: response.data.response.role_company,
                        Stage: response.data.response.role_stage,
                        Region: response.data.response.role_region,
                        MarketSpace: response.data.response.role_market_space,
                        // Title: response.data.response.role_title,
                        Function: response.data.response.role_function,
                        marketSpaceLookupData: this.props.lookups.marketSpaceLookUp.data,
                        regionLookupData: this.state.company_region_lookup_data,
                        stageLookupData: this.props.lookups.stageLookUp.data,
                        loading: false
                    })

                }
                const userJobIdDashBoardData = {
                    job_id: this.props.user_id.user_id,
                    Title: response.data.response.role_title,
                    Function: "Engineering",                                  // response.data.response.role_function,
                    role_years: this.props.value.role_years,
                    base_percentile: (this.state.base_percentile / 100).toString(),
                    MarketSpace: response.data.response.role_market_space,
                    Company: response.data.response.role_company,
                    Region: response.data.response.role_region,
                    Stage: response.data.response.role_stage
                }
                this.props.value.Title = response.data.response.role_title
                this.props.value.Function = "Engineering"                    // response.data.response.role_function
                this.props.offerDashPercentageHiddenAction(response.data.response.role_stage)
                this.props.fnComparisonCompensationEstimateDashboard(userJobIdDashBoardData)
            }).catch(error => console.log(error));
        } else {
            getHRDetails(this.props.auth.user._id).then(response => {
                if (response.data.msg.company_name) {
                    let companyName = response.data.msg.company_name
                    getCompanyDetailsBasedOnCompanyName(companyName)
                        .then(companyResponse => {
                            let employeeData = (companyResponse) ? companyResponse.data.data[0].employees : ""
                            let userDashBoardData = {}

                            if (response.data.user.overall_experience !== undefined) {
                                if (response.data.user.overall_experience.title_level === "") {
                                    this.state.Company = response.data.msg.company_name
                                    this.state.Title = response.data.msg.company_title
                                    this.state.Function = response.data.msg.hiring_role
                                    this.state.MarketSpace = response.data.msg.company_market_space
                                    this.state.Region = response.data.msg.role_region
                                    this.state.Stage = response.data.msg.company_stage
                                    this.state.employees = employeeData
                                    userDashBoardData = {
                                        Title: response.data.msg.company_title,
                                        Function: response.data.msg.hiring_role,
                                        role_years: this.props.value.role_years,
                                        base_percentile: (this.state.base_percentile / 100).toString(),
                                        MarketSpace: response.data.msg.company_market_space,
                                        Company: response.data.msg.company_name,
                                        Region: response.data.msg.role_region,
                                        Stage: response.data.msg.company_stage,
                                        employees: this.state.employees
                                    }
                                    this.state.loading = false
                                } else {
                                    this.state.Company = response.data.msg.company_name
                                    this.state.Title = response.data.msg.company_title
                                    this.state.Function = response.data.msg.hiring_role
                                    this.state.MarketSpace = response.data.msg.company_market_space
                                    this.state.Region = response.data.msg.role_region
                                    this.state.Stage = response.data.msg.company_stage
                                    this.state.employees = employeeData

                                    userDashBoardData = {
                                        // Title: response.data.user.overall_experience.title_level,
                                        Title: response.data.msg.company_title,
                                        Function: "Engineering",                          //response.data.msg.hiring_role,
                                        // Function: response.data.user.overall_experience.job_function,
                                        role_years: "0-2",
                                        base_percentile: (this.state.base_percentile / 100).toString(),
                                        MarketSpace: response.data.msg.company_market_space,
                                        Company: response.data.msg.company_name,
                                        Region: response.data.msg.role_region,
                                        Stage: response.data.msg.company_stage,
                                        employees: this.state.employees
                                    }
                                    this.state.loading = false
                                }
                                this.props.value.Title = response.data.user.overall_experience.title_level
                                this.props.value.Function = "Engineering"                  //response.data.user.overall_experience.job_function
                                this.props.offerDashPercentageHiddenAction(this.state.Stage)
                                this.props.fnComparisonCompensationEstimateDashboard(userDashBoardData)
                            } else {
                                this.state.Company = response.data.msg.company_name
                                this.state.Title = response.data.msg.company_title
                                this.state.Function = response.data.msg.hiring_role
                                this.state.MarketSpace = response.data.msg.company_market_space
                                this.state.Region = response.data.msg.role_region
                                this.state.Stage = response.data.msg.company_stage
                                this.state.marketSpaceLookupData = this.props.lookups.marketSpaceLookUp.data
                                this.state.regionLookupData = this.state.company_region_lookup_data
                                this.state.employees = employeeData

                                let userDashBoardData2 = {
                                    Title: response.data.msg.company_title,
                                    Function: "Engineering",                              //response.data.msg.hiring_role,
                                    role_years: this.props.value.role_years,
                                    base_percentile: (this.state.base_percentile / 100).toString(),
                                    MarketSpace: response.data.msg.company_market_space,
                                    Company: response.data.msg.company_name,
                                    Region: response.data.msg.role_region,
                                    Stage: response.data.msg.company_stage,
                                    employees: this.state.employees
                                }
                                this.props.value.Title = this.state.Title
                                this.props.value.Function = "Engineering"                 //userDashBoardData2.Function
                                this.props.offerDashPercentageHiddenAction(response.data.msg.company_stage)
                                this.props.fnComparisonCompensationEstimateDashboard(userDashBoardData2)
                                this.state.loading = false
                                // this.props.fnEstimateDashboard(userDashBoardData2);
                            }

                            if (response.data) {
                                this.setState({
                                    Company: response.data.msg.company_name,
                                    Stage: response.data.msg.company_stage,
                                    Region: response.data.msg.role_region,
                                    MarketSpace: response.data.msg.company_market_space,
                                    Title: response.data.msg.company_title,
                                    employees: this.state.employees,
                                    Function: "Engineering"                               // response.data.msg.hiring_role
                                })
                            }
                        });
                }
            }).catch(error => console.log(error));
        }

    }
    onChangeLineGraphData = e => {
        const value = e.target.value;
        this.state[e.target.name] = value;
        if (e.target.name === "comparison_benchmark_hard_code_value") {

            this.props.inputValueAction(e.target.name, 75);
            if (e.target.value === "75th Percentile") {
                this.state.comparison_benchmark_percentile = 75
                this.props.estimateDashboard.value = "75"
                if (this.state.pick_benchMark === "Custom percentile") {
                    this.setState({
                        isBasePercentileFieldVisible: true
                    });
                } else if (this.state.pick_benchMark === "Your company rank") {
                    this.setState({
                        isBasePercentileFieldVisible: true
                    });
                } else if (this.state.pick_benchMark === "Competition") {
                    this.setState({
                        isBasePercentileFieldVisible: false
                    });
                } else if (this.state.pick_benchMark === "60th percentile") {

                    this.setState({
                        isBasePercentileFieldVisible: false
                    });
                } else if (this.state.pick_benchMark === "") {
                    this.setState({
                        isBasePercentileFieldVisible: true
                    });
                }
            }
            if (e.target.value === "60th Percentile") {
                this.state.comparison_benchmark_percentile = 60
                this.props.estimateDashboard.value = "60"
                this.props.inputValueAction(e.target.name, 60);
                if (this.state.pick_benchMark === "Custom percentile") {
                    this.setState({
                        isBasePercentileFieldVisible: true
                    });
                } else if (this.state.pick_benchMark === "Your company rank") {
                    this.setState({
                        isBasePercentileFieldVisible: true
                    });
                } else if (this.state.pick_benchMark === "Competition") {
                    this.setState({
                        isBasePercentileFieldVisible: false
                    });
                } else if (this.state.pick_benchMark === "60th percentile") {
                    this.setState({
                        isBasePercentileFieldVisible: false
                    });
                } else if (this.state.pick_benchMark === "") {
                    this.setState({
                        isBasePercentileFieldVisible: true
                    });
                }
            }
            if (e.target.value === "50th Percentile") {
                this.state.comparison_benchmark_percentile = 50
                this.props.inputValueAction(e.target.name, 50);
                this.props.estimateDashboard.value = "50"
                if (this.state.pick_benchMark === "Custom percentile") {
                    this.setState({
                        isBasePercentileFieldVisible: true
                    });
                } else if (this.state.pick_benchMark === "Your company rank") {
                    this.setState({
                        isBasePercentileFieldVisible: true
                    });
                } else if (this.state.pick_benchMark === "Competition") {
                    this.setState({
                        isBasePercentileFieldVisible: false
                    });
                } else if (this.state.pick_benchMark === "60th percentile") {
                    this.setState({
                        isBasePercentileFieldVisible: false
                    });
                } else if (this.state.pick_benchMark === "") {
                    this.setState({
                        isBasePercentileFieldVisible: true
                    });
                }
            }
        }
        const userDashBoardData = {
            Title: this.state.Title,
            Function: this.state.Function,
            role_years: this.state.role_years,
            base_percentile: (this.state.base_percentile / 100).toString(),
            MarketSpace: this.props.inputValues.MarketSpace,
            Company: this.props.inputValues.Company,
            Region: this.props.inputValues.Region,
            Stage: this.props.inputValues.Stage,
            comparison_benchmark_percentile: this.state.comparison_benchmark_percentile,
            employees: this.state.employees
        }
        this.props.value.Title = this.state.Title
        this.props.value.Function = this.state.Function
        this.props.fnComparisonCompensationEstimateDashboard(userDashBoardData)
    }
    onChange = e => {
        if (this.state.loading === false) {
            this.state.loading = true
        }
        const value = e.target.value;
        this.state[e.target.name] = value;
        this.setState({
            [e.target.name]: value,
            titleLookupData: titlesDataSort(value, this.props.lookups.titleLookUp.data),
            functionalityLookupData: functionalityDataSort(value, this.props.lookups.functionLookUp.data),
            marketSpaceLookupData: this.props.lookups.marketSpaceLookUp.data,
            regionLookupData: this.state.company_region_lookup_data
        });

        this.props.inputValueAction(e.target.name, e.target.value);

        if (e.target.name === "Title") {
            const titleList = this.props.lookups.titleLookUp.data
            let titleName = e.target.value
            if (titleList.some(el => el === this.state.Title)) {
                getFunctionBasedOnTitle(titleName).then(response => {
                    let titleResultData = response.data.data
                    if (this.state.loading === false) {
                        this.state.loading = true
                    }
                    for (let i = 0; i < titleResultData.length; i++) {

                        if (titleResultData[i]["title"] === e.target.value) {
                            if (titleResultData[i]["function"] === undefined) {
                                this.setState({
                                    Function: this.state.Function
                                });
                            } else {
                                this.setState({
                                    Function: titleResultData[i]["function"]
                                });
                            }
                            // this.setState({
                            //   Function: titleResultData[i]["function"]
                            // });
                        }
                    }
                    const userDashBoardData = {
                        Title: this.state.Title,
                        Function: this.state.Function,
                        role_years: this.state.role_years,
                        base_percentile: (this.state.base_percentile / 100).toString(),
                        MarketSpace: this.props.inputValues.MarketSpace,
                        Company: this.props.inputValues.Company,
                        Region: this.state.Region,
                        Stage: this.props.inputValues.Stage,
                        employees: this.state.employees
                    }
                    this.props.value.Title = this.state.Title
                    this.props.value.Function = this.state.Function
                    if (this.state.Title != "") {
                        this.props.fnComparisonCompensationEstimateDashboard(userDashBoardData)
                        this.state.loading = false
                    }

                })
                    .catch(err => console.log("get function from title", err));
            }
            if (this.state.Title === "") {
                this.setState({
                    Function: ""
                });
            }
        }

        if (e.target.value === "Custom percentile") {
            this.props.inputValueAction(e.target.name, 50);
            this.state.base_percentile = "50"
            this.setState({
                isBasePercentileFieldVisible: true
            });
        } else if (e.target.value === "Your company rank") {
            this.props.inputValueAction(e.target.name, 0);
            this.state.base_percentile = ""
            this.setState({
                isBasePercentileFieldVisible: true
            });
        } else if (e.target.value === "Competition") {
            this.props.inputValueAction(e.target.name, 64);
            this.state.base_percentile = "64"
            this.setState({
                isBasePercentileFieldVisible: false
            });
        } else if (e.target.value === "60th percentile") {
            this.props.inputValueAction(e.target.name, 60);
            this.state.base_percentile = "60"
            this.setState({
                isBasePercentileFieldVisible: false
            });
        }
        if (e.target.name !== "MarketSpace" && e.target.name !== "role_years") {
            const userDashBoardData = {
                Title: this.state.Title,
                Function: this.state.Function,
                role_years: this.state.role_years,
                base_percentile: (this.state.base_percentile / 100).toString(),
                MarketSpace: this.props.inputValues.MarketSpace,
                Company: this.props.inputValues.Company,
                Region: this.state.Region,
                Stage: this.props.inputValues.Stage,
                employees: this.state.employees
            }
            this.props.value.Title = this.state.Title
            this.props.value.Function = this.state.Function

            const titleList = this.props.lookups.titleLookUp.data
            if (titleList.some(el => el === this.state.Title && this.state.Function != "")) {
                this.props.fnComparisonCompensationEstimateDashboard(userDashBoardData)
            }
            setTimeout(
                function () {
                    this.setState({ loading: false });
                }
                    .bind(this),
                1000
            );

            // this.props.fnEstimateDashboard(userDashBoardData);
        }
        if (e.target.name === "MarketSpace" || e.target.name === "role_years") {
            this.state.loading = false
        }
    }


    onInviteMemberClick = () => {
        const userData = {
            user_id: this.props.auth.user._id,
            role_company: this.state.Company,
            role_stage: this.state.Stage,
            role_market_space: this.state.MarketSpace,
            role_region: this.state.Region,
            role_status: "Open",
            role_title: this.state.Title,
            role_function: this.state.Function,
            compensation_total_high: this.props.estimateDashboard.estimateDashboard.data.offer['total'],
            compensation_base_high: this.props.estimateDashboard.estimateDashboard.data.offer['base'],
            compensation_bonus_high: this.props.estimateDashboard.estimateDashboard.data.offer['bonuses'],
            compensation_equity_high: this.props.estimateDashboard.estimateDashboard.data.offer['equity'],
        }

        this.props.FnInsertRole(userData, this.props.history, "inviteMember");
        this.props.offerDashRoleInsertFlagAction(1)
        this.props.offerDashAddMemeberIconAction(2)
    }

    onFilterChange = (event) => {
        var changeRegionLkpData = this.props.lookups.companyRegionLookUp.filter((el, index) =>
            this.props.lookups.companyRegionLookUp.indexOf(el) === index
        );
        this.setState({
            regionLookupData: regionDataSort(event.filter.value,
                changeRegionLkpData.sort())
        })
    }
    render() {

        if (this.props.estimateDashboard.name === "pick_benchMark" || this.props.estimateDashboard.name === "donutarrow") {

            this.state.base_percentile = this.props.estimateDashboard.value
        }
        if (this.props.lookups.marketSpaceLookUp.data !== undefined) {
            this.state.marketSpaceLookupData = this.props.lookups.marketSpaceLookUp.data.sort().filter(function (el) {
                return el != "";
            });
        }
        if (this.props.lookups.companyRegionLookUp.length !== undefined) {
            const company_region_lookup_data = this.props.lookups.companyRegionLookUp.sort().filter(function (el) {
                return el != "";
            });
            company_region_lookup_data.map(regionLookupUniqueData => {
                if (this.state.company_region_lookup_data.indexOf(regionLookupUniqueData) === -1) {
                    this.state.company_region_lookup_data.push(regionLookupUniqueData)
                }
                let location_data = this.state.company_region_lookup_data
                this.state.company_region_lookup_data = location_data.filter(function (element) {
                    return element !== undefined;
                });
            });
        }

        return (
            <div> {this.state.loading === true ? <Spinner /> : null}
                <div className="row" style={{
                    paddingTop: "0px", marginTop: "10px", paddingLeft: "5px",
                    paddingBottom: "0px", marginBottom: "-35px"
                }}>
                    <div className="col-md-8  col-sm-6 col-xs-6 col-lg-8 paddingRight" style={{ fontSize: "18px" }} >

                    </div>
                    <div className="col-md-4 col-sm-6 col-xs-6 col-lg-4 paddingRight" style={{ marginTop: "0px", textAlign: "center" }}>
                        <Button className="buttons" onClick={this.onInviteMemberClick} style={{ color: "#3b3f4f", border: "2px solid #3b3f4f", borderRadius: "50px", marginRight: "-50px", fontWeight: "bold" }}>
                            Rate < i className="fa fa-hand-pointer-o" aria-hidden="true" style={{ fontSize: "25px", marginLeft: "10px" }}></i>
                        </Button>
                    </div>
                </div>
                <div className="row dashboard-input-wrapper" style={{
                    paddingTop: "0px", marginTop: "48px", paddingLeft: "5px"
                }}>


                    <div className="col-md-3 paddingRight" >
                        <AutoComplete
                            name="Company"
                            onChange={this.onChange}
                            label="Company"
                            value={this.state.Company}
                            style={{ width: '100%' }}
                            className="inputDotClass"
                            disabled
                        />
                    </div>
                    <div className="col-md-3 paddingRight">
                        <DropDownList
                            name="MarketSpace"
                            onChange={this.onChange}
                            data={this.state.marketSpaceLookupData}
                            label="Market Space"
                            value={this.state.MarketSpace}
                            style={{ width: '100%' }}
                            className="inputDotClass"
                        />
                    </div>
                    <div className="col-md-3 paddingRight">
                        <ComboBox
                            name="Stage"
                            onChange={this.onChange}
                            // data={this.state.stageLookupData}
                            label="Stage"
                            // disabled={this.state.disabled}
                            value={this.state.Stage}
                            style={{ width: '100%' }}
                            className="inputDotClass"
                            disabled
                        />
                    </div>
                    <div className="col-md-3 paddingRight">
                        <ComboBox
                            name="Region"
                            onChange={this.onChange}
                            data={this.state.regionLookupData}
                            label="Location"
                            value={this.state.Region}
                            style={{ width: '100%' }}
                            className="inputDotClass"
                            onFilterChange={this.onFilterChange}
                            filterable={true}
                        />

                    </div>
                </div>


                <div className="row dashboard-input-wrapper" style={{ paddingLeft: "5px" }}>
                    <div className="col-md-3 paddingRight">
                        <AutoComplete
                            name="Title"
                            onChange={this.onChange}
                            data={this.state.titleLookupData}
                            label="Title/Level"
                            value={this.state.Title}
                            style={{ width: '100%' }}
                            className="inputDotClass"
                        />
                    </div>
                    <div className="col-md-3 paddingRight">
                        <AutoComplete
                            name="Function"
                            onChange={this.onChange}
                            data={this.state.functionalityLookupData}
                            label="Function"
                            value={this.state.Function}
                            style={{ width: '100%' }}
                            className="inputDotClass"
                        />

                    </div>
                    <div className="col-md-3 paddingRight">
                        <ComboBox
                            name="role_years"
                            onChange={this.onChange}
                            data={constValues.roleYears}
                            value={this.state.role_years}
                            label="Role Yrs"
                            style={{ width: '100%' }}
                        />

                    </div>
                    <div className="col-md-3 paddingRight">
                        <DropDownList
                            name="pick_benchMark"
                            data={constValues.benchMark}
                            label="Pick Benchmark"
                            onChange={this.onChange}
                            value={this.state.pick_benchMark}
                            style={{ width: '100%' }

                            }
                        />
                    </div>
                    <div className="col-md-3"></div>
                    <div className="col-md-3"></div>
                    <div className="col-md-3"></div>
                    {this.state.isBasePercentileFieldVisible ? <div className="col-md-3 paddingRight" style={{ marginTop: "20px" }}>

                        <Input
                            name="base_percentile"
                            type="text"
                            onChange={this.onChange}
                            label="Custom Percentile"
                            value={this.state.base_percentile}
                            style={{ width: '100%' }}
                            className="inputDotClass"
                        />
                    </div> : false}
                    <br />
                    <div className="row col-md-12" style={{
                        marginTop: "50px"
                    }}>
                        < div className="col-md-6 col-sm-12 col-xs-12 col-lg-6 paddingRight" style={{ float: "right", textAlign: "right", paddingTop: "28px", fontSize: "16px" }}>Comparison Benchmark: </div>
                        <div className="col-md-2 col-sm-12 col-xs-12 col-lg-2 paddingRight dropdown-label-width" style={{ display: "flex", flexDirection: "row", marginTop: "10px", paddingLeft: "5px" }}>
                            <DropDownList
                                name="comparison_benchmark_hard_code_value"
                                onChange={this.onChangeLineGraphData}
                                data={constValues.comparisonBenchmark}
                                value={this.state.comparison_benchmark_hard_code_value}
                                style={{ width: "120%" }}
                                className="dropdownDotClass2"
                            />
                        </div>

                    </div>
                </div >
            </div >
        )
    }
}


const mapStateToProps = state =>
    ({
        lookups: state.lookups,
        auth: state.auth,
        role: state.role,
        estimateDashboard: state.estimateDashboard,
    });
export default connect(mapStateToProps, {
    getTitleLookUpData,
    getFunctionLookUpData,
    fnEstimateDashboard,
    inputValueAction,
    FnInsertRole,
    offerDashPercentageHiddenAction,
    offerDashAddMemeberIconAction,
    getgraphAPIListMarketSpaceLookUpData,
    getCompanyLocationRegionLookUpData,
    fnComparisonCompensationEstimateDashboard,
    offerDashRoleInsertFlagAction,
    getCompanyDetailsBasedOnCompanyName
}
)(DashBoardInputs);
