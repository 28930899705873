import React from 'react';
import './App.css';
import { withRouter } from 'react-router-dom';
import { Switch } from 'react-router-dom';
import { Route } from "react-router-dom";
import { Landing } from './components/landing/Landing.js';
import LinkedInAuthentication from './components/authentication/linkedInAuth.js';
import { Layout } from './components/layout/Layout.js'
import { NotFound } from './components/not-found/NotFound.js'
import LoginClass from "./components/authentication/login";
import RegisterClass from "./components/authentication/register";
import ForgotPassword from "./components/authentication/ForgotPassword";
import ResetPassword from "./components/authentication/ResetPassword";
import RegisterMasterClass from "./components/authentication/scriptMasterRegister";
import ResendEmail from "./components/authentication/ResendEmail";
import EmailVerified from "./components/authentication/email_verified";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUserLogin, logoutUser } from "./actions/authAction";
import store from "./store";

if (localStorage.jwtToken) {
  setAuthToken(localStorage.jwtToken);
  const payloads = localStorage.payload;
  const decoded = jwt_decode(localStorage.jwtToken);
  store.dispatch(setCurrentUserLogin(decoded, payloads));
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(logoutUser());
    window.location.href = "/login";
  }
}
class App extends React.Component {
  render() {
    return (
      <div className="App">
        <Switch>
          <Route exact path="/" component={Landing} />
          <Route exact path='/login' component={LoginClass} />
          <Route exact path='/register' component={RegisterMasterClass} />
          <Route exact path='/register-with-email' component={RegisterClass} />
          <Route exact path='/forgot-password' component={ForgotPassword} />
          <Route exact path='/verify-email' component={ResendEmail} />
          <Route exact path='/:email/resetPassword/:id' component={ResetPassword} />
          <Route exact path='/emailVerification/:email/:systemUniqueKey/' component={EmailVerified} />
          <Route exact path='/authkeylogin/:email/:key/' component={EmailVerified} />
          <Route
            path="/home"
            component={Layout}
          />
          <Route
            path="/secureLogin/:token/:profile_updated/:email_verified/:first_name/:last_name"
            component={LinkedInAuthentication}
          />
          <Route component={NotFound} />
        </Switch>
      </div>
    );
  }
}

export default withRouter(App);
