import React from 'react';
import { GridCell } from '@progress/kendo-react-grid';
import { Link } from "react-router-dom";
import '@progress/kendo-ui';
import OfferDashUpdateCandidateButton from './offerDashboardUpdateCandidate';
import {
    fnDeleteCandidateFromOfferDashboard, fnGetAllCandidatesFromOfferDashboard
} from "../../../../actions/roleCandidateAction";

export default function MyOffergridbutttons(editField, props) {
    return class extends GridCell {
        deleteCandidate = () => {
            let id = this.props.dataItem["_id"]
            let new_hire_id = this.props.dataItem.new_hire_id
            let delete_query = {
                new_hire_id: new_hire_id,
                offer_candidate_id: id
            }

            fnDeleteCandidateFromOfferDashboard(delete_query).then(response => {
                this.setState({
                    success: response.data.message
                })
                let query = {
                    new_hire_id: this.props.dataItem.new_hire_id,
                    hr_id: props.auth.user._id
                }
                props.fnGetAllCandidatesFromOfferDashboard(query);
                // setTimeout(() => {
                //     let query = {
                //         new_hire_id: this.props.dataItem.new_hire_id,
                //         hr_id: props.auth.user._id
                //     }
                //     props.fnGetAllCandidatesFromOfferDashboard(query);
                // }, 500)
            }
            ).catch(error => console.log(error));
        }
        render() {
            const UPDATE_URL = "/home/estimate-dashboard?user_id=" + this.props.dataItem.new_hire_id + "&candidate_id=" + this.props.dataItem["_id"]


            return !this.props.dataItem[editField]
                ? (
                    <td>
                        <Link type="button"
                            to={UPDATE_URL}
                            title="Edit"
                            className="k-button" >
                            <span data-toggle="modal" data-target="#myModal1" className="k-icon k-i-edit .k-i-pencil" UPDATE_URL={UPDATE_URL}></span>
                        </Link>



                        <div type="button"
                            title="Delete"
                            className="k-button"
                            onClick={this.deleteCandidate}>
                            <span className="k-icon k-i-delete .k-i-trash">
                            </span>
                        </div>
                    </td>
                )
                : null;
        }
    }

};

