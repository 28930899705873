import React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { AutoComplete } from '@progress/kendo-react-dropdowns';
import { NumericTextBox } from '@progress/kendo-react-inputs';
import { Input } from '@progress/kendo-react-inputs';
import '../../../css/Addcandidate_form.css';
import { Link } from "react-router-dom";
import FormValidator from '../../../validation/FormValidator'
import validationParams from '../../../validation/validationParams.json'
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getTitleLookUpData, 
         getFunctionLookUpData, 
         getCompanyLocationRegionLookUpData,
         getStageLookUpData, 
         getgraphAPIListMarketSpaceLookUpData, 
         getcompanyLocationNoOfEmployeesLookUpData,
         getCompanyRelatedData,
        } from "../../../actions/lookupAction";
import { titlesDataSort, 
         functionalityDataSort, 
         cityDataSort, 
         stateDataSort,
         regionDataSort, 
         stageDataSort, 
         marketSpaceDataSort, 
         employeesDataSort, 
         companyForEmployessDataSort 
       } from "../../dropdown/lookupSortingData";
import { FnInsertRoleCandidates, fnGetRoleCandidateMember, FnUpdateRoleCandidateMembers } from "../../../actions/roleCandidateAction"

export class AddnewCandiadateform extends React.Component {
  constructor(props) {
    super(props);
    this.validator = new FormValidator(validationParams.AddnewCandidateformValidations);
    this.state = {
      success: false,
      first_name: "",
      last_name: "",
      email: "",
      message: "",
      base_salary: "",
      annual_bonus: "",
      commission: "",
      equity_percent: "",
      equity_dollar_value: "",
      total_dollar_value: "",
      candidate_accepted: "",
      company: "",
      stage: "",
      region: "",
      company_revenue: "",
      market_space: "",
      employees_count: "",
      location_city: "",
      location_state: "",
      validation: this.validator.valid()
    };
    this.submitted = false;
    this.CandidateFormSubmit = this.CandidateFormSubmit.bind(this);

    this.props.getTitleLookUpData();
    this.props.getFunctionLookUpData();
    this.props.getCompanyLocationRegionLookUpData();
    this.props.getStageLookUpData();
    this.props.getCompanyRelatedData();
    this.props.getgraphAPIListMarketSpaceLookUpData();
    this.props.getcompanyLocationNoOfEmployeesLookUpData();

    if (this.props.match.params.new_hire_id) {

      const getRoleCandidateData = {
        role_candidate_id: this.props.match.params.role_candidate_id,
        new_hire_id: this.props.match.params.new_hire_id
      };

      fnGetRoleCandidateMember(getRoleCandidateData).then(response => {
        const userData = response.data.new_hire;
        const candidateData = response.data.candidate_user_data;
        const HrData = response.data.hiring_role_candidate.company_offer[0];
        const candidate_id = response.data.hiring_role_candidate.candidate_user_id;
        this.setState({
          first_name: candidateData.first_name,
          last_name: candidateData.last_name,
          email: candidateData.email,
          base_salary: HrData['base_value'],
          annual_bonus: HrData['annual_bonus_value'],
          equity_dollar_value: HrData['equity_dollar_value'],
          total_dollar_value: HrData['total_dollar_value'],
          candidate_accepted: HrData['candidate_accepted'],
          location_state: candidateData.location_state,
          location_city: candidateData.location_city,
          title_level: candidateData.overall_experience.title_level,
          job_function: candidateData.overall_experience.job_function,
          equity_type_rsu: HrData['equity_type_rsu'],
          equity_type_stock: HrData['equity_type_stock'],
          candidate_user_id: candidate_id
        });
      }).catch(error => console.log(error));
    }
  }
  onChange = e => {
    const value = e.target.value;
    this.setState({
      [e.target.name]: e.target.value,
      stateLookupData: stateDataSort(value, this.props.lookups.companyStatesLookUp),
      cityLookupData: cityDataSort(value, this.props.lookups.companyLocationLookUp),
      titleLookupData: titlesDataSort(value, this.props.lookups.titleLookUp.data),
      functionalityLookupData: functionalityDataSort(value, this.props.lookups.functionLookUp.data),
      stageLookupData: stageDataSort(value, 
      this.props.lookups.stageLookUp.data),
      regionLookupData: regionDataSort(value, 
      this.props.lookups.companyRegionLookUp),
      marketSpaceLookupData: marketSpaceDataSort(value, this.props.lookups.marketSpaceLookUp.data),
      employeeLookupData: employeesDataSort(value, this.props.lookups.noOfEmployeesLookUp),
      companyForEmployesLookupData: companyForEmployessDataSort(value, this.props.lookups.companyForEmployeesLookUp)
    });

    if (e.target.name === "location_city") {
      const locationregioncity = this.props.lookups.locationRegiongCityLookUp;
      for (let i = 0; i < locationregioncity.length; i++) {
        if (locationregioncity[i]["location_city"] === e.target.value) {
          this.setState({
            location_state: locationregioncity[i]["location_state"],
            role_region: locationregioncity[i]["region"]
          });
        }
      }
    }

    if (e.target.name === "company") {
      const companyRelatedDataLookup = this.props.lookups.companyRelatedData;
      for (let i = 0; i < companyRelatedDataLookup.length; i++) {
        if (companyRelatedDataLookup[i]["company"] === e.target.value) {
          this.setState({
            company: companyRelatedDataLookup[i]["company"],
            market_space: companyRelatedDataLookup[i]["marketspace"],
            region: companyRelatedDataLookup[i]["region"],
            employees_count: companyRelatedDataLookup[i]["employees"],
            stage: companyRelatedDataLookup[i]["stage"]
          });
          this.state.company_competitors01 = companyRelatedDataLookup[i].Competitors01
        }
      }
    }

  };

  toggleChange = () => {
    this.setState({
      equity_type_rsu: !this.state.equity_type_rsu,
    });
  }
  toggleChange2 = () => {
    this.setState({
      equity_type_stock: !this.state.equity_type_stock
    });
  }

  CandidateFormSubmit = (event) => {
    event.preventDefault();

    const userData = {
      hr_id: this.props.auth.user._id,
      new_hire_id: this.props.match.params.id,
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      email: this.state.email,
      location_state: this.state.location_state,
      location_city: this.state.location_city,
      experience: {
        company: this.state.company,
        market_space: this.state.market_space,
        stage: this.state.stage,
        employees_count: this.state.employees_count,
        region: this.state.region
      },
      overall_experience: {
        title_level: this.state.title_level,
        job_function: this.state.job_function,
      },
      salary: {
        base_salary: this.state.base_salary,
        annual_bonus: this.state.annual_bonus,
        commission: this.state.commission,
        equity_percent: this.state.equity_percent,
        equity_dollar_value: this.state.equity_dollar_value,
        total_dollar_value: this.state.total_dollar_value,
        candidate_accepted: this.state.candidate_accepted,
        equity_type_rsu: this.state.equity_type_rsu,
        equity_type_stock: this.state.equity_type_stock,
      }
    };

    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;
    if (validation.isValid) {
      if (this.props.match.params.new_hire_id) {
        const updateData = {
          hr_id: this.props.auth.user._id,
          id: this.state.candidate_user_id,
          new_hire_id: this.props.match.params.new_hire_id,
          first_name: this.state.first_name,
          last_name: this.state.last_name,
          email: this.state.email,
          location_state: this.state.location_state,
          location_city: this.state.location_city,
          overall_experience: {
            title_level: this.state.title_level,
            job_function: this.state.job_function,
          },
          salary: {
            base_salary: this.state.base_salary,
            annual_bonus: this.state.annual_bonus,
            equity_dollar_value: this.state.equity_dollar_value,
            total_dollar_value: this.state.total_dollar_value,
            candidate_accepted: this.state.candidate_accepted,
            equity_type_rsu: this.state.equity_type_rsu,
            equity_type_stock: this.state.equity_type_stock,
          }
        };

        this.props.FnUpdateRoleCandidateMembers(updateData, this.props.history, this.props.match.params.new_hire_id)
      } else {
        this.props.FnInsertRoleCandidates(userData, this.props.history, this.props.match.params.id)
      }


    }
    setTimeout(() => {
      this.setState({
        message: ""
      });
    }, 2000);
  }
  render() {
    if(this.state.location_city === "") {
      this.state.location_state = ""
    }
    
    if (this.props.match.params.new_hire_id) {
      let TotalDollarvalue = parseInt(this.state.base_salary) + 
                             parseInt(this.state.annual_bonus) +
                             parseInt(this.state.equity_dollar_value) +
                             parseInt(this.state.commission)
      this.state.total_dollar_value = TotalDollarvalue;
    } else {
      let TotalDollarvalue = this.state.base_salary + 
                             this.state.annual_bonus +
                             this.state.equity_dollar_value +
                             this.state.commission
      this.state.total_dollar_value = TotalDollarvalue;
    }
    let CANCEL_BUTTON = "/home/add-candidate?id=" + this.props.match.params.id;
    if (this.props.match.params.new_hire_id) {
      CANCEL_BUTTON = "/home/add-candidate?id=" + this.props.match.params.new_hire_id;
    }
    let validation = this.submitted ?
      this.validator.validate(this.state) :
      this.state.validation
    return (
      <div>
        <div className="container-fluid card-layout">

          <div className="row">
            <div className="col-sm-12 col-md-12 col-xs-12 col-lg-12">
              <form className="k-form" onSubmit={this.CandidateFormSubmit}>
                <fieldset>
                  {this.props.match.params.new_hire_id ? <h1 className="h1s">Update Candidate to New Role</h1> : <h1 className="h1s"> Add Candidate to New Role</h1>}
                  
                  <h3 className="h3details"> CANDIDATE DETAILS </h3>
                  <div className="mb-3 row" >
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="mb-3 col-md-5 col-sm-11 col-xs-11 col-lg-5" >
                      <Input
                        name="first_name"
                        type="text"
                        style={{ width: '100%' }}
                        label="First Name *"
                        value={this.state.first_name}
                        onChange={this.onChange}
                      />
                      <div style={{ color: "red" }}>
                        {validation.first_name.message}
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-1 col-xs-1 col-lg-6"></div>
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="mb-3 col-md-5 col-sm-11 col-xs-11 col-lg-5" >
                      <Input
                        name="last_name"
                        type="text"
                        onChange={this.onChange}
                        style={{ width: '100%' }}
                        label="Last Name *"
                        value={this.state.last_name}
                      />
                      <div style={{ color: "red" }}>
                        {validation.last_name.message}
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-1 col-xs-1 col-lg-6"></div>
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="mb-3 col-md-5 col-sm-11 col-xs-11 col-lg-5" >
                      <Input
                        name="email"
                        type="text"
                        style={{ width: '100%' }}
                        label="Email *"
                        value={this.state.email}
                        onChange={this.onChange}
                      />
                      <div style={{ color: "red" }}>
                        {validation.email.message}
                      </div>
                    </div>
                  </div>

                  <h3 className="h3location"> CANDIDATE LOCATION </h3>
                  <div className="mb-3 row" >
                  <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="mb-3 col-md-5 col-sm-11 col-xs-11 col-lg-5" >
                      <AutoComplete
                        name="location_city"
                        onChange={this.onChange}
                        data={this.state.cityLookupData}
                        value={this.state.location_city}
                        label="City"
                        style={{ width: '100%' }}
                      />
                    </div>
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="mb-3 col-md-5 col-sm-11 col-xs-11 col-lg-5" >
                      <AutoComplete
                        name="location_state"
                        onChange={this.onChange}
                        data={this.state.stateLookupData}
                        value={this.state.location_state}
                        label="State"
                        style={{ width: '100%' }}
                        disabled
                      />
                    </div>
                    
                  </div>
                  <h3 className="h3experience"> CANDIDATE EXPERIENCE</h3>
                  <div className="mb-3 row" >
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="mb-3 col-md-5 col-sm-11 col-xs-11 col-lg-5" >
                      <AutoComplete
                        name="title_level"
                        onChange={this.onChange}
                        data={this.state.titleLookupData}
                        value={this.state.title_level}
                        label="Title/Level"
                        style={{ width: '100%', color: "rgba(0,0,0,0.87)" }}
                      />
                    </div>
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="mb-3 col-md-5 col-sm-11 col-xs-11 col-lg-5" >
                      <AutoComplete
                        name="job_function"
                        onChange={this.onChange}
                        value={this.state.job_function}
                        data={this.state.functionalityLookupData}
                        label="Function"
                        style={{ width: '100%' }}
                      />
                    </div>
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                  </div>

                  <h3 className="h3experience"> COMPANY DATA</h3>
                  <div className="mb-3 row">
                <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                <div className="col-md-4 col-sm-11 col-xs-11 col-lg-4">
                  <AutoComplete
                    name="company"
                    onChange={this.onChange}
                    data={this.state.companyForEmployesLookupData}
                    value={this.state.company}
                    label="Company *"
                    style={{ width: '100%' }}
                  />
                  {/* <div style={{ color: "red", paddingLeft: "0px" }}>
                    {validation.company.message}
                  </div> */}
                </div>
                <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                <div className="col-md-4 col-sm-11 col-xs-11 col-lg-4">
                  <AutoComplete
                    name="stage"
                    onChange={this.onChange}
                    value={this.state.stage}
                    data={this.state.stageLookupData}
                    label="Stage *"
                    style={{ width: '100%' }}
                  />
                  {/* <div style={{ color: "red", paddingLeft: "0px" }}>
                    {validation.stage.message}
                  </div> */}
                </div>
                <div className="col-md-2 col-sm-1 col-xs-1 col-lg-2"></div>
              </div>

              <div className="mb-3 row">
                <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                <div className="col-md-4 col-sm-11 col-xs-11 col-lg-4">
                  <AutoComplete
                    name="region"
                    onChange={this.onChange}
                    required={true}
                    data={this.state.regionLookupData}
                    value={this.state.region}
                    disabled
                    label="Region"
                    style={{ width: '100%' }}
                  />
                </div>
                <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                <div className="col-md-4 col-sm-11 col-xs-11 col-lg-4">
                  <AutoComplete
                    name="market_space"
                    onChange={this.onChange}
                    value={this.state.market_space}
                    data={this.state.marketSpaceLookupData}
                    label="Market Space *"
                    style={{ width: '100%' }}
                  />
                  {/* <div style={{ color: "red", paddingLeft: "0px" }}>
                    {validation.market_space.message}
                  </div> */}
                </div>
                <div className="col-md-2 col-sm-1 col-xs-1 col-lg-2"></div>
              </div>

              <div className="mb-3 row">
                <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                <div className="col-md-4 col-sm-11 col-xs-11 col-lg-4">
                <AutoComplete
                    name="employees_count"
                    onChange={this.onChange}
                    value={this.state.employees_count}
                    data={this.state.employeeLookupData}
                    label="# of employees"
                    style={{ width: '100%' }}
                  />
                </div>
                <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                <div className="col-md-4 col-sm-11 col-xs-11 col-lg-4">
                  
                </div>
                <div className="col-md-2 col-sm-1 col-xs-1 col-lg-2"></div>
              </div>

              <h3 className="h3s">OFFER COMPENSATION </h3>
                <div className="mb-3 row">
                  <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                  <div className="col-md-5 col-sm-5 col-xs-5 col-lg-5">
                      <NumericTextBox
                        name="base_salary"
                        type="text"
                        spinners={false}
                        style={{ width: '50px', color: "rgba(0,0,0,0.87)" }}
                        onChange={this.onChange}
                        label="Base $*"
                        value={this.state.base_salary || null}
                      />
                      <div style={{
                        color: "red",
                      }}>
                        {validation.base_salary.message}
                      </div>
                    </div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <NumericTextBox
                        name="annual_bonus"
                        type="text"
                        spinners={false}
                        onChange={this.onChange}
                        style={{ width: '100%' }}
                        label="Bonus $"
                        value={this.state.annual_bonus || null}
                      />
                    </div>
                  </div>

                  <div className="mb-3 row">
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-5 col-xs-5 col-lg-5">
                      <NumericTextBox
                        name="commission"
                        type="text"
                        spinners={false}
                        style={{ width: '50px', color: "rgba(0,0,0,0.87)" }}
                        onChange={this.onChange}
                        label="Commission $*"
                        value={this.state.commission || null}
                      />
                      {/* <div style={{
                        color: "red",
                      }}>
                        {validation.commission.message}
                      </div> */}
                    </div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <NumericTextBox
                        name="equity_percent"
                        type="text"
                        spinners={false}
                        onChange={this.onChange}
                        style={{ width: '100%' }}
                        label="Equity %*"
                        value={this.state.equity_percent || null}
                      />
                      {/* <div style={{
                        color: "red",
                      }}>
                        {validation.equity.message}
                      </div> */}
                    </div>
                  </div>

                  <div className="mb-3 row">
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5" >
                      <NumericTextBox
                        name="equity_dollar_value"
                        type="text"
                        spinners={false}
                        style={{ width: '100%', color: "rgba(0,0,0,0.87)" }}
                        onChange={this.onChange}
                        label="Annual Equity Value $"
                        value={this.state.equity_dollar_value || null}

                      />
                    </div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5" >
                      <NumericTextBox
                        name="total_dollar_value"
                        type="text"
                        spinners={false}
                        disabled
                        onChange={this.onChange}
                        style={{ width: '100%' }}
                        label="Total Value $"
                        value={this.state.total_dollar_value || null}

                      />
                    </div>
                  </div>

                  <div className="row mb-3" >
                    <div className="col-md-1"></div>
                    <div className="col-md-3">Types of Equity</div>
                    <div className="mb-3 col-md-3 col-lg-3 col-sm-6 col-xs-6" id="checkbox2" style={{ marginBottom: "10px", width: "40%", paddingTop: "0px", marginRight: "25px" }}>
                      <input className="k-checkbox"
                        onChange={this.toggleChange} checked={this.state.equity_type_rsu} name="equity_type_rsu" type="checkbox" id="auth-2fa" />
                      <label className="k-checkbox-label" htmlFor="auth-2fa">  RSUs. </label>
                    </div>
                    <div className="col-md-5 col-lg-5 col-sm-6 col-xs-6" id="checkbox3" style={{ paddingLeft: "0px", paddingTop: "0px" }}>
                      <input className="k-checkbox" type="checkbox" name="equity_type_stock"
                        onChange={this.toggleChange2} checked={this.state.equity_type_stock} id="auth-2fb" />
                      <label className="k-checkbox-label" htmlFor="auth-2fb">Stock options </label>
                    </div>
                  </div>

                </fieldset>
                <div className="row" style={{marginTop: "-10%"}}>
                  <div className="col-md-4"></div>
                  <div className="col-md-2 col-xs-6 col-sm-6 col-lg-2">
                    <Button className="mt-3 col-md-12 col-xs-12 col-sm-12 col-lg-12" type="submit" primary={true} >Save</Button>
                  </div>
                  <div className="col-md-2 col-xs-6 col-sm-6 col-lg-2" style={{ border: "1px slid red" }}>
                    <Link type="button" to={CANCEL_BUTTON} className="k-button col-md-12 col-xs-12 col-sm-12 col-lg-12" style={{ marginTop: "16px" }}>CANCEL</Link>
                  </div>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div >
    );
  }
}
AddnewCandiadateform.propTypes = {
  auth: PropTypes.object.isRequired,
  FnInsertRoleCandidates: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  AddnewCandiadateform: state.AddnewCandiadateform,
  roleCandidate: state.roleCandidate,
  lookups: state.lookups,
  errors: state.errors,
  candidates: state.candidates
});
export default connect(mapStateToProps, 
  { getTitleLookUpData, 
    getFunctionLookUpData, 
    getStageLookUpData,
    getCompanyLocationRegionLookUpData, 
    FnInsertRoleCandidates, 
    fnGetRoleCandidateMember, 
    FnUpdateRoleCandidateMembers,
    getgraphAPIListMarketSpaceLookUpData, 
    getcompanyLocationNoOfEmployeesLookUpData,
    getCompanyRelatedData,
  })(AddnewCandiadateform);