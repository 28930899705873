import React from 'react'
import '../../../../css/dashboard.css';
import { connect } from "react-redux";
import 'hammerjs';
import { inputValueAction, offerDashDialogBoxAction } from "../../../../actions/dashboard";
import {
  fnSaveOfferFromCreateOfferDashboard,
  fnGetSavedCandidateOffers,
  offerCandidateFlagAction
} from "../../../../actions/roleHiringTeamMemberAction";
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Input } from '@progress/kendo-react-inputs';
import FormValidator from '../../../../validation/FormValidator';
import validationParams from '../../../../validation/validationParams.json';

export class OfferDashOfferSaveButton extends React.Component {
  constructor(props) {
    super(props);
    this.validator = new FormValidator(validationParams.saveOfferFromCandidateDashboard);
    this.state = {
      visible: true,
      name: '',
      message: "",
      validation: this.validator.valid()
    }
    this.submitted = false;
    this.SaveCandidateOffer = this.SaveCandidateOffer.bind(this);
  }

  toggleDialog = () => {
    this.setState({
      visible: !this.state.visible
    });
    this.props.offerDashDialogBoxAction(this.state.visible, false)

    let roleId = this.props.parentData.role_id === undefined ? this.props.parentData.hiring_role_ids : this.props.parentData.role_id
    this.props.history.push("/home/estimate-dashboard?user_id=" + roleId)
  }
  toggleDialogStable = () => {
    this.setState({
      visible: this.state.visible
    });
  }

  onChange = e => {
    let { value, name } = e.target;

    this.setState({
      [name]: value
    });
  }

  SaveCandidateOffer = (event) => {
    event.preventDefault();
    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;
    if (validation.isValid) {
      const userData = {
        hr_id: this.props.auth.user._id,
        hiring_role_id: this.props.parentData.role_id === undefined ? this.props.parentData.hiring_role_ids : this.props.parentData.role_id,
        name: this.state.name,
        base_salary: this.props.parentData.base,
        base_pctl: this.props.parentData.offer_base_percentile,
        bonus: this.props.parentData.bonus,
        bonus_pctl: this.props.parentData.offer_bonus_percentile,
        annual_equity_value: this.props.parentData.annual_equity,
        annual_equity_percentage: this.props.parentData.equity ? this.props.parentData.equity * 100 : this.props.parentData.equity,
        annual_equity_pctl: this.props.parentData.offer_Equity_percentile,
        total_dollar_value: this.props.parentData.base + this.props.parentData.bonus + this.props.parentData.annual_equity,
        offer_strength: this.props.parentData.offer_offerStrength ? this.props.parentData.offer_offerStrength : 0
      }

      fnSaveOfferFromCreateOfferDashboard(userData).then(response => {
        this.setState({
          message: response.data.message,
          visible: !this.state.visible
        });
        this.props.offerDashDialogBoxAction(this.state.visible, false)
        this.props.offerCandidateFlagAction(1)
        const queryData = {
          hiring_role_id: this.props.parentData.role_id,
          hr_id: this.props.auth.user._id
        }
        this.props.fnGetSavedCandidateOffers(queryData);
        if (response.data.message) {
          let roleId = this.props.parentData.role_id === undefined ? this.props.parentData.hiring_role_ids : this.props.parentData.role_id

          const queryData = {
            hiring_role_id: roleId,
            hr_id: this.props.auth.user._id
          }
          this.props.fnGetSavedCandidateOffers(queryData);

          this.props.history.push("/home/estimate-dashboard?user_id=" + roleId)
        }
      }).catch(error => console.log(error));
    }
    setTimeout(() => {
      this.setState({
        message: ""
      });
    }, 2000);
  }

  render() {
    if (this.props.estimateDashboard.togglename === "floppy_icon") {
      this.state.visible = true
    } else {
      this.state.visible = false
    }

    return (
      <div>
        {this.state.visible && <Dialog className="offer-save-div-modal">
          <div className="col-md-12 col-lg-12 col-sm-10 col-xs-10 offer-save-dialog-div" onClick={this.toggleDialogStable}>
            <div className="col-md-11 col-lg-11 col-sm-8 col-xs-8">
              Save Offer
            </div>
            <div className="col-md-1 col-lg-1 col-sm-4 col-xs-4" style={{ textAlign: "right" }}>
              <button className="offer-dash-offer-save-dialog-button" onClick={this.toggleDialog}>X</button>
            </div>
          </div>
          <div className="offer-save-dialog-input-div">
            <div className="col-md-12 col-sm-11 col-xs-11 col-lg-12 offer-save-input-field">
              <Input
                name="name"
                type="text"
                style={{ width: '100%', color: "rgba(0,0,0,0.87)" }}
                onChange={this.onChange}
                label="Name *"
                value={this.state.name}
              /></div></div>
          <div className="offer-save-buttons-div">
            <DialogActionsBar >
              <button className="k-button col-xs-6 col-sm-6" onClick={this.toggleDialog}>CANCEL</button>
              <button className="k-button col-xs-6 col-sm-6" onClick={this.SaveCandidateOffer}>SUBMIT</button>
            </DialogActionsBar>
          </div>
        </Dialog>}
      </div >

    )
  }
}
const mapStateToProps = state => ({
  auth: state.auth,
  estimateDashboard: state.estimateDashboard,
  errors: state.errors
});
export default connect(mapStateToProps, {
  inputValueAction,
  offerDashDialogBoxAction,
  fnGetSavedCandidateOffers,
  offerCandidateFlagAction
}
)(OfferDashOfferSaveButton);
