import {
  SET_CURRENT_CANDIDATE_MEMBERS,
  GET_ROLE_CANDIDATE_LOADING,
  GET_CANDIDATE_FROM_OFFER_DASHBOARD,
  GET_ROLE_CANDIDATE,
  GET_SELECTED_CANDIDATES_FLAG
} from "../actions/types";

const initialState = {
  candidates: {},
  candidatesData: null,
  candidatesRoleInfoData: null,
  RoleHrSuccessData: null,
  loading: false,
  offerCandidates: {},
  offerCandidatesData: null,
  changeCandidateLkpFlag:0
};

export default function (state = initialState, action) {

  switch (action.type) {
    case GET_ROLE_CANDIDATE_LOADING:
      return {
        ...state,
        loading: true
      };
    case SET_CURRENT_CANDIDATE_MEMBERS:
      return {
        ...state,
        candidates: action.payload,
        loading: true
      };
    case GET_ROLE_CANDIDATE:
      return {
        ...state,
        candidatesData: action.payload,
        candidatesRoleInfoData: [action.roleInfo],
        loading: true
      };
    case GET_CANDIDATE_FROM_OFFER_DASHBOARD:
      return {
        ...state,
        offerCandidatesData: action.payload,
        loading: true
      };
    case GET_SELECTED_CANDIDATES_FLAG:
        return {
          ...state,
          changeCandidateLkpFlag: action.flag,
          loading: true
        };
    default:
      return state;
  }
}
